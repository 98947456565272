// @ts-strict-ignore
import { getWeekOfMonth, isSameYear } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { zhCN } from 'date-fns/locale';
import { ShadedTextUtils } from 'phoenix/assets/lang/ShadedTextUtils';
import { AppliedAppTheme } from 'phoenix/constants/AppliedAppTheme';
import { PremiumProducts } from 'phoenix/constants/PremiumProducts';
import { GetPriceFormatInfo } from 'phoenix/hooks/UsePriceFormatInfo';
import { ApiTradeRequest } from 'phoenix/models/ApiTradeRequest';
import { AssetClass } from 'phoenix/models/AssetClasses/AssetClass';
import { GetAssetClassForSecurity } from 'phoenix/models/AssetClasses/useAssetClass';
import { TransferRequest } from 'phoenix/models/TransferRequest';
import { CryptoSymbol } from 'phoenix/redux/models/Crypto/CryptoSymbol';
import { FundingLimits } from 'phoenix/redux/models/Funding/FundingLimits';
import { FundingSource } from 'phoenix/redux/models/Funding/FundingSource';
import { SecurityMetadata } from 'phoenix/redux/models/Securities/SecurityMetadata';
import { Environment } from '../../constants';
import { IndustryGroups } from '../../constants/IndustryGroups';
import { SecurityTypes } from '../../constants/SecurityTypes';
import { OrderTypes } from '../../constants/Trade';
import { Account } from '../../redux/models/Accounts/Account';
import { SnexDocument } from '../../redux/models/Documents/SnexDocument';
import { FundingManagementLimits } from '../../redux/models/Funding/FundingManagementLimits';
import { FuturesSymbol } from '../../redux/models/Futures/FuturesSymbol';
import { OptionSymbol } from '../../redux/models/Options/OptionSymbol';
import { Order } from '../../redux/models/Orders/Order';
import { OptionsPutCallAbbreviation, OrderType, TradeableSecurityType, TradeRequest } from '../../redux/models/Trading/TradeRequest';
import { Transaction } from '../../redux/models/Transactions/Transaction';
import { SpokenJoin } from '../../util/ArrayMutations';
import { SafeFormat, SafeFormatLocale, SafeFormatToNow } from '../../util/DateFormatting';
import { DetermineOrderType } from '../../util/DetermineOrderType';
import { FormatNumber } from '../../util/FormatNumber';
import { DecodeSecurityMasterType } from '../../util/IsMutualFund';
import { QualifiedId } from '../../util/QualifiedId';
import { disclosures, proDisclosures } from './disclosures';
import { ActionSummaryProps, getActionSummary } from './helpers';
import { Dark, Light, ShadedTextList, Snex1LanguagePack } from './Snex1LanguagePack';

const DelayedParagraph = [{ text: 'Update frequency: 15-min delay' }];

const nums = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'];

export const Snex1Mandarin: Snex1LanguagePack = {
    funding: {
        delete: {
            title: '删除帐户',
            message: '您确定要删除此帐户吗？',
            submitButton: '删除帐户',
            cancelButton: '不，保留帐户'
        }
    },
    errorBoundary: {
        title: '哎呀！',
        sorryMessage: '抱歉！看起来出了些问题。',
        oopsSomethingWentWrong: '哎呀，出了点问题。',
        tryAgain: '重试',
        contactSupport: '联系客服',
        onboardingErrorOccurred: '发生错误',
        onboardingTryAgainOrContactSupport: '请重试，如果问题仍然存在，请联系支持人员'
    },
    welcome: {
        skipTour: '跳过游览',
        showMeAround: '带我四处看看！',
        pickLang: '请选择您最舒适的语言',
        successText: '您的帐户创建成功！',
        loadingText: '正在检查您的帐户状态...',
        errorText: `查找您的帐户时出现问题。您的帐户可能需要几分钟的时间才能初始化。如果 10 分钟后仍不可用，请联系我们的支持人员:`,
        noAccountsText: '您的帐户似乎尚未准备好。您的帐户可能需要几分钟的时间才能初始化。如果 10 分钟后仍不可用，请联系我们的支持人员:'
    },
    webTos: {
        title: '接受条款和条件',
        url: 'https://snex1storage.blob.core.windows.net/$web/docs/StoneX_Client_TC.pdf',
        linkText: '查看条款和条件',
        agreeButtonLabel: '我同意'
    },
    general: {
        at: '在',
        around: '大约',
        above: '以上',
        below: '以下',
        to: '到',
        ai: '人工智能',
        languageNameEnglish: 'Chinese Simplified',
        languageNameLocal: ' 简体中文',
        dateFnsLocale: zhCN,
        localeShort: 'zh',
        localeLong: 'zh-CN',
        submit: '提交',
        continue: '继续',
        acceptRateAndSubmit: '接受汇率并提交',
        acceptTermsAndSubmit: '接受条款并提交',
        submitting: '正在提交...',
        loading: '加载中',
        cancel: '取消',
        news: (showMI?: boolean) => `新闻${showMI ? '和市场情报' : ''}`,
        writtenInteger0Thru10: (n: number) => (n >= 0 && n <= 10 ? nums[n] : FormatNumber.toCommas(n)),
        unavailable: '不可用',
        open: '开启',
        done: '完成',
        none: '无',
        dismiss: '关闭',
        confirmAndContinue: '确认并继续',
        contactSupport: '联系客服',
        search: '搜索',
        trade: '交易',
        dateUnknown: '日期未知',
        unknown: '未知',
        unableToAuthenticate: '抱歉，我们无法对您进行身份验证',
        browseDataUnavailable: '抱歉，浏览数据目前不可用。',
        yesLeave: '是，离开',
        noStayHere: '否，留在这里',
        returningToStonex: '返回到StoneX One',
        aboutToReturnToStonex: '您即将返回到StoneX One。您确定吗？',
        months: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
        monthsShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        quantity: '数量',
        strikes: '期权连锁行使',
        shares: (n = 0) => (Math.abs(n) === 1 ? '股' : '股'),
        fundShares: (n = 0) => (Math.abs(n) === 1 ? '股' : '股'),
        contracts: (n = 0) => (Math.abs(n) === 1 ? '合约' : '合约'),
        bonds: (n = 0) => (Math.abs(n) === 1 ? '债券' : '债券'),
        coins: (n = 0) => (Math.abs(n) === 1 ? '硬币' : '硬币'),
        unit: (symbol: string, quantity = 0, typeHint: string = null) => {
            if (FuturesSymbol.IsFuturesSymbol(symbol)) return Snex1Mandarin.general.contracts(quantity);
            if (OptionSymbol.IsOptionSymbol(symbol) || DecodeSecurityMasterType(typeHint) === 'option') return Snex1Mandarin.general.contracts(quantity);
            if (CryptoSymbol.IsCryptoSymbol(symbol)) Snex1Mandarin.general.coins(quantity);
            const torchType = typeHint;
            if (torchType === 'Fixed Income' || torchType === 'Debt') return Snex1Mandarin.general.bonds(quantity);
            return Snex1Mandarin.general.shares(quantity);
        },

        symbolName: (
            symbol: string,
            optionVariant: 'extra-short' | 'short' | 'long' | 'no-exp' | 'symbol-putcall' = 'short',
            quantity = 1,
            secMeta: SecurityMetadata = null
        ) => {
            const optSym = new OptionSymbol(symbol);

            if (optSym.isOption) {
                const { underlyingSymbol, expDate } = optSym;
                const strike = secMeta?.strikePrice || optSym.strike;
                const term = getWeekOfMonth(expDate, { weekStartsOn: 6 }) !== 3 ? '每周' : '每周';
                const putness = Snex1Mandarin.general.options.putCallWord(optSym.putCall);
                const strikeFormat = secMeta ? GetPriceFormatInfo(symbol, secMeta.tickSize) : undefined;
                const strikeStr = FormatNumber.toMoneyOpt2(strike, strikeFormat?.moneyFormatOptions);
                const { exp } = Snex1Mandarin.general.options;
                const locale = Snex1Mandarin.general.dateFnsLocale;

                switch (optionVariant) {
                    case 'long':
                        return `${underlyingSymbol} ${SafeFormatLocale({ date: expDate, formatString: 'dd MMMM yyyy', locale })} ${putness}${quantity === 1 ? '' : 's'}`;
                    case 'no-exp':
                        return `${underlyingSymbol} ${strikeStr} ${putness}${quantity === 1 ? '' : 's'}`;
                    case 'symbol-putcall':
                        return `${optSym.underlyingSymbol} ${putness}`;
                    case 'extra-short': {
                        const dateFmt = isSameYear(expDate, new Date()) ? 'MM/dd' : 'MM/dd/yy';
                        return `${optSym.underlyingSymbol} ${strikeStr}${optSym.putCall} ${SafeFormatLocale({ date: expDate, formatString: dateFmt, locale })}`;
                    }
                    case 'short':
                    default:
                        return `${underlyingSymbol} ${strikeStr} ${putness}, ${exp} ${SafeFormatLocale({ date: expDate, formatString: 'MM/dd/yyyy', locale })}`;
                }
            }

            return QualifiedId.RemovePrefix(symbol);
        },
        spokenJoin: (items: string[]) => SpokenJoin(items, '和'),
        isShort: '这是一个空头仓位',
        buyingPower: '购买力',
        availableBalance: '可用余额',
        pendingApproval: '等待批准',
        pending: '等待中',
        maintenanceCall: '维持保证金通知',
        maintenanceCallForAccounts: '一个或多个账户的维持保证金通知',
        maintenanceCallTooltip: '保证金通知是指证券账户中的证券价值低于一定水平，即维持保证金水平，要求账户持有人存入额外的现金或证券以满足保证金要求。',
        fedCallTooltip: '当投资者购买股票且账户中的净值不足以满足50%的净值要求时，将触发联邦保证金通知。',
        dayTradingFlag: '此账户已被标记为日内交易账户。',
        fplEligible: '此帐户有资格注册全额付款贷款',
        fplEnabled: '此帐户已注册全额付款贷款',
        ninetyDayRestriction: {
            description: '客户可能因以下原因而被处于90天限制状态：',
            forAccounts: '一个或多个账户的90天限制',
            list: [
                '未支付的REG T义务',
                '在展期期间除现金存款外的任何活动（清算、取消等）',
                '违反自由买卖规定',
                '在滚动的12个月内发生三次善意违规',
                '未支付的日内交易通知'
            ],
            secondParagraph: '在90天限制期间，客户仅限于使用已结算资金进行交易。当结算资金不足时，客户可能无法开立交易。超出已结算资金的交易也可能会被取消。',
            title: '90天限制',
            withDate: (date) => `90-day Restriction${date ? ` expires ${SafeFormat(date, 'MM/dd/yyyy')}` : ''}`
        },
        options: {
            put: '看跌期权',
            call: '看涨期权',
            exp: '到期',
            putCallWord: (putCall: OptionsPutCallAbbreviation) => {
                switch (putCall) {
                    case 'C' as OptionsPutCallAbbreviation:
                        return Snex1Mandarin.general.options.call;
                    case 'P' as OptionsPutCallAbbreviation:
                        return Snex1Mandarin.general.options.put;
                    default:
                        return Snex1Mandarin.general.unknown;
                }
            },
            adjustedOptionsSubtext: (deliverableCount: number) => {
                return `${deliverableCount} 股份每合同`;
            }
        },
        productTypes: {
            'offshore-mutual-fund': { singular: '共同基金', plural: '共同基金' },
            'mutual-fund': { singular: '共同基金', plural: '共同基金' },
            adr: { singular: 'ADR', plural: 'ADR' },
            crypto: { singular: '加密货币', plural: '加密货币' },
            equity: { singular: '股票', plural: '股票' },
            etf: { singular: 'ETF', plural: 'ETF' },
            future: { singular: '期货', plural: '期货' },
            option: { singular: '期权', plural: '期权', upperSingular: '期权' },
            unknown: { singular: '证券', plural: '证券' }
        },
        assetClassLabels: {
            equities: '股票',
            futures: '期货',
            cryptos: '加密货币'
        },
        sorrySomethingWentWrong: '抱歉，发生了错误',
        notApplicable: '不适用',
        learnMore: '了解更多',
        expiresSoon: '即将到期',
        expiresToday: '今天到期',
        expiresTomorrow: '明天到期',
        daily: '日常的',
        oneTime: '一度',
        repeatDaily: '每日重复',
        numberBought: '＃ 买',
        numberSold: '＃ 卖',
        avgBuyPrice: '平均买入价',
        avgSellPrice: '平均售价',
        topDayActivity: '顶级日活动',
        ascending: '升序',
        descending: '降序'
    },
    webRegistration: {
        verifySMSCodeTitle: '使用手机验证',
        enterCodeAndLogin: '在下面输入您的验证码，让我们开始吧！',
        verifySMSCodeSubtitle: (phone: string) => `通过短信向${phone}发送验证码`,
        messageChargesAlert: '可能会产生运营商短信费用',
        sendingSMSCode: '正在验证验证码',
        invalidSMSCode: '无效的短信验证码',
        resentSMSCode: '重新发送短信验证码',
        getSMSCode: '通过短信接收验证码',
        submitSMSCode: '提交验证码',
        alternateMFA: '选择其他多重身份验证方法',
        welcome: '欢迎！',
        getAnAccount: '让我们为您创建一个StoneX One账户',
        getUserInfo: '让我们获取您的姓名和电子邮件',
        nextButton: '下一步',
        firstName: '名字',
        lastName: '姓氏',
        phoneNumber: '电话号码',
        verifyEmail: '验证电子邮件',
        emailVerified: '电子邮件已验证！',
        email: '电子邮件',
        confirmEmail: '确认电子邮件',
        userExists: (email) => `已存在使用电子邮件${email || ''}的用户。`,
        personalInfo: '个人信息',
        usCitizenQuestion: '您是否是美国公民？',
        yes: '是',
        no: '否',
        usCitizenAnswer: '我是美国公民',
        nonUSCitizenAnswer: '我不是美国公民',
        usCitizenAlert: '目前您必须是美国公民才能开设账户',
        createPassword: '让我们创建一个密码',
        createUser: '创建用户',
        passwordRequirement: '密码必须至少包含14个字符',
        password: '密码',
        confirmPassword: '确认密码',
        verifyYourEmail: '让我们验证您的电子邮件',
        sendingVerificationCode: '我们正在向之前提供的电子邮件发送验证码。请在下面的框中输入。',
        enterInBoxBelow: '请将其输入下面的框中。',
        invalidVerificationCode: '无效的验证码',
        resentVerificationCode: '重新发送验证码',
        verificationCode: '验证码',
        submit: '提交',
        resendCode: '重新发送验证码',
        completeTitle: '注册完成',
        completeSubtitle: '点击下面的按钮并登录 StoneX One',
        login: '登录',
        alreadyExists: '已找到现有用户',
        wouldYouLikeToLogin: '您想登录 StoneX One 吗',
        steps: {
            welcome: '欢迎',
            userInfo: '用户信息',
            personalInfo: '个人信息',
            setupPassword: '设置密码',
            verifyCreate: '验证并创建',
            enrollMFA: '注册多重身份验证',
            login: '登录'
        },
        errors: {
            required: '字段为必填项',
            userInfo: {
                invalidPhone: '无效的电话号码',
                invalidEmail: '不合规电邮',
                emailDoesNotMatch: '电子邮件条目不匹配',
                emailExists: '电子邮件已经存在'
            },
            password: {
                passwordsDoNotMatch: '密码输入不匹配',
                invalidPassword: '密码必须至少为 14 个字符'
            }
        }
    },
    assetClass: {
        futures: '期货',
        cryptocurrency: '加密货币',
        crypto: '加密货币',
        futuresOptions: '期货期权',
        future: '期货',
        equitiesOption: '股票期权',
        otcOption: '场外期权',
        etf: '交易所交易基金',
        mutualFund: '共同基金',
        oilGasReit: '油气房地产投资信托',
        escrowReceiptsLetterGuaranty: '托管收据/保函',
        treasury: '国库券',
        municipleBond: '市政债券',
        corporateUit: '企业投资信托',
        municipalUit: '市政投资信托',
        unit: '单位',
        preciousMetals: '贵金属',
        commonStock: '普通股',
        annuity: '年金',
        miscellaneous: '其他',
        offShoreMutualFund: '离岸互惠基金',
        brokerage: '股票'
    },
    appReview: {
        yes: '是',
        no: '否',
        enjoyingStoneX: '您喜欢使用StoneX One吗？',
        rateApp: '您想给应用评分吗？',
        later: '稍后',
        noThanks: '不，谢谢',
        feedback: '感谢您的反馈。您想与聊天支持交谈吗？'
    },
    connectionStatus: {
        title: '无连接',
        message: '看起来您未连接到互联网。',
        retryConnection: '重试连接'
    },
    premiumModal: {
        addTheseTools: '将这些工具添加到您的工具库中：',
        ready: '准备好获取额外的优势了吗？',
        unlock: '解锁无与伦比的分析。',
        upgradeToday: '立即升级到高级版',
        deepAnalysis: {
            title: '深度分析访问',
            content: '使用实时市场数据和独家公司见解进行市场导航，汇集成一个全面的视图。'
        },
        multiCharts: {
            title: '多个图表，一个屏幕',
            content: '在单个视图上执行多个图表的技术分析。分析和比较不同证券的图表模式。'
        },
        streamingNews: {
            title: '实时新闻',
            content: '随着市场的变动而行动，利用实时新闻。新文章将显示在您的新闻流的顶部。'
        },
        alerts: {
            title: '价格提醒',
            content: '使用自定义提醒来关注对您最重要的证券。永远不要错过机会。',
            isAbove: '上面是',
            isAboveOrEqual: '高于或等于',
            isBelow: '在下面',
            isBelowOrEqual: '低于或等于'
        },
        miPremium: {
            title: '市场情报',
            content: '了解我们所有市场的新闻、数据和评论，直接来自经纪人和分析师的观点。包括行动洞察，让您提前一步！'
        }
    },

    marketTimeBadge: {
        open: '美国股市开放',
        premarket: '美国股市盘前时间',
        holiday: '交易假日',
        postmarket: '美国股市盘后时间',
        closed: '美国股市休市',
        loading: '加载中'
    },
    blankViews: {
        holdingsTable: {
            main: '未找到持仓',
            secondary: '尝试更改您的筛选条件',
            notFundedMain: '欢迎来到持仓屏幕',
            notFundedSecondary: '一旦您的账户获得资金，您将在此找到您的持仓和余额',
            notFundedAction: '资金账户'
        },
        documents: {
            main: (documentType) => `您目前没有${documentType}`,
            secondary: (documentType) => `未来的${documentType}将在可用时在此处找到`
        },
        transactions: {
            main: '看起来您没有任何交易记录',
            secondary: '尝试其他筛选条件或稍后再查看'
        },
        projectedIncome: {
            main: '此账户没有找到预期的收入',
            secondary: '尝试另一个账户或稍后再查看'
        },
        tradeHistory: {
            main: '看起来您没有与搜索条件匹配的已记录交易',
            secondary: '搜索证券并进行交易以在此处查看'
        },
        messages: {
            main: '未找到任何消息',
            secondary: '您会在此处找到您收到的任何消息。稍后检查是否有新内容！'
        }
    },
    premiumScreen: {
        createAlert: (symbol) => `为${symbol}创建提醒`,
        deepAnalysisAlt: '深度分析屏幕的预览',
        returnToSummary: '返回摘要',
        subHeader: '您刚刚提升了自己的水平。使用下面的按钮来查看您的新超能力。',
        viewCharts: (symbol, symbol2) => `比较${symbol}和${symbol2}`,
        viewChartsAlt: '多个图表的预览',
        viewDeepAnalysis: (symbol) => `查看${symbol}的深度分析`,
        viewMi: (symbol) => `立即查看${symbol}的市场情报内容！`,
        viewMiAlt: '期货商品的图像',
        welcomeHeader: (name) => `欢迎来到${name}！`,
        checkout: {
            title: '结账',
            changePlan: '更改计划',
            nextBilled: (date) => `您将在${format(date, 'yyyy年MM月dd日')}进行下一次付款`,
            cancelAnytime: '随时取消',
            offerTerms: '适用优惠条件',
            details: {
                title: '重要',
                body: (productName) => `对于${productName}的付款将通过您的StoneX账户自动进行。`,
                multipleAccounts: '由于您有多个账户，您需要告诉我们您想使用哪个账户。请在下面选择（您随时可以更改此选项）：'
            },
            selectAccount: (hasMultipleAccounts) => (hasMultipleAccounts ? '选择要扣款的经纪账户' : '要扣款的经纪账户'),
            buttonText: '开始订阅'
        },
        selectProduct: {
            goPremium: '升级到高级版',
            or: '或',
            pickYourPackage: '选择您的套餐',
            perMonth: '每月',
            comingSoon: '即将推出！',
            comingSoonToStonexOne: '即将推出至StoneX One：',
            select: '选择',
            bestValue: '最优惠！',
            jumbotron: {
                ourBestDeal: '我们的最佳优惠！',
                title: [
                    // [
                    // { text: 'StoneX One Premium', weight: 'bold', size: 'title' },
                    // { text: ' 和 ', size: 'title' },
                    // { text: 'Market Intelligence', weight: 'bold', size: 'title' },
                    // { text: '在一个便捷的套餐中。', size: 'title' }
                    // ]
                ],
                perMonthAbbr: '/月',
                moreOptions: '更多选项',
                getStarted: '开始'
            },
            products: {
                sx1Premium: 'StoneX One 高级版',
                miPremium: '市场情报 高级版',
                sx1miBundle: 'StoneX One + 市场情报 套餐'
            },
            features: {
                deepAnalysis: {
                    title: '深度分析',
                    subtitle: '内幕消息，盈利通话等等！'
                },
                multiChart: { title: '多个图表' },
                customAlerts: { title: '自定义提醒' },
                miPremiumArticles: { title: '高级商品研究' }
            }
        }
    },

    managePremiumScreen: {
        premium: '高级版',
        havePremiumTitle: '您已拥有 StoneX One 高级版！',
        havePremiumSubtitle: '继续加油！',
        dontHavePremiumTitle: '您还没有高级版',
        dontHavePremiumSubtitle: '解锁独一无二的分析。',
        features: {
            earningsCalls: '收听所有股票的最近和历史盈利通话，附有逐字稿。',
            financials: '获取诸如情感分析、资产负债表、损益表、现金流报告、预计收入、分红历史等所有股票的财务信息。',
            institutions: '查看哪些机构、基金和领导人正在为任何公司交易最多的股份。'
        },
        startForJust: (price: string | number) => `仅需 $${price}/月即可开始。`,
        startPremiumNow: '立即开始高级版！',
        howPaymentsAreMade: '付款直接从您的 StoneX 账户扣除，无需信用卡。',
        paymentAccount: '付款账户',
        selectPaymentAccount: '选择付款账户',
        nextBill: '下次账单',
        contactSupportForBillingHistory: '如有关于您的账单历史的查询，请联系支持。',
        contactSupport: '联系支持',
        cancelMyPremium: '取消我的高级版',
        cancelAreYouSure: '您确定要取消吗？',
        cancelYouCanUncancel: (ex: Date) => `在 ${SafeFormat(ex, 'MMMM d')} 前，您可以选择撤销取消，以保留您的高级版。`,
        yesCancelMyPremium: '是的，取消我的高级版',
        noKeepMyPremium: '不，保留我的高级版',
        premiumEnd: '高级版结束',
        canceledTitle: (ex: Date) => `您的高级版将于 ${SafeFormat(ex, 'MMMM d')} 结束`,
        canceledSubtitle: '在下面撤销您的取消以保留您的高级版',
        undoCancelation: '撤销取消'
    },
    weather: {
        sectionTitle: '天气',
        current: {
            chanceOfPrecipitation: '降水几率',
            dewPoint: '露点',
            humidity: '湿度',
            visibility: '能见度',
            feelsLike: '体感温度',
            airPressure: '气压',
            wind: '风',
            gusts: '阵风',
            sprayCondition: '喷雾状况'
        },
        hourly: {
            hour: '小时',
            climate: '气候',
            rainChance: '降雨几率',
            rainAmount: '降雨量',
            temperature: '温度',
            windGust: '风（阵风）'
        }
    },
    misc: {
        backToTorch: '查看上一个版本',
        manageAccountApplications: '管理账户申请',
        selectAnAccount: '选择账户',
        account: '账户',
        allAccounts: '所有账户',
        showFewer: '显示更少',
        showLess: '显示更少',
        showXMore: (n: number) => `显示更多 ${n}`,
        showMore: '显示更多',
        accounts: (n: number) => `账户${n === 1 ? '' : '们'}`,
        heldBy: '由',
        addAccountButton: '添加账户',
        addAccount: '添加账户',
        addEquitiesAccount: '添加股票账户',
        addFuturesAccount: '添加期货账户',
        noResults: '无结果',
        noMoreResults: '没有更多结果',
        errorLoadingResults: '加载结果时出现错误',
        tryAgain: '请重试',
        today: '今天',
        ranges: {
            oneDay: '1天',
            twentyFourHours: '24小时',
            fiveDay: '5天',
            oneWeek: '1周',
            oneMonth: '1个月',
            threeMonths: '3个月',
            sixMonths: '6个月',
            oneYear: '1年',
            fiveYear: '5年',
            all: '全部'
        },
        search: {
            noResults: '未找到结果，请尝试其他搜索',
            placeholder: '搜索股票...'
        },
        news: {
            noTitle: '无标题',
            noSummaryAvailable: '无摘要可用'
        },
        tradeTypeButton: {
            buyToOpen: '买入开仓',
            sellToOpen: '卖出开仓',
            sellToClose: '卖出平仓',
            buy: '买',
            sell: '卖',
            shortSell: '卖空',
            buySell: '买卖',
            sellBuy: '卖买',
            buyToClose: '买入平仓',
            liquidate: '清算',
            liquidatePosition: (securityId: string) => `或者，您可以清算您的头寸，这将出售您在 ${securityId} 中的所有股份。`
        },
        chatWithSupport: '与我们聊天',
        showVolume: '显示成交量',
        showWorkingOrders: '显示工作订单',
        loggingOut: '正在退出...',
        amountEstimate: '此金额为估计值。您的订单将以最佳可能价格执行',
        newIPO: '新的 IPO 即将来临。',
        offeringPrice: '发行价：',
        screenNotExist: '此屏幕不存在。',
        goHomeScreen: '前往主屏幕！',
        stopAllStreaming: '停止所有流式传输'
    },
    disclosureMessages: {
        tax: 'StoneX One 不提供税务建议。',
        finra: 'StoneX © 2021。账户由 StoneX Financial Inc. 承载，为 FINRA/SIPC 成员，是 StoneX Group Inc. 的全资子公司。',
        delayedQuotes: '报价延迟 15 分钟。',
        glancePreviousClose: '变化截至前一个收盘价',
        projectedOrderCost: '基于限价计算的订单成本预计，实际成本可能有所不同',
        topMovers: `SECTORS 和 MOVERS 列表使用第三方数据提供者提供的数据生成。
        特定业务部门中的公司列表可能会显示公司，如果源数据表明公司从相关部门获取一部分收入。
        列表仅供信息目的，不构成投资建议或购买、持有或出售任何证券的推荐。
        列表不是针对您或任何其他客户的个性化建议，列出的证券可能不适合您。
        您不应在首先确定其是否适合您的投资组合或投资策略之前购买或出售任何证券。
        StoneX One 对任何第三方信息的准确性、及时性或完整性不作任何保证或担保，提供此信息不构成推荐。`,
        totalPnL: '此金额反映了此头寸的未实现利润/损失'
    },
    header: {
        alerts: '提醒',
        tip: '提示',
        trySearch: '尝试搜索您喜欢的股票',
        summary: '摘要',
        positions: '头寸',
        banking: '现金',
        messages: '消息',
        profile: '个人资料',
        help: '帮助',
        legalMore: '法律和更多',
        goPaperless: '无纸化',
        documents: '文件',
        tradeHistory: '命令',
        fixedPlus: 'Fixed+',
        transactions: '交易',
        projectedIncome: '预计收入',
        disclosures: '披露',
        logOut: '退出登录',
        buyingPower: '购买力',
        portfolioValue: '投资组合价值',
        holdings: '头寸',
        upcomingEarnings: '即将发布的盈利',
        upcomingIpos: '即将发布的 IPO',
        searchForAnything: '搜索任何交易...',
        exploreMarkets: '探索市场',
        addToList: '添加到列表',
        optionsHint: '对于期权',
        chatWithUs: '与我们聊天',
        emailUs: '给我们发邮件',
        resetTours: '查看向导',
        verifyResetTours: '您确定要重置您的向导进度吗？',
        amex: '今天尝试 AMEX！',
        helpPopup: {
            contactUs: '联系我们',
            liveChat: '在线聊天'
        }
    },
    notFoundSceen: {
        header: '看起来这个页面不存在...',
        securityHeader: '看来这个安全措施不存在...',
        otherOptions: '以下是您可能喜欢的其他选项',
        portfolioButton: '我的投资组合',
        positionsButton: '我的头寸',
        mobile: {
            wrongTurn: '很抱歉，看起来我们走错了方向！',
            backToStoneX: '返回 StoneX'
        }
    },

    watchlistScreen: {
        items: (numItems?: number) => `${numItems} ${numItems === 1 ? '个项目' : '个项目'}`,
        notFound: {
            primary: '看起来这个监视列表不存在',
            secondary: '选择另一个监视列表或单击右侧的“ + ”创建一个'
        },
        noItems: {
            primary: '看起来这个列表是空的',
            secondary: (name: string) => `添加到'${name}'的任何项目都将显示在这里。`
        },
        marketCap: {
            header: '什么是市值？'
        },
        noLists: {
            noListTitle: '没有列表',
            noListSubtitle: '通过一个列表关注您喜欢的证券。在下面创建您的第一个列表。',
            createNewList: '创建新列表',
            addToList: '添加到列表',
            emptyListTitle: '看起来这个列表是空的',
            emptyListSubtitle: '通过将它们添加到这个列表中，了解您喜欢的公司的动态。',
            addMore: '添加更多'
        },
        defaultDescription: '添加监视列表描述',
        createNewWatchlist: '创建新的监视列表',
        addToWatchlist: (qsi: string) => `将${qsi}添加到监视列表`,
        editWatchlist: '编辑监视列表名称',
        createWatchlist: '创建监视列表',
        watchlistName: '监视列表名称',
        create: '创建',
        update: '更新',
        topStories: '头条新闻'
    },
    portfolioScreen: {
        skipToWatchlists: '跳转到监视列表',
        portfolio: '投资组合',
        buyingPower: {
            buyingPower: '购买力',
            marginCashAvailable: '可用保证金现金',
            cashAvailable: '可用现金',
            marginAvailable: '可用保证金',
            nyseExcess: 'NYSE 余额',
            startUsingMargin: '开始使用保证金',
            totalAvailableForTrading: '用于交易的总额',
            whichAccountToAddMargin: '您要向哪个账户添加保证金？',
            initialMargin: '初始保证金',
            netLiquidity: '净清算价值'
        },
        summary: {
            allAccounts: '所有账户',
            assetsHeldAway: '持有的资产',
            buyingPower: '购买力',
            cash: '现金',
            cashAndEquivalents: '现金与等值物',
            initialMargin: '初始保证金',
            longMarketValue: '长头寸市值',
            longOptionValue: '长期权市值',
            totalAccountValue: '账户总价值',
            netLiq: '净清算价值',
            netOptionValue: '净期权价值',
            openPnL: '未平仓盈亏',
            realizedPnL: '今日（已实现）损益',
            shortMarketValue: '空头寸市值',
            shortOptionValue: '空期权市值',
            summary: '摘要',
            totalPnL: '总盈亏',
            unrealizedPnL: '未结（未实现）损益',
            viewProjectedIncome: '查看预计收入'
        },
        portfolioBreakdown: {
            cashAndEquivalents: '现金与等值物',
            equities: '股票',
            stocks: '股票',
            mutualFunds: '共同基金',
            fixedIncome: '固定收益',
            rightsAndWarrants: '权益和认股权证',
            options: '期权',
            portfolioBreakdown: '投资组合分析',
            contractsBreakdown: '合同分析',
            accountBreakdown: '账户分析',
            holdingsBreakdown: '头寸分析',
            shares: '股份',
            breakdown: '分析',
            sectorExposure: '行业暴露',
            top10holdings: '前10个持仓',
            investing: '投资',
            futures: '期货',
            uits: 'UITs',
            others: '其他',
            preciousMetals: '贵金属',
            entertainment: '娱乐',
            miscellaneous: '其他',
            telecommunications: '电信',
            broadcastingRadioAndTv: '广播 - 无线电与电视',
            automobileAndTrucks: '汽车/卡车',
            instrumentation: '汽车/卡车',
            notGrouped: '未分组',
            investmentCompanies: '投资公司',
            representingAssets: (number: string) => `代表总资产的 ${number}`
        },
        sectors: {
            sectors: '行业',
            asOf: (date: Date) => `截至 ${SafeFormat(date, 'yyyy年MM月dd日，hh:mm a')}`,
            customerDiscretionary: '消费者自由支配',
            healthCare: '医疗保健',
            technology: '技术',
            realEstate: '房地产',
            consumerStaples: '日用品',
            utilities: '公共事业',
            communicationServices: '通信服务',
            financials: '金融',
            materials: '材料',
            industrials: '工业',
            energy: '能源',
            addToWatchlist: '添加到监视列表',
            noSecurities: '没有证券',
            emptyList: '看起来这个列表是空的。'
        },
        topMovers: {
            futuresTopMovers: '前月移动',
            topMovers: '热门移动',
            topGainers: '热门涨幅',
            topLosers: '热门跌幅',
            unavailable: '数据不可用',
            topMoversUnavailable: '当前无法获取热门移动',
            frontMoversUnavailable: '当前无法获取前月移动',
            topGainersUnavailable: '当前无法获取热门涨幅',
            topLosersUnavailable: '当前无法获取热门跌幅'
        },
        glance: {
            title: '投资术语',
            portfolioValue: '投资组合价值',
            accountValue: '账户价值',

            amountInvested: '投资金额',
            amountInvestedInfo: '此金额包括您的经纪账户中的未平仓证券头寸以及您的期货账户中的现金和成本基础。',

            todaysGainLoss: '今天的盈亏',
            todaysGainLossInfo: '此计算不包括任何期货头寸。',

            unrealizedGainLoss: '未平仓盈亏',
            unrealizedGainLossInfo: '所有未平仓头寸的当前未实现盈亏。',

            buyingPower: '购买力',

            cash: '现金',

            asOfPreviousClose: '（截至前次收盘）',
            lastUpdated: (updated: string) => `上次更新 ${updated}`
        },
        sidebar: {
            stocks: '股票',
            mutualFunds: '共同基金',
            fixedIncome: '固定收益',
            options: '期权',
            shares: '股份',
            watchlists: '监视列表',
            sortBy: '排序方式',
            price: '价格',
            value: '价值',
            display: '显示',
            delete: '删除',
            add: '添加',
            edit: '编辑详细信息...',
            expires: (date: Date) => `到期 ${SafeFormat(date, 'yyyy年MM月dd日')}`,
            strike: '行权价'
        },
        warnings: {
            pleaseCheckLater: '请稍后检查此问题的进一步发展。',
            systemBulletin: '系统公告',
            resolvingIssues: '我们正在解决影响我们',
            system: '系统',
            systems: '系统',
            weAreResolvingIssues: '我们正在解决一个系统事件。',
            theseSystems: '这些系统',
            theSystem: '这个系统',
            incidentFirstReported: ' 此事件首次报告于 ',
            statusEvaluated: (systems: string) => `${systems}的状态上次评估 `
        },
        totalPortfolioValue: '总投资组合价值',
        asOfDate: '截至日期',
        commonStock: '普通股',
        preferredStock: '优先股',
        newListName: '新的列表名称',
        create: '创建',
        documents: '文件',
        readyToStart: (isBeta: boolean) => (isBeta ? '准备开始？' : '准备开始交易？'),
        depositFunds: '添加资金',
        getStarted: '通过进行第一笔交易开始。',
        myPositions: '我的头寸',
        topStories: '热门新闻'
    },
    accountScreen: {
        accountBreakdown: '账户分析',
        summaryDeficientChartPoints: '当前，摘要图表不包括一个或多个账户的值。这不会影响您实际的账户或购买力。',
        accountDeficientChartPoints: '当前，账户图表没有足够的数据来呈现。这不会影响您实际的账户或购买力。',
        pending: {
            title: (plural?: boolean) => `${plural ? '账户' : '账户'}正在等待批准`,
            info: (plural?: boolean) => `您的${plural ? '账户正在' : '账户正在'}等待我们的专家批准。请稍后查看是否更新了此状态。`,
            extrasHeader: '等待期间',
            explore: '探索市场',
            getHelp: '从我们的支持团队获取帮助'
        },
        viewDetails: '查看详细信息',
        noAssetBreakdownAvailable: '此账户没有可用的资产分解。',
        noIndustryBreakdownAvailable: '此账户没有可用的行业分解。',
        byAsset: '按资产',
        byIndustry: '按行业',
        buyingPower: {
            initialMargin: '初始保证金',
            netLiquidity: '净清算价值'
        },
        myAccountStatusApplication: {
            docsRequired: {
                title: '需要文件',
                info: '我们需要一些文件来处理您的申请。'
            },
            inProgress: {
                title: '申请审核中',
                info: '您的账户正在被我们的专家审核。稍后查看您的账户是否已被批准。'
            },
            rejected: {
                title: '申请被拒绝',
                info: '您的账户申请已被拒绝。查看您的账户申请或在下面联系我们的支持团队。'
            },
            verificationFailed: {
                title: '账户验证失败',
                info: '我们无法验证申请中的某些信息。查看您的账户申请或在下面联系我们的支持团队。'
            },
            submitted: {
                title: '申请已提交',
                info: '您的账户申请已提交！稍后查看您的账户是否已被批准。'
            },
            viewApplication: '查看申请状态'
        }
    },

    positionsScreen: {
        account: '帐户',
        allIndustries: '所有行业',
        allTypes: '所有类型',
        averageCost: '平均成本',
        change: '变化',
        changePercent: '变化 %',
        closed: '已关闭',
        cost: '成本',
        date: '日期',
        downloadAll: '下载全部',
        downloadClosed: '下载已关闭仓位',
        downloadClosedFileName: () => `closed-positions-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`,
        downloadFilename: () => `positions-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`,
        hide: '隐藏',
        longAmount: '多头数量',
        marketValue: '市值',
        marketValueFull: '市值',
        name: '名称',
        noOpenLots: '没有可用于此仓位的开放税务份额。',
        notGrouped: '未分组',
        open: '开放',
        openPnL: '未平盈亏',
        openPnLPercent: '未平盈亏%',
        positions: '仓位',
        price: '价格',
        quantity: '数量',
        realizedPnlFor: (thingItsFor: string) => `已实现的利润和损失，适用于${thingItsFor}`,
        rollPosition: '滚动位置',
        shortAmount: '空头数量',
        show: '显示',
        showClosedLots: '显示已关闭税务份额',
        taxLotPreviousDayDisclosure: '使用可用的调整后成本计算成本和盈亏值。',
        taxLotsFor: (thingItsFor: string) => `${thingItsFor}的税务份额`,
        taxYear: '税年',
        term: '期限',
        todaysGainLoss: '今日盈亏',
        todaysGainLossPercent: '今日盈亏 %',
        todaysGainLossTab: '今日利润/损失',
        totalCost: '总成本',
        totalGainLoss: '总盈亏',
        totalGainLossPercent: '总盈亏 %',
        totalGainLossTab: '总利润/损失',
        type: '类型',
        unrealizedProfitLoss: '未实现的利润/损失',
        sortBy: '排序方式',
        industryGroups: {
            [IndustryGroups.cashAndEquivalents]: '现金与等价物',
            [IndustryGroups.automotiveAndTrucks]: '汽车及卡车',
            [IndustryGroups.broadcastingRadioAndTv]: '广播 - 电台与电视',
            [IndustryGroups.conglomerates]: '企业集团',
            [IndustryGroups.metalsAndMining]: '金属与采矿',
            [IndustryGroups.miscellaneous]: '杂项',
            [IndustryGroups.mutualFunds]: '共同基金',
            [IndustryGroups.consumerProducts]: '消费品',
            [IndustryGroups.commercialPaper]: '商业票据',
            [IndustryGroups.treasuryBillsFlat]: '国库券 - 平价',
            [IndustryGroups.corporateBonds]: '公司债券',
            [IndustryGroups.mortgageBackedBonds]: '抵押支持债券',
            [IndustryGroups.govermentBonds]: '政府债券',
            [IndustryGroups.municipalBonds]: '市政债券',
            [IndustryGroups.municipalBondsPut]: '市政债券-可放置',
            [IndustryGroups.preferredStocks]: '优先股',
            [IndustryGroups.aerospace]: '航空航天',
            [IndustryGroups.advertising]: '广告',
            [IndustryGroups.airTransport]: '航空运输',
            [IndustryGroups.aluminum]: '铝',
            [IndustryGroups.autoParts]: '汽车零部件',
            [IndustryGroups.banks]: '银行',
            [IndustryGroups.beveragesBrewers]: '饮料 - 啤酒',
            [IndustryGroups.beveragesDistillers]: '饮料 - 烈酒',
            [IndustryGroups.beveragesSoftDrinks]: '饮料 - 软饮料',
            [IndustryGroups.biotechnology]: '生物技术',
            [IndustryGroups.brokerageFirms]: '经纪公司',
            [IndustryGroups.buildingMaterials]: '建筑材料',
            [IndustryGroups.businessServices]: '商业服务',
            [IndustryGroups.cement]: '水泥',
            [IndustryGroups.chemicalDiversified]: '化学 - 多元化',
            [IndustryGroups.chemicalSpecialties]: '化学特种',
            [IndustryGroups.coal]: '煤炭',
            [IndustryGroups.copper]: '铜',
            [IndustryGroups.containersMetalGlass]: '金属 / 玻璃容器',
            [IndustryGroups.containersPlastic]: '塑料容器',
            [IndustryGroups.cosmetics]: '化妆品',
            [IndustryGroups.dataProcessing]: '数据处理',
            [IndustryGroups.drugs]: '药物',
            [IndustryGroups.electricEquipment]: '电力设备',
            [IndustryGroups.ermergingGrowth]: '新兴增长',
            [IndustryGroups.entertainment]: '娱乐',
            [IndustryGroups.finance]: '金融',
            [IndustryGroups.fertilizers]: '化肥',
            [IndustryGroups.foods]: '食品',
            [IndustryGroups.foodServiceAndVending]: '餐饮服务及自动贩卖机',
            [IndustryGroups.forestProducts]: '林产品',
            [IndustryGroups.gaming]: '游戏',
            [IndustryGroups.healthCareFacilities]: '医疗保健设施',
            [IndustryGroups.homeFurnishings]: '家居用品',
            [IndustryGroups.hospitalSupplies]: '医院用品',
            [IndustryGroups.hotelsMotels]: '酒店与汽车旅馆',
            [IndustryGroups.householdAppliances]: '家用电器',
            [IndustryGroups.industrialServices]: '工业服务',
            [IndustryGroups.instrumentation]: '仪器仪表',
            [IndustryGroups.insurance]: '保险',
            [IndustryGroups.investmentCompanies]: '投资公司',
            [IndustryGroups.leisureTime]: '休闲时间',
            [IndustryGroups.machinery]: '机械',
            [IndustryGroups.machineTools]: '机床',
            [IndustryGroups.manufacturing]: '制造业',
            [IndustryGroups.metalsFabricating]: '金属制造',
            [IndustryGroups.mobileHomes]: '移动房屋',
            [IndustryGroups.officeEquipment]: '办公设备',
            [IndustryGroups.oilAndGas]: '石油与天然气',
            [IndustryGroups.oilService]: '油气服务',
            [IndustryGroups.paper]: '纸张',
            [IndustryGroups.photography]: '摄影',
            [IndustryGroups.printingAndPublishing]: '印刷与出版',
            [IndustryGroups.railroads]: '铁路',
            [IndustryGroups.realEstate]: '房地产',
            [IndustryGroups.restaurants]: '餐馆',
            [IndustryGroups.retailStores]: '零售 - 商店',
            [IndustryGroups.retailFoodChains]: '零售 - 食品连锁',
            [IndustryGroups.retailDrugChains]: '零售 - 药品连锁',
            [IndustryGroups.retailMerchandising]: '零售 - 商品销售',
            [IndustryGroups.savingsAndLoan]: '储蓄与贷款',
            [IndustryGroups.shoes]: '鞋类',
            [IndustryGroups.soaps]: '肥皂',
            [IndustryGroups.steels]: '钢铁',
            [IndustryGroups.telecommunications]: '电信',
            [IndustryGroups.textiles]: '纺织品',
            [IndustryGroups.tiresAndRubber]: '轮胎与橡胶',
            [IndustryGroups.tobacco]: '烟草',
            [IndustryGroups.toys]: '玩具',
            [IndustryGroups.transportation]: '运输',
            [IndustryGroups.trucking]: '货运',
            [IndustryGroups.utilitiesElectric]: '公共事业 - 电力',
            [IndustryGroups.utilitiesGas]: '公共事业 - 天然气',
            [IndustryGroups.utilitiesOther]: '公共事业 - 其他',
            [IndustryGroups.options]: '期权'
        },
        closedPositions: {
            parentHeader: {
                symbolId: '标志 / ID',
                description: '描述',
                totalQuantity: '总数量',
                totalCost: '总成本',
                totalProceeds: '总收益',
                totalRealizedGainLoss: '总实现盈亏',
                totalRealizedGainLossPercent: '总实现盈亏 %'
            },
            childHeader: {
                adjustedCost: '调整后成本',
                closeDate: '关闭日期',
                closePrice: '关闭价格',
                cost: '成本',
                exchange: '交易所',
                openDate: '开放日期',
                price: '价格',
                priceOf: (qsi: string) => `${qsi} 价格`,
                proceeds: '收益',
                quantity: '数量',
                realizedGainLoss: '实现盈亏',
                realizedGainLossPercent: '实现盈亏 %',
                realizedGl: '实现盈亏',
                side: '方向',
                term: '期限',
                totalCost: '总成本',
                tradeDate: '交易日期'
            }
        }
    },
    bankingScreen: {
        errors: {
            ainsIdentity: {
                title: '无法验证身份。',
                description: '很抱歉，我们无法验证您的身份。请在下方与我们联系，我们将尽力解决问题。'
            },
            ainsMax: '很抱歉，您已达到允许的最大关联银行账户数。请删除一个账户以继续。',
            ainsMaxVoid: '很抱歉，银行账户删除限制为每两周一次。请稍后再试。',
            betaDowntimeWindow: '每日下午5点到8点东部时间无法转账。',
            transfersUnavailable: '目前无法提交转账申请。',
            insufficientWithdrawalBalanceTitle: '余额不足',
            insufficientWithdrawalBalance: '您的转账请求资金不足。如果您最近卖出了一个或多个持仓，您可能需要等待到下一个交易日，以便您的资金结算并可用于转账。',
            noAccountsAvailable: {
                title: '无可用账户',
                description: '很抱歉，您目前没有可添加的账户。请在下方与我们联系，我们将尽力解决问题。'
            },
            pendingAccount: '待处理账户',
            retrievingAccountsFailed: {
                title: '抱歉，检索您的帐户时出错。请稍后重试或',
                actionTitle: '联系支持'
            }
        },
        nextVoidAllowed: (nextVoid: Date) => `您可以在 ${format(nextVoid, 'MM/DD/YYYY')} 删除此账户。`,
        maxReached: (maxAccounts: number) => `已达到最大账户数 ${maxAccounts}。`,
        fundingError: {
            title: '发生错误',
            subtitle: '请重试或联系我们以获得帮助。',
            tryAgain: '重试'
        },
        tryAgainIn: (dateForUnlock: Date) => `请于 ${SafeFormatToNow(dateForUnlock)} 后重试`,
        tryAgainTomorrow: '明天重试',
        tryAgainOnMonday: '周一重试',
        dontSeeAccount: '找不到您想添加的账户？请在下方与我们联系，我们将解决问题。',
        history: '历史记录',
        transactionHistory: '交易记录',
        requests: '要求',
        disclaimer: '上述信息仅供补充和参考。请查看您的每日结单以获取您账户的官方记录。',
        transferFunds: '转账资金',
        depositVerb: '存入',
        withdrawVerb: '提取',
        depositNoun: '存款',
        withdrawalNoun: '提款',
        fundsWillBeCredited: '资金将在接下来的几天内存入您的银行账户。',
        fundsWillBeDebited: '您的资金将很快可用。',
        from: '从',
        to: '到',
        amount: '金额',
        review: '审核',
        reviseTransfer: '修改转移',
        depositsLocked: (days: number) =>
            days === 1 ? '因失败尝试过多，存款被锁定。请明天重试或联系支持部门。' : `因失败尝试过多，存款被锁定。请${days}天后重试或联系支持部门。`,
        withdrawLocked: (days: number) =>
            days === 1 ? '因失败尝试过多，提款被锁定。请明天重试或联系支持部门。' : `因失败尝试过多，提款被锁定。请${days}天后重试或联系支持部门。`,
        cashHeader: '现金',
        depositFunds: '从关联的银行账户直接存入您的StoneX账户。',
        withdrawFunds: '从您的StoneX账户提款到关联的银行账户。',
        selectLinkedBankAcct: '选择您想使用的关联的银行账户。',
        retrievingBankAcct: '正在检索您的银行账户...',
        linkBankAcctWith: '将银行账户关联到',
        amountMayNotExceed: (limit: number) => `金额不得超过${limit}。`,
        amountMustBeAtleast: (limit: number) => `金额必须至少为${limit}。`,
        dailyLimitText: (limits) => [
            Light('剩余每日限额 '),
            Dark(FormatNumber.toMoney(limits?.remainingClearance)),
            Light('，总共 '),
            Dark(FormatNumber.toMoney(limits?.dailyMaximum))
        ],
        dailyMaximumText: (limits: FundingLimits, isDeposit: boolean) => [
            Light('剩余每日限额 '),
            Dark(FormatNumber.toMoney(isDeposit ? limits?.remainingDepositAmount : limits?.remainingWithdrawAmount)),
            Light('，总共 '),
            Dark(FormatNumber.toMoney(isDeposit ? limits?.maxDailyDepositAmount : limits?.maxDailyWithdrawAmount))
        ],
        dailyLimitText2: (limits) => {
            const cented = (money: number) => FormatNumber.toMoney(money).replace(/\.00$/, '');
            return `${cented(limits.transferredToday)} 今日已转账，总共 ${cented(limits.dailyMaximum)}。`;
        },
        minimumAllowedDepositText: (isDeposit: boolean, minAllowedDeposit: number | null) => {
            const minAllowedDepositText =
                !!isDeposit && !!minAllowedDeposit ? [Light(' ( '), Dark(minAllowedDeposit ? FormatNumber.toMoney(minAllowedDeposit) : null), Light(' 最低限度 )')] : [];
            return minAllowedDepositText;
        },
        managementLimitText: (limits: FundingManagementLimits | null) => {
            if (!limits) return '';
            if (!limits.canAdd) {
                if (!limits.canVoid) return '要添加或移除关联的银行账户，请联系支持部门';
                else return '要添加关联的银行账户，请联系支持部门';
            } else if (!limits.canVoid) return '要移除关联的银行账户，请联系支持部门';
            else return '';
        },
        fundingAvailableBalance: (availableBalance?: number) => [Light('可提现：'), Dark(FormatNumber.toMoneyOpt2(availableBalance))],
        amountToTransfer: (transferDirection?: 'deposit' | 'withdraw') => `${transferDirection === 'deposit' ? '存款' : '提款'} 金额`,
        amountExceedsLimit: '金额超过每日限额',
        amountExceedsBalance: '金额超过银行余额',
        amountExceedsAccountBalance: '金额超过账户余额',
        accountIsPending: '您选择的StoneX账户正在等待审核',
        plaidReauthNeeded: '需要重新使用 Plaid 进行身份验证',
        plaidAuthenticationBroken: 'Plaid 身份验证出现问题，请联系客服',
        addYourBankAccount: '添加您的银行账户',
        addABankAccount: '添加银行账户',
        addASandboxBankAccount: '添加一个沙盒银行账户',
        back: '返回',
        pleaseWait: '请稍候...',
        validatingTransfer: '验证转账',
        thereWasAProblem: '出现问题',
        unknownError: {
            title: '发生了意外情况。',
            subtitle: '请联系支持部门。'
        },
        fundingNotAllowed: {
            title: '不允许资金操作。',
            subtitle: '如果您认为您错误地收到了此消息，请联系支持部门。'
        },
        failedDailyFundingLimitCheck: {
            title: '您的请求超过了每日资金限额。',
            subtitle: '如果您认为您错误地收到了此消息，请联系支持部门。'
        },
        cosmosPlaidAccessFailed: {
            title: '检索 Plaid 数据失败。',
            subtitle: '请联系支持部门或重试。'
        },
        plaidBalanceCheckFailed: {
            title: '账户余额不足。',
            subtitle: '如果您认为您错误地收到了此消息，请联系支持部门。'
        },
        cosmosPlaidLinkIdMatchFailed: {
            title: '关联账户查找错误。',
            subtitle: '请联系支持部门或重试。'
        },
        plaidIdentityInfoFailed: {
            title: '从 Plaid 检索银行数据失败。',
            subtitle: '请联系支持部门或重试。'
        },
        likeKindNameCheckFailed: {
            title: '无法验证账户所有权。',
            subtitle: '请联系支持部门。'
        },
        previouslyLinkedAccountIdLookupFailed: {
            title: '链接 Plaid 账户到 StoneX One 账户失败。',
            subtitle: '请联系支持部门或重试。'
        },
        failedToLinkAccount: {
            title: '链接 Plaid 账户到 StoneX One 账户失败。',
            subtitle: '请联系支持部门或重试。'
        },
        transferWindowClosed: {
            title: '转账系统正在维护中。',
            subtitle: '如果您认为您错误地收到了此消息，请联系支持部门。'
        },
        transferFailed: {
            title: '转账失败。',
            subtitle: '请联系支持部门或重试。'
        },
        transferRequestFailed: 'Request failed',
        submittedRequestSuccessfully: 'Request submitted',
        asyncInfo: 'You will be notified once this request is processed. Please do not logout until this request has processed.',
        depositsLockedError: {
            title: '存款已锁定',
            subtitle: '尝试次数过多。'
        },
        minimumDepositNotMet: {
            title: (amt?: number) => (amt ? `最低存款金额不足 ${FormatNumber.toMoneyOpt2(amt)}` : '最低存款金额不足'),
            subtitle: '如果您认为您错误地收到了此消息，请联系支持部门。'
        },
        maximumAccountsReached: {
            title: '无法添加账户。已达到最大账户数量',
            subtitle: '如果您认为您错误地收到了此消息，请联系支持部门。'
        },
        ainsInstructionsInactive: {
            title: '无法检索用于转账的银行账户信息。',
            subtitle: '请联系支持部门。'
        },
        maximumDepositsReached: {
            title: '存款已锁定',
            subtitle: (amt: number, days: number) => (days === 1 ? `每天只能进行 ${amt} 次存款。` : `每 ${days} 天只能进行 ${amt} 次存款。`)
        },
        maximumWithdrawalsReached: {
            title: 'Withdrawals Locked',
            subtitle: (amt: number, days: number) => (days === 1 ? `每天只能提 ${amt} 次。` : `每 ${days} 天只能提款 ${amt} 次。`)
        },
        pleaseTryAgain: '请重试',
        submittedSuccessfully: '转账已提交！',
        operationSubmittedSuccessfully: (transfer) => {
            const operation = (() => {
                switch (transfer.transferDirection) {
                    case 'deposit':
                        return '存款';
                    case 'withdraw':
                        return '取款';
                    default:
                        return '';
                }
            })();

            return `${operation} 已提交`;
        },
        returnToSummary: '返回摘要',
        startAnotherTransfer: '开始另一笔转账',
        bankingUnavailable: '银行服务不可用',
        pleaseComeBackLaterOrContactUs: '很抱歉，银行服务当前不可用。请稍后再试，或联系我们以便解决问题。',
        depositUnavailableExplanation: '很抱歉，存款服务当前不可用。请稍后再试，或联系我们以便解决问题。',
        depositUnavailableDueToTimeExplanation: '很抱歉，存款服务在东部标准时间下午2点到凌晨8点之间不可用。请在这些时间之外再试，或联系我们以便解决问题。',
        depositUnavailableDueToTimeExplanationShort: '下午5点至6点之间不可用',
        depositUnavailableDueHolidaysExplanationShort: '交易节假日期间不可用',
        withdrawUnavailableExplanation: '很抱歉，取款服务在东部标准时间下午2点到凌晨8点之间不可用。请在这些时间之外再试，或联系我们以便解决问题。',
        withdrawUnavailableExplanationShort: '下午2点至7点之间不可用',
        withdrawUnavailableWeekendsExplanation: '周末不可用',
        withdrawUnavailableHolidaysExplanation: '假日期间不可用',
        accountPending: '您选择的 StoneX 账户正在等待审批',
        billPay: '账单支付',
        creditCardDirect: '信用卡直接支付',
        wiringInstructions: '电汇指南',
        viewWiringInstructions: '查看电汇指南',
        wiringInstructionsPdfUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/zh-CN/WiringInstructions.pdf',
        verificationStatus: (status) => (status.includes('pending') ? '等待审核中' : '审核未通过'),

        // Mobile
        selectAccount: '选择账户',
        forAddedSecurity: '为了增加安全性，您将被要求重新输入您的银行凭证',
        cannotAddLimitReachedTitle: '无法添加另一个账户',
        cannotOpenPlaid: '无法打开 Plaid，未提供链接令牌',
        cannotAddLimitReachedExplainer: '一次只能链接两个银行账户',
        linkAcctContactSupport: '要添加链接的账户，请联系支持',
        confirmText: (transfer: Partial<TransferRequest>, snexAccount: Account, fundingSource?: FundingSource) => {
            const isDeposit = transfer.transferDirection === 'deposit';
            const extPreposition = isDeposit ? '从' : '到';
            const snexPreposition = isDeposit ? '到' : '从';
            const source = fundingSource || transfer.fundingSource;

            return [
                { text: `您正在${isDeposit ? '存入' : '取出'}`, shade: 'light' },
                { text: FormatNumber.toMoneyOpt2(transfer.transferAmount), shade: 'dark' },
                { text: `${extPreposition}您的`, shade: 'light' },
                { text: source?.name, shade: 'dark' },
                { text: '账户结尾为', shade: 'light' },
                { text: `****${source?.mask}`, shade: 'dark' },
                { text: `${snexPreposition}`, shade: 'light' },
                { text: snexAccount?.nickname || snexAccount?.name, shade: 'dark' }
            ];
        },
        lost: {
            title: '未找到',
            subtitle: '看起来我们走错了路！请再试一次，如果问题仍然存在，请联系我们。',
            startOver: '重试'
        },

        limitReached: '已达到限制',
        linkYourBankAccount: '链接您的银行账户',
        limitReachedExplainer: (limits: FundingManagementLimits): ShadedTextList => {
            const maxLinked = limits?.maxLinkedCount;
            const maxVoid = limits?.maxVoidCount;
            const voidDays = limits?.maxVoidFrequencyDays;
            const s = (n: number) => (n === 1 ? '' : 's');
            const num = Snex1Mandarin.general.writtenInteger0Thru10;

            const addRule: ShadedTextList = [
                {
                    text: '作为一项安全措施，您只能添加 ',
                    shade: 'light'
                },
                {
                    text: `${num(maxLinked)} 个银行账户`,
                    shade: 'dark'
                },
                { text: '。 ', shade: 'light' }
            ];
            const voidRule: ShadedTextList = [
                {
                    text: '请注意，您还可以每 ',
                    shade: 'light'
                },
                { text: `${num(voidDays)} 天`, shade: 'dark' },
                { text: ' 只移除 ', shade: 'light' },
                { text: `${num(maxVoid)} 个账户`, shade: 'dark' },
                { text: '。 ', shade: 'light' }
            ];

            const contactSupport: ShadedTextList = [
                {
                    text: '如果您想添加或移除账户，请在下方联系支持团队。',
                    shade: 'light'
                }
            ];

            return [...addRule, ...voidRule, ...contactSupport];
        },
        limitReachedExplainerV2: (maxAdd: number, maxVoid: number, voidDays: number): ShadedTextList => [
            { text: 'As a security measure, you are only allowed to add', shade: 'light' },
            { text: `${maxAdd} bank account${maxAdd === 1 ? '' : 's'}`, shade: 'dark' },
            { text: '. Be advised that you may also only remove ', shade: 'light' },
            { text: `${maxVoid} account${maxVoid === 1 ? '' : 's'}`, shade: 'dark' },
            { text: ' every ', shade: 'light' },
            { text: `${voidDays} day${voidDays === 1 ? '' : 's'}`, shade: 'dark' },
            { text: '. If you wish to add or remove an account, please reach out below.', shade: 'light' }
        ],
        youMayLinkNMoreAccounts: (n) => {
            if (n <= 0) return 'You may not link any more bank accounts';
            return `You may add ${n} more bank account${n === 1 ? '' : 's'}`;
        },
        linkYourAccountToAddFunds: '链接您的银行账户以添加资金到您的 StoneX 账户。',
        authenticateWithPlaid: '使用 Plaid 进行身份验证',
        noBankAccounts: '没有银行账户',
        linkAnotherBankAccount: '链接另一个银行账户',

        acats: {
            acatsComingSoon: {
                title: '帐户转移即将推出！',
                subtitle: '有任何问题请随时联系我们。'
            },
            infoIcon: 'ACATS 拒绝 - 最常见的原因',
            transferAccount: '转移账户',
            introText:
                '将现金和投资从其他经纪公司转移到您的 StoneX One 账户，可以全额或部分地转移。在几分钟内提交您的转移，然后在 2-3 周内看到您转移的资产出现在您的账户中。',
            from: '从',
            accountNumber: '账号',
            accountTitle: '账户名称',
            accountSubtitle: '账户副标题',
            to: '目标',
            start: '开始',
            continue: '继续',
            skip: '跳过',
            back: '返回',
            submit: '提交',
            selectTransferType: '选择转账类型',
            fullTransfer: '全部转账',
            fullTransferDescription: '将外部账户中的所有现金和持仓转移到您的 StoneX One 账户',
            partialTransfer: '部分转账',
            partialTransferDescription: '选择您想要转移到 StoneX One 账户的现金和持仓',
            cashOptions: '现金选项',
            transferAllCash: '全部现金转账',
            transferNoCash: '无现金转账',
            transferCustomCash: '自定义金额转账',
            positions: '持仓',
            transferNoPositions: '无持仓转账',
            addPosition: '添加持仓',
            positionAccountType: '账户类型',
            cash: '现金',
            margin: '保证金',
            all: '全部',
            custom: '自定义',
            or: '或者',
            positionQuantity: '数量',
            done: '完成',
            transferringAllCash: '全部现金',
            transferringNoneCash: '无现金',
            transferringCustomCash: (amount) => `转账 ${FormatNumber.toMoney(amount)}`,
            transferringNoPositions: '无持仓',
            confirmTransfer: (type) => {
                if (type === 'Full' || type === 'Partial') return `确认${type}转账`;
                else return '确认转账';
            },
            transferringFull: '所有资产将转移到您的 StoneX One 账户。',
            assets: '资产',
            noAssetsSelected: {
                title: '无法提交转账；未选择资产',
                subtitle: '要完成转账，请返回并添加现金和/或持仓到您的转账中，或将转账更改为全部转账',
                switchToFull: '切换到全部转账'
            },
            submitting: {
                title: '正在提交您的转账',
                subtitle: '请等待...'
            },
            success: {
                title: '转账已提交！',
                subtitle: '您的账户应在接下来的 14-21 个工作日内转移。'
            },
            failure: {
                title: '出了些问题',
                subtitle: '请与我们联系，我们会解决问题。'
            },
            returnToSummary: '返回摘要'
        }
    },

    newVersionScreen: {
        newVersion: '新版本',
        nowAvailable: '现已提供',
        updateAndSee: '更新并查看我们如何为您的财务旅程提供更好的体验！',
        update: '更新',
        notNow: '暂不更新',
        accountInfoNotShared: '更新时，您的账户信息不会与任何第三方实体共享。',
        privacyPolicyLearnMore: '了解更多关于我们的隐私政策。'
    },

    messagesScreen: {
        messages: '消息',
        noMessages: '无消息',
        errorFetchingMessages: '抱歉，目前无法获取您的消息',
        viewAll: '查看全部',
        read: '已读',
        messageId: '消息 ID',
        stoneXOneTeam: 'StoneX One 团队',
        unknownSender: '未知发送者',
        backToAllMessages: '返回所有消息',
        deleteAll: '删除全部',
        noMessagesTitle: '您的消息将显示在这里',
        noMessagesSubtitle: '您的收件箱是空的。一旦收到消息，它们将显示在这里。',
        newMessage: '新消息'
    },
    profileScreen: {
        profile: '个人资料',
        logOut: '登出',
        copyClipBoard: '复制到剪贴板',
        emailSettings: '电子邮件设置',
        settings: '设置',
        about: '关于',
        build: '版本',
        version: '版本',
        snex1Premium: 'StoneX One 高级版',
        deactivate: '停用会员资格',
        errorDuplicateValue: '输入的值与记录中的值相匹配。无需进一步操作。',
        tooltip: {
            oneProNetwork:
                'One Pro Network 提供有关股票、商品、货币和宏观趋势的深入分析、实时桌面警报和信息视频，所有这些均由 StoneX 分析师准备。这些全面的信息使您能够做出更明智的贸易决策。'
        },
        tabs: {
            personalInfo: '个人信息',
            accounts: '账户',
            paperless: '无纸化',
            local: '本地'
        },
        themes: {
            dark: '深色',
            light: '浅色',
            system: '系统偏好',
            marketHours: '美国股市时间'
        },
        personalInfo: {
            tabs: {
                profilePicture: '个人图片',
                personalDetails: '个人详细信息',
                preferences: '首选项',
                notifications: '通知设置',
                taxInformation: '税务信息',
                premiumSubscription: '您的订阅'
            },
            viewPersonalInfo: {
                title: '个人详细信息',
                name: '姓名',
                email: '电子邮件',
                birthDate: '出生日期 '
            },
            loginForm: {
                signup: '注册',
                next: '下一步',
                code: '验证码',
                codeSent: '验证码已发送',
                phoneNumber: '手机号码',
                tosAndPrivacyConsentText: [
                    { text: '通过登录，您同意我们的', shade: 'light' },
                    { text: '服务条款', shade: 'dark', id: 'tos' },
                    { text: '和', shade: 'light' },
                    { text: '隐私政策', shade: 'dark', id: 'privacy' },
                    { text: '。', shade: 'light' }
                ],
                verificationCodeSentMessage: '我们已向您的电子邮件地址发送了验证码。请在此处输入验证码。',
                confirmPassword: '确认密码',
                logIn: '登录',
                startTradingMessage: '开始交易',
                getStarted: '开始',
                wantMore: '想要更多吗？',
                startTrading: (qsi: string) => `开始交易 ${qsi}`,
                couldNotLogIn: '很抱歉，登录时出了问题',
                couldNotResetYourPassword: '很抱歉，我们无法重置您的密码',
                username: {
                    email: '电子邮件'
                },
                confirmSignup: {
                    newUserTitle: '欢迎使用 StoneX One！',
                    newUserSubtitle: (email: string) => `看起来我们在系统中没有使用电子邮件 ${email}，是否继续注册？`,
                    returningUserTitle: '欢迎回到 StoneX One!',
                    returningUserSubtitle: `看起来你在之前尝试注册时未能完成。我们需要你重新输入一些信息并验证你的电子邮件。你想继续吗？`
                },
                confirmEmployeeEmail: {
                    title: '员工登录提示',
                    subtitle: '鼓励StoneX员工使用非公司电子邮件访问StoneX One。如果您继续，将被引导至我们的在线登录门户。',
                    androidInstructionsTitle: 'Android用户注意事项',
                    androidInstructions: '建议Android用户在继续之前从Okta Verify应用中复制验证码，选择在询问时输入代码，并在提示时粘贴代码。',
                    continueAnyway: '仍然继续'
                },
                password: {
                    forgotPassword: '忘记密码',
                    resetPassword: '重置密码',
                    recoverAccount: '恢复账户',
                    password: '密码'
                },
                mfa: {
                    title: '多因素验证',
                    sendingCodePleaseWait: '发送验证码，请稍等',
                    mfaCodeSendMessage: '我们已向您的手机号发送了一个验证代码。请在此输入代码。',
                    pleaseEnterYourPhone: '我们需要您的电话号码进行身份验证。请在下方输入您的手机号码。'
                },
                bioauth: {
                    biometrics: '生物识别',
                    whatBiometricsAre: '您想使用生物特征（指纹、面部等）访问您的帐户吗？',
                    allUnlocksTitle: '全部解锁',
                    allUnlocksDescription: '每次启动StoneX One时，我们都会要求您提供生物识别因素',
                    verificationOnlyTitle: '仅验证',
                    verificationOnlyDescription: '仅在需要验证身份时，我们才会要求您提供生物识别因素',
                    disableTitle: '已禁用',
                    disableDescription: '我们不会要求您提供生物识别因素',
                    enableTitle: '启用生物识别',
                    enableDescription: '将使用生物特征认证来访问 StoneX One',
                    notDesiredTitle: '不，不要使用生物识别技术',
                    notDesiredDescription: '不会通过生物识别技术保障访问安全（不推荐）',
                    bioEnabledTitle: '生物识别技术已启用',
                    bioEnabledDescription: '您的帐户通过生物特征认证得到保护',
                    bioDisabledTitle: '生物识别已禁用',
                    bioDisabledDescription: 'StoneX One 在尝试登录时不会使用生物特征认证（不推荐）',
                    notpermitted: {
                        title: '生物识别未启用',
                        message: '请在设置中启用生物识别以启用面容 ID 或指纹登录',
                        link: '使能够',
                        dismiss: '现在不要'
                    },
                    disableBiometrics: '禁用生物识别',
                    yesUpdateBiometrics: '是的，更新生物识别信息',
                    passwordUpdateSuccess: '密码更新成功！',
                    confirmReenableBioAuth: '您是否想用新密码重新启用生物识别登录？'
                },
                country: {
                    countryOfCitizenship: '国籍'
                },
                passwordReset: {
                    resetSent: '恢复消息已发送',
                    instructions: '我们已向您发送电子邮件，提供进一步的步骤，以帮助您恢复访问您的账户。',
                    returnToLogin: '返回登录页'
                },
                whiteLabelAlert: {
                    title: '未找到帐户',
                    message: '如果您认为这是一个错误，请联系您的销售代表或联系支持人员。',
                    dismiss: '解雇'
                }
            },
            profile: '个人资料',
            firstName: '名字',
            lastName: '姓氏',
            emailAddress: '电子邮件地址',
            language: '语言',
            theme: '主题',
            themeDescription: '系统偏好设置将遵循您设备的主题，当市场不开市时，市场交易时间将变黑。',
            oneClickTrading: '一键交易',
            oneClickTradingDescription: '一键交易允许您一键下单，无需查看交易。您可以禁用一键交易，以在下单前继续查看审核屏幕。',

            // advancedChartTradeTicket: 'StoneX One Advanced Chart Trade Ticket',
            advancedChartTradeTicket: 'StoneX One 高级图表交易票',
            advancedChartTradeTicketDescription:
                '在高级图表中启用 StoneX One 标准交易单，以便您可以在高级图表中对任何资产下订单，包括加密货币、小数数量订单或美元面额的加密货币订单。',
            oneProNetwork: '一个专业网络',
            oneProNetworkDescription:
                'One Pro Network 提供有关股票、商品、货币和宏观趋势的深入分析、实时桌面警报和信息视频，所有这些均由 StoneX 分析师准备。这些全面的信息使您能够做出更明智的贸易决策。',
            futuresStrikes: '期货期权链行权价',
            optionsChainStrikes: '期权连锁罢工',
            systemDefault: '系统默认',
            dockSidePanel: '停靠开放订单和监视列表',
            tradingViewTicker: '股票行情栏',
            tradingViewTickerDescription:
                '股票代码栏让您可以水平浏览由 TradingView 提供支持的重要工具统计数据。包括符号的最新价格以及每日变化。部分价格可能会延迟 15 分钟或更长时间。',
            dockSidePanelDescription:
                '开放订单和监视列表侧边栏允许您查看您的开放股票与期权、共同基金和期货订单，以及您的监视列表。请注意，这不会出现在摘要或账户屏幕上。',
            timezone: '时区',
            edit: '编辑',
            smsNumber: '短信电话号码',
            email: '电子邮件',
            smsPhone: '短信电话',
            notificationsDescription: '请告诉我们您希望如何以及何时联系您以获取非必要信息。',
            sendEmail: '发送电子邮件给我',
            sendText: '发送短信给我',
            addPhoneNumber: '添加电话号码',
            verificationNeeded: '需要验证',
            profilePictureDisclaimer: (maxMb: number) => `您选择的个人头像将公开显示，并受到我们的使用条款约束。请选择大小不超过 ${maxMb} MB 的图像。`,
            removeProfilePicture: '删除当前图片',
            imageTooLarge: (maxMb: number) => `抱歉，您的图像太大。请选择大小小于 ${maxMb} MB 的图像。`,
            unexpectedUploadError: '在更新您的图片时出现了意外问题',
            idType: {
                idType: '身份证类型',
                alienId: '外国人身份证',
                governmentId: '政府身份证',
                passport: '护照',
                ssn: '社会安全号码',
                taxIdEin: '美国税务识别号 / 税号'
            },
            mdcVerify: {
                email: {
                    title: '更新电子邮件',
                    inputText: '在下面输入您的新电子邮件地址。',
                    tryDifferent: '使用不同的电子邮件地址',
                    successText: '电子邮件地址已验证和更新！'
                },
                phone: {
                    title: '更新电话号码',
                    phone: '电话',
                    phoneNumber: '电话号码',
                    inputText: '在下面输入您的新电话号码。',
                    tryDifferent: '使用不同的电话号码',
                    successText: '电话号码已验证和更新！'
                },
                pin: 'PIN',
                badPin: '很抱歉，该PIN码无法识别',
                resendPin: '重新发送验证PIN码',
                unableToSendPin: '无法发送您的代码',
                unableToSendPinInstructions: (num) => `看起来您的电话号码已选择不接收我们的短信。请将START发送到${FormatNumber.toPhone(num)}，然后再次尝试。`,
                tryAgain: '重试',
                unexpectedError: '很抱歉，我们遇到了意外错误',
                alreadyHaveAVerificationCode: '已有验证PIN码？',
                clickHereToEnterYourCode: '点击这里输入您的PIN码',
                updateButton: '更新',
                verifyButton: '验证',
                pinSentPleaseEnter: '验证PIN码已发送。请在下方输入您收到的PIN码。',
                toRecieveTextNotifications: '要接收文本通知，请添加您的电话号码。',
                addPhoneNumber: '添加电话号码'
            },
            taxId: '税务识别号',
            dateOfBirth: '出生日期',
            birthYear: '出生年份',
            birthMonth: '月份',
            birthDay: '日期',
            whyDoWeNeedThis: '我们为什么需要这个？',
            taxInfoExplainer:
                '我们使用您的身份证和出生日期来确定您与不同账户的关系，允许您管理无纸化交付偏好并为您的账户提供资金。此信息还用于验证您对其他账户的所有权。没有这些信息，我们将无法为您提供完整的账户访问权限。',
            toUpdateThisInformation: '要更新此信息，请联系StoneX One支持',
            okay: '好的',
            save: '保存',
            saving: '保存中...',
            saved: '已保存！',
            mdcControls: {
                notificationSettings: '通知设置',
                notificationSettingsToolTips: {
                    missingPhone: '请添加有效的电话号码以启用此送货方式',
                    invalidEventOrDeliveryMethod: '此活动的消息和传递方式不可用'
                },
                email: '电子邮件',
                sms: '短信',
                smsSettings: '短信设置',
                pushNotifications: '推送通知',
                notifications: '通知',
                events: {
                    newOrderIsPlaced: '新订单已下达',
                    orderIsFilled: '订单已成交',
                    orderIsPartiallyFilled: '订单已部分成交',
                    orderIsCanceled: '订单已取消',
                    orderIsRejected: '订单已拒绝',
                    orderIsModified: '订单已修改',
                    orderIsStopped: '订单已停止',
                    orderIsSuspended: '订单已暂停',
                    orderIsOpenForBidding: '订单已开放竞价',
                    customAlertFired: '自定义警报已触发',
                    optionExpiringSoon: '持有的期权即将到期'
                }
            },
            managePremium: {
                cancel: '取消高级订阅',
                canceled: (exp: string) => `您已取消订阅。您将在 ${exp} 前访问高级功能。`,
                cancelModalText: (exp: string) => `您确定要取消高级订阅吗？\n\n您可以选择在 ${exp} 前撤消取消以保留您的高级功能。`,
                confirmCancelation: '确认取消',
                get: '获取高级功能',
                loading: '请稍等...',
                pitch: `收听盈利电话、查看您最喜欢的股票的顶级投资者，分析数千种金融产品的完整财务概况，仅需每月 ${FormatNumber.toMoney(
                    PremiumProducts.premium.price
                )}。`,
                reactivate: '重新激活 / 管理',
                subscribed: (exp: string) => `恭喜！您已订阅StoneX One高级功能。您的下一个 ${FormatNumber.toMoney(PremiumProducts.premium.price)} 账单将于 ${exp} 发布。`
            }
        },

        accounts: {
            accounts: '账户',
            top: '顶部',
            addNewAccount: '添加新账户',
            accountApplicationStatus: '账户申请状态',
            accountInformation: {
                accountInformation: '账户信息',
                acctHolderInformation: '账户持有人信息',
                accountName: '账户名称',
                address: '地址',
                altPhone: '备用电话',
                dateOfBirth: '出生日期',
                email: '电子邮箱地址',
                foreignId: '外国身份证',
                homePhone: '家庭电话',
                taxId: '税号'
            },
            registration: {
                registration: '注册',
                accountType: '账户类型',
                lastStatement: '最近报表',
                institutionCode: '机构代码',
                restrictionCode: '限制代码',
                accountStatus: '账户状态',
                accountOpened: '账户开立'
            },
            setup: {
                setup: '设置',
                marginAgreementOnFile: '已备案的保证金协议',
                fplAgreementsOnFile: 'FPL 协议存档',
                principalPayments: '本金支付',
                dividendReinvest: '股息再投资',
                cashStandingInstructions: '现金定期指示',
                marginStandingInstructions: '保证金定期指示',
                dividendStandingInstructions: '股息定期指示',
                calls: '看涨期权',
                puts: '看跌期权',
                price: '价格',
                noOptionsContracts: '当前没有适用于此证券的期权合约。',
                optionsLevel: {
                    optionsLevel: '期权交易权限',
                    disallowed: '不允许',
                    level1: '备兑认购', // Covered calls
                    level2: '购买认购 + 购买认沽', // Purchase Calls + Puts
                    level3: '价差 / 跨式买入', // Spreads / Long Straddles
                    level4: '权益认沽', // Equity Put Writing
                    level5: '不备兑认购 + 指数认沽 / 短跨式卖出', // Uncovered call writing + Index put writing / short straddles
                    level6: '不备兑指数期权' // Uncovered index options
                }
            },
            investmentProfile: {
                investmentProfile: '投资概况',
                investmentObjective: '投资目标',
                netWorth: '净资产',
                federalTaxBracket: '联邦税收等级',
                liquidityNeeds: '流动性需求',
                sourceOfFunds: '资金来源',
                occupation: '职业',
                stockExperience: '股票经验',
                bondExperience: '债券经验',
                optionExperience: '期权经验',
                mutualFundExperience: '共同基金经验',
                annuityExperience: '年金经验',
                investmentTimeHorizon: '投资时间视野'
            },
            accountDetails: {
                title: '账户详细信息',
                type: '类型',
                accountNumber: '账户号码',
                accountName: '账户名称',
                rename: '重命名',
                startMargin: '开始使用保证金',

                optionsTrading: '期权交易',
                optionsEnabled: '已启用期权',
                optionsLevelsForPendingAcct: '一旦您的账户获得批准，您将可以交易期权。',
                youveEnabledOptions: '您已为此账户启用了期权。',
                optionsNotEnabledDesc: '此账户未启用期权交易。期权交易使您可以交易合同，以获利于资产价格的涨跌。',

                marginAgreementAddedDesc: '看起来您已为此账户添加了保证金协议。保证金协议为您提供了更多期权策略、投机性资产（如期货）等的访问权限。',
                accountDoesNotHaveMarginDesc: '此账户未备案保证金协议。保证金协议为您提供了更多期权策略、投机性资产（如期货）等的访问权限。',

                corporateActionDesc: '公司行动是公司采取的直接影响股东价值的行动。',
                corporateActionsAvailable: '可用的公司行动',
                corporateActions: '公司行动',
                viewCorporateActions: '查看公司行动',
                noCorporateActionsAvailable: '无可用的公司行动',

                enableOptions: '启用期权交易',
                gotoAccount: '前往账户',
                closeAccount: '关闭账户',
                contactUsToCloseAccount: '请与我们联系，我们将帮助您关闭账户。',
                options: '期权',
                margin: '保证金',
                changeOptionLevel: '更改期权等级',
                marginEnabled: '已启用保证金',
                unableToFindInformation: '看起来我们无法找到有关您的账户的信息。非常抱歉！',
                ohNo: '哦不！',
                execute: '您可以执行以下操作：',
                useButtonModify: '使用上面的按钮进行修改。',
                renameAccount: '重命名账户',
                cancel: '取消',
                general: {
                    general: '一般',
                    dateOpened: '开户日期',
                    lastStatement: '最近报表',
                    status: '状态',
                    restrictionCode: '限制代码'
                }
            }
        },

        paperless: {
            goPaperless: '开启无纸化',
            paperless: '无纸化',
            confirms: '确认',
            stonexCommunications: 'StoneX 通讯',
            investorRelations: '投资者关系',
            prospectus: '招股说明书',
            statements: '账单',
            taxDocuments: '税务文件',
            goCompletelyPaperless: '全面无纸化',
            goPaperlessLine1: '准备全面无纸化？按上方按钮，选择通过电子邮件接收所有账户文件，而不是通过邮寄。',
            goPaperlessLine2: '或者，自定义账户的交付选项如下。',
            completelyPaperlessExplain: '通过电子邮件接收所有文件，同时保护环境。',
            allMail: '所有邮件',
            allEDelivery: '所有电子邮件',
            allElectronic: '全部电子',
            mail: '邮寄',
            edelivery: '电子邮件',
            paperlessButton: '您已100%无纸化！',
            customizeOptions: '在下方自定义账户的交付选项。',
            youArePaperless: (percent: string) => `您已实现${percent}的无纸化`,
            paperlessPopup: {
                title: '请确认',
                message: '这将使您的所有账户不再通过邮寄交付文件。继续吗？',
                confirm: '是的，开启无纸化'
            }
        },
        bioauth: {
            biometrics: '生物特征识别',
            whatBioauthIs: '生物特征登录允许您使用生物特征（如面部或指纹）快速登录。',
            bioauthIsCurrentlyOnOff: (on: boolean) => [
                { text: '生物特征登录当前为', shade: 'light' },
                { text: on ? '开启' : '关闭', shade: 'dark' },
                { text: '。', shade: 'light' }
            ],
            clearMyDataAndTurnBioauthOff: '清除我的登录数据并关闭生物特征登录',
            turnBioauthOn: '开启生物特征登录',

            whatBiounlockIs: '如果您启用了生物特征登录，您可以通过需要生物特征来解锁 StoneX One，防止他人使用您的登录。',
            biounlockIsCurrentlyOnOff: (on: boolean) => [
                { text: '生物特征解锁当前为', shade: 'light' },
                { text: on ? '开启' : '关闭', shade: 'dark' },
                { text: '。', shade: 'light' }
            ],
            turnBiounlockOff: '关闭生物特征解锁',
            turnBiounlockOn: '开启生物特征解锁',

            errors: {
                unableToLogYouIn: '抱歉，我们无法为您登录。',
                somethingWentWrong: '抱歉，出现了一些问题。',
                couldNotVerifyYourIdentity: '抱歉，我们无法验证您的身份。'
            },

            verifyYourLoginToActivate: '验证您的登录以激活生物特征',
            verify: '验证',
            email: '电子邮箱',
            password: '密码'
        },

        changePassword: {
            changeFailedTitle: '更新失败',
            changeFailedMessage: '抱歉，但我们无法更新您的密码。请重试，或联系以下支持人员',
            validationFailedTitle: '更新失败',
            validationFailedMessage: '一些输入缺失或无效。请确保您已输入现有密码，并且两次输入的密码正确。',
            authenticationFailedTitle: '无法确认身份',
            authenticationFailedMessage: '您输入的现有密码不正确',
            tryAgain: '重试',
            contactSupport: '联系支持',
            changePassword: '修改密码',
            forgotPassword: '忘记密码',
            currentPassword: '现有密码',
            newPassword: '新密码',
            confirmPassword: '确认密码',
            updatePassword: '更新密码',
            passwordMinLength: (minLength: number) => `密码长度必须至少为${minLength}个字符`,
            passwordLetter: '密码必须包含至少一个字母',
            passwordRequirementsMessage: (minLength: number) => `密码必须至少包含 ${minLength} 个字符，并且至少包含一个大写字母，一个小写字母和一个数字。`,
            cannotReuseOldPassword: '新密码不能与现有密码相同',
            newPasswordConfirmMismatch: '新密码和确认密码不匹配',
            passwordRequirements: '密码要求',
            passwordRecoveryRequirements: (minLength: number) => [`必须至少包含 ${minLength} 个字符`, '必须至少包含一个大写字母', '必须至少包含一个数字']
        }
    },
    documentsScreen: {
        documents: '文档',
        filters: '筛选',
        name: '名称',
        date: '日期',
        account: '账户',
        preview: '预览',
        download: '下载',
        share: '分享',
        moreActions: '更多操作',
        viewDocument: '查看文档',
        allAccounts: '所有账户',
        downloading: '正在下载...',
        saveToDownloads: '保存至下载目录',
        documentNotFound: '没有找到该日期的文件。',
        docTypes: {
            taxDocuments: '税务文件',
            intlPublicForms: '国际公共表格',
            ifsNewAccts: 'IFS NEWACCTS',
            marginRequirements: '客户保证金要求',
            tradeConfirmations: '交易确认',
            checks: '支票',
            accountStatements: '账户报表',
            accountNotices: '账户通知',
            detailedVariationAnalysis: '详细波动分析',
            moneyOptionReport: '货币期权报告',
            memoStatement: '备忘账单',
            monthlyDiscretionaryAccounts: '月度自主账户',
            annualStatement: '年度报表',
            quaterlyStatement: '季度报表',
            monthlyStatementExcel: '月度报表（Excel）',
            monthlyStatement: '月度报表',
            dailyStatement: '日报表',
            fullyPaidEarnings: '全额支付的收入',
            fullyPaidLendingConfirms: '全额付款贷款确认书',
            fullyPaidLendingPositions: '全额支付的贷款头寸'
        },
        downloadToasts: {
            savingUnavailableTitle: '无法保存',
            savingUnavailableDesc: '抱歉，您的设备不支持保存。',
            permissionDenied: '权限被拒绝',
            allowStonexSaveFiles: '请允许 StoneX One 保存文件到您的设备。',
            fileSavedTitle: '文件已保存',
            fileSavedInDevice: (foldername: string) => `您的文件已保存至设备的${foldername}目录`,
            savingErrorTitle: '保存错误',
            problemDownloading: '抱歉，下载文件时出现问题。'
        },
        docNames: {
            statement: (doc: SnexDocument) => `${doc.accountNumber} ${SafeFormat(doc.documentDate, 'MMMM')} 报表`,
            check: (doc: SnexDocument) => doc.name,
            misc: (doc: SnexDocument) => doc.name,
            taxes: (doc: SnexDocument) => `${doc.accountNumber} ${SafeFormat(doc.documentDate, 'yyyy')} 税务文件`
        },
        symbol: '代码',
        type: '类型',
        noDocumentsTitle: '没有找到文件',
        noDocumentsDescription: '根据以下筛选条件，我们找不到任何文件。',
        searchDocuments: '搜索文档',
        goPaperless: '全面无纸化',
        modal: {
            loading: '获取文档中...',
            untitled: '无标题文档'
        }
    },

    tradeHistory: {
        tradeHistory: '订单历史',
        filters: '筛选',
        account: (accountNumber?: string) => (accountNumber ? `账户 #${accountNumber}` : '账户'),
        allAssetClasses: '所有产品',
        equities: '股票与期权',
        futures: '期货及期权',
        futuresOptions: '期货期权',
        mutualFunds: '共同基金',
        allAccounts: '所有账户',
        futuresTimeSpreads: '期货价差',
        cryptos: '加密货币',
        invalidFilters: {
            popoverHeader: '无效的过滤器',
            popoverBody: '您的一些过滤器相互抵消。请重新检查您的过滤器，然后再次尝试搜索。',
            popoverActionLabel: '好的'
        },
        status: {
            status: '状态',
            assetClass: '产品',
            all: '所有',
            allStatuses: '所有状态',
            openAll: '全部开放',
            closed: '已关闭',
            closedAll: '全部已关闭',
            verification: '验证',
            created: '已创建',
            open: '开放/进行中',
            route: '路径',
            routed: '已路由',
            cancel: '取消',
            canceled: '已取消',
            filled: '已成交',
            rejected: '已拒绝',
            deleted: '已删除',
            expired: '已过期',
            confirmed: '已确认',
            unknown: '未知',
            pending: '等待中',
            completed: '已完成',
            cancelled: '已取消',
            cancelPending: '取消等待中',
            cancelPartialFill: '部分取消',
            allocated: '已分配',
            preAllocated: '预分配'
        },
        orders: '订单',
        symbol: '代码',
        accountTitle: '账户',
        downloadTooltip: '全部下载',
        fetchingError: '抱歉！在获取您的交易历史时出现问题。',
        noOrdersFound: '未找到订单',
        noOrdersFoundDescription: '根据以下筛选条件，我们找不到任何符合的订单。',
        downloadFilename: () => `交易历史-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`
    },

    transactionsScreen: {
        tooltips: {
            partialResults: '部分结果返回'
        },
        account: '账户',
        transactions: '交易',
        symbolId: '代码',
        date: '日期',
        type: '类型',
        anyType: '任意类型',
        amount: '金额',
        description: '描述',
        noTransactionsTitle: '无交易记录',
        noTransactionsDesc: '您的交易、转账、红利、利息以及所有其他账户活动将在此显示。',
        sendingOrder: '发送订单中...',
        tradeDescription: (m: Transaction) => {
            const verb = (() => {
                if (m.action?.toUpperCase() === 'BUY') return '购买';
                else if (m.action?.toUpperCase() === 'SELL') return '卖出';
                else return m.action;
            })();
            const unit = Snex1Mandarin.general.unit(m.symbol, m.quantity, m.securityType);
            const decimalPlaces = `${m?.price}`.split('.')[1]?.length;
            return [SecurityTypes.Futures, SecurityTypes.FuturesOptions].includes(m?.securityType)
                ? `${verb} ${FormatNumber.toDecimal(m.quantity, 3)} ${m.description}`
                : `${verb} ${FormatNumber.toDecimal(m.quantity, 3)} ${unit} ${m.symbol} @ ${FormatNumber.toLocaleDecimal({
                      decimalPlaces,
                      value: m.price
                  })}`;
        },
        expand: '展开',
        action: '操作',
        quantity: '数量',
        price: '价格',
        baseCurrency: '基本货币',
        accountType: {
            accountType: '账户类型',
            cash: '现金',
            longMargin: '保证金（多头）',
            shortMargin: '保证金（空头）',
            annuitiesAway: '分离年金',
            stockLoan: '股票借贷',
            tefraWithhold: 'TEFRA 扣缴',
            whenIssued: '发行日'
        },
        securityType: '证券类型',
        asOfDate: '截至日期',
        tradeDate: '交易日期',
        settleDate: '结算日期',
        principal: '本金',
        interest: '利息',
        cancelStatus: '取消状态',
        rebillStatus: '重账状态',
        controlNumber: '控制号码',
        securityNumber: '证券号码',
        security: '证券',
        exportAll: (accountNumber: string) => `导出 ${accountNumber} 的所有交易`,
        filename: (accountNumber: string) => `交易-${accountNumber}-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`,
        filters: {
            filters: '筛选条件',
            sort: '排序',
            date: '日期'
        },
        processDate: '处理日期',
        netAmount: '净金额',
        defaultSort: '默认排序',
        ascending: '升序',
        descending: '降序',
        search: '搜索',
        all: '全部',
        swipeUpSubmit: '上滑提交',
        activityType: {
            tradeActivity: '交易活动',
            cashAndEquivalents: '现金和等值物',
            dividendsAndInterest: '红利和利息',
            options: '期权',
            receiveAndDeliver: '接收和交付',
            exerciseAndExpiration: '行权和到期',
            stockLoans: '股票借贷',
            misc: '杂项',
            deposit: '存款',
            withdrawal: '提款',

            trade: '交易',
            cash: '现金',
            dividentInterest: '红利 / 利息',
            exerciseExpire: '行权 / 到期',
            receiveDeliever: '接收 / 交付',
            receiveDelieverAbbrev: '接 / 交', // Abbreviated on Android,
            funding: '资金',
            other: '其他'
        }
    },

    projectedIncomeScreen: {
        projectedIncome: '预计收入',
        accountNumber: '账户号码',
        symbol: '代码',
        saveFilter: '保存筛选',
        reset: '重置',
        viewAll: '查看全部',
        pleaseSelectAnAccount: '请选择一个账户以查看您的预计收入',
        noDataAvailable: '所选账户没有可用的数据',
        name: '名称',
        income: '收入',
        yield: '收益率',
        paymentDate: '付款日期',
        nextPaymentDate: '下次付款',
        maturityDate: '到期日期',
        frequency: {
            frequency: '频率',
            semiAnnually: '半年度',
            quarterly: '季度',
            monthly: '月度',
            annually: '年度'
        },
        projectedTotal: '预计总额',
        projectedFor: (qsi: string) => `预计 ${qsi}`,
        couponRate: '票息率',
        type: '类型',
        downloadTooltip: '下载预计收入',
        downloadFilename: () => `预计收入-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`,
        overall: '总体',
        selectAnAccountEmptyTitle: '您的预计收入将在此显示。请选择一个账户以查看。',
        incomeBreakdown: '收入分析',
        totalProfits: '周期内总利润：',
        noIncomeForMonth: '无预计收入',
        selectAnotherPeriod: '在上面的图表中选择另一个周期以查看您的预计收入。',
        noIncomeProjection: '无可用的收入预测',
        noPositionSecurity: '看起来这个账户没有在任何支付红利的证券中持有头寸。'
    },
    disclosuresPage: {
        disclosures: '披露',
        documentContent: {
            pricingDisclosure: ['头寸价格截至最近一次交易。如果股票证券今天未交易，价格信息基于前一个工作日的最佳买价。当前买入或卖出可能与显示的价格不同。'],
            projectedIncomeDisclosure: [
                '某些类型的证券的预计收入和预计收益率可能包括本金回报或资本利得，在这种情况下，预计收入和预计收益率将被高估。',
                '预计收入和预计收益率是估计值，实际收入和收益率可能低于或高于估计值。',
                '预计收益率仅反映投资产生的收入，不反映其价格的变化，价格可能波动。'
            ],
            marketIntelligenceDisclosure: [
                {
                    text: '此处所包含的任何内容均不得被解释为赋予订阅者或任何第三方根据任何法律（无论是普通法还是法定法律）、规则或法规（包括但不限于与版权或其他知识产权权利有关的法律）的无限制许可或权利，无论是明示、禁止或其他方式。'
                },
                {
                    text: '此报告及其内容不应被视为财务建议。此报告及其中的内容仅供订阅者用于信息目的，不是从合格的个人、公司或法人处获得专业建议的替代品，必要时应从合格的个人、公司或法人处获得专业建议。不保证此报告及其内容的准确性、完整性、充足性或及时性。订阅者承认，此报告及其中的内容可能是使用、合并或引用第三方信息的工作成果。'
                },
                {
                    text: '未经授权使用此报告及其内容是完全禁止的。此报告及其内容仅供订阅者按照其订阅进行使用，不得复制、修改、复制、再版、分发、显示或传输用于商业、非盈利或公共目的的全部或任何部分，以及其它为其自身或任何第三方的利益或利益。为了避免疑义，订阅者不得使用此报告及其内容传输任何虚假、误导、欺诈或非法的通信。'
                },
                {
                    text: 'StoneX Group Inc. 对基于订阅、订阅者使用以及任何第三方使用此报告及其内容产生的任何目的而产生的依赖不承担任何责任。'
                }
            ]
        },
        documentTitles: {
            termsAndConditions: '条款和条件',
            pricingDisclosure: '定价披露',
            generalDisclosures: '一般披露',
            cryptoDisclosure: '加密货币披露',
            projectedIncomeAndYieldDisclosure: '预计收入和收益披露',
            marketIntelligenceDisclosure: '市场情报披露'
        }
    },

    securityScreen: {
        premarket: '盘前',
        postmarket: '上市后',
        afterhours: '盘后',
        advancedChart: '高级图表',
        advancedDomAndChart: '高级 DOM 和图表',
        deepAnalysis: '深度分析',
        tradeOptions: '交易期权',
        tradeFuturesSpreads: 'TODO',
        notFoundHeader: (qsi: string) => `${qsi} 未找到`,
        notFoundSubText: '看起来我们找不到关于这个代码的任何信息。',
        searchForSomethingElse: '搜索其他内容',
        enableOptionTrading: '启用期权交易',
        changeOptionLevel: '更改期权级别',
        addToList: '添加至列表',
        notOnAnyOfYourLists: '不在您的任何列表中',
        lineChart: '折线图',
        candlestick: '蜡烛图',
        showVolume: '显示成交量',
        tomorrow: '明天',
        today: '今天',
        security: '证券',
        securities: '证券',
        openOrders: '未成交订单',
        viewAll: '查看全部',
        viewAllSpreads: 'TODO',
        viewFewer: '查看更少',
        viewMore: '查看更多',
        viewLess: '查看更少',
        chartDataComingSoon: '图表数据即将推出',
        problemLoadingSecuritiesFor: (qsi: string) => `加载与 ${qsi} 相关的证券时出现问题`,
        noRelatedSecuritiesFor: (qsi: string) => `没有与 ${qsi} 相关的证券可用`,
        about: {
            about: (symbol: string) => '关于',
            noDescriptionAvailable: '没有可用的描述',
            readMore: '阅读更多',
            readLess: '收起',
            ceo: '首席执行官',
            employees: '员工',
            headquarters: '总部',
            website: '网站',
            exchange: '交易所',
            open: '开放',
            unavailable: '不可用',
            whitepaper: '白皮书',
            reddit: 'Reddit',
            coin: '币种'
        },
        stats: {
            stats: '统计',
            specifications: '规格',
            bid: '买入价',
            ask: '卖出价',
            open: '开盘价',
            close: '收盘价',
            previousClose: '前一日收盘价',
            previousSettle: '前一日结算价',
            high: '最高价',
            low: '最低价',
            previousVolume: '前一日成交量',
            volume: '成交量',
            volume24h: '成交量(24小时)',
            averageVolume: '平均成交量',
            averageVolume7d: '平均成交量(7天)',
            week52High: '52周最高价',
            week52Low: '52周最低价',
            marketCap: '市值',
            peRatio: '市盈率',
            ytdChange: '年初至今涨跌幅',
            startTime: '开始时间',
            stopTime: '停止时间',
            endTime: '结束时间',
            contractSize: '合约大小',
            tickSize: '最小价位变动',
            tickPrice: '最小价格变动',
            initialMargin: '初始保证金',
            dayTradeMargin: '当日交易保证金',
            dayTradeMarginToolTip: '日内交易保证金的提供时间为中部时间上午 7:45 至下午 3:30',
            maintenanceMargin: '维持保证金',
            changePercent: '改变 ％',
            low52: '52低',
            high52: '52高'
        },
        ratings: {
            analystRatings: '分析师评级',
            asOf: '截至',
            noRatingsAvailable: '暂无分析师评级',
            analystRatingExplain: (secName: string, action: string) => `平均而言，分析师目前将 ${secName} 评为${action}`,
            analystRatingExplainShaded: (secName: string, action: string) => [
                {
                    shade: 'light',
                    text: '平均而言，分析师目前将 '
                },
                { shade: 'dark', text: secName },
                { shade: 'light', text: ' 评为' },
                { shade: 'dark', text: action }
            ],
            aConsensusOf: (totalNumberOfRatings: number, consensusDate: string | Date) => [
                { text: '基于 ', shade: 'light' },
                {
                    text: `${FormatNumber.toCommas(totalNumberOfRatings)} 条评级${totalNumberOfRatings === 1 ? '' : 's'}`,
                    shade: 'dark'
                },
                { text: ' 的共识，截至于 ', shade: 'light' },
                {
                    text: SafeFormat(consensusDate, 'yyyy年M月d日'),
                    shade: 'dark'
                }
            ],
            noRatingsDataAvailble: (secName: string) => `目前没有 ${secName} 的评级可用。`,
            problemLoadingRatings: (secName: string) => `加载 ${secName} 的评级时出现问题`,
            noRatingsAvailableFor: (secName: string) => `${secName} 暂无评级`,
            buy: '买入',
            sell: '卖出',
            hold: '持有',
            totalRatings: '总评级数'
        },
        earningsEstimates: {
            earningsAndEstimates: '收益与预估',
            actual: '实际',
            estimated: '预估',
            actualEPS: '实际每股收益',
            estimatedEPS: '预估每股收益',
            quarter: (quarterNumber: number) => `${quarterNumber} 季度`,
            noData: (securityName?: string) => `暂时没有关于 ${securityName || '这只证券'} 的收益数据`,
            problemLoadingEarnings: (securityName: string) => `加载 ${securityName} 的收益时间线时出现问题`,
            noEarningsTimeline: (securityName: string) => `${securityName} 暂无收益时间线`
        },
        options: {
            options: '期权',
            optionsTitle: (symbol: string) => `${symbol} 期权`,
            optionsSublabel: '交易看涨期权和看跌期权',
            contracts: '合约',
            value: '价值',
            todaysGainLoss: '今日盈亏',
            totalGainLoss: '总盈亏',
            account: '账户',
            exp: '到期',
            contractsLong: (quantity: number) => `${FormatNumber.toCommas(quantity)} ${Snex1Mandarin.general.contracts(quantity)}多头`,
            contractsShort: (quantity: number) => `${FormatNumber.toCommas(quantity)} ${Snex1Mandarin.general.contracts(quantity)}空头`,
            unknownExpiration: '未知到期',
            contract: (n: number) => Snex1Mandarin.general.contracts(n),
            timeSpreadsTitle: (symbol: string) => `${symbol} 时间差`,
            timeSpreadsSublabel: '在不同到期日买入和卖出合约',
            noTimeSpreadsSublabel: '本合同不提供时间差'
        },
        history: {
            history: '历史',
            tradeHistory: '查看交易历史',
            orderHistory: '查看订单历史',
            viewMoreHistory: '查看更多历史记录'
        },
        related: {
            related: '相关',
            relatedEtfs: '相关 ETF',
            noRelatedStocks: '暂无相关证券'
        },
        holdings: {
            account: '账户',
            accountsHolding: '持有账户',
            acct: (number: string) => `账户号码#${QualifiedId.Format(number)}`,
            assetsInFunds: '% 资产在基金中',
            averageCost: '平均成本',
            cost: '成本',
            marketValue: '市值',
            marketValueTooltip: '这些值基于前一天。今天填充的任何订单不计入账户。',
            marketValueTooltipFutures: '市值是您所拥有的合约数量乘以平均成本。',
            portfolio: '投资组合',
            portfolioDiversity: '投资组合分配',
            positionPrice: '头寸价格',
            quantity: '数量',
            share: (n: number) => Snex1Mandarin.general.shares(n),
            shares: '股',
            showAllAccounts: '显示所有账户',
            todaysReturn: '今日盈亏',
            topHoldings: '头部持股',
            totalReturn: '总回报',
            viewMore: '查看更多',
            yourMarketValue: '您的市值',
            yourPosition: '您的头寸',
            buyMore: '购买更多',
            shortMore: '加大做空',
            sell: '卖',
            buy: '买',
            buyCover: '购买封面',
            sellCover: '卖封面',
            coverPosition: '平仓',
            sellQsi: (qsi: string) => `出售 ${qsi}`,
            noTopHoldingAvailable: (security) => `暂无 ${security} 的头部持股信息`,
            openPnL: '未平盈亏',
            openPnLPercent: '未平盈亏%'
        },
        news: {
            news: '新闻',
            marketIntelligence: '市场情报',
            andNews: '和新闻',
            newsAndMI: '新闻和市场情报',
            newsUnavailable: '当前无新闻',
            noNewsFor: (symbol?: string) => `暂无 ${symbol || '这只证券'} 的新闻`
        },
        crypto: {
            comingSoon: '加密货币交易即将推出',
            marketComparison: {
                title: '市场对比',
                pastYear: '过去一年'
            },
            socialActivity: {
                title: '社交活动',
                twitterFol: 'Twitter 关注者',
                redditFol: 'Reddit 关注者',
                githubFol: 'Github 关注者'
            },
            popularity: '受欢迎程度',
            circulatingSupply: '流通供应量',
            totalSupply: '总供应量',
            maxSupply: '最大供应量',
            coins: '硬币'
        }
    },

    sectorScreen: {
        sectors: '行业',
        sectorNames: {
            consumerDiscretionary: '消费者自由选择',
            healthCare: '医疗保健',
            technology: '科技',
            realEstate: '房地产',
            consumerStaples: '日用品',
            utilities: '公用事业',
            communicationServices: '通信服务',
            financials: '金融',
            materials: '材料',
            industrials: '工业',
            energy: '能源'
        },
        symbol: '符号',
        name: '名称',
        price: '价格',
        change: '变化',
        changePercent: '变化百分比',
        marketCap: '市值',
        item: '项',
        items: '项',
        securityChangeSelector: {
            rising: '上涨',
            falling: '下跌'
        },
        marketCapSelector: {
            large: '大',
            mid: '中',
            small: '小',
            micro: '微小',
            nano: '纳米',
            andBelow: '及以下'
        }
    },
    fundScreen: {
        minimumInvestmentNotMet: (minInvestment) => `最低投资未达标: ${FormatNumber.toMoneyOpt2(minInvestment, { showDecimals: false })}`,
        profile: {
            about: '关于',
            noDescription: '暂无描述',
            category: '类别',
            inceptionDate: '创立日期',
            indexTracked: '跟踪指数',
            netAssetValue: '资产净值',
            totalAssets: '总资产',
            beta: '贝塔',
            deferredLoad: '递延费',
            expenseRatio: '费用比率',
            fee12b1: '12B1费用',
            holdingsTurnover: '持股成交量',
            frontLoad: '前端费用',
            family: '基金家族',
            manager: '基金经理',
            maxRedepmtionFee: '最大赎回费',
            minimumInvestment: '最低投资',
            minimumInitialInvestment: '最低初始投资',
            aum: '资产管理规模',
            averageVolume: '平均成交量',
            companySymbol: '公司',
            priceToBook: '市净率',
            priceToEarnings: '市盈率',
            website: '网站',
            open: '开盘',
            benchmark: '基准',
            stats: '统计数据',
            bid: '买入价',
            ask: '卖出价'
        },
        countryExposure: {
            countryExposureTitle: '国家敞口',
            noExposureDataAvailable: '暂无敞口数据',
            country: '国家',
            exposure: '敞口',
            countries: '国家',
            noCountryExposure: '暂无国家敞口数据'
        },
        sectorExposure: {
            sectorExposureTitle: '这个基金包含什么？',
            noSectorExposure: '暂无行业敞口数据',
            sectors: '行业'
        },
        // Offshore mutual fund stats
        Alternative: '选择',
        Equity: '公平',
        FixedIncome: '固定收益',
        Guaranteed: '有保证',
        Hedge: '树篱',
        High: '高',
        Low: '低',
        Medium: '媒介',
        MoneyMarket: '货币市场',
        MultiAsset: '多资产',
        Others: '其他',
        RealEstate: '房地产',
        Sector: '部门',
        SpanishDomestic: '西班牙国内',
        Traditional: '传统的',
        active: '积极的',
        assetClass: '资产类别',
        cutoffTimeRedemptions: '截止时间（兑换）',
        cutoffTimeRedemptionsDescription: '表示基金赎回的截止时间，以欧洲标准时间显示。',
        cutoffTimeSubscriptions: '截止时间（订阅）',
        cutoffTimeSubscriptionsDescription: '表示基金认购的截止时间，以欧洲标准时间显示。',
        fundCountry: '基金国家',
        fundCurrency: '基金货币',
        fundGroup: '基金组',
        fundGroupDescription: '表示基金组的名称，由 AllFunds Bank 指定。',
        fundHouse: '基金屋',
        fundHouseCountry: '基金公司国家',
        fundHouseDescription: '处理不同类别基金的结算。包括处理资金转账的转账代理。',
        geographicArea: '地理区域',
        geographicZone: '地理区域',
        inceptionDate: '开始日期',
        inceptionDateDescription: '基金开始运营的日期。',
        maximumAmount: '最大金额',
        maximumAmountDescription: '表示基金允许认购的最大金额。',
        minAdditionalAmount: '最小附加金额',
        minAdditionalAmountDescription: '表示基金后续认购允许的最低金额。',
        minInitialAmount: '最小初始金额',
        minInitialAmountDescription: '表示基金首次认购允许的最低金额。',
        notActive: '不活跃',
        preNotificationDaysRedemptions: '预先通知天数（兑换）',
        preNotificationDaysRedemptionsDescription: '表示基金赎回基金所需的预先通知天数。',
        preNotificationDaysSubscriptions: '预通知天数（订阅）',
        preNotificationDaysSubscriptionsDescription: '表示基金认购该基金所需的预先通知天数。',
        redemptionsByAmount: '按金额兑换',
        redemptionsByAmountDescription: '指示是否允许按美元金额进行兑换。',
        redemptionsByShares: '按股份赎回',
        redemptionsBySharesDescription: '指示是否允许按股份金额赎回。',
        riskCategory: '风险类别',
        riskCategoryDescription: '基于基金的 SRRI 评级，指示风险类别，该类别基于损失或利润的风险。',
        settlementDaysRedemptions: '结算天数（赎回）',
        settlementDaysRedemptionsDescription: '表示基金赎回的标准结算天数。',
        settlementDaysSubscriptions: '结算天数（订阅）',
        settlementDaysSubscriptionsDescription: '表示基金认购的标准结算天数。',
        status: '状态',
        subAssetClass: '子资产类别',
        subscriptionsByAmount: '按金额订阅',
        subscriptionsByAmountDescription: '指示是否允许按美元金额进行订阅。',
        subscriptionsByShares: '按份额订阅',
        subscriptionsBySharesDescription: '指示是否允许按股份数量进行订阅。',
        totalSettlementDaysRedemptions: '总结算天数（赎回）',
        totalSettlementDaysRedemptionsDescription: '表示基金赎回的总结算天数，其中还包括基金要求的预先通知天数。',
        totalSettlementDaysSubscriptions: '总结算天数（订阅）',
        totalSettlementDaysSubscriptionsDescription: '表示基金认购的总结算天数，其中还包括基金要求的预先通知天数。',
        transferableFund: '可转让资金',
        transferableFundDescription: '指示资金是否可转让（符合 trapaso 资格），特别是针对西班牙市场。',
        typeOfFund: '基金类型',
        volatilityRange: '波动范围',
        volatilityRangeDescription: '基于基金的SRRI评级，表示基金的波动范围，这是衡量与基金价格波动相关的风险水平的指标。',

        srri: '综合风险和回报指标',
        potentiallyLowerReward: '可能较低的奖励',
        lowerRisk: '较低风险',
        higherRisk: 'l较高风险',
        potentiallyHigherReward: '潜在更高的奖励',
        potentialRiskVsReward: '潜在风险与回报',
        riskIndicator: '风险指标',
        risk: '风险'
    },
    deepAnalysisScreen: {
        viewFull: {
            statement: '查看完整报告',
            balanceSheet: '查看完整资产负债表',
            cashFlow: '查看完整现金流量表',
            viewAll: '查看全部'
        },
        deepAnalysis: '深入分析',
        quarterly: '季度',
        annual: '年度',
        showMore: '显示更多',
        showLess: '显示更少',
        showAll: '显示全部',
        financials: '财务数据',
        loadingFinancials: '正在加载财务数据...',
        sentimentAnalysis: '情绪分析',
        noFinancialsFoundFor: (s) => `没有找到${s}的财务数据`,
        noDataFoundFor: (s) => `没有找到${s}的数据`,
        fundamentals: {
            fundamentals: '基本面',
            projectedRevenue: '预计收入',
            dividends: '股息'
        },
        balanceSheet: {
            balanceSheet: '资产负债表',
            reportDate: '报告日期',
            filingType: '报表类型',
            fiscalDate: '财政日期',
            fiscalQuarter: '财政季度',
            fiscalYear: '财政年度',
            fixedAssets: '固定资产',
            currency: '货币',
            currentCash: '流动现金',
            shortTermInvestments: '短期投资',
            receivables: '应收账款',
            inventory: '库存',
            otherCurrentAssets: '其他流动资产',
            currentAssets: '流动资产',
            longTermInvestments: '长期投资',
            propertyPlantEquipment: '不动产、厂房和设备',
            propertyPlantEquipmentShort: '不动产 / 厂房 / 设备',
            goodwill: '商誉',
            intangibleAssets: '无形资产',
            otherAssets: '其他资产',
            totalAssets: '总资产',
            accountsPayable: '应付账款',
            accountsReceivable: '应收账款',
            currentLongTermDebt: '流动长期债务',
            otherCurrentLiabilities: '其他流动负债',
            totalCurrentLiabilities: '总流动负债',
            longTermDebt: '长期债务',
            otherLiabilities: '其他负债',
            minorityInterest: '少数股东权益',
            totalLiabilities: '总负债',
            commonStock: '普通股',
            retainedEarnings: '留存收益',
            treasuryStock: '库存股',
            capitalSurplus: '股本剩余',
            shareholderEquity: '股东权益',
            netTangibleAssets: '净有形资产',
            longTermLiabilities: '长期负债'
        },
        incomeStatement: {
            incomeStatement: '损益表',
            reportDate: '报告日期',
            filingType: '报表类型',
            fiscalDate: '财政日期',
            fiscalQuarter: '财政季度',
            fiscalYear: '财政年度',
            currency: '货币',
            totalRevenue: '总收入',
            costOfRevenue: '营业成本',
            grossProfit: '毛利润',
            researchAndDevelopment: '研发支出',
            sellingGeneralAndAdmin: '销售、一般和管理支出',
            operatingExpense: '营业支出',
            operatingIncome: '营业收入',
            otherIncomeExpenseNet: '其他收入/支出净额',
            ebit: '息税前利润（EBIT）',
            interestIncome: '利息收入',
            interestIncomeNonOperating: '非营业利息收入',
            interestExpense: '利息支出（非营业收入）',
            pretaxIncome: '税前利润',
            incomeTax: '所得税',
            minorityInterest: '少数股东权益',
            netIncome: '净利润',
            netIncomeBasic: '基本每股净利润'
        },
        cashFlow: {
            cashFlow: '现金流量表',
            capitalExpenditures: '资本支出',
            cashChange: '现金变化',
            cashFlowFinancing: '融资现金流',
            changesInInventories: '存货变动',
            changesInReceivables: '应收账款变动',
            currency: '货币',
            depreciation: '折旧',
            dividendsPaid: '已支付股息',
            exchangeRateEffect: '汇率影响',
            filingType: '报表类型',
            fiscalDate: '财政日期',
            fiscalQuarter: '财政季度',
            fiscalYear: '财政年度',
            investingActivityOther: '其他投资活动',
            investments: '投资',
            netBorrowings: '净借款',
            netIncome: '净利润',
            otherFinancingCashFlows: '其他融资现金流',
            reportDate: '报告日期',
            symbol: '符号',
            totalInvestingCashFlows: '总投资现金流'
        },
        earningsCalls: {
            earningsCalls: '盈利电话会议',
            noEarningsCallsAvailable: '暂无盈利电话会议',
            play: '播放',
            playing: '正在播放',
            translateEarningsCall: (str) => str
        },
        investors: {
            investors: '投资者',
            insiders: '内部人员',
            institutions: '机构',
            funds: '基金',
            insideInvestors: '内部投资者',
            institutionalInvestors: '机构投资者',
            fundInvestors: '基金投资者'
        },
        insiderRoster: {
            insiderRoster: '内部人员名单',
            noInsidersFound: (symbol: string) => `未找到${symbol}的内部人员名单`,
            shares: '股份',
            asOf: '截至'
        },
        topInsiders: '顶级内部人员',
        advancedStats: {
            advancedStats: '高级统计数据',
            beta: '贝塔',
            totalCash: '总现金',
            currentDebt: '流动债务',
            revenue: '收入',
            grossProfit: '毛利润',
            totalRevenue: '总收入',
            ebitda: 'EBITDA',
            revenuePerShare: '每股收入',
            revenuePerEmployee: '每员工收入',
            debtToEquity: '债务资本比',
            profitMargin: '利润率',
            enterpriseValue: '企业价值',
            enterpriseValueToRevenue: '企业价值/收入',
            priceToSales: '市销率',
            priceToBook: '市净率',
            forwardPERatio: '前进市盈率',
            pegRatio: 'PEG比率',
            peHigh: '高市盈率',
            peLow: '低市盈率',
            week52HighDate: '52周最高日期',
            week52LowDate: '52周最低日期',
            week52HighDateSplitAdjustOnly: '52周最高日期（仅考虑拆分调整）',
            week52LowDateSplitAdjustOnly: '52周最低日期（仅考虑拆分调整）',
            putCallRatio: '认购认沽比'
        },
        priceTarget: {
            priceTarget: '价格目标',
            averagePriceTarget: '平均价格目标',
            range: '范围',
            low: '低',
            median: '中位数',
            high: '高',
            average: '平均'
        },
        otherInvestors: {
            insititutionalInvestors: '机构投资者',
            fundInvestors: '基金投资者',
            holder: '持有者',
            shares: '股份',
            asOf: '截至',
            value: '价值'
        },
        splits: {
            splits: '拆分',
            noSplitsOnRecord: (symbol: string) => `未找到${symbol}的拆分记录`,
            exDate: '除息日',
            from: '从',
            to: '至',
            ratio: '比率',
            reverse: '反向拆分',
            translateDescription: (desc: string) => desc
        },
        estimatedRevenue: {
            estimatedRevenue: '预计收入',
            noData: (symbol: string) => `未找到${symbol}的收入预计数据`,
            avg: '平均',
            high: '高',
            low: '低',
            nAnalysts: '# 分析师',
            numberAnalysts: '分析师人数',
            period: '周期',
            revenueAvg: '平均收入',
            revenueHigh: '高收入',
            revenueLow: '低收入'
        },
        dividends: {
            dividends: '股息',
            noData: (symbol: string) => `未找到${symbol}的股息数据`,
            mostRecentDividend: '最近的股息',
            dividendForDate: '日期的股息',
            amount: '金额',
            currency: '货币',
            declaredDate: '宣布日期',
            exDate: '除权日',
            flag: '标志',
            frequency: '频率',
            paymentDate: '支付日期',
            recordDate: '登记日',
            symbol: '符号',
            date: '日期'
        },
        AccountsPayable: '应付账款',
        AccumulatedMinorityInterest: '累计少数股东权益',
        BalanceSheetItemType: '资产负债表项目类型',
        BookValuePerShare: '每股账面价值',
        CapitalExpenditures: '资本支出',
        CashDividendsPaid: '已支付现金股息',
        CashShortTermInvestments: '现金短期投资',
        ChangeInCapitalStock: '股本变化',
        ChangeInLongTermDebt: '长期债务变化',
        ChangesInWorkingCapital: '营运资本的变化',
        CommonDividends: '普通股利',
        ConsolidatedNetIncome: '综合净利润',
        CostOfGoodsSold: '已售商品成本',
        DecreaseInDeposits: '存款减少',
        DecreaseInLoans: '贷款减少',
        DeferredTaxAssets: '递延税资产',
        DeferredTaxLiabilities: '递延税项负债',
        DeferredTaxesInvestmentTaxCredit: '递延税投资税收抵免',
        DepreciationDepletionAmortizationCashFlow: '折旧消耗摊销现金流',
        EBITDA: '息税折旧及摊销前利润',
        EBITOperatingIncome: '息税前利润运营收入',
        EPSBasicBeforeExtraordinaries: 'EPS Basic Before Extraordinaries',
        EPSDilutedBeforeUnusualExpense: '在异常费用之前稀释 EPS',
        EPSFullyDiluted: 'EPS 完全稀释',
        EquityInEarningsOfAffiliatesIncome: '附属公司收入中的权益',
        ExchangeRateEffect: '汇率效应',
        ExtraordinaryItem: '非凡物品',
        ForeignCurrencyAdjustment: '外币调整',
        FreeCashFlow: '自由现金流',
        FundsFromOperations: '运营资金',
        GrossIncome: '总收入',
        IncomeStatementItem: '损益表项目',
        IncomeTaxPayable: '应交所得税',
        IncomeTaxes: '所得税',
        IncomeTaxesPayable: '应付所得税',
        IncreaseInDeposits: '存款增加',
        IncreaseInLoans: '增加贷款',
        IntangibleAssets: '无形资产',
        InterestExpense: '利息费用',
        Inventories: '库存',
        InvestmentTaxCredits: '投资税收抵免',
        IssuanceOfLongTermDebt: '长期债务的发行',
        IssuanceOrReductionOfDebtNet: '债务网的发行或减少',
        LongTermDebt: '长期债务',
        LongTermNoteReceivable: '长期应收票据',
        MinorityInterestExpense: '少数股东利息支出',
        MiscellaneousFunds: '杂项基金',
        NetAssetsFromAcquisitions: '收购产生的净资产',
        NetChangeInCash: '现金净变化',
        NetFinancingCashFlow: '净融资现金流',
        NetIncomeAvailableToCommonBasic: '可用于 Common Basic 的净收入',
        NetIncomeCashFlow: '净收入现金流',
        NetIncomeContinuingOperations: '净利润持续运营',
        NetInvestingCashFlow: '净投资现金流',
        NetOperatingCashFlow: '净运营现金流',
        NetPropertyPlantEquipment: '净财产工厂设备',
        NonEquityReserves: '非股权储备',
        NonOperatingIncomeExpense: '非营业收入支出',
        OperatingLeaseExpense: '经营租赁费用',
        OtherAfterTaxAdjustments: '其他税后调整',
        OtherAssets: '其他资产',
        OtherCurrentAssets: '其他流动资产',
        OtherCurrentLiabilities: '其他流动负债',
        OtherFinancingActivitiesUses: '其他融资活动用途',
        OtherFinancingActivity: '其他融资活动',
        OtherFunds: '其他基金',
        OtherLiabilities: '其他负债',
        OtherOperatingExpense: '其他运营费用',
        OtherProceedsfromStockOptions: '股票期权的其他收益',
        OtherSources: '其他来源',
        OtherUses: '其他用途',
        PreferredDividends: '优先股利',
        PreferredDividendsCashFlow: '优先股息现金流',
        PreferredStockCarryingValue: '优先股账面价值',
        PretaxIncome: '税前收入',
        ProceedsFromStockOptions: '股票期权收益',
        ProvisionForRisksCharges: '风险准备金',
        PurchaseOfInvestments: '购买投资',
        PurchaseOrSaleOfInvestments: '购买或出售投资',
        ReductionOfLongTermDebt: '长期债务减少',
        RepurchaseOfCommonPreferredStock: '回购普通优先股',
        ResearchAndDevelopment: '研究与开发',
        SaleOfCommonPreferredStock: '出售普通优先股',
        SaleOfFixedAssetsBusinesses: '固定资产出售业务',
        SaleOrMaturityOfInvestments: '投资的出售或到期',
        SalesOrRevenue: '销售额或收入',
        SellingGeneralAdministrativeExpenses: '销售一般行政费用',
        ShortTermDebt: '短期债务',
        ShortTermReceivables: '短期应收款',
        StockOptionCompensationExpense: '股票期权补偿费用',
        TangibleBookValuePerShare: '每股有形账面价值',
        TotalAssets: '总资产',
        TotalCommonEquity: '普通股总股本',
        TotalCurrentAssets: '流动资产总额',
        TotalCurrentLiabilities: '流动负债总额',
        TotalEquity: '总股本',
        TotalInvestmentsAndAdvances: '总投资和预付款',
        TotalLiabilities: '负债总额',
        TotalLiabilitiesStockholdersEquity: '总负债股东权益',
        TotalShareholdersEquity: '股东权益总额',
        UnusualExpense: '异常费用'
    },

    // Option List Screen
    optionListScreen: {
        ask: '卖价',
        askValue: '卖价',
        bid: '买价',
        bidValue: '买价',
        breakEven: '保本点',
        buy: '购买',
        call: '认购',
        change: '变动',
        changePercent: '变动%',
        close: '收盘价',
        contractsYouSelect: '您选择的合约将显示在此处。',
        high: '最高价',
        impliedVolatility: '隐含波动率',
        intrinsicValue: '内在价值',
        low: '最低价',
        open: '开盘价',
        openInterest: '未平仓合约数量',
        price: '最新价格',
        put: '认沽',
        sell: '卖出',
        sharePrice: '股价',
        strikePrice: '执行价格',
        theGreeks: '希腊人',
        disclaimer: '延误15分钟',
        optionGreeksTooltipIntro: '期权希腊字母用于衡量期权的风险，并衡量期权对构成该风险的变量的敏感性——这些变量由希腊字母 Delta、Gamma、Theta、Vega 和 Rho 表示。',
        optionGreeksTooltipDelta: 'Delta Δ：每 1 美元基础证券的变化率',
        optionGreeksTooltipGamma: 'Gamma Γ：Δ 的变化率',
        optionGreeksTooltipTheta: 'Theta θ：价格随时间的变化率',
        optionGreeksTooltipVega: 'Vega ν：期权价格与标的证券隐含波动率之间的变化率',
        optionGreeksTooltipRho: 'Rho ρ：期权价值与利率 1% 变化之间的变化率',
        optionGreeksDelayed: '延误15分钟',
        toBreakEven: '达到保本',
        tradeFutures: '交易期货',
        tradeOptions: '交易期权',
        tradeStock: '交易股票',
        viewMore: '查看更多',
        viewOptionChain: '查看期权链',
        optionChain: '期权链',
        volume: '成交量',
        calendarSpreads: 'TODO',
        expirationDate: 'TODO',
        lastPrice: 'TODO',
        floatingButtonText: (qsi: string) => `${qsi} 期权链`
    },

    // A Piece of Logic in src/components/TradeTicket/Option/OptionTradeInputPage.tsx(83,20) prevents this from being inside optionListScreen
    greeks: {
        delta: {
            text: '三角洲',
            symbol: 'Δ',
            tooltip: '每 1 美元基础证券的变化率'
        },
        gamma: {
            text: '伽玛',
            symbol: 'Γ',
            tooltip: 'Δ 的变化率'
        },
        theta: {
            text: '西塔',
            symbol: 'Θ',
            tooltip: '价格随时间的变化率'
        },
        vega: {
            text: '织女星',
            symbol: 'v',
            tooltip: '期权价格与标的证券隐含波动率之间的变化率'
        },
        rho: {
            text: '罗氏',
            symbol: 'ρ',
            tooltip: '期权价值与利率 1% 变化之间的变化率'
        }
    },

    enableOptionsScreen: {
        enableOptions: '启用期权',
        title: '看起来您还不能交易期权！',
        getStartedWithOptions: '通过在下方选择一个账户开始交易期权',
        noOptionsEnabled: '您的账户尚未启用期权。您想启用期权交易吗？',
        enableOptionsTrading: '是的，请启用期权交易',
        notNow: '暂时不要',
        noTradingOptionsEnabled: '您的任何账户都尚未启用期权交易。请选择一个账户开始交易。'
    },

    optionContractScreen: {
        expiresOnDate: (date: Date) => `到期日：${SafeFormat(date, 'dd MMM yyyy')}`,
        expiresSoon: '即将到期',
        symbolLastQuote: (symbol: string) => `${symbol} 最新行情`,
        tradeUnderlying: (symbol: string) => `交易 ${symbol}`,
        viewAllOptions: '查看所有期权',
        viewMoreHistory: '查看更多历史记录',
        viewMoreDetails: '查看更多详情',
        history: '历史记录',
        addLeg: 'Agregar pierna',
        removeLeg: 'Quitar pierna',
        stats: {
            stats: '统计数据',
            bid: '买价',
            ask: '卖价',
            previousClose: '前收盘价',
            breakEven: '保本点',
            open: '开盘价',
            close: '收盘价',
            high: '最高价',
            low: '最低价',
            volume: '成交量',
            openInterest: '未平仓合约数量',
            impliedVolatility: '隐含波动率',
            intrinsicValue: '内在价值'
        }
    },

    futuresContractScreen: {
        baseContract: '基础合约',
        expiresDate: (date: string | Date) => `到期日：${SafeFormat(date, 'dd MMM yyyy')}`,
        upgradeToPremium: '升级至市场情报高级版',
        viewMoreContracts: '查看更多合约',
        domNotInSession: {
            title: (symbol: string) => `${symbol} 未在交易时段`,
            message: '由于此合约不在交易时段内，订单簿当前不可用。'
        }
    },
    advancedChartScreen: {
        notifications: {
            orderNotPlaced: '未下订单',
            invalidSecurity: '安全符号无效',
            invalidAccount: '帐号无效',
            shortSellNotAllowed: '不允许从高级图表卖空'
        }
    },
    tradeTicket: {
        adjustedOptionsDisclaimer: '由于调整期权的流动性和结算风险，我们只能允许平仓订单。',
        betaMaintenanceHours: '非工作时间 - 上午 12 点至凌晨 1:59（东部标准时间）周日至周六不可进行交易',
        cancelEquityOrdersHours: '股票订单只能在系统维护/非工作时间之外取消，这些时间是东部时间凌晨12点到1:59，周日到周六。请稍后再试。',
        cancelEquityOptionsOrdersHours: '股票期权订单只能在系统维护/非工作时间之外取消，这些时间是东部时间凌晨12点到1:59，周日到周六。请稍后再试。',
        modifyEquitiesDayOrdersHours: '股票日间订单只能在东部时间上午8点到下午3:59之间进行编辑，周一到周五。如果您现在想更改此订单，请取消并重新提交新订单。',
        modifyEquitiesOptionsDayOrdersHours: '股票期权日间订单只能在东部时间上午8点到下午3:59之间进行编辑，周一到周五。如果您现在想更改此订单，请取消并重新提交新订单。',
        modifyEquitiesGtcOrdersHours: '股票良好直到取消订单只能在东部时间上午8点到下午4:59之间进行编辑，周一到周五。如果您现在想更改此订单，请取消并重新提交新订单。',
        modifyEquitiesOptionsGtcOrdersHours:
            '股票期权良好直到取消订单只能在东部时间上午8点到下午4:59之间进行编辑，周一到周五。如果您现在想更改此订单，请取消并重新提交新订单。',
        oneClickTradingTitle: '一键交易',
        timeFrames: '时间范围',
        position: '位置',
        sellAll: '全部出售',
        buyAll: '购买所有',
        oneClickTradingDescription:
            '下次下单时，跳过此屏幕。当您选择启用一键交易时，在下单之前我们不会让您再次检查交易\n\n您可以通过进入“偏好设置”下的“个人资料”部分关闭此功能。',
        misc: {
            noChangesToReview: '无变更需要审查',
            setQuantity: '设置数量',
            startTradingOptions: '开始交易期权',
            securityUnavailable: (symbol: string) => `抱歉，${Snex1Mandarin.general.symbolName(symbol)} 在StoneX One上不可交易。`,
            enableOptionsTradingNow: '现在启用期权交易',
            createFuturesAccount: '创建期货账户',
            createEquitiesAccount: '创建股票账户',
            accountPending: '此账户正在等待批准',
            applicationPending: '正在审核中',
            notAvailableToTrade: '此证券尚不可在StoneX One上交易',
            noPricing: '没有可用的定价信息。',
            noPricingCall: '请致电 1-866-443-5847 寻求帮助。',
            cannotEdit: '无法编辑',
            orderExtendedHoursError: (orderType: string) =>
                `在延长交易时段期间，${(orderType.charAt(0).toUpperCase() + orderType.slice(1)).replace('Stoplimit', '限价止损')}订单不可用`,
            extendedHoursMustBeChecked: '在延长交易时段期间，必须勾选延长交易框才能下单',
            badTimeInForce: '当前时间不支持所选的订单过期时间',
            badTimeForStrat: '此时无法使用',
            premarketRequired: '目前仅允许使用启用了盘前执行的订单',
            postmarketRequired: '目前仅允许使用启用了盘后执行的订单',
            notAvailableToEdit: '目前无法编辑订单',
            isHoliday: '市场休假期间无法交易',
            isUnderMaintenance: '当前为非交易时段。请稍后再试。',
            overnightMarginCost: '隔夜保证金成本',
            notInSession: (s, e) => `此证券的交易时段为${SafeFormat(s, 'h:mm bb')} - ${SafeFormat(e, 'h:mm bb')} 美东时间`,
            notInSessionOptions: (s, e, tz) => `期权交易只能在 ${SafeFormat(s, 'h:mm bb')} - ${SafeFormat(e, 'h:mm bb')} ${tz}`,
            notInSessionOptionsSymbolic: (s, e, tz) => `期权交易只能在 ${s} - ${e} ${tz}`,
            notInSessionFutures: (s, e) =>
                `此期货合约的交易时段为 ${s ? format(utcToZonedTime(s, 'America/Chicago'), 'h:mm bb') : '---'} - ${
                    e ? format(utcToZonedTime(e, 'America/Chicago'), 'h:mm bb z', { timeZone: 'America/Chicago' }) : '---'
                }`,
            notInSessionFuturesOptions: (s, e) =>
                `此期货合约的交易时段为 ${s ? format(utcToZonedTime(s, 'America/Chicago'), 'h:mm bb') : '---'} - ${
                    e ? format(utcToZonedTime(e, 'America/Chicago'), 'h:mm bb z', { timeZone: 'America/Chicago' }) : '---'
                }`,
            notInSessionShortSell: `卖空订单只能在东部标准时间周一至周五上午 9:30 至下午 4:00 之间下达。`,
            invalidEditTimeAdvisory: '在美东时间下午5:00 - 凌晨7:56之间无法编辑订单。',
            residualSharesWillBeSettled: '剩余的分数股将显示为待处理，并将在交易两天内自动结算。',
            minimumNotMetTitle: '未达到最低初始投资额',
            minimumNotMetSubtext: (minimumInvestment: number): string => `该基金要求最低初始投资额为 ${FormatNumber.toMoneyOpt2(minimumInvestment)}。`,
            closedToNewInvestors: '此基金已关闭，不接受新投资者',

            isWeekend: '周末无法交易此证券',
            notInSessionGeneral: '抱歉，当前不可交易此证券',
            securityTypeUnavailable: (type: TradeableSecurityType | 'loading') => {
                switch (type || 'unknown') {
                    case 'loading':
                        return '正在加载';
                    case 'unknown':
                        return '抱歉，当前不支持交易此类型的证券';
                    default:
                        return `抱歉，当前不支持交易此类型的证券：${Snex1Mandarin.general.productTypes[type]?.plural || '证券'}`;
                }
            },
            validatingOrder: '正在验证订单...'
        },

        input: {
            retry: '重试',
            cancel: '取消',
            notes: '备注',
            tradeNotPermitted: '不允许交易',
            orderNotAllowed: '订单不允许',
            cannotShort: '无法做空',
            invalidQuantity: '数量无效',
            strategy: '策略',
            x1pAlgo: 'X1P 算法',
            care: '谨慎',
            careDescription: '订单不被持有',
            vwap: 'VWAP',
            vwapDescription: '成交量加权平均价格',
            twap: 'TWAP',
            twapDescription: '时间加权平均价格',
            pov: 'POV',
            povDescription: '成交量百分比',
            sor: 'SOR',
            sorDescription: '智能订单路由',
            applyForMargins: '申请保证金',
            unknownTitle: (isError: boolean) => (isError ? '未知错误' : '未知警告'),
            unknownMessage: (isError: boolean) => (isError ? '对不起，发生了未知错误' : '对不起，发生了未知警告'),

            tradeNow: '立即交易',
            unitName: (symbol) => (FuturesSymbol.IsFuturesSymbol(symbol) || OptionSymbol.IsOptionSymbol(symbol) ? '合约' : '股'),
            futures: {
                enforceFractional: '无效的小数值',
                enforceTickSize: (tickSize: number) => `价格必须以 ${tickSize} 的增量计价。`,
                numberPerContract: (formattedNumber: string) => `每合约 ${formattedNumber} `,
                side: '方向'
            },
            action: {
                buy: '买入',
                sell: '卖出',
                tabFontSize: 20
            },
            buyingPower: '购买力',
            marketValue: '市值',
            buyingPowerAdvisory: (t) => `您没有足够的资金购买 ${t.quantity} 股 ${t.securityId}。`,
            addFundsToContinue: '请充值以继续',
            depositFunds: '充值',
            dismiss: '忽略',
            shares: '股',
            tradeWholeShares: '以整股交易',
            tradeDollarAmount: '以金额交易',
            securityCannotBeTraded: '此证券无法以此单位交易。',
            bid: '买入价',
            ask: '卖出价',
            mid: '中间价',
            vol: '成交量',
            held: (symbol) => `${Snex1Mandarin.general.unit(symbol, 2)} 持有`,
            options: {
                breakEven: '保本点',
                credit: '信用',
                debit: '借记',
                even: '平衡',
                last: '最后',
                netAsk: '净卖出价',
                netBid: '净买入价',
                midpoint: '中间点',
                underlyingShares: '标的股数量',
                contracts: '合约',
                sharesPerContract: (n: number) => `${FormatNumber.toCommas(n)} 股每合约`,
                actionToOpenClose: (t, openClose) => `${openClose === 'Open' ? '开仓' : '平仓'} ${t.action}`,
                unlimited: '无限制',
                maxGain: '最大收益',
                maxLoss: '最大亏损',
                enableOptionsTrading: '启用期权交易',
                upgradeOptionsLevel: '升级您的期权级别'
            },
            advisories: {
                notice: '注意',
                immediateExecution: {
                    orderPriceError: (priceName: 'limit' | 'stop', priceIs: 'above-market' | 'below-market') =>
                        `由于${priceName === 'limit' ? '限价' : '止损'}价格${priceIs === 'above-market' ? '高于' : '低于'}市价，订单将立即执行。`,
                    stopLimitPriceError: (priceIs: 'above-stop' | 'below-stop') =>
                        `由于限价价格${priceIs === 'above-stop' ? '高于' : '低于'}止损价格，一旦达到止损价格，您的订单可能会立即执行。`
                },
                insufficientValue: {
                    notEnoughBuyingPower: '购买力不足',
                    notEnoughMarketValue: '持有量不足',
                    depositFunds: '充值资金',
                    dismiss: '忽略'
                },
                invalidStopOrder: {
                    buy: '买入止损订单需要比当前市价更高的止损价格。',
                    sell: '卖出止损订单需要比当前市价更低的止损价格。'
                },
                amountIsEstimate: {
                    mainText: '此金额为估算值。',
                    subText: '您的订单将以最佳价格执行。'
                },
                notionalValueExceeded: {
                    mainText: '超过最大名义价值',
                    subText: '您的交易预估总成本超过了您的配置的最大交易价值。请联系客服获取更多信息。'
                }
            },
            dollars: '美元',
            limitPrice: '限价',
            stopPrice: '止损价',
            stopLimitPrice: '止损限价价',
            marketPrice: '市价',
            expiration: '到期日',
            orderType: {
                limit: '限价',
                loc: 'LOC',
                market: '市价',
                moc: 'MOC',
                stop: '止损',
                stoplimit: '止损限价'
            },
            reviewAction: (t) => `审查${t.action}`,
            reviewEdit: (t) => `审查${t.action}订单编辑`,
            reviewTrade: '评论贸易',
            buySymbol: (symbolName) => `购买${symbolName}`,
            sellSymbol: (symbolName) => `出售${symbolName}`,
            sellAll: (t, amount) => `全部卖出 ${FormatNumber.toDecimal(amount, 3)} ${Snex1Mandarin.general.unit(t.securityId, amount)}`,
            selectAnAccount: '选择账户',
            timeInForce: '有效时限',
            timesInForce: {
                day: '当日有效',
                gtc: '长期有效',
                fok: '全部成交或取消',
                ioc: '即时成交或取消'
            },
            advancedRouting: {
                notHeld: '不持有'
            },
            dvp: 'DVP',
            extendedHours: {
                GTXPre: '允许在延长交易时段执行此订单',
                GTXPost: '允许在延长交易时段执行此订单',
                nte: '允许在延长交易时段执行此订单'
            },
            totalCost: '总成本',
            estimatedSymbolAmount: (symbol: string) => `预计总数 ${symbol}`,
            estimatedTotalCredit: '预估信用',
            estimatedTotalCost: '预估总成本',
            totalGain: '总信用',
            estimatedTotalGain: '预估信用',
            error: '有问题',
            warning: '请确认',
            resubmit: '重试',
            submitAnyway: '继续',
            submitShort: '继续做空订单',
            continue: '继续',
            reviseOrder: '修改订单'
        },

        reinvestmentOptions: {
            reinvestmentOptions: '再投资选项',
            instructions: (t) =>
                `请指定您想将以下哪些收益再投资到您的 ${t.securityId} 股票仓位中。这些将是您的再投资偏好，用于此次以及今后通过账户 ${t.accountNumber} 购买的所有 ${t.securityId} 股票交易，将无法在之后更改。`,
            mutualFundInstructions: (t) =>
                `默认情况下，互惠基金分配将以自由信用余额形式应用于现金账户。如果您愿意再投资股息和/或资本利得分配，请在下面选择。设置的任何再投资偏好将立即生效，并将适用于所有今后通过账户 ${t.accountNumber} 进行的 ${t.securityId} 交易。请注意，互惠基金分配不受再投资偏好影响，不论是否再投资。我希望在以下证券中再投资收益：`,
            offshoreCashInstructions: (accountNumber: string, isin: string) =>
                `该离岸共同基金的分配会自动以现金（自由贷方余额）的形式存入您的帐户。这将立即应用，并将适用于以后通过帐户 ${accountNumber} 进行的所有 ${isin} 购买。通过继续执行此订单，您了解该离岸共同基金的优先权将设置为现金。`,
            offshoreReinvestInstructions: (accountNumber: string, isin: string) =>
                `该离岸共同基金的分配将作为再投资自动应用到您的帐户。这将立即应用，并将适用于以后通过帐户 ${accountNumber} 进行的所有 ${isin} 购买。通过继续执行此命令，您了解该离岸共同基金的优先权将设置为再投资。`,
            review: '审查',
            dividends: '股息',
            shortTermCapitalGains: '短期资本利得',
            longTermCapitalGains: '长期资本利得',
            editButtonText: '编辑再投资选择',
            saveButton: '保存偏好',
            reinvest: '再投资',
            mutualFundsDistribution: '无论是否再投资，互惠基金分配都需纳税',
            usePreferences: '使用偏好',
            shortTermsGains: '短期收益',
            longTermGains: '长期收益',
            shortTerm: '短期',
            longTerm: '长期',
            reinvestScreenDesc: (symbol: string, accountNumber?: string) =>
                `您可以将 ${symbol} 收益分配到现金账户的自由信用余额中，也可以将其重新投资到 ${symbol} 中。一旦您下单，这些偏好将用于通过账户 ${accountNumber} 进行的所有 ${symbol} 交易，您可以随时更改这些偏好。`
        },

        review: {
            confirmTrade: '确认',
            gtxDisclaimer:
                '在延长交易时段（包括盘前交易时段（周一至周五上午8:00至上午9:30 ET），以及盘后交易时段（周一至周五下午4:00至下午5:00 ET），在假日交易时段除外），存在独特的风险，例如更大的价格波动，流动性降低，买卖价差加大以及市场可见性降低，可能不适合所有投资者。StoneX One无法保证在任何给定证券上执行延长交易时段订单时会以最佳价格执行，也无法保证任何此类订单（包括市价订单）是否会被执行。在StoneX One上的延长交易时段下发订单，包括在任何StoneX One交易平台或移动平台上下发订单，即表示您理解在延长交易时段交易的风险。',
            offshoreDisclaimer:
                '离岸共同基金的截止时间、结算期和预先通知期因基金而异。您将收到该基金发行的第一个可用资产净值。投资前请参阅基金招募说明书，了解截止时间、基金费用和其他重要信息。离岸共同基金订单一经提交，不得通过系统取消或修改。请联系支持人员请求取消或修改。',
            equities: (priceFormatter, trade, currentMarketPrice, totalCost, limitPrice?: number, stopPrice?: number) => {
                const symbol = Snex1Mandarin.general.symbolName(trade.securityId, 'long');
                const formattedSymbol = Dark(symbol);
                const formattedLimitPrice = Dark(priceFormatter(limitPrice));
                const formattedStopPrice = Dark(priceFormatter(stopPrice));
                const formattedTotalOrderPrice = Dark(`$${totalCost.toFixed(2)}`);
                const formattedCurrentMarketPrice = Dark(priceFormatter(currentMarketPrice));

                const isOnCloseOrder = [OrderTypes.loc, OrderTypes.moc].includes(trade?.orderType);
                if (trade?.liquidate) return [Light('您正在'), Dark('出售您在'), formattedSymbol, Light(' 以'), Dark(' 市价'), Light('.')];

                const tifQualifierLogic = [
                    {
                        rule: trade?.timeInForce?.toUpperCase() === 'GTXPRE' || trade?.extendedTimeInForce?.toUpperCase() === 'GTXPRE',
                        value: '在东部标准时间（ET）的上午8点到下午5点之间'
                    },
                    {
                        rule: trade?.timeInForce?.toUpperCase() === 'NTE' || trade?.extendedTimeInForce?.toUpperCase() === 'NTE',
                        value: '在今天的扩展盘后交易时间内进行交易（下午4:00 - 5:00 EST）'
                    },
                    {
                        rule: (trade?.timeInForce?.toUpperCase() === 'DAY' || isOnCloseOrder) && !trade?.extendedTimeInForce,
                        value: '今天'
                    },
                    {
                        rule: true, // default to GTC
                        value: '在接下来的180天里'
                    }
                ];

                const tifQualifier = tifQualifierLogic.find((x) => x.rule).value;

                // if equities apply extended time in force check to tif qualifier
                const actionWording = trade?.action === 'Buy' ? '购买' : '出售';
                const actionWordVerb = trade?.action === 'Buy' ? '购买' : '出售';
                const actionWordNoun = trade?.action === 'Buy' ? '购买订单' : '出售订单';
                const xUnits = `${FormatNumber.toCommas(trade.quantity)} ${Snex1Mandarin.general.unit(trade.securityId, trade?.quantity)?.toLowerCase()}`;
                const unit = Snex1Mandarin.general.unit(trade.securityId, 1)?.toLowerCase();

                switch (true) {
                    case [OrderTypes.market, OrderTypes.moc].includes(trade?.orderType as OrderType): {
                        const perShare = isNaN(currentMarketPrice) ? '.' : `per ${unit}.`;
                        return [
                            Light('您正在'),
                            Dark(`${actionWording} ${FormatNumber.toCommas(trade.quantity)}`),
                            Light(`持有${Snex1Mandarin.general.unit(trade.securityId, trade.quantity)?.toLowerCase()}的`),
                            formattedSymbol,
                            Light('以'),
                            Dark('市场价格'),
                            ...(isNaN(currentMarketPrice) ? [] : [Light('当前'), formattedCurrentMarketPrice]),
                            Light(perShare)
                        ];
                    }
                    case [OrderTypes.limit, OrderTypes.loc].includes(trade.orderType):
                        return [
                            Light('如果'),
                            formattedSymbol,
                            Light('的价格达到'),
                            formattedLimitPrice,
                            Light(`${tifQualifier}，您将下一个`),
                            Dark(actionWordNoun),
                            Light('，数量为'),
                            Dark(xUnits),
                            Light('，价格为'),
                            formattedTotalOrderPrice,
                            Light('或更好。')
                        ];
                    case trade.orderType === 'stop':
                        return [
                            Light('如果'),
                            formattedSymbol,
                            Light('的价格达到'),
                            formattedStopPrice,
                            Light(`${tifQualifier}，您将下一个`),
                            Dark(actionWordNoun),
                            Light('，数量为'),
                            Dark(xUnits),
                            Light('，以'),
                            Dark('市场价格'),
                            Light('下单。')
                        ];
                    case trade.orderType === 'stoplimit':
                        return [
                            Light('如果'),
                            Dark(symbol),
                            Light('的价格达到'),
                            formattedStopPrice,
                            Light(`${tifQualifier}，您将下一个限价订单以`),
                            Dark(`${actionWordVerb} ${xUnits}`),
                            Light('的方式下单，订单金额为'),
                            formattedTotalOrderPrice,
                            Light('，限价为'),
                            formattedLimitPrice,
                            Light('或更好。')
                        ];
                }
            },
            tifQualifier(trade, formattedSymbol) {
                const isOnCloseOrder = [OrderTypes.loc, OrderTypes.moc].includes(trade?.orderType);
                if (trade?.liquidate) return [Light('您正在'), Dark('以市价出售您持有的全部股份'), Dark(formattedSymbol), Light('。')];

                const tifQualifierLogic = [
                    {
                        rule: trade?.timeInForce?.toUpperCase() === 'GTXPRE' || trade?.extendedTimeInForce?.toUpperCase() === 'GTXPRE',
                        value: '在东部标准时间（ET）的上午8点到下午5点之间'
                    },
                    {
                        rule:
                            trade?.timeInForce?.toUpperCase() === 'NTE' ||
                            trade?.extendedTimeInForce?.toUpperCase() === 'NTE' ||
                            trade?.timeInForce?.toUpperCase() === 'GTXPOST' ||
                            trade?.extendedTimeInForce?.toUpperCase() === 'GTXPOST',
                        value: '在今天的扩展盘后市场交易时段（美东时间下午4:00 - 5:00）'
                    },
                    {
                        rule: (trade?.timeInForce?.toUpperCase() === 'DAY' || isOnCloseOrder) && !trade?.extendedTimeInForce,
                        value: '今天'
                    },
                    {
                        rule: true, // 默认为GTC
                        value: '在接下来的180天里'
                    }
                ];

                return tifQualifierLogic.find((x) => x.rule).value;
            },
            actionText: (trade: Partial<TradeRequest>, assetClass?: AssetClass, secMeta?: SecurityMetadata) => {
                return {
                    actionWording: trade?.action === 'Buy' ? '购买' : '出售',
                    actionWordVerb: trade?.action === 'Buy' ? '购买' : '出售',
                    actionWordNoun: trade?.action === 'Buy' ? '购买订单' : '出售订单',
                    xUnits: `${FormatNumber.toCommas(trade.quantity)} ${Snex1Mandarin.general.unit(trade.securityId, trade?.quantity)?.toLowerCase()}`,
                    unit: Snex1Mandarin.general.unit(trade.securityId, 1)?.toLowerCase()
                };
            },
            equitiesV2: (assetClass, trade, secMeta, currentMarketPrice, totalCost, limitPrice?, stopPrice?) => {
                const symbol = Snex1Mandarin.general.symbolName(trade.securityId, 'long');
                const formattedSymbol = Dark(symbol);
                const formattedLimitPrice = Dark(assetClass.formatPrice(limitPrice, secMeta));
                const formattedStopPrice = Dark(assetClass.formatPrice(stopPrice, secMeta));
                const formattedTotalOrderPrice = Dark(assetClass.formatPrice(totalCost, secMeta));
                const formattedCurrentMarketPrice = Dark(assetClass.formatPrice(currentMarketPrice, secMeta));

                const tifQualifier = Snex1Mandarin.tradeTicket.review.tifQualifier(trade, formattedSymbol);
                if (typeof tifQualifier !== 'string') return tifQualifier;

                const { actionWording, actionWordVerb, actionWordNoun, xUnits, unit } = Snex1Mandarin.tradeTicket.review.actionText(trade);

                const nonMarketTradeText = () => {
                    const isLimit = [OrderTypes.limit, OrderTypes.loc].includes(trade?.orderType as OrderType);
                    const isStop = trade.orderType === 'stop';
                    const isStopLimit = trade.orderType === 'stoplimit';
                    let text = [Light('如果'), formattedSymbol, Light('的价格达到')];

                    isStop || isStopLimit ? text.push(formattedStopPrice) : text.push(formattedLimitPrice);

                    if (isStopLimit) {
                        return text.concat([
                            Light(`${tifQualifier}，您将下一个限价订单到`),
                            Dark(`${actionWordVerb} ${xUnits}`),
                            Light('以'),
                            Dark(assetClass.formatPrice(trade.stopLimitPrice, secMeta)),
                            Light('或更好的价格')
                        ]);
                    } else text = text.concat([Light(`${tifQualifier}，您将下达`), Dark(actionWordNoun), Light('，数量为'), Dark(xUnits)]);

                    if (isLimit) return text.concat([Light('，价格为'), formattedTotalOrderPrice, Light('或更好。')]);
                    else return text.concat([Light('，价格为'), Dark('市价'), Light('。')]);
                };

                if ([OrderTypes.market, OrderTypes.moc].includes(trade?.orderType as OrderType)) {
                    const perShare = isNaN(currentMarketPrice) ? '。' : `每${unit}。`;
                    return [
                        Light('您正在'),
                        Dark(`${actionWording} ${FormatNumber.toCommas(trade.quantity)}`),
                        Light(`股份${Snex1Mandarin.general.unit(trade.securityId, trade.quantity)?.toLowerCase()}，`),
                        formattedSymbol,
                        Light('，价格为'),
                        Dark('市价'),
                        ...(isNaN(currentMarketPrice) ? [] : [Light('，当前价格为'), formattedCurrentMarketPrice]),
                        Light(perShare)
                    ];
                } else return nonMarketTradeText();
            },
            sharesV2: (assetClass, trade, secMeta, price, sharePrice?) => {
                const creditDebit = {
                    Credit: Snex1Mandarin.tradeTicket.input.options.credit,
                    Debit: Snex1Mandarin.tradeTicket.input.options.debit,
                    Even: Snex1Mandarin.tradeTicket.input.options.even
                }[trade?.leg2DebitCredit];
                const symbol = Snex1Mandarin.general.symbolName(trade.securityId, 'long');

                const tifQualifier = Snex1Mandarin.tradeTicket.review.tifQualifier(trade, symbol);
                if (typeof tifQualifier !== 'string') return tifQualifier;

                const { actionWording, actionWordVerb, actionWordNoun, xUnits, unit } = Snex1Mandarin.tradeTicket.review.actionText(trade, assetClass, secMeta);

                const isMultiLeg = !!trade?.leg2Quantity;
                const leg2ActionWordNoun = trade?.leg2Action === 'Buy' ? '购买订单' : '出售订单';
                const leg2Sym = Snex1Mandarin.general.symbolName(trade?.leg2SecurityId, 'long');

                const nonMarketNonMultiLegText = () => {
                    const isLimit = [OrderTypes.limit, OrderTypes.loc].includes(trade.orderType);
                    const isStopLimit = trade.orderType === 'stoplimit';

                    let text = [Light('如果'), Dark(symbol), Light('的价格达到'), Dark(assetClass.formatPrice(trade.orderPrice, secMeta))];

                    if (isStopLimit) {
                        return text.concat([
                            Light(`${tifQualifier}，您将下一个限价订单到`),
                            Dark(`${actionWordVerb} ${xUnits}`),
                            Light('以'),
                            Dark(assetClass.formatPrice(trade.stopLimitPrice, secMeta)),
                            Light('或更好的价格')
                        ]);
                    } else text = text.concat([Light(`${tifQualifier}，您将下达`), Dark(actionWordNoun), Light('，数量为'), Dark(xUnits)]);

                    if (isLimit) return text.concat([Light('，价格为'), Dark(assetClass.formatPrice(price, secMeta)), Light('或更好。')]);
                    else return text.concat([Light('，价格为'), Dark('市价'), Light('。')]);
                };

                const isEven = trade.orderType === 'even';
                const formattedPrice = isEven ? '' : `${assetClass.formatPrice(price || 0, secMeta)} `;

                const forPrice = trade?.orderType === 'even' ? '甚至钱' : `${formattedPrice}${creditDebit}`;

                switch (true) {
                    case isMultiLeg:
                        return [
                            Light('您正在下达'),
                            Dark(`${leg2ActionWordNoun}`),
                            Light('，数量为'),
                            Dark(`${FormatNumber.toCommas(trade.leg2Quantity)}`),
                            Light(`${Snex1Mandarin.general.unit(trade.leg2SecurityId, trade.leg2Quantity)?.toLowerCase()}的`),
                            Dark(leg2Sym),
                            ...(trade?.orderType === 'market' ? [Light('，价格为'), Dark('市价'), Light('。')] : [Light('订单，共计'), Dark(forPrice)])
                        ];
                    case [OrderTypes.market, OrderTypes.moc].includes(trade?.orderType as OrderType): {
                        const sharePriceFigure = '市价';
                        const perShare = isNaN(sharePrice) ? '。' : `每${unit}。`;
                        return [
                            Light('您正在'),
                            Dark(`${actionWording} ${assetClass?.formatQuantity(trade.quantity, secMeta)}`),
                            Light(`股份${Snex1Mandarin.general.unit(trade.securityId, trade.quantity)?.toLowerCase()}，`),
                            Dark(symbol),
                            Light('，价格为'),
                            Dark(sharePriceFigure),
                            ...(isNaN(sharePrice) ? [] : [Light('，当前价格为'), Dark(assetClass.formatPrice(sharePrice, secMeta))]),
                            Light(perShare)
                        ];
                    }
                    default:
                        return nonMarketNonMultiLegText();
                }
            },
            dollarsV2: (assetClass, trade, secMeta, sharePrice?) => {
                const symbol = Snex1Mandarin.general.symbolName(trade.securityId);
                const { actionWording } = Snex1Mandarin.tradeTicket.review.actionText(trade);
                const sharePriceFigure = '市价';
                return [
                    Light(`您正在${actionWording}`),
                    Dark(assetClass.formatPrice(trade.quantity, secMeta)),
                    Light('，数量为'),
                    Dark(symbol),
                    Light('，价格为'),
                    Dark(sharePriceFigure),
                    ...(isNaN(sharePrice) ? [] : [Light('，当前价格为'), Dark(`${assetClass.formatPrice(sharePrice, secMeta)}`)]),
                    Light('。')
                ];
            },
            futuresV2: (assetClass, trade, secMeta) => {
                const format = (value: number) => assetClass.formatPrice(value, secMeta);
                const type = trade.orderType === 'stoplimit' ? 'stop-limit' : trade.orderType;
                const action = Snex1Mandarin.tradeTicket.review.actionText(trade).actionWordVerb;
                const symbol = Snex1Mandarin.general.symbolName(trade.securityId, 'short', trade.quantity, secMeta);
                const s = trade.quantity === 1 ? '' : 's';

                const base: ShadedTextList = [
                    { text: '您正在下达', shade: 'light' },
                    { text: `${type} ${action}`, shade: 'dark', assetClass: 'futures' },
                    { text: '订单，数量为', shade: 'light' },
                    { text: `${trade.quantity} ${symbol}`, shade: 'dark', assetClass: 'futures' },
                    { text: `合约${s}`, shade: 'light' }
                ];

                const suffGtc: ShadedTextList = [{ text: '，有效期至撤销', shade: 'light' }];
                const suffPeriod: ShadedTextList = [{ text: '。', shade: 'light' }];
                const suffPrice = (price: number): ShadedTextList => [
                    { text: '，价格为', shade: 'light' },
                    { text: format(price), shade: 'dark', assetClass: 'futures' }
                ];
                const suffStopLimit = (stop: number, limit: number): ShadedTextList => [
                    { text: '，触发价为', shade: 'light' },
                    { text: format(stop), shade: 'dark', assetClass: 'futures' },
                    { text: '，限价为', shade: 'light' },
                    { text: format(limit), shade: 'dark', assetClass: 'futures' }
                ];

                const parts = [
                    base,
                    type === 'limit' || type === 'stop' ? suffPrice(trade.orderPrice) : null,
                    type === 'stop-limit' ? suffStopLimit(trade.orderPrice, trade.stopLimitPrice) : null,
                    trade.timeInForce === 'GTC' ? suffGtc : null,
                    suffPeriod
                ].filter((x) => !!x);

                return ShadedTextUtils.combine(parts);
            },
            futuresSpreads: (assetClass, trade, secMeta): ShadedTextList => {
                const format = (value: number) => assetClass.formatPrice(value, secMeta);
                const type = trade.orderType === 'stoplimit' ? 'stop-limit' : trade.orderType;
                const futuresSym = new FuturesSymbol(trade.securityId);
                const inverseAction = futuresSym.nearMonthDirection === '-';
                const { actionWordVerb: action } = Snex1Mandarin.tradeTicket.review.actionText(trade);

                const fullSymbol = Snex1Mandarin.general.symbolName(trade.securityId, 'short', trade.quantity, secMeta);
                const firstSymbol = futuresSym.nearMonthContract.slice(2);
                const secondSymbol = futuresSym.farMonthContract.slice(2);

                const s = trade.quantity === 1 ? '' : '个';

                const firstActioning = (trade?.action === 'Buy' && !inverseAction) || (trade?.action === 'Sell' && inverseAction) ? '买入' : '卖出';
                const secondActioning = (trade?.action === 'Buy' && !inverseAction) || (trade?.action === 'Sell' && inverseAction) ? '卖出' : '买入';

                const suffPeriod: ShadedTextList = [{ text: '。', shade: 'light' }];

                const base: ShadedTextList = [
                    { text: '您正在下', shade: 'light' },
                    { text: `${type} ${action}`, shade: 'dark', assetClass: 'futures' },
                    { text: '订单，购买', shade: 'light' },
                    { text: `${trade.quantity} ${fullSymbol}`, shade: 'dark', assetClass: 'futures' },
                    { text: `合约${s}`, shade: 'light' }
                ];

                const symbolExplanation: ShadedTextList = [
                    { text: '您正在', shade: 'light' },
                    { text: `${firstActioning} ${firstSymbol}`, shade: 'dark', assetClass: 'futures' },
                    { text: '以及', shade: 'light' },
                    { text: `${secondActioning} ${secondSymbol}`, shade: 'dark', assetClass: 'futures' },
                    ...suffPeriod
                ];

                const suffGtc: ShadedTextList = [{ text: '，长期有效。', shade: 'light' }];
                const suffPrice = (price: number): ShadedTextList => [
                    { text: '以', shade: 'light' },
                    { text: format(price), shade: 'dark', assetClass: 'futures' }
                ];
                const suffStopLimit = (stop: number, limit: number): ShadedTextList => [
                    { text: '，设定止损价为', shade: 'light' },
                    { text: format(stop), shade: 'dark', assetClass: 'futures' },
                    { text: '，以及限价为', shade: 'light' },
                    { text: format(limit), shade: 'dark', assetClass: 'futures' }
                ];

                const flattenedArray = () => {
                    const parts = [base];
                    if (type === 'limit' || type === 'stop') parts.push(suffPrice(trade.orderPrice));
                    if (type !== 'stop-limit' && trade.timeInForce !== 'GTC') parts.push(suffPeriod);
                    if (type === 'stop-limit') parts.push(suffStopLimit(trade.orderPrice, trade.stopLimitPrice));
                    if (type === 'stop-limit' && trade.timeInForce !== 'GTC') parts.push(suffPeriod);
                    if (trade.timeInForce === 'GTC') parts.push(suffGtc);
                    parts.push(symbolExplanation);

                    return parts.flat(1);
                };

                return flattenedArray();
            },
            cryptos: (assetClass, trade, secMeta, price, sharePrice): ShadedTextList => {
                const symbol = Snex1Mandarin.general.symbolName(trade.securityId, 'long');

                const tifQualifier = Snex1Mandarin.tradeTicket.review.tifQualifier(trade, symbol);
                if (typeof tifQualifier !== 'string') return tifQualifier;

                const { actionWordVerb, actionWording } = Snex1Mandarin.tradeTicket.review.actionText(trade);

                const isDollars = trade?.quantityQualifier === 'EvenDollar';

                if (trade.orderType === 'market') {
                    return [
                        Light('您正在下单'),
                        isDollars
                            ? Dark(`${actionWording} ${assetClass.formatPrice(trade?.quantity)} ${symbol}`, '', 'crypto')
                            : Dark(`${actionWording} ${trade?.quantity} ${symbol}`, '', 'crypto'),
                        Dark('以限价', '', 'crypto'),
                        Light('的价格为'),
                        Dark(assetClass.formatPrice(sharePrice, secMeta), '', 'crypto'),
                        Light('对于每种加密货币.')
                    ];
                } else {
                    const isStopLimit = trade.orderType === 'stoplimit';
                    const isStop = trade.orderType === 'stop';
                    const tif = isStop ? 'gtc' : trade?.timeInForce?.toLowerCase();
                    const expandedTif = Snex1Mandarin.tradeTicket.input.timesInForce[tif].toLowerCase();

                    if (isStopLimit) {
                        return [
                            Light('如果价格达到'),
                            Dark(symbol, '', 'crypto'),
                            Light('，则会下限价单，价格为'),
                            Dark(assetClass.formatPrice(trade.orderPrice, secMeta), '', 'crypto'),
                            Light(`${tifQualifier}，您将下限价单以`),
                            isDollars
                                ? Dark(`${actionWordVerb} ${assetClass.formatPrice(trade?.quantity)} ${symbol}`, '', 'crypto')
                                : Dark(`${actionWordVerb} ${trade?.quantity} ${symbol}`, '', 'crypto'),
                            Light('，价格为'),
                            Dark(assetClass.formatPrice(trade.stopLimitPrice, secMeta), '', 'crypto'),
                            Light('或更好。')
                        ];
                    } else if (isStop) {
                        return [
                            Light('您正在下达一个'),
                            Dark(`止损${actionWordVerb}`, '', 'crypto'),
                            Light('订单，用于'),
                            isDollars
                                ? Dark(`${assetClass.formatPrice(trade?.quantity)} 的 ${symbol}`, '', 'crypto')
                                : Dark(`${trade?.quantity} ${symbol}`, '', 'crypto'),
                            Light('价格为'),
                            Dark(assetClass.formatPrice(trade.orderPrice, secMeta), '', 'crypto'),
                            Light(`或更好的价格，`),
                            Dark(`${expandedTif}。`, '', 'crypto')
                        ];
                    } else {
                        return [
                            Light('您正在下单'),
                            Dark(`以限价 ${actionWordVerb}`, '', 'crypto'),
                            Light('市场'),
                            isDollars ? Dark(`${assetClass.formatPrice(trade?.quantity)} ${symbol}`, '', 'crypto') : Dark(`${trade?.quantity} ${symbol}`, '', 'crypto'),
                            Light('的价格为'),
                            Dark(assetClass.formatPrice(price, secMeta), '', 'crypto'),
                            Light(`以上,`),
                            Dark(`${expandedTif}.`, '', 'crypto')
                        ];
                    }
                }
            },
            reinvestmentOptions: {
                willBeCashed: (labels: string[]) => [Light(`${SpokenJoin(labels)} 将以`), Dark('现金'), Light('的形式存入您的账户。')],
                willBeReinvested: (labels: string[]) => [Light(`${SpokenJoin(labels)} 将会`), Dark('重新投资'), Light('。')],
                allWillBeCashed: [Light('所有收益将以'), Dark('现金'), Light('的形式存入您的账户。')],
                allWillBeReinvested: [Light('所有收益将会'), Dark('重新投资'), Light('。')]
            }
        },

        receipt: {
            tradeReceipt: '交易回执',
            orderSummary: '订单摘要',
            orderType: {
                orderType: '订单类型',
                marketOrder: '市价订单',
                mocOrder: '交通部订单',
                marketOrderDesc: '立即以最佳价格交易',
                limitOrder: '限价订单',
                locOrder: '本地订单',
                limitOrderMinMax: (sell: boolean) => `以${sell ? '最低' : '最高'}价格或更好价格交易`,
                limitOrderDesc: '以指定价格或更好价格交易',
                stopOrder: '止损订单',
                stopOrderDesc: '以固定止损价格触发市价交易',
                stopLimitOrder: '限价止损订单',
                stopLimitOrderDesc: '以固定止损价格触发限价交易',
                tradeCantBePlaced: '无法对此类证券进行此类型的交易',
                setStop: '设置止损',
                setLimit: '设置限价',
                setStopLimit: '设置止损限价',
                buyShareMaxPrice: '以最高价格或更低价格购买股票。',
                sellSharesMaxPrice: '以最低价格或更高价格出售股票。',
                triggerMarket: (orderAction: string) => `以固定止损价格触发市场${orderAction}.`,
                triggerLimit: (orderAction: string) => `以固定止损价格触发限价${orderAction}.`
            },
            marketPrice: '市场价格',
            limitPrice: '限价',
            stopPrice: '止损价格',
            stopLimitPrice: '限价止损价格',
            numberOfUnits: (symbol: string) => `${Snex1Mandarin.general.unit(symbol, 0)}数量`,
            orderSubmitted: '订单已提交',
            checkForStatusUpdatesUnderPositions: '在持仓下检查状态更新',
            submitAnotherOrder: '提交另一个订单',
            submittingOrder: '正在提交订单',
            pleaseWait: '请稍候...',
            youMayClickAway: '您现在可以点击离开，我们会在事务完成后通知您。',
            thereWasAProblem: '出现问题',
            pleaseTryAgain: '请重试',
            estimatedTotalCost: '估计总成本',
            estimatedTotalGain: '估计总收益',
            estimatedTotalCredit: '预计总学分',
            estimatedTotalDebit: '预计借方总额',
            leg1: '腿 1',
            leg2: '腿 2',
            timeInForce: '有效时间'
        },
        afterHours: {
            disclosure: {
                title: '延长交易时段提示',
                shortDisclosure: '在延长交易时段交易存在独特风险。',
                longDisclosure: [
                    { shade: 'dark', text: '在延长交易时段交易' },
                    {
                        shade: 'light',
                        text: '（包括周一至周五上午8:00至9:30的盘前交易时段，以及周一至周五下午4:00至5:00的盘后交易时段，具体受节假日交易时间影响）'
                    },
                    { shade: 'dark', text: '，存在独特风险，' },
                    {
                        shade: 'light',
                        text: '如更大的价格波动、更低的流动性、更宽的买卖价差和更少的市场可见性，可能不适合所有投资者。\n\nStoneX One不能保证延长交易时段的订单将以任何给定证券的最佳价格成交，也不能保证任何此类订单（包括市价订单）将得到成交。通过请求进入订单并在StoneX One的延长交易时段内输入任何订单，包括在任何StoneX One交易平台或移动平台上，您确认已了解在延长交易时段交易的风险。'
                    }
                ]
            }
        },
        shortSales: {
            acceptance: (quantity, rate) => {
                return [Light('您正在进行卖空操作'), Dark(`${quantity} 股`), Light('以比率'), Dark(`${rate}%`), Light('.')];
            },
            thisWillShortYou: (quantityShort: number, rate: number) => {
                return [Light('您将进行卖空操作'), Dark(`${quantityShort} 股`), Light('以比率'), Dark(FormatNumber.toPercent(rate, undefined, 4)), Light('.')];
            },
            disclosureTitle: '借券利率',
            disclosure: (isExpanded) =>
                isExpanded
                    ? '所显示的借券利率为指示性利率，可能不代表实际借出证券所支付的利率. 指示性借券利率由StoneX Financial Inc.根据多种因素和多个来源制定。实际借出证券的支付利率可能会与指示性利率在证券借出时的市场条件相比发生显著变化。有关任何问题，请致电证券借贷部门：(212) 379-5450。'
                    : '所显示的借券利率为指示性利率，可能不代表实际借出证券所支付的利率.',
            viewMore: '查看更多',
            viewLess: '查看更少'
        },
        offshore: {
            disclosure: {
                title: '离岸共同基金交易重要信息',
                shortDisclosure: '离岸共同基金交易重要信息',
                longDisclosure: [
                    {
                        shade: 'dark',
                        text: '离岸共同基金的截止时间、结算期和预先通知期因基金而异。您将收到该基金发行的第一个可用资产净值。投资前请参阅基金招募说明书，了解截止时间、基金费用和其他重要信息。离岸共同基金订单一经提交，不得通过系统取消或修改。请联系支持人员请求取消或修改'
                    }
                ]
            }
        },
        crypto: {
            disclosure: {
                title: '加密货币交易和账户信息',
                shortDisclosure: '加密货币交易涉及重大风险，可能导致您投资资本的损失。请负责任地交易。',
                longDisclosure: [
                    {
                        text: '通过 StoneX One Pro 进行的所有加密货币交易活动均通过受信任的第三方服务提供商 ETANA 进行。请注意，当您参与加密货币交易时，您直接与 ETANA 互动，所有交易都在他们的系统上执行。',
                        lineBreak: 'double'
                    },
                    {
                        text: '\n\n此外，您的加密货币账户在 ETANA 开立和维护。这意味着 ETANA 负责持有、保护和管理您的加密货币资产。StoneX One Pro 作为 ETANA 服务的中介，为您提供其交易环境的访问权限。',
                        lineBreak: 'double'
                    },
                    {
                        text: '\n\n请注意以下关键点：',
                        lineBreak: 'double'
                    },
                    {
                        text: '\n1.'
                    },
                    {
                        shade: 'dark',
                        text: '所有交易的执行'
                    },
                    {
                        text: '：在 StoneX One Pro 上发起的所有加密货币交易均由 ETANA 执行。我们不在我们的系统上执行任何交易。',
                        lineBreak: true
                    },
                    {
                        text: '\n2.'
                    },
                    {
                        shade: 'dark',
                        text: '账户管理'
                    },
                    {
                        text: '：您的加密货币账户，包括所有存款和取款，均由 ETANA 管理。',
                        lineBreak: true
                    },
                    {
                        text: '\n3.'
                    },
                    {
                        shade: 'dark',
                        text: '安全和托管'
                    },
                    {
                        text: '：ETANA 负责您的加密货币资产的安全和托管。StoneX Financial 不直接持有或保护任何加密货币资产。',
                        lineBreak: true
                    },
                    {
                        text: '\n4.'
                    },
                    {
                        shade: 'dark',
                        text: '费用和收费'
                    },
                    {
                        text: '：任何与加密货币交易相关的费用或收费将由 ETANA 确定和收取。请参阅 ETANA 的收费表以获取详细信息。',
                        lineBreak: true
                    },
                    {
                        text: '\n5.'
                    },
                    {
                        shade: 'dark',
                        text: '监管监督'
                    },
                    {
                        text: '：ETANA 根据其自身的监管框架运营。审查 ETANA 提供的服务条款、隐私政策和任何监管披露是至关重要的。',
                        lineBreak: 'double'
                    },
                    {
                        text: '\n\n使用 StoneX One Pro 进行加密货币交易即表示您承认并同意这些条款。进行自己的尽职调查并了解加密货币交易所涉及的风险是很重要的。有关您的加密账户或交易的任何问题，请直接联系 ETANA。',
                        lineBreak: 'double'
                    },
                    {
                        shade: 'dark',
                        text: '\n\n免责声明'
                    },
                    {
                        text: '：加密货币交易涉及重大风险，可能导致您投资资本的损失。请负责任地交易。'
                    }
                ]
            }
        }
    },
    orders: {
        multipleOrders: '多个订单',
        accountNumber: '帐户编号',
        account: '帐户',
        acct: 'Acct',
        contractSymbol: '合约标志',
        dateAndTime: '日期和时间',
        filledAmount: '已成交金额',
        fillPrice: '成交价',
        onClose: '在收盘时',
        order: '订单',
        openOrders: '未成交订单',
        tradeHistory: '交易历史',
        orderHistory: '订单历史',
        tradeConfirmation: '查看交易确认',
        expirationDate: '到期日',
        true: '是',
        spreadSymbol: '传播符号',
        buySide: '买方',
        sellSide: '卖方',
        completedDate: '完成日期',
        fees: '费用',
        averageTradePrice: '平均价格',
        actionSummary: (props: ActionSummaryProps): string => {
            const action = Snex1Mandarin.orders.typedAction(props.order);
            const marketPriceText = props?.format === 'short' ? 'MP' : 'market price';
            return getActionSummary({ ...props, action, marketPriceText });
        },
        stratValue: (strat) => {
            switch (strat) {
                case 'Care':
                    return '谨慎';
                case 'SOR':
                    return 'SOR';
                case 'VWAP':
                    return 'VWAP';
                case 'TWAP':
                    return 'TWAP';
                case 'POV2':
                    return 'POV 2%';
                case 'POV5':
                    return 'POV 5%';
                case 'POV10':
                    return 'POV 10%';
                case 'POV15':
                    return 'POV 15%';
                case 'POV20':
                    return 'POV 20%';
                case 'POV25':
                    return 'POV 25%';
            }
        },

        fullActionTypeToOpenOrClose: (o) => {
            switch (o.action?.toLowerCase()) {
                case 'buytoopen':
                    return '买入开仓';
                case 'buytoclose':
                    return '买入平仓';
                case 'selltoopen':
                    return '卖出开仓';
                case 'selltoclose':
                    return '卖出平仓';
                case 'sellshort':
                    return '卖空';
                default:
                    return o.action;
            }
        },

        numberFilledOfTotal: (o) =>
            typeof o.leavesQuantity === 'number'
                ? `${FormatNumber.toDecimal(o.orderQuantity - o.leavesQuantity, 0)} 已成交 ${FormatNumber.toDecimal(o.orderQuantity, 0)}`
                : '',

        actionSummary2: (o, opt, hideMoneySymbol, formatValue) => {
            const act = Snex1Mandarin.orders.typedAction(o);
            const q = Snex1Mandarin.orders.quantityPriceSummary2(o, opt, hideMoneySymbol, formatValue);
            switch (opt?.format) {
                case 'short':
                    return `${act} ${q}`;
                case 'long':
                default:
                    return `${act} ${q}`;
            }
        },

        quantityPriceSummary2: (o, opt, hideMoneySymbol, formatValue) => {
            const ac = GetAssetClassForSecurity(o.symbol);
            const orderType = DetermineOrderType(o);
            if (o.quantityQualifier === 'EvenDollar') return `${FormatNumber.toMoney(o?.orderQuantity)}`;

            const amt: string = (() => {
                const fmt = (n: number): string =>
                    formatValue ? formatValue(n) : FormatNumber.toMoney(n, undefined, hideMoneySymbol ? 'never' : undefined, true, opt?.decimalPlaces || 2);
                const mp = opt?.format === 'short' ? 'MP' : '市场价格';
                switch (orderType) {
                    case 'limit':
                    case 'stoplimit':
                        return fmt(o.limitPrice);
                    case 'stop':
                        return fmt(o.stopPrice);
                    case 'market':
                        return mp;
                    case 'even':
                        return '平衡';
                }
            })();
            return `${ac.formatOrderQuantity(o)} @ ${amt}`;
        },

        action: (a, secType) => {
            const actions = {
                buy: '买',
                sell: '卖',
                buytoopen: secType !== 'futures' ? '开仓买入' : '买入',
                buytoclose: secType !== 'futures' ? '平仓买入' : '买入',
                selltoopen: secType !== 'futures' ? '开仓卖出' : '卖出',
                selltoclose: secType !== 'futures' ? '平仓卖出' : '卖出',
                sellshort: '卖空',
                buytocover: '买入平空',
                selltocover: '卖出平空'
            };

            const actionInput = (a || '')?.toLowerCase();
            if (actionInput === 'fullliquidation') return '全部清仓';
            const action = actions[actionInput] || actionInput;

            return action;
        },
        multiLegAction: (o) => {
            const actionInput = (o?.action || '')?.toLowerCase();
            const leg2Input = (o?.complexOrders?.[0]?.action || '')?.toLowerCase();
            return actionInput === leg2Input
                ? `${Snex1Mandarin.orders.action(actionInput)} 第2腿`
                : `${Snex1Mandarin.orders.action(actionInput)} 第1腿，${Snex1Mandarin.orders.action(leg2Input)} 第1腿`;
        },
        typedAction: (o, secType) => {
            const orderType = DetermineOrderType(o);

            const orderTypeLabel = (() => {
                switch (orderType) {
                    case 'market':
                        return '市价';
                    case 'limit':
                        return '限价';
                    case 'stop':
                        return '止损';
                    case 'stoplimit':
                        return '止损限价';
                    case 'even':
                        return '平衡';
                }
            })();

            return `${orderTypeLabel} ${Snex1Mandarin.orders.action(o.action, secType)}`;
        },
        orderSetup: {
            orderSetup: '订单设置',
            expires: '到期',
            orderType: '订单类型',
            quantityType: '数量类型',
            notional: '名义金额',
            algorithm: '算法策略',
            stratPercent: '策略百分比',
            sellAll: '全部卖出',
            done: '完成',
            discardTitle: '放弃',
            discardChanges: '放弃更改',
            discardChangesMsg: '您有未保存的更改。您确定要放弃更改吗？',
            stayHere: '留在此页',

            action: 'Action',
            actionBottomSheet: {
                buySell: '买卖',
                sellBuy: '卖买',
                buyTheEarlierAndSellTheLater: '买入先前的合同，卖出后来的合同',
                sellTheEalierAndBuyTheLater: '卖出先前的合同，买入后来的合同',
                buying: '购买',
                selling: '销售'
            },

            expiresBottomsheet: {
                expires: '到期',
                goodThruTitle: '有效至当日结束',
                goodThruSubtitle: '订单将在当日交易结束时结束。',
                goodTillCancelled: '有效至取消 (GTC)',
                goodTillCancelltedSubtitle: '订单将一直有效，直至被取消，最长为180天。',
                dayOrdersUnavailable: '当天订单当前不可用',
                gtcOrdersUnavailable: 'GTC订单当前不可用',
                premarketUnavailable: '盘前交易当前不可用',
                postMarketUnavailable: '盘后交易当前不可用',
                preMarketExecution: '盘前交易执行',
                preMarketActive: '订单将处于激活状态，可在美国东部时间上午 8 点至下午 5 点之间执行',
                postMarketExcution: '盘后交易执行',
                postMarketActive: '订单仅在当天的PM交易时段内有效，可能在4:00 PM至5:00 PM EST之间执行',
                goodTilCancelActive: '订单将一直有效，直至被取消，最长为180天。',
                goodThruDayActive: '订单将在当日交易结束时结束。',

                fillOrKillExecution: 'Fill Or Kill',
                fillOrKillActive: 'Fill entire order immediately or cancel it'
            }
        },
        quantity: '数量',
        commission: '佣金',
        stopPrice: '止损价格',
        limitPrice: '限价',
        totalCost: '总成本',
        orderTotalImpactLabel: (action: 'Buy' | 'Sell' | string) => {
            const regex = /sell/i; // This will match any variation of 'Sell'
            const containsSell = regex.test(action);
            return containsSell ? '总信用' : '总成本';
        },
        swipeUpTrade: '向上滑动以交易',
        placed: '已下单',
        by: (who: string) => `下单人：${who}`,
        timeInForce: '有效时间',
        gtxPre: '盘前',
        gtxPost: '盘后',
        isin: 'ISIN',
        strategy: '策略',
        averagePrice: '平均价格',
        actionLabel: '操作',

        placedBy: '下单人',
        securityName: '证券名称',
        contactRepToChange: '要取消或更改您的订单，请联系支持人员。',
        cannotBeCanceled: '订单无法取消或修改',
        lockedForOpen: '订单被锁定以等待打开',
        orderNumber: '订单号',
        orderNumbers: '订单号',
        cancelingOrder: '取消订单中',
        orderCanceled: '订单已取消',
        orderModified: '订单已修改',
        orderFilled: '订单已成交',
        orderPartiallyFilled: '订单部分完成',
        orderPlaced: '订单已下单',
        orderRejected: '订单已拒绝',
        orderStopped: '订单已停止',
        orderSuspended: '订单暂停',
        orderBiddingOpen: '公开招标订购',
        orderId: '订单ID',
        cancelOrder: '取消订单',
        editOrder: '编辑订单',
        edit: '编辑',
        cancel: '取消',
        orderAgain: '再次订购',
        cannotCancelEditOrder: (action: string) => {
            return action === 'edit' ? '无法编辑订单' : '无法取消订单';
        },
        cannotCancelEditSublabel: (action: string) => {
            return action === 'edit' ? '此订单目前无法编辑' : '此订单目前无法取消';
        },
        contactLabel: (useSupport: boolean) => {
            return useSupport ? '联系支持' : '联系代表';
        },
        contactSubLabel: (onlyEdit: boolean, useSupport: boolean, editAndCancel?: boolean) => {
            return `此订单只能通过联系${useSupport ? '支持' : '您的代表'}${onlyEdit ? '编辑' : editAndCancel ? '编辑或取消' : '取消'}`;
        },
        partialFill: (symbol, filled, quantity) => {
            const unit = OptionSymbol.IsOptionSymbol(symbol) ? '合约' : '股';
            return `已成交 ${filled}/${quantity} ${unit}`;
        },
        fills: {
            sequenceNumber: '成交序号',
            price: '成交价格',
            quantity: '成交数量',
            timestamp: '时间戳'
        },
        statuses: {
            open: '未完成',
            working: '进行中',
            pending: '待处理',
            completed: '已完成',
            filled: '已成交',
            cancelled: '已取消',
            cancelPending: '取消待处理',
            cancelPartialFill: '部分取消',
            allocated: '已分配',
            preAllocated: '预分配',
            created: '已创建',
            expired: '已到期',
            route: '已路由',
            routed: '已路由',
            verification: '待验证',
            change: '变更',
            notProcessed: '未处理',
            processed: '已处理',
            confirmed: '已确认',
            cancel: '已取消',
            canceled: '已取消',
            rejected: '已拒绝',
            unknown: '未知',
            deleted: '已删除'
        }
    },

    messages: {
        subjectByPayload: {
            'System:AdminBroadcast': '公告',
            'System:AdminDirect': '管理员消息',
            'System:Welcome': '欢迎！',
            'CustomAlert:Default': '警报',
            'OrderUpdate:New': '订单开启',
            'OrderUpdate:Filled': '订单已成交',
            'OrderUpdate:Rejected': '订单被拒绝',
            'OrderUpdate:Canceled': '订单已取消',
            defaultOrderUpdate: '订单更新'
        }
    },

    tours: {
        stepsBadgePreposition: '的',
        lastStepButtonText: '完成',
        portfolioScreen: {
            chart: [[{ text: '使用投资组合图表跟踪您的' }, { text: '投资组合', weight: 'bold' }, { text: '。' }]],
            watchlist: [
                [
                    {
                        text: '您所有账户的持仓都在此显示'
                    }
                ],
                [{ text: '您还可以' }, { text: '自定义您的自选列表', weight: 'bold' }, { text: '。' }]
            ],
            depositFunds: [
                [
                    { text: '点击这里' },
                    { text: '存入资金', weight: 'bold' },
                    {
                        text: '。一旦存入资金，您就可以开始交易了！'
                    }
                ]
            ],
            search: [[{ text: '搜索股票、共同基金或其他任何内容！' }]],
            profile: [
                [
                    { text: '点击上方的个人资料图片以查看您的' },
                    {
                        text: '账户设置、交易记录等等',
                        weight: 'bold'
                    },
                    { text: '！' }
                ]
            ]
        },
        stockScreen: {
            candlestick: [
                [{ text: '想要' }, { text: '蜡烛图', weight: 'bold' }, { text: '吗？' }],
                [
                    {
                        text: '点击此图标在线图和蜡烛图之间切换。'
                    }
                ]
            ],
            advancedChart: [
                [
                    { text: '我们的' },
                    { text: '高级图表', weight: 'bold' },
                    {
                        text: '为您提供一系列图表工具，以及'
                    },
                    { text: '直接从图表交易的能力', weight: 'bold' },
                    { text: '。' }
                ]
            ],
            addToWatchlist: [[{ text: '想要关注此证券吗？' }, { text: '将其添加到您的自选列表', weight: 'bold' }, { text: '。' }]],
            orderType: [[{ text: '通过点击此处的铅笔图标修改' }, { text: '订单类型', weight: 'bold' }, { text: '。' }], [{ text: '(市价、限价、止损等)' }]],
            optionsTrading: [[{ text: '您可以通过点击此处访问此证券的' }, { text: '期权链', weight: 'bold' }, { text: '。' }]]
        },
        premiumStockScreen: {
            advancedChart: [[{ text: '在单一屏幕上显示多图表' }], [{ text: '点击此处在单一视图上进行' }, { text: '技术分析', weight: 'bold' }, { text: '！' }]],
            alerts: [[{ text: '设置快速提醒' }], [{ text: '点击铃铛图标设置基于' }, { text: '实时价格变动', weight: 'bold' }, { text: '的触发器。成为第一个知道！' }]],
            deepAnalysis: [
                [{ text: '深入了解数据' }],
                [
                    {
                        text: '点击此处以综合视图，将公司洞察与实时市场数据结合。'
                    }
                ]
            ],
            streamingNews: [
                [{ text: '您拥有实时新闻！' }],
                [
                    {
                        text: '最新消息会在发布时立即显示在顶部。'
                    }
                ]
            ]
        },
        tickerBar: {
            tradingViewMarquee: [
                [{ text: 'On the Ticker Bar,  grab a horizontal glance of indices, commodities, and more.  ' }],
                [{ text: 'Click on the symbols to learn more. You can toggle this on/off from your Profile settings.' }]
            ]
        }
    },

    mobileTours: {
        summaryScreen: {
            watchlist: {
                text: '向上滑动此处以查看和管理您的自选列表',
                name: '自选列表'
            }
        },
        securityScreen: {
            alerts: {
                text: '点击此处以管理与此证券相关的提醒',
                name: '提醒'
            },
            chartToggle: {
                text: '通过简单点击此处在图表视图之间切换（线性/蜡烛图）',
                name: '图表切换'
            },
            advancedChart: {
                text: '点击此处以打开高级图表，具备指标等功能，尽在指尖之间',
                name: '高级图表'
            },
            trade: {
                text: '从此处向上滑动以打开交易窗口并开始交易！',
                name: '交易'
            }
        },
        navigateToursAlert: {
            title: '查看导览',
            message: '浏览应用程序以查看导览。'
        }
    },

    newFeatures: {
        advancedOptionTrading: {
            title: '高级期权交易',
            subtitle: '多腿期权交易涉及一次执行多个期权交易以创建特定策略。',
            item1Title: '一次两笔交易',
            item1Subtitle: '多腿期权交易同时执行多个期权交易。在一张交易单中滚动现有的备兑和多头头寸。创建价差和跨式组合。',
            item2Title: '控制风险与回报',
            item2Subtitle: '通过组合期权，您可以创建特定的风险与回报配置。',
            item3Title: '测试您的市场预测',
            item3Subtitle: '可以采用不同的期权策略根据您对价格波动、波动性变化或时间衰减的预测来增加或保护您的投资组合。'
        },
        cryptoTrading: {
            title: '加密货币现已上线！',
            subtitle: '开启您的旅程：轻松买卖加密货币，迎接金融的未来！',
            item1Title: '交易执行',
            item1Subtitle: '所有在 StoneX One Pro 上发起的加密货币交易均由 ETANA 执行。我们不在我们的系统上执行任何交易。',
            item2Title: '账户管理',
            item2Subtitle: '您的加密货币账户，包括所有存款和取款，均由 ETANA 管理。',
            item3Title: '安全和托管',
            item3Subtitle: 'ETANA 负责您加密货币资产的安全和托管。StoneX Financial 不直接持有或保管任何加密货币资产。'
        }
    },

    miniplayer: {
        loading: (title: string | null) => `正在加载${title || '媒体'}...`,
        noTranscriptAvailable: '无可用的文本记录',
        noMediaAvailable: '无可用的媒体',
        viewTranscript: '查看文本记录'
    },

    modals: {
        done: '完成',
        appIdle: {
            modalHeader: '您处于闲置状态！',
            noticedInactivity: '我们注意到您已经有一段时间没有活动了',
            logoutInSeconds: (seconds: number) => `如果不采取任何操作，您将在 ${seconds} 秒内注销。`,
            logout: '注销',
            stay: '保持登录'
        },
        addManyToListContent: {
            title: '使用上面的搜索添加证券',
            button: '添加到自选列表',
            addSecurity: '添加证券'
        },
        addToWatchlist: {
            addSymbolToWatchlist: (symbol: string) => `将 ${symbol} 添加到自选列表`,
            createAList: '创建列表',
            newListName: '新列表名称',
            create: '创建',
            creating: '创建中...'
        },
        viewWatchlistDetails: '查看监视列表详细信息',
        deleteWatchlist: {
            deleteThisList: '删除此列表',
            areYouSure: (listName: string) => [[{ text: '您确定要永久删除 ' }, { text: listName, weight: 'bold' }, { text: ' 吗？此操作无法撤销。' }]],
            areYouSureShaded: (listName: string) => [
                {
                    text: '您确定要永久删除 ',
                    shade: 'light'
                },
                { text: listName, shade: 'dark' },
                { text: ' 吗？此操作无法撤销。', shade: 'light' }
            ],
            yesDeleteList: (listName: string) => `是的，删除 ${listName}`,
            delete: '删除',
            deleting: '删除中...'
        },
        editWatchlistDetails: {
            editList: (listName: string) => `编辑 ${listName}`,
            name: '自选列表名称...',
            description: '自选列表描述...',
            save: '保存',
            saving: '保存中...'
        },
        deleteAllMessages: {
            label: '删除所有消息',
            body: '您确定要删除所有消息吗？',
            no: '取消',
            yes: '确定'
        },
        cancelOrder: {
            orderCancelled: '订单已取消！',
            cancelOrder: '取消订单',
            cancelOrderFailed: '取消订单失败!',
            retry: '请重试，或者如果问题仍然存在，请联系支持人员。',
            areYouSure: '您确定要取消订单吗？',
            cancelLabel: '算了',
            ctrlKeyTip: [
                [
                    { text: '提示：', weight: 'bold' },
                    { text: '在取消订单时按住 ' },
                    { text: ' Ctrl ', weight: 'bold' },
                    { text: ' 或 ' },
                    { text: ' Command ⌘ ', weight: 'bold' },
                    {
                        text: ' 键可跳过此确认步骤 '
                    }
                ]
            ],
            cancel: '确认取消订单',
            retryCancel: '重试取消订单',
            canceling: '取消中...'
        },
        rename: {
            rename: '重命名',
            save: '保存',
            saving: '保存中...'
        },

        errorModal: {
            unknownError: '发生未知错误'
        },
        existingAccountModal: {
            cannotRemoveAcctTitle: '无法删除账户',
            cannotRemoveAcctDesc: '很抱歉，每六个月只能删除一次账户。如果您希望更快删除，请联系支持部门。',
            cannotRemoveAcctConfirm: '确认',
            removeLinkedAcctTitle: '移除关联账户',
            removeLinkedAcctConfirm: (name: string) => `您确定要移除 ${name} 作为关联账户吗？`,
            yesRemoveAcct: '是的，移除账户',
            noKeppAcct: '不，保留账户',
            loadingYourAcct: '正在加载您的账户...',
            voidingAcct: '正在作废账户...',
            addAnAcct: '添加账户'
        },
        linkedAccountsModal: {
            retrivingLinkableAcct: '正在检索您的可关联账户...',
            linkingAcctWait: '正在关联您的账户，请稍候...'
        },
        plaidModal: {
            plaidCompleted: 'Plaid 完成，未返回账户',
            overlimitAccounts: '很抱歉，您当前无法添加账户。',
            holdTight: '请稍候，正在添加您的账户',
            verifyingBankAuth: '正在验证您的银行授权...'
        }
    },

    toasts: {
        error: '错误',
        sessionExpired: '会话已过期',
        logInAgain: '重新登录',
        orderSubmitted: '订单已提交！',
        onboardingSuccess: () => {
            return '恭喜！您的账户正在处理中，很快将显示出来。';
        },
        onboardingSuccessTitle: (accountNumber) => {
            return `您的期货账户（${accountNumber}）正在创建中。`;
        },
        orderSubmittedContent: (trade: Partial<Order>) =>
            `您的订单 ${trade?.action?.toUpperCase()} ${trade?.orderQuantity} ${Snex1Mandarin.general.unit(trade?.symbol, trade?.orderQuantity)} ${
                trade?.symbol
            } 已提交（订单号：${trade?.orderNumber}）`,
        orderUpdateTitle: (update) => {
            const name = update.symbol ? Snex1Mandarin.general.symbolName(update.symbol) : update.symbol || update.securityName || update.securityNumber;
            return name ? `订单更新：${name}` : '订单更新';
        },
        orderUpdate: (update, message) => {
            const action = (() => {
                switch (update.action) {
                    case 'Buy':
                        return '买入';
                    case 'Sell':
                        return '卖出';
                    case 'BuyToOpen':
                        return '买入开仓';
                    case 'SellToOpen':
                        return '卖出开仓';
                    case 'BuyToClose':
                        return '买入平仓';
                    case 'SellToClose':
                        return '卖出平仓';
                    default:
                        return '交易';
                }
            })();

            const statusPhrase = (() => {
                switch (update.orderStatus) {
                    case 'Allocated':
                        return '已分配';
                    case 'Verification':
                        return '正在验证中';
                    case 'Created':
                        return '已创建';
                    case 'Open':
                        return FuturesSymbol.IsFuturesSymbol(update.symbol) ? '正在进行中' : '已开启';
                    case 'Route':
                        return '已准备好路由';
                    case 'Routed':
                        return '已路由';
                    case 'Cancel':
                        return '准备取消';
                    case 'Canceled':
                        return '已取消';
                    case 'Filled':
                        return '已成交';
                    case 'Rejected':
                        return '已拒绝';
                    case 'Deleted':
                        return '已删除';
                    case 'Expired':
                        return '已过期';
                    case 'Pending':
                        return '待处理';
                    case 'Completed':
                        return '已完成';
                    case 'Cancel Pending':
                        return '待取消';
                    case 'Pre-Allocated':
                        return '已预分配';
                    case 'Cancel Partial Fill':
                    case 'Unknown':
                    default:
                        return `已更新为状态 "${update.orderStatus}"`;
                }
            })();

            const sym = update.symbol ? Snex1Mandarin.general.symbolName(update.symbol) : update.securityName || update.securityNumber;

            if (message.payloadType === 'OrderUpdate:Rebook') return `您编辑的 ${sym} 订单现已生效`;

            if (update.orderQuantity === null) {
                return `您的订单 ${update.action ? `为 ${action}` : '为'} ${sym} ${message?.data['filledAt']}${statusPhrase}（订单号：${update.orderNumber}）`;
            }
            return `您的订单 ${update.action ? `为 ${action}` : '为'} ${update.orderQuantity} ${Snex1Mandarin.general.unit(
                update.symbol,
                update.orderQuantity
            )} ${sym} ${statusPhrase}（订单号：${update.orderNumber}）`;
        }
    },

    infoIconHelpText: {
        client: {
            aboutSecurity: [
                [
                    {
                        text: '某些基本、市场数据或其他信息由第三方数据提供商直接或间接提供，可能与其他来源有所不同。'
                    }
                ]
            ],
            aboutSrri: [
                [
                    {
                        text: '综合风险与回报指标（或 SRRI）是关键投资者信息文件（或 KIID）的重要组成部分。 SRRI 通过制定 1 至 7 之间的数字来表示 UCITS 基金的风险水平，其中 1 表示风险较低，7 表示风险较高。'
                    }
                ]
            ],
            acatTransfer: [
                [
                    {
                        text: 'ACATS转账请求的拒绝通常由交付经纪人采取行动发起，一旦该经纪人有机会审查请求并确认要转移的账户的详细信息。在某些拒绝通知的情况下（即下面的1-5和10类），ACATS流程为接收经纪人（IB）提供了一个24小时的时间窗口，可在此期间传输修订信息，此后转账请求将需要客户重新提交。在这个24小时的时间窗口内，IB将尝试联系转账客户，以解决导致初始拒绝通知的任何差异。拒绝通常分为以下类别：'
                    }
                ],
                [{ text: '交付经纪人的拒绝：' }],
                [
                    {
                        text: '1. 社会安全号码/税号不匹配 - 由接收经纪人提供的客户纳税人识别号与交付经纪人记录不一致。'
                    }
                ],
                [
                    {
                        text: '2. 账户名称不匹配 - 由接收经纪人提供的客户账户名称与交付经纪人记录不一致。'
                    }
                ],
                [
                    {
                        text: '3. 需要文档 - 交付经纪人需要额外的账户文件以处理转账请求（例如，死亡或婚姻法律文件）。'
                    }
                ],
                [
                    {
                        text: '4. 账户空仓 - 账户没有可转让的资产。'
                    }
                ],
                [
                    {
                        text: '5. 无效账户号码 - 由接收经纪人提供的客户账户号码不在交付成员的账簿中。'
                    }
                ],
                [
                    {
                        text: '6. 重复 - 交付经纪人已经收到涉及相同接收经纪人和客户账户的转账请求。'
                    }
                ],
                [
                    {
                        text: '7. 分发或转账中的账户 - 另一个ACATS转账或直接向客户分发账户资产的流程已经在进行中。'
                    }
                ],
                [
                    {
                        text: '8. 客户撤销 - 客户已经提交书面请求取消转账请求。'
                    }
                ],
                [
                    {
                        text: '9. 缺少授权签名 - 转账请求需要额外的客户和/或托管人签名。'
                    }
                ],
                [{ text: '接收经纪人的拒绝：' }],
                [
                    {
                        text: '10. 信用违规 - 如果执行转账将使账户陷入保证金赤字并受到强制清算。'
                    }
                ]
            ],
            buyingPower: [
                [
                    {
                        text: '您的购买力是账户中可用于购买证券的总现金金额加上所有可用保证金。'
                    }
                ],
                DelayedParagraph
            ],
            topMoversAndSectors: [
                [
                    {
                        text: 'SECTORS和MOVERS列表是使用第三方数据提供商提供的数据生成的。特定业务领域中的公司列表可能会显示一家公司，如果源数据表明该公司从相关领域获取了部分收入。此列表仅供信息目的，不构成投资建议或购买、持有或出售任何证券的推荐。列表不是针对您或任何其他客户的个性化建议，列出的证券可能不适合您。您不应该在未首先确定适合您的投资组合或投资策略的情况下购买或出售任何证券。StoneX One不对任何第三方信息的准确性、及时性或完整性提供任何担保或保证，提供此信息并不构成推荐。'
                    }
                ],
                DelayedParagraph
            ],
            news: [
                [
                    {
                        text: '新闻、评论和事件来自于与StoneX无关的第三方来源。StoneX不赞同或采用其内容。StoneX不保证所提供的信息是否准确、完整或及时，并不提供与其使用结果相关的任何担保。'
                    }
                ]
            ],
            portfolioValue: [
                [
                    {
                        text: '您的投资组合价值是账户中所有持仓的总市值，包括现金余额，减去所有未偿还的借方余额和覆盖在内的卖出期权头寸所需的金额。'
                    }
                ],
                DelayedParagraph
            ]
        },
        one: {
            aboutSecurity: [
                [
                    {
                        text: '某些基本、市场数据或其他信息由第三方数据提供商直接或间接提供，可能与其他来源有所不同。'
                    }
                ]
            ],
            aboutSrri: [
                [
                    {
                        text: '综合风险与回报指标（或 SRRI）是关键投资者信息文件（或 KIID）的重要组成部分。 SRRI 通过制定 1 至 7 之间的数字来表示 UCITS 基金的风险水平，其中 1 表示风险较低，7 表示风险较高。'
                    }
                ]
            ],
            acatTransfer: [
                [
                    {
                        text: 'ACATS转账请求的拒绝通常由交付经纪人采取行动发起，一旦该经纪人有机会审查请求并确认要转移的账户的详细信息。在某些拒绝通知的情况下（即下面的1-5和10类），ACATS流程为接收经纪人（IB）提供了一个24小时的时间窗口，可在此期间传输修订信息，此后转账请求将需要客户重新提交。在这个24小时的时间窗口内，IB将尝试联系转账客户，以解决导致初始拒绝通知的任何差异。拒绝通常分为以下类别：'
                    }
                ],
                [{ text: '交付经纪人的拒绝：' }],
                [
                    {
                        text: '1. 社会安全号码/税号不匹配 - 由接收经纪人提供的客户纳税人识别号与交付经纪人记录不一致。'
                    }
                ],
                [
                    {
                        text: '2. 账户名称不匹配 - 由接收经纪人提供的客户账户名称与交付经纪人记录不一致。'
                    }
                ],
                [
                    {
                        text: '3. 需要文档 - 交付经纪人需要额外的账户文件以处理转账请求（例如，死亡或婚姻法律文件）。'
                    }
                ],
                [
                    {
                        text: '4. 账户空仓 - 账户没有可转让的资产。'
                    }
                ],
                [
                    {
                        text: '5. 无效账户号码 - 由接收经纪人提供的客户账户号码不在交付成员的账簿中。'
                    }
                ],
                [
                    {
                        text: '6. 重复 - 交付经纪人已经收到涉及相同接收经纪人和客户账户的转账请求。'
                    }
                ],
                [
                    {
                        text: '7. 分发或转账中的账户 - 另一个ACATS转账或直接向客户分发账户资产的流程已经在进行中。'
                    }
                ],
                [
                    {
                        text: '8. 客户撤销 - 客户已经提交书面请求取消转账请求。'
                    }
                ],
                [
                    {
                        text: '9. 缺少授权签名 - 转账请求需要额外的客户和/或托管人签名。'
                    }
                ],
                [{ text: '接收经纪人的拒绝：' }],
                [
                    {
                        text: '10. 信用违规 - 如果执行转账将使账户陷入保证金赤字并受到强制清算。'
                    }
                ]
            ],
            buyingPower: [
                [
                    {
                        text: '您的购买力是账户中可用于购买证券的总现金金额加上所有可用保证金。'
                    }
                ]
            ],
            topMoversAndSectors: [
                [
                    {
                        text: 'SECTORS和MOVERS列表是使用第三方数据提供商提供的数据生成的。特定业务领域中的公司列表可能会显示一家公司，如果源数据表明该公司从相关领域获取了部分收入。此列表仅供信息目的，不构成投资建议或购买、持有或出售任何证券的推荐。列表不是针对您或任何其他客户的个性化建议，列出的证券可能不适合您。您不应该在未首先确定适合您的投资组合或投资策略的情况下购买或出售任何证券。StoneX One不对任何第三方信息的准确性、及时性或完整性提供任何担保或保证，提供此信息并不构成推荐。'
                    }
                ]
            ],
            news: [
                [
                    {
                        text: '新闻、评论和事件来自于与StoneX无关的第三方来源。StoneX不赞同或采用其内容。StoneX不保证所提供的信息是否准确、完整或及时，并不提供与其使用结果相关的任何担保。'
                    }
                ]
            ],
            portfolioValue: [
                [
                    {
                        text: '您的投资组合价值是账户中所有持仓的总市值，包括现金余额，减去所有未偿还的借方余额和覆盖在内的卖出期权头寸所需的金额。'
                    }
                ]
            ],
            marketCap: [
                [
                    {
                        text: '市值是公司流通股票的估值。根据该值的大小，公司被分为大型、中型、小型、微型和纳米等类别。'
                    }
                ]
            ]
        }
    },

    errors: {
        imageUploadError: '无法上传图片。请确保文件为图像，且大小不超过10MB。',
        accessRestricted: '访问受限',
        loginDead: '嗨！我们看到您正在尝试进入StoneX One！\n请联系下面的电子邮件以激活您的帐户。',
        loginDeadWithDetail: (getIntoWhat: string, email: string): string =>
            `您好！我们看到您正在尝试进入${getIntoWhat}！\n请通过以下选项联系支持人员，以启用您的用户账户，并将电子邮件地址 ${email}。`,
        noDataFound: '未找到数据',
        trade: {
            generalTitle: '出现问题',
            generalSubmit: '无法下单。如果您认为这是错误，请联系支持。',
            generalVerify: '无法验证您的订单。如果您认为这是错误，请联系支持。',
            restrictedError: '抱歉，此证券已限制交易。',
            cantVerify: '抱歉，我们无法验证您的订单。',
            dimeProduct: '抱歉，此产品仅以十分之一的增量报价。请将您的价格调整为十分之一的倍数并重新提交。',
            nickelProduct: '抱歉，此产品仅以五分之一的增量报价。请将您的价格调整为五分之一的倍数并重新提交。',
            pennyProduct: '抱歉，此产品仅以一分之一的增量报价。请将您的价格调整为一分之一的倍数并重新提交。',
            securityOutOfSession: '抱歉，在这个时间段无法交易此产品。',
            accountRestricted: '此账户已被限制。请联系我们以重新激活。',
            securityUnavailable: '抱歉，StoneX One不支持此产品的交易。',
            fundTradingDenied: '抱歉，您无权交易共同基金。',
            basicTradingDenied: '抱歉，您无权进行交易。',
            noBuy: '抱歉，此交易不允许买入。',
            noOptionPosition: '关闭订单的数量不得超过当前持有的数量。',
            optionAgreement: '抱歉，您的账户不允许交易期权。',
            optionLevel: '抱歉，您的账户不允许以此方式交易期权。',
            optionPermission: '抱歉，您无权交易期权。',
            offHours: '抱歉，目前处于非交易时间。请稍后再试。',
            generalNotAvailable: '不可交易',
            notAvailable: (product: string) => `抱歉，${product}在StoneX One上不可交易`,
            sellStopLessThanMarket: '卖出止损订单需要小于当前市场价格的止损价格。',
            buyStopGreaterThanMarket: '买入止损订单需要大于当前市场价格的止损价格。',
            sxDenied: '您无权进行交易。如果您认为收到此消息是错误的，请联系支持。',
            shortNotAllowed: '该证券不允许卖空。',
            thisIsAShortOrder: '您正在出售您当前不拥有的股份。这是一个短期订单。',
            adjustedCloseOnly: '抱歉，调整后的选项不允许打开。',
            adjustedCloseOnlyMessage: '由于流动性和结算风险，我们只允许紧缩期权头寸的平仓订单。',
            insufficientShortQuantity: (qty: number): string => {
                const sharePlurality = qty === 1 ? `有1份` : `有${qty}股`;
                const sharesAvailable = qty === 0 ? `没有股份` : sharePlurality;
                const reduceQuantityText = qty > 1 ? '请减少您要求的数量。' : '';
                return `此请求授权所需的数量不足。 ${sharesAvailable} 可用的。${reduceQuantityText}`;
            },
            mutualFundQrpOnly: '购买此美国共同基金仅适用于合格的退休计划。如果您觉得到达此处有误，请联系支持部门。',
            mutualFundNoDomesticOnForeign: '外国账户不允许美国共同基金订单。如果您觉得到达此处有误，请联系支持部门。',
            mutualFundNoForeignOnDomestic: '国内账户不允许离岸共同基金订单。如果您觉得到达此处有误，请联系支持部门。'
        },
        alerts: {
            duplicateRuleMessage: '您已经使用此条件集定义了一个警报规则。',
            duplicateRuleTitle: '重复警报规则',
            somethingWentWrongMessage: '抱歉，出现了问题。请稍后再试。',
            unableToVerifyCode: '无法验证您的代码，请重试。',
            temporarilyUnavailable: '暂时不可用',
            alertsTemporarilyUnavailableMessage: '我们正在升级我们的提醒服务。不幸的是，提醒将在几周内暂停。当提醒恢复时，您将需要重新创建您的提醒。'
        }
    },
    warnings: {
        pretradeDisclosures: {
            cryptos:
                '继续在我们的平台上进行加密货币交易，即表示您确认已阅读、理解并接受本披露声明，并同意 ETANA 为加密货币交易和账户管理制定的条款和条件。\n\n如果您有任何问题或需要进一步澄清本披露声明的内容，请联系我们的支持团队。'
        },
        trade: {
            cannotAfford: (t: Partial<TradeRequest> | ApiTradeRequest, ac?: AssetClass) => {
                const isDollar = t?.quantityQualifier === 'EvenDollar';
                const q = isDollar ? ac?.formatPrice(t.quantity) : ac?.formatQuantity(t.quantity);

                switch (t.action) {
                    case 'Buy': {
                        const unit = Snex1Mandarin.general.unit(t.securityId, t.quantity)?.toLowerCase();
                        const commonSentence = `你的资金不足，无法购买 ${q}`;
                        const unitSentence = isDollar ? `的 ${QualifiedId.RemovePrefix(t.securityId)}。` : `${unit} 的 ${QualifiedId.RemovePrefix(t.securityId)}。`;
                        return `${commonSentence} ${unitSentence}`;
                    }
                    case 'Sell': {
                        const units = Snex1Mandarin.general.unit(t.securityId, 2)?.toLowerCase();
                        const unit = Snex1Mandarin.general.unit(t.securityId, t.quantity)?.toLowerCase();
                        const showUnit = isDollar && ac?.family === 'cryptos' ? `${q}` : `${q} ${unit}`;

                        return `您没有足够的 ${units} 出售 ${showUnit} ${QualifiedId.RemovePrefix(t.securityId)}。`;
                    }
                    default:
                        return '抱歉，您无法承担此交易。';
                }
            },
            shortsNotPermittedWhenLong: (t: Partial<TradeRequest> | ApiTradeRequest) => {
                return '我们注意到您目前持有多头仓位。如果要卖空，请先平仓您的多头仓位。';
            },
            shortsNotPermittedWhenAccountNotMargin: (t: Partial<TradeRequest> | ApiTradeRequest, ac?: AssetClass) => {
                const cannotAfford = Snex1Mandarin.warnings.trade.cannotAfford(t, ac);
                return `${cannotAfford}\n\n在现金账户中不允许卖空股票，请检查您的持仓并修改您的订单。`;
            },
            buyExceedsShortsHeld: (t: Partial<TradeRequest> | ApiTradeRequest) => {
                return '我们注意到您目前持有空头仓位。如果要做多，请先平仓您的空头仓位。';
            },
            shortNotPermittedAccountValue: (t: Partial<TradeRequest> | ApiTradeRequest) => {
                return '您不得在资产低于$2,000的保证金账户中卖空股票，请检查您的订单或存入资金。';
            },
            invalidQuantity: '我们不支持按指定数量交易该证券，请修改您的数量。',
            invalidQuantityOptions: '我们不支持输入的选项数量。请修改您的订单。',
            insufficientQuantity: '此请求授权所需的数量不足。如需更多信息，请联系支持。',
            minimumNotMet: '抱歉，您的订单未达到此共同基金的最低投资要求。',
            possibleDuplicate: '类似的挂单已经存在。',
            orderGreaterThanPosition: '订单超过总仓位。请在路由前查看订单。',
            accountChangedToMargin: '账户类型已更改为保证金账户。',
            accountChangedToCash: '账户类型已更改为现金账户。',
            foreignAcctDomesticFunds: '所选账户为外国账户，可能无法交易国内共同基金',
            optionLevel: '您当前的期权等级不允许您执行此交易。',
            nextDayOrder: '此订单将在明天下单。',
            continue: '您确定要提交此订单吗？',
            optionsLowVolumeWarning: '此订单活动/兴趣较低。您的订单可能无法成交。',
            isShort: '您正在卖出您目前未持有的股票。这是一笔空头订单。请确认这是您预期要进行的交易。',
            cutoffTime: '该订单已超过基金的截止时间。这是次日订单，将收到基金发行的第一个可用资产净值。',
            acknowledgeTitle: '确认并同意'
        }
    },

    footer: {
        global: {
            chatWithSupport: '与支持人员交谈',
            copyrightLine: [
                [
                    {
                        text: '在FINRA的BrokerCheck上检查StoneX Financial Inc或其投资专业人员的背景：'
                    },
                    {
                        text: 'https://brokercheck.finra.org/',
                        url: 'https://brokercheck.finra.org/'
                    },
                    { text: '。 © ' },
                    { text: new Date().getFullYear().toString() },
                    { text: ' StoneX Group Inc。保留所有权利。' }
                ]
            ],
            disclosures,
            disclosuresByEnvironement: (env: Environment) => (env === 'pro' ? proDisclosures : disclosures),
            importantDisclosures: '重要披露信息',
            marketDataDisclosure: '市场数据由Xignite提供',
            openAnAccount: '开设帐户',
            privacy: '隐私',
            systemRequirements: '系统要求',
            termsOfUse: '使用条款'
        },
        disclosurePdfUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/en-US/DisclosuresFull.pdf'
    },

    mobileUi: {
        bottomBar: {
            summary: '摘要',
            cash: '现金',
            browse: '浏览',
            messages: '消息'
        }
    },

    alerts: {
        addCondition: '添加条件',
        saveAlert: '保存警报',
        updateAlert: '更新提醒',
        editExistingAlert: '编辑现有警报',
        createAlert: '创建新警报',
        createAnAlertToSeeItHere: '创建一个警报以在此处查看。',
        interpretConditions: (field: 'latestPrice' | 'changePercent' | 'Last', operator: '>' | '>=' | '<' | '<=', valueNumberString: string) => {
            const { fieldLabel, formattedValue } = (() => {
                switch (field) {
                    case 'Last':
                    case 'latestPrice':
                        return {
                            fieldLabel: '价格',
                            formattedValue: FormatNumber.toMoney(Number(valueNumberString))
                        };
                    case 'changePercent':
                        return {
                            fieldLabel: '价格百分比变化',
                            formattedValue: FormatNumber.toPercent(Number(valueNumberString))
                        };
                    default:
                        return {
                            fieldLabel: '值',
                            formattedValue: FormatNumber.toCommas(Number(valueNumberString))
                        };
                }
            })();
            const op = (() => {
                switch (operator) {
                    case '>':
                        return '大于';
                    case '>=':
                        return '大于等于';
                    case '<':
                        return '小于';
                    case '<=':
                        return '小于等于';
                    default:
                        return '达到';
                }
            })();

            return `${fieldLabel} ${op} ${formattedValue}`;
        },
        priceAlert: '价格警报',
        youDontHaveAlerts: '看起来您没有任何警报！',
        mutualFundsUnavailable: '共同基金不支持警报',
        futuresUnavailable: '期货不支持警报',
        createAlertSheet: {
            selectCondition: '选择条件',
            change: '变化 %',
            latestPrice: '最新价格',
            priceAlert: '价格警报',
            createAlert: '创建警报',
            updateAlert: '更新警报',
            deleteAlert: '删除警报',
            alert: '警报',
            updateAlertFor: (symbol: string) => (symbol ? `更新 ${symbol} 的警报` : '更新警报'),
            target: '目标',
            chooseSecurity: '选择一个证券',
            greaterThan: '大于',
            greaterThanEqual: '大于等于',
            lessThan: '小于',
            lessThanEqual: '小于等于',
            alertsTitle: '警报',
            newAlert: '新警报',
            searchForSecurity: '搜索证券...',
            searchSecurity: '搜索证券',
            noAlertsYet: '还没有警报',
            createNewAlertRule: '创建新警报规则',
            editSymbol: '编辑符号',
            repeatDaily: '每天重复',
            instructions: {
                createAlertBtn: '使用下面的按钮创建您的第一个警报规则！',
                lookUpSecurity: '查找证券以创建您的第一个警报规则！',
                getNotified: '实时通知证券价格变化。'
            }
        }
    },
    equitiesMarginOnboarding: {
        title: '您正在为您的账户增加保证金',
        agreementNotAllowed: '抱歉，看起来我们无法向此账户添加保证金协议。',
        contactSupport: '如果您认为这是意外的，请联系我们的支持团队。',
        byChoosing: '选择向您的账户添加保证金，意味着您正在申请使用您的资产作为抵押品从StoneX借款的授权。',
        understandRisk: '非常重要的是，您充分了解使用保证金的风险，无论是交易有保证金的证券还是将您的保证金账户资产用于其他目的。',
        agreementDescription: '保证金协议是您与经纪人之间的合同协议。该协议使您能够使用借款资金购买证券。在使用保证金交易之前，请仔细阅读保证金协议。',
        close: '关闭',
        agreeAndContinue: '同意并继续',
        reviewAgreement: '审查保证金协议',
        submitted: '完成！您的保证金协议已添加到您的账户',
        failed: '看起来我们无法将保证金协议添加到您的账户'
    },
    futuresOnboarding: {
        sfi: 'SFI的期货佣金商',
        stepLabels: ['欢迎！', '联系信息', '个人信息', '账户信息', '就业信息', '交易经验', '关联关系', '监管信息', '财务信息', '审核', '协议', '认证'],
        submitMessage: '请稍等，我们正在准备您的期货账户...',
        submitMessageTitle: '正在创建您的新期货账户',
        successMessage: (account: string) => `您的期货账户（${account}）正在创建中。`,
        errorMessage: '出现了问题。',
        tryAgain: '请重试，或者联系我们，我们会帮助您解决问题',
        okButton: '好的',
        closeButton: '关闭',
        accountDelayMessage: '恭喜！您的期货账户正在处理中，即将显示。',
        openAccount: '开设期货账户',
        openAccountNotAllowed: '请联系您的代表寻求帮助',
        openDocumentToEnable: '打开并查看文件以启用',
        openAccountComingSoon: '即将开设期货账户！',
        welcome: (name: string) => `欢迎，${name}！`,
        hasReachedMax: '您已在StoneX One上完成了一个期货账户的入驻。请点击下方按钮返回StoneX One主界面。',
        missingRequiredData: '缺少必要数据',
        nextButton: '下一步',
        returnHome: '返回首页',
        disabledNextText: '请在继续之前完成上方的必填项',
        submitButton: '提交',
        goBackButton: '返回',
        yesButton: '是',
        noButton: '否',
        welcomeSubtitle: '让我们为您开设期货账户',
        contactInfo: {
            title: '填写您的联系信息',
            name: '姓名',
            firstName: '名字',
            lastName: '姓氏',
            email: '电子邮件',
            phone: '电话',
            street: '街道',
            streetAddress: '街道地址',
            street2: '公寓号、楼号等...',
            city: '城市',
            state: '州或地区',
            country: '国家',
            zip: '邮编',
            error: '必须至少21岁'
        },
        personalInfo: {
            title: '个人信息',
            citizenQuestion: '您是美国公民吗？',
            citizenYes: '我是美国公民',
            citizenNo: '我不是美国公民',
            ssnQuestion: '您是否拥有社会安全号码或ITIN？',
            ssnYes: '我拥有社会安全号码或ITIN',
            ssnNo: '我没有社会安全号码或ITIN',
            ssn: '社会安全号码或ITIN',
            country: '公民国家',
            dob: '出生日期',
            governmentId: '政府颁发的身份证号码'
        },
        accountInfo: {
            title: '让我们收集您的账户信息',
            accountType: '个人',
            accountTypeSubtitle: '仅有一个所有者的标准账户',
            sourceOfFunds: '资金来源',
            intendedInitialDeposit: '预期初始存款',
            expectedMonthlyTrading: '预期每月交易活动',
            sourceOfFundsOptions: [
                { label: '经纪人', value: 'Broker' },
                { label: '礼物', value: 'Gift' },
                { label: '继承', value: 'Inheritance' },
                { label: '保险', value: 'Insurance' },
                { label: '法律和解', value: 'Legal Settlement' },
                { label: '养老金', value: 'Pension' },
                { label: '退休', value: 'Retirement' },
                { label: '销售', value: 'Sale' },
                { label: '工资', value: 'Wages' }
            ],
            intendedInitialDepositOptions: [
                { label: '0 - 1,000美元', value: 0 },
                { label: '1,001 - 10,000美元', value: 1000 },
                { label: '10,001 - 50,000美元', value: 10000 },
                { label: '50,001 - 100,000美元', value: 50000 },
                { label: '100,001 - 250,000美元', value: 100000 },
                { label: '250,001 - 500,000美元', value: 250000 },
                { label: '500,001 - 1,000,000美元', value: 500000 },
                { label: '1,000,001 - 5,000,000美元', value: 1000000 },
                { label: '5,000,000美元以上', value: 5000000 }
            ],
            expectedMonthlyTradingOptions: [
                { label: '0 - 100', value: 0 },
                { label: '101 - 1,000', value: 100 },
                { label: '1,001 - 10,000', value: 1000 },
                { label: '10,001 - 50,000', value: 10000 },
                { label: '50,001美元以上', value: 50000 }
            ]
        },
        employmentInfo: {
            title: '就业信息',
            status: '就业状态',
            employedTitle: '在职',
            employedSubtitle: '我目前在一家公司工作',
            studentTitle: '自雇',
            studentSubtitle: '我目前是自雇人士',
            unemployedTitle: '其他',
            unemployedSubtitle: '我目前没有就业，也没有退休',
            retiredTitle: '退休',
            retiredSubtitle: '我目前退休',
            employerAddress: {
                title: '雇主地址',
                country: '国家',
                street: '地址行1',
                street1: '地址行2',
                city: '城市',
                state: '州',
                zip: '邮政编码'
            },
            years: '在雇主处工作年限',
            employerDetailsTitle: '雇主详细信息',
            occupationTitle: '职业',
            name: '雇主名称',
            jobTitle: '职位',
            industryTitle: '行业',
            jobFunctionTitle: '职能',
            primarySourceOfIncomeTitle: '您的主要收入来源是什么？',
            incomeOptions: [
                { label: '经纪人', value: 'Broker' },
                { label: '礼物', value: 'Gift' },
                { label: '继承', value: 'Inheritance' },
                { label: '保险', value: 'Insurance' },
                { label: '法律和解', value: 'Legal Settlement' },
                { label: '养老金', value: 'Pension' },
                { label: '退休', value: 'Retirement' },
                { label: '销售', value: 'Sale' },
                { label: '工资', value: 'Wages' }
            ],
            occupationOptions: [
                {
                    label: '会计师/审计师/簿记员',
                    value: 'Accountant/Auditor/Bookeeper'
                },
                { label: '管理员/办公室经理', value: 'Admin/Office Manager' }
            ],
            industryOptions: [], // No options provided
            jobFunctionOptions: [] // No options provided
        },
        tradingExperience: {
            title: '告诉我们您的以往交易经验',
            experienceYears: [
                { label: '不到1年', value: '0' },
                { label: '1至2年', value: '1' },
                { label: '2至3年', value: '2' },
                { label: '3年或更多', value: '3' }
            ],
            commoditiesTitle: '大宗商品交易经验',
            commoditiesQuestion: '您是否有商品交易经验？',
            commoditiesYears: '商品交易经验年限',
            securitiesQuestion: '您是否有证券交易经验？',
            securitiesYears: '证券交易经验年限',
            otcTitle: '场外交易经验',
            otcQuestion: '您是否有场外交易（OTC）经验？',
            otcYears: '场外交易经验年限',
            professionalTrader: '您是否符合 CME 定义的市场数据专业人士资格？',
            professionalTraderUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/Futures-Market-Data-Subscription-Terms.pdf',
            professionalTraderPdfLabel: '期货市场数据订阅条款',
            tradingExperienceTitle: '交易经验',
            doTradingExperience: '你有交易经验吗',
            yearsExperience: '经验年限',
            professionalTraderTitle: '专业交易员',
            commodityExchangeAct: '商品交易法',
            governmentRelations: '政府关系',
            bankruptcy: '破产',
            legalDisputes: '法律纠纷',
            closedCommodityAccounts: '关闭的商品账户'
        },
        affiliationsInfo: {
            title: '只需回答几个简短的问题',
            politicalOrGovernment: '您或您的直系家庭成员是否是政府的任职官员或与政府拥有企业有关联，而不是美国政府？',
            employeeOfStoneX: '您或您的直系家庭成员是否是StoneX Financial Inc.（“FCM”）的员工或其子公司的员工或与其员工有关？',
            regulatoryMember: '您是否注册在美国国家期货协会（“NFA”）或其他任何监管机构或交易所的会员？',
            regulatoryBody: '监管机构',
            registrationNumber: '注册号',
            employeeName: '员工姓名',
            employeeRelationship: '关系',
            nfaBody: 'NFA机构名称 ',
            nfaRegNumber: 'NFA注册号'
        },
        regulatoryInfo: {
            title: '让我们收集一些监管信息',
            registrationExemption: '您是否根据商品交易法的注册豁免操作？',

            bankruptcy: '您是否曾是破产程序、接管程序或类似行动的主体？',
            disputeOrArbitration: '您是否曾涉及与商品账户有关的法律纠纷、仲裁或赔偿行动？',
            unsatisfiedDebitBalance: '您是否曾经在商品公司关闭账户时有不满意的借方余额？'
        },
        financialInfo: {
            title: '财务信息',
            error: '财务状况不符合期货账户的要求',
            page2Title: '继续填写财务信息',
            annualRevenue: '来自所有来源的年收入（美元）',
            annualRevenueOptions: [
                { label: '低于$25,000', value: '0' },
                { label: '$25,000 - $50,000', value: '0.025' },
                { label: '$50,000 - $100,000', value: '0.05' },
                { label: '$100,000 - $250,000', value: '0.1' },
                { label: '$250,000 - $500,000', value: '0.25' },
                { label: '$500,000 - $1,000,000', value: '0.5' },
                { label: '$1,000,000 - $5,000,000', value: '1' },
                { label: '大于$5,000,000', value: '5' }
            ],
            netWorth: '总净资产（美元）',
            netWorthOptions: [
                { label: '低于$100,000', value: '0' },
                { label: '$100,000 - $500,000', value: '0.1' },
                { label: '$500,000 - $1,000,000', value: '0.5' },
                { label: '$1,000,000 - $5,000,000', value: '1' },
                { label: '大于$5,000,000', value: '5' }
            ],
            workingCapital: '可用营运资本（美元）',
            workingCapitalOptions: [
                { label: '低于$25,000', value: '0' },
                { label: '$25,000 - $100,000', value: '0.025' },
                { label: '$100,000 - $250,000', value: '0.1' },
                { label: '$250,000 - $500,000', value: '0.25' },
                { label: '$500,000 - $1,000,000', value: '0.5' },
                { label: '$1,000,000 - $5,000,000', value: '1' },
                { label: '大于$5,000,000', value: '5' }
            ],
            annualRevenueShort: '年收入',
            totalNetWorth: '总净值',
            availableWorkingCapital: '可用工作资金'
        },
        review: {
            title: '让我们审查您的信息',
            subTitle: '我们快要完成了！让我们仔细查看您提供的答案，确保一切准确无误',
            personal: '个人',
            account: '账户',
            funding: '资金',
            trustedContact: '信任联系人',
            addTrustedContact: '添加信任联系人',
            tradingInfo: '交易信息',
            regulatoryInfo: '监管信息',
            stoneXAffiliation: 'StoneX 关联',
            nfaAffiliation: 'NFA 关联',
            jobTitle: '职位',
            employer: '雇主',
            yearsWithEmployer: '在雇主处工作年限',
            employment: '就业',
            neitherStoneXAffiliation: '无论您还是您家庭成员，以及您同一住户内的任何人，都不是StoneX Group Inc.或StoneX关联公司的雇员或代理人。',
            neitherNfaAffiliation: '无论您还是您家庭成员，以及您同一住户内的任何人，都未被雇佣或与股票交易所、股票交易所成员公司、FINRA或市政债券经销商相关联。',
            notice: '通知',
            noticeDescription: '期货账户是在SFI期货佣金商下创建的。',
            employedStatus: (employedStatus: string) => {
                return [Light('您是'), Dark(`${employedStatus}`), Light('.')];
            },
            accountOpening: (accountType: string) => {
                return [Light('您正在开设一个'), Dark(`${accountType}`), Light('.')];
            },
            exemptUnderCommodityExchangeAct: (areNot: string) => {
                areNot = areNot === 'are' ? '正在' : '不在';
                return `根据商品交易法，您${areNot}依据注册豁免操作。`;
            },
            relatedToGovernment: (areNot: string) => {
                areNot = areNot === 'are' ? '是' : '不是';
                return `您或您的任何直系家庭成员${areNot}任何政府部门的现任政治官员或隶属于政府拥有的企业，除了美国政府。`;
            },
            hasBankruptcy: (haveNot: string) => {
                haveNot = haveNot === 'have' ? '有' : '没有';
                return `您${haveNot}经历过破产程序、接管或类似行动。`;
            },
            hasLegalDisputeWithCommodities: (haveNot: string) => {
                haveNot = haveNot === 'have' ? '有' : '没有';
                return `您${haveNot}参与过与商品账户相关的法律纠纷、仲裁或赔偿行动。`;
            },
            hasClosedCommodityAccountWithDebitBalance: (haveNot: string) => {
                haveNot = haveNot === 'have' ? '有' : '没有';
                return `您${haveNot}在商品公司关闭过带有不满足的借方余额的账户。`;
            },
            isProffesionalTrader: (areNot: string) => {
                areNot = areNot === 'are' ? '是' : '不是';
                return `您${areNot}专业交易员。`;
            }
        },
        agreements: {
            arbitration: '仲裁',
            accept: '我接受',
            doNotAccept: '我不接受',
            acceptArbitration: '您是否接受仲裁协议？（可选',
            arbitrationAgreement: '我声明并保证我已阅读“仲裁协议”的条款并理解其规定，我正在作出以下选择：',
            pageTitle: '适用于您的账户的重要文件',
            titleText: '要求您仔细审查此页面上的所有内容，以及下面的每个必需文件，只需点击下面的文件标题即可访问其内容。在完成“客户认证”部分之前，请仔细阅读所有文件。',
            part1Title: '第1部分：隐私声明',
            part2Title: '第2部分：期货与期货期权风险披露',
            part3Title: '第3部分：期货与期货期权账户协议',
            part4Title: '第4部分：期货与期货期权市场数据条款',
            clientCertTitle: '客户认证',
            acceptAndContinueText: '点击下面的“接受并继续”按钮：',
            W9title: 'IRS W-9表认证',
            perjuryCertTitle: '在伪证罪的惩罚下，我证明：',
            perjuryCertItem1: '我是美国公民或其他美国居民',
            perjuryCertItem2: '我的纳税人识别号是正确的',
            perjuryCertItem3: '我不受备份代扣的约束',
            perjuryCertItem4:
                '此表格上的FATCA代码（如果有）指示免于FATCA报告，代码正确无误。（作为在美国维护您的账户的美国金融机构，我们未要求FATCA代码，因此此项不适用）。 ',
            clientAcknowledgementTitle: '客户确认',
            clientAcknowledgementItem1: [
                [
                    { text: '我确认我已阅读并理解“第1部分：隐私声明”内的所有文件内容' },
                    { text: '，“FCM Division of StoneX Financial Inc.”', weight: 'bold' },
                    {
                        text: '和“StoneX Markets LLC”分别依据此信息为建立一个或多个客户账户的基础。我在此证明，所有信息，包括经审计或未经审计的财务数据，或填写收入和净值信息的申请人在账户申请中填写的所有其他信息都符合反洗钱政策和程序。'
                    }
                ]
            ],
            clientAcknowledgementItem2: [
                [
                    {
                        text: '我在此确认，我已阅读并理解“第2部分：期货与期货期权风险披露”内的所有文件内容，包括期货和交易所交易期权风险披露，'
                    },
                    {
                        text: '商品期权风险披露，以及所有“其他披露文件”内的所有内容。'
                    }
                ]
            ],
            clientAcknowledgementItem3: [
                [
                    {
                        text: '我在此确认，我已阅读并理解完整的“',
                        weight: 'bold'
                    },
                    {
                        text: 'StoneX Financial Inc. 期货与交易所交易期权客户协议',
                        weight: 'bold'
                    },
                    {
                        text: '”，并同意遵守其中的条款和条件，根据其条款随时进行修改。'
                    }
                ]
            ],
            clientAcknowledgementItem4: [
                [
                    {
                        text: '我在此确认，我已阅读并理解完整的“',
                        weight: 'bold'
                    },
                    {
                        text: '平台使用协议',
                        weight: 'bold'
                    },
                    {
                        text: '”，并同意遵守其中的条款和条件，以我在访问和使用平台方面的情况为准。'
                    }
                ]
            ],
            clientAcknowledgementItem5: [
                [
                    {
                        text: '与期货账户相关的争议的仲裁选项：',
                        weight: 'bold'
                    },
                    {
                        text: '我在此确认，我已阅读“仲裁协议”',
                        weight: 'bold'
                    },
                    {
                        text: '并理解其中的规定，我作出以下选项：',
                        weight: 'bold'
                    }
                ]
            ],
            clientAcknowledgementItem6: [
                [
                    {
                        text: '以下信息适用于选择通过自动清算系统（ACH）进行电子转账的客户：',
                        weight: 'bold'
                    },
                    {
                        text: '我在此授权“FCM Division of StoneX Financial Inc.”',
                        weight: 'bold'
                    },
                    {
                        text: '（以下简称“FCM”），在客户在FCM开设账户的情况下，根据FCM客户协议的条款，通过自动清算系统（ACH）发起消费者预安排的支付和存款（PPD）信用分录，将资金转入我通过平台选定并经过身份验证的存放在存款金融机构的指定账户，并从该账户中不时扣除款项，以支付FCM账户中的任何“保证金调用”或其他赤字，以符合FCM客户协议的规定。'
                    },
                    {
                        text: '我确认根据美国法律的规定，FCM以自动清算系统（ACH）向我的账户发起交易必须符合U.S. law的规定。'
                    },
                    {
                        text: '我进一步确认，根据此授权执行的所有扣款金额可能会有所变化，但每笔扣款的金额应等于当时到期的“保证金调用”或其他赤字的金额（根据FCM客户协议确定）以及适用的任何费用。'
                    }
                ]
            ],
            clientAcknowledgementItem7: [
                [
                    { text: '我在此确认，我已阅读并理解“', weight: 'bold' },
                    { text: '期货市场数据订阅条款', weight: 'bold' },
                    { text: '”的全部内容，并同意遵守其中的条款和条件，以我在访问和使用平台方面的情况为准。' }
                ]
            ],
            arbitrationAcceptText: '接受仲裁规定',
            arbitrationDeclineText: '拒绝仲裁规定',
            IRSnote: '除了避免备份代扣所要求的认证外，IRS不要求您对本文件的任何规定作出同意。',
            acceptAndContinueButton: '接受并继续'
        },
        documents: {
            privacyPolicy: 'StoneX隐私政策和StoneX隐私法',
            antiMoneyLaundering: '反洗钱程序',
            commoditiesFuturesRiskDisclosure: '商品期货交易委员会风险披露',
            commoditiesOptionsRiskDisclosure: '期权披露声明',
            addlDisclosures: '其他披露文件，包括以下内容：',
            subText: {
                nfaRiskDisclosure: 'NFA额外风险披露',
                addlRiskDisclosure: '额外风险披露',
                materialConflicts: '重大利益冲突披露',
                electronicTrading: '电子交易和订单路由披露',
                noticeToOmnibus: '关于对制裁方访问限制的对交易商账户通知',
                underlyingOrSpot: '标的或现货虚拟货币产品披露',
                clearedSwaps: '已结算掉期交易披露'
            },
            stoneXCustomerAgreement: 'StoneX Financial Inc. 期货与交易所交易期权客户协议',
            arbitrationAgreement: '仲裁协议',
            platformAgreement: '平台使用协议',
            virtualCurrencyRiskDisclosure: '虚拟货币风险披露声明',
            marketDataSubscriptionTerms: '期货市场数据订阅条款',
            blobStorageUrls: {
                privacyPolicies: 'https://snex1storage.blob.core.windows.net/$web/docs/StoneX-Privacy-Policies.pdf',
                commodityFuturesTradingComissionRiskDisclosure:
                    'https://snex1storage.blob.core.windows.net/$web/docs/Commodity-Futures-Trading-Commission-Risk-Disclosure-202210X.pdf',
                optionsDisclosureStatement: 'https://snex1storage.blob.core.windows.net/$web/docs/Options-Disclosure-Statement-202210X.pdf',
                acknowledgementOfAdditionalDisclosures: 'https://snex1storage.blob.core.windows.net/$web/docs/Acknowledgement-of-Additional-Disclosures-202210X.pdf',
                virtualCurrencyRiskStatements: 'https://snex1storage.blob.core.windows.net/$web/docs/NFA-CFTC-Virtual-Currency-Risk-Statements-202210X.pdf',
                futuresAndExchangeTradedOptionsCustomerAgreement:
                    'https://snex1storage.blob.core.windows.net/$web/docs/Futures-and-Exchange-Traded-Options-Customer-Agreement-SFI_FCM_202210X.pdf',
                platformUserAgreement: 'https://snex1storage.blob.core.windows.net/$web/docs/Platform-User-Agreement-SFI_FCM_202210X.pdf',
                arbitrationAgreement: 'https://snex1storage.blob.core.windows.net/$web/docs/Arbitration-Agreement-SFI_FCM_202210X.pdf',
                futuresMarketDataSubscriptionTerms: 'https://snex1storage.blob.core.windows.net/$web/docs/Futures-Market-Data-Subscription-Terms.pdf'
            }
        },
        submit: {
            title: '条款和协议'
        }
    },
    mediantSSO: {
        buttonText: '公司行动'
    },
    equitiesOnboarding: {
        yes: '是',
        no: '否',
        firstName: '名字',
        lastName: '姓氏',
        email: '电子邮件',
        continue: '继续',
        showIssues: '显示问题',
        are: '是',
        areNot: '不是',
        openAccount: '开立股票交易账户',
        personalInfo1: {
            title: '您的姓名和出生日期是？',
            birthDate: '出生日期'
        },
        contactInfo: {
            title: '如何联系您？',
            validationMessage: '电话号码必须为10位数字'
        },
        personalInfo2: {
            title: '您的国籍',
            label: '您是美国公民吗？',
            yesCitizen: '是的，我是美国公民',
            noCitizen: '不，我不是美国公民',
            ssnHeader: 'SSN或税号必须为9位数字',
            ssnOr: 'SSN或ITIN',
            alert: '抱歉，StoneX One目前仅适用于拥有社会安全号码的美国公民。',
            desc: '目前只有拥有社会安全号码的美国公民可以访问StoneX One。'
        },
        chooseAccount: {
            title: '选择您的账户',
            description: '长按账户类型以获取更多详情',
            individual: '个人账户',
            individualDescription:
                '一个只有一个所有者的应税账户，您可以在其中投资股票、债券、ETF和共同基金。您可以申请保证金和期权交易。此账户无需强制分配，您可以随时存入和提取资金。',
            individualForeign: '个人外国账户',
            individualForeignDescription:
                '一个只有一个所有者的账户，您可以在其中投资股票、债券、ETF和共同基金。您可以申请保证金和期权交易。此账户无需强制分配，您可以随时存入和提取资金。',
            rothIRA: 'Roth IRA账户',
            rothIRADescription:
                '个人退休账户，允许您使用税后美元进行贡献。虽然目前没有当年的税收优惠，但您的贡献和任何投资收益都是免税的，您可以在59岁半后且账户开立满五年后免税且无罚款地提取它们。',
            traditionalIRA: 'Traditional IRA账户',
            traditionalIRADescription:
                '个人退休账户，允许您使用税前美元进行贡献，任何投资收益均可在退休时延期纳税。此账户适用于一定的贡献限额、提前提取罚款和必需的最低分配。'
        },
        addressInfo: {
            title: '您住在哪里？',
            secondary: '公寓号、楼号等'
        },
        employmentOptions: {
            title: '您的就业状况是？',
            employment: '就业',
            employmentStatus: {
                employed: '已就业',
                youAreEmployed: '已就业',
                unemployed: '失业',
                youAreUnemployed: '失业',
                student: '学生',
                youAreStudent: '学生',
                retired: '退休',
                youAreRetired: '退休'
            },
            employmentInfo: {
                title: '就业信息',
                occupation: '职业',
                employer: '雇主',
                jobTitle: '职位',
                jobFunction: '职能',
                industry: '行业',
                employerPhone: '雇主电话',
                yearsEmployed: ' 工作年限'
            },
            employmentAddress: '就业地址',
            occupations: {
                accountingOrAuditing: '会计或审计',
                advertisingOrMarketingSVCE: '广告或营销（SVCE）',
                civilService: '公务员',
                it: 'IT',
                domesticHelp: '家政服务',
                education: '教育',
                trainingEmployment: '培训与就业',
                engineering: '工程',
                executiveManagement: '高管',
                finance: '金融',
                insurance: '保险',
                doctor: '医生',
                dental: '牙医',
                nurse: '护士',
                socialServices: '社会服务',
                homemaker: '家庭主妇',
                hospitality: '款待',
                tourism: '旅游',
                transport: '运输',
                humanResources: '人力资源',
                manufacturing: '制造业',
                military: '军队',
                adminSecty: '行政秘书',
                customerService: '客户服务',
                other: '其他',
                salesOrRetail: '销售或零售',
                science: '科学',
                agent: '代理人',
                banker: '银行家',
                business: '商业',
                policeFireLaw: '警察、消防、法律',
                attorneyJudgeLegal: '律师、法官、法律',
                politician: '政治家'
            },
            jobFunction: {
                creditCollections: '信用收款',
                financialPlanning: '财务规划',
                financialAccounting: '财务会计',
                payroll: '工资单',
                procurement: '采购',
                riskManagement: '风险管理',
                tax: '税务',
                treasury: '财务',
                businessManagement: '商业管理',
                adminManager: '行政和管理',
                executiveAssistant: '高级助理',
                officeClerks: '办公室职员',
                counseling: '咨询',
                recruiting: '招聘',
                training: '培训',
                applicationsSystems: '应用与系统',
                businessSystems: '业务系统',
                databaseAdmin: '数据库管理员',
                ITArchitecture: 'IT架构',
                ITProjectManagement: 'IT项目管理',
                networkServices: '网络服务',
                softwareDevelopment: '软件开发',
                systemsSupport: '系统支持',
                technicalServices: '技术服务',
                webServices: '网络服务',
                generalCounsel: '总顾问',
                legalServices: '法律服务',
                legalSupport: '法律支持',
                audioVisual: '音频和视觉',
                communications: '通信',
                designServices: '设计服务',
                marketing: '市场营销',
                mediaRelations: '媒体关系',
                publications: '出版物',
                writingEditorial: '写作和编辑',
                other: '其他'
            },
            industries: {
                accounting: '会计',
                advertisingMarketing: '广告和营销',
                aerospaceDefense: '航空和国防',
                architectureDesign: '建筑和设计',
                automotive: '汽车',
                childcare: '儿童看护',
                communicationsTelecommunications: '通信和电信',
                constructionCarpentryLandscaping: '建筑和木工',
                education: '教育',
                energy: '能源',
                engineering: '工程',
                financialServices: '金融服务',
                gamingCasinoCardClub: '游戏、赌场、卡片俱乐部',
                governmentPublicAdministration: '政府和公共管理',
                healthcareMedicalServices: '医疗保健和医疗服务',
                hotelHospitality: '酒店和款待',
                informationTechnology: '信息技术',
                insurance: '保险',
                legalServicesPublicSafety: '法律服务和公共安全',
                manufacturing: '制造业',
                mediaEntertainment: '媒体和娱乐',
                nonProfitCharity: '非营利和慈善',
                printingPublishing: '印刷和出版',
                realEstate: '房地产',
                restaurantFoodService: '餐厅和食品服务',
                transportation: '交通',
                travel: '旅行',
                other: '其他'
            }
        },
        funding: {
            title: '您的资金来源是？',
            sourceIncome: {
                title: '收入来源',
                wages: '工资',
                sale: '出售',
                inheritance: '继承',
                gift: '礼物',
                insurance: '保险',
                legalSettlement: '法律和解',
                pension: '养老金',
                retirement: '退休金',
                broker: '经纪人'
            },
            estimatedLiquidNet: {
                title: '',
                a: '少于$50,000',
                b: '$50,000 - $99,999',
                c: '$100,000 - $199,999',
                d: '$200,000 - $499,999',
                e: '$500,000 - $999,999',
                f: '$1百万 - $2.49百万',
                g: '$2.5百万+'
            }
        },
        trustedContact: {
            title: '可信联系人',
            description: '您是否想添加一个可信联系人？',
            contactInfo: {
                title: '可信联系人信息',
                relationship: '关系',
                phoneNumber: '电话号码',
                contactsEmailOpt: '联系人的电子邮件（可选）',
                addAddress: '您是否想添加可信联系人的地址？'
            },
            addressTitle: '可信联系人地址'
        },
        stoneXAffiliation: {
            title: 'StoneX 关联',
            titleInfo: 'StoneX 关联信息',
            description: '您，或者您的直系亲属或与您同住的人员，是否是StoneX Group Inc.或其附属公司的员工或代理商，或StoneX关联公司的员工或代理商？',
            position: '在 StoneX 所占职位',
            relationToEmployee: '与员工的关系',
            affiliatedEntity: '关联实体',
            affiliationName: 'StoneX关联名称',
            affiliationRelationship: '关联关系',
            relationship: {
                child: '子女',
                self: '本人',
                spouse: '配偶',
                other: '其他'
            },
            entity: '与 StoneX 关联的实体',
            affiliate: {
                stnx: 'STNX',
                infa: 'INFA',
                sswm: 'SSWM',
                ssia: 'SSIA'
            },
            affiliation: {
                // FirstName
                // LastName
                areYou: '您是否是',
                you: '您',
                areOtherUsBrokers: '您是否与行业成员以外的美国注册经纪人有关联？',
                areAccreditedMember: '您是否被行业成员认定为合格投资者？',
                areRegisteredAdvisor: '您是否与美国注册投资顾问有关？',
                areOfficerOwnsTenPercent: '您是否是公共公司的高管、董事或持有10%或更多股份的股东？',
                areEmployeeOfStonex: '您是否是 StoneX Financial Inc. 或其引荐公司的员工或关联人员？',
                areNaturalPersonOrEntityWithoutTaxId: '您是否是没有美国税号的自然人或法律实体？',
                otherUsBrokers: '与行业成员以外的美国注册经纪人有关联。',
                accreditedMember: '被行业成员认定为合格投资者。',
                registeredAdvisor: '与美国注册投资顾问有关。',
                officerOwnsTenPercent: '是公共公司的高管、董事或持有10%或更多股份的股东。',
                employeeOfStonex: '是 StoneX Financial Inc. 或其引荐公司的员工或关联人员。',
                naturalPersonOrEntityWithoutTaxId: '是没有美国税号的自然人或法律实体。'
            }
        },
        industryAffiliation: {
            title: '行业关联',
            description: '您，或者您的直系亲属或与您同住的人员，是否在股票交易所、股票交易所成员公司、FINRA 或市政债券经销商工作或有关联？',
            // yes
            // no
            firmName: '公司名称',
            sec144: '您，或者您的直系亲属或与您同住的人员，是否是根据 SEC Rule 144 规定被视为政策制定官员、董事、持有10%或更多股权的股东，或其他公开交易公司的关联人员？',
            companyName: '公司名称',
            ticker: '公司股票代码或 CUSIP'
        },
        review: {
            title: '复查',
            personal: '个人信息',
            // firstName
            // lastName
            // email
            // PhoneNumber
            countryOfCitizenship: '公民国籍', // 重复
            ssnItin: '社会安全号码 / 纳税人识别号',
            account: '账户信息',
            accountDescription: '您正在开设一个', // 您正在开设一个
            employment: '就业信息', // 就业信息
            employmentDescription: '您', // 您
            funding: '资金来源', // 资金来源
            // sourceofIncome 和 type（使用上述资金来源）
            // estimated net worth 和 amount（使用上述资金来源）
            // trusted contact
            // firstName（来自 sharedTranslations）
            // lastName（来自 sharedTranslations）
            // phoneNumber
            relationship: '关系',
            noStoneXAffiliation: '',
            address: '地址',
            // firmName
            sec144Name: 'SEC Rule 144 公司名称', // SEC Rule 144 公司名称
            sec144Ticker: 'SEC Rule 144 公司代码', // SEC Rule 144 公司代码
            notPolicyMaker:
                '您、您的直系亲属或与您同住的人员都不是政策制定官员、董事、持有10%或更多股权的股东，也没有被视为根据 SEC Rule 144 规定与公开交易公司有关联的人员。',
            notEmployed: '您、您的直系亲属或与您同住的人员都没有在股票交易所、股票交易所成员公司、FINRA 或市政债券经销商工作或有关联。'
        },
        agreements: {
            title: '添加非异议受益所有人',
            description:
                '根据法规，StoneX Financial Inc. 将账户所有人的姓名和地址提供给他们所持有的证券的公司或发行人。这些公司或发行人在需要联系股东进行重要的股东沟通时会请求这些信息。',
            label: '您是否选择不向这些公司和/或发行人发布此信息。（可选）',
            optIn: '选择 - 根据需要分享我的信息',
            optOut: '选择 - 不要分享我的信息'
        },
        disclosures: {
            title: '披露和协议',
            reviewAccept: '复查并接受我们的披露和协议',
            reviewDisclosures: '查看披露',
            disclosuresAccepted: '已接受披露！',
            acceptContinue: '接受并继续',
            pressFinishToCreate: '按“完成”创建您的账户',
            finish: '完成',
            missingData: '看起来我们还缺少一些数据',
            finishLater: '稍后完成'
        }
    },
    equitiesOptionsOnboarding: {
        gotIt: '有了',
        whatAreOptions: {
            title: '什么是期权',
            atItsCore: '就其核心而言，期权交易就像获得一张 "票"，赋予你在特定时间内以特定价格买入或卖出股票的权利，但不是义务',
            analogy: '我们来打个简单的比方',
            imagine: `想象一下，你看到下个月你最喜欢的乐队将举办一场音乐会，但你不确定自己能否参加。你不用马上买票，而是支付一小笔费用购买 "期权"，在演唱会开始前随时以当前价格购买门票`
        },
        financialInformation: {
            title: '财务信息',
            annualIncome: '年收入',
            estNw: '估计净资产',
            estLiquidNw: '估计流动净资产',
            selectAnnualIncome: '选择年收入',
            selectEstNw: '选择估计净资产',
            selectLiquidNw: '选择估计流动资产净值'
        },
        investmentExperience: {
            title: '投资经验',
            0: '0  年',
            1: '0-2  年',
            2: '2-5  年',
            3: '5 年以上',
            stocksAndBonds: '股票和债券',
            mutualFunds: '共同基金',
            options: '选项',
            annuities: '年金',
            altInvestments: '另类投资 另类投资',
            selectNumYears: '选择年数'
        },
        requestOptionsLevel: {
            title: '申请期权级别',
            description: `由于期权交易具有不同程度的风险，因此期权交易被分为多个 "级别 "或 "层级"。我们要求您根据自己的经验、财务状况和对相关风险的理解，申请特定级别的交易`
        },
        riskTolerance: {
            title: '风险容忍度',
            description: '长按每个选项了解更多信息',
            conservative: '保守',
            moderate: '适中',
            aggressive: '激进',
            speculative: '投机',
            conservativeDesc: '您追求的是当前收入。长期资本增长不是目标。您愿意接受极低的波动性和可能损失的部分本金，但资本保值仍是重要的考虑因素',
            moderateDesc: '您同时追求资本增长和当前收入。为了实现您的投资目标，您愿意接受中等程度的波动，以获得资本增值或更高的收入回报，以及可能损失的部分本金',
            aggressiveDesc: '您追求长期资本增长。当前收入不是目标。为了实现您的投资目标，您愿意接受极高的波动性以及可能损失大部分本金的风',
            speculativeDesc: '您追求证券价格短期波动带来的已实现收益。为了实现您的投资目标，您愿意接受最大程度的波动以及可能出现的本金全部损失'
        },
        optionsLevel: {
            title: '期权等级',
            description: '我们根据您的风险承受能力启用了期权级别',
            level1: {
                title: '第 1 级',
                action: '写入备兑看涨期权s'
            },
            level2: {
                title: '第 2 级',
                action1: '1 级战略',
                action2: '购买看涨期权和看跌期权'
            },
            level3: {
                title: '第 3 级',
                action1: '1-2 级策略',
                action2: '价差和跨式'
            },
            level4: {
                title: '第 4 级',
                action1: '1-3 级策略',
                action2: '写入未覆盖的看跌期权',
                action3: '写入有现金担保的股票认沽权证'
            },
            level5: {
                title: '第 5 级',
                action1: '1-4 级策略',
                action2: '写入未平仓看涨期权'
            },
            requiresMargin: '需要保证金',
            addMargin: 'Add margin to your account',
            levelRequiresMargin: '水平要求保证金',
            level2Margin: 'For trading levels above 2, you must have a margin agreement on file.'
        },
        optionsAgreement: {
            title: '期权协议',
            description: '在提交申请之前，您必须仔细阅读并理解以下协议',
            review: '审查期权协议',
            close: '关闭'
        },
        review: {
            title: '评论',
            financialInfo: '财务信息',
            estLiquidNw: '估计流动净值',
            netWorth: '净值',
            income: '收入',
            experienceInfo: '经验信息',
            stocksAndBonds: '股票和债券',
            options: '选项',
            mutualFunds: '共同基金',
            annuities: '年金',
            altInvestments: 'Alt. 投资',
            optionsInfo: '选项信息',
            optionsLevel: '选项级别',
            investmentObjective: '投资目标'
        }
    },
    sharedOnboarding: {
        submissionModal: {
            multiAssetTrading: '多资产交易即将来临。',
            loading: '请稍候...',
            allDone: '全部完成！让我们开始交易。',
            notAbleToCreateAccount: '看起来我们无法完成创建您的账户。',
            tapAnywhereContinue: '点击任意位置继续',
            tapAnywhereForSupport: '点击任意位置与客服聊天',
            applicationPending: '您的账户正在处理中。在您的账户创建完成后，我们会通过电子邮件通知您。',
            applicationQueued: '您的账户申请已进入队列。在您的账户创建完成后，我们会通过电子邮件通知您。'
        },
        agreementRequired: (agreementName: string) => `必须接受 ${agreementName} 才能继续。`,
        selectAccount: {
            title: (name: string) => `欢迎 ${name}！`,
            subtitle: '让我们为您创建 StoneX One 新账户',
            equitiesButtonLabelTitle: '股票',
            futuresButtonLabelTitle: '期货',
            equitiesButtonLabelSubtitle: '开设股票交易账户',
            futuresButtonLabelSubtitle: '开设期货交易账户'
        },
        true: '是',
        false: '否',
        signup: {
            leaveSignup: '离开注册',
            confirmCancelSignup: '您确定要取消注册吗？',
            yesCancel: '是，取消',
            noStayHere: '不，留在这里'
        },
        headerTranslations: {
            personal: '个人信息',
            account: '账户信息',
            address: '地址信息',
            employment: '就业信息',
            funding: '资金信息',
            trustedContact: '可信联系人',
            stonexAffiliation: 'StoneX 关联',
            industryAffiliation: '行业关联',
            whichAccount: '您想要开设哪种账户？'
        },
        fieldTranslations: {
            personal: {
                firstName: '名字',
                lastName: '姓氏',
                email: '电子邮件',
                phoneNumber: '电话号码',
                usCitizen: '您是美国公民吗？',
                countryOfCitizenship: '国籍',
                ssnOrItin: '社会安全号码或纳税人识别号'
            },
            account: {
                accountType: '账户类型'
            },
            address: {
                address: '地址',
                city: '城市',
                state: '州',
                zipCode: '邮编'
            },
            employment: {
                employmentStatus: '就业状态',
                occupation: '职业',
                employer: '雇主',
                employerPhoneNumber: '雇主电话号码',
                jobTitle: '职位',
                industry: '行业',
                jobFunction: '职能',
                employerCountry: '雇主所在国家',
                employerAddress: '雇主地址',
                employerAddress2: '雇主地址2',
                employerCity: '雇主城市',
                employerState: '雇主州',
                employerZipCode: '雇主邮编'
            },
            funding: {
                sourceOfIncome: '收入来源',
                estimatedLiquidNetWorth: '估计的流动净值'
            },
            trustedContact: {
                addTrustedContact: '添加可信联系人',
                withTrustedContact: '添加可信联系人？',
                trustedContactFirstName: '可信联系人名字',
                trustedContactLastName: '可信联系人姓氏',
                trustedContactEmail: '可信联系人电子邮件',
                trustedContactPhoneNumber: '可信联系人电话号码',
                trustedContactRelationship: '可信联系人关系',

                withTrustedContactAddress: '添加可信联系人地址？',
                trustedContactAddress: '可信联系人地址',
                trustedContactCity: '可信联系人城市',
                trustedContactState: '可信联系人州',
                trustedContactZipCode: '可信联系人邮编'
            },
            stonexAffiliation: {
                withStonexAffiliation: '添加 StoneX 关联？',
                stonexAffilFirstName: 'StoneX 关联名字',
                stonexAffilLastName: 'StoneX 关联姓氏',
                stonexAffilPosition: 'StoneX 关联职位',
                stonexAffilEntity: 'StoneX 关联实体',
                sameAsEmploymentInformation: '与就业信息相同',

                stonexAffilOtherUsBrokers: '添加 StoneX 关联其他美国经纪人？',
                stonexAffilAccreditedMember: 'StoneX 关联认定会员',
                stonexAffilWithRegisteredAdvisor: 'StoneX 关联是否注册顾问',
                stonexAffilOfficerOwnsTenPercent: 'StoneX 关联高管持有10%股份',
                stonexAffilEmployeeOfStonex: 'StoneX 关联 StoneX 员工',
                stonexAffilNaturalPersonOrEntityWithoutTaxId: 'StoneX 关联自然人或无税号实体'
            },
            industryAffiliation: {
                withIndustryAffiliation: '添加行业关联？',
                industryAffilFirmName: '行业关联公司名',
                withIndustryAffiliationRule144: '添加行业关联规则144？',
                industryAffilRule144CompanyName: '行业关联规则144公司名',
                industryAffilRule144CompanySymbolOrCusip: '行业关联规则144公司股票代码或CUSIP'
            },
            whichAccount: {
                continueStockTradingApplication: '继续股票交易申请',
                stockTradingAccount: '股票交易账户',
                futuresTradingAccount: '期货交易账户'
            }
        },
        accountOpeningSelection: {
            pageTitle: '账户',
            pageSubtitle: '选择一个账户以管理或创建一个新账户',
            margin: '保证金',
            options: '期权',
            accountNumber: '账户号码',
            accountType: '类型',
            activeApplication: '活跃申请',
            selectedAccountOptions: {
                addMargin: '添加保证金',
                marginSubLabel: '保证金使您可以使用各种期权策略等功能。',
                addOptions: '添加期权',
                optionsSubLabel: '期权交易允许您交易合约，如果您认为资产的价格会上涨或下跌。',
                continueApplication: '继续申请',
                continueApplicationSubLabel: '从上次离开的地方继续您的申请。'
            },
            selectionBadge: {
                inProgress: '进行中',
                created: '已创建',
                pending: '待处理'
            }
        }
    },
    futuresGlance: {
        labels: {
            cash: '现金',
            netLiq: '净值',
            openPnl: '持仓盈亏',
            realizedPnl: '实现盈亏',
            buyingPower: '购买力'
        },
        info: {
            netLiq: '以当前市场价格清算账户的价值。',
            openPnl: '所有持仓的当前盈亏。',
            realizedPnl: '这个值是当日最高实现的盈亏。',
            lastUpdated: '对于您的期货账户，这是账户的净资产值。',
            buyingPower: '您的购买力是账户中可用于购买期货和期货期权的总现金金额，加上所有可用保证金。'
        }
    },
    fplBanner: {
        bannerText: (isSummary?: boolean, theme?: AppliedAppTheme): ShadedTextList => [
            {
                text: isSummary ? '您的一个或多个账户似乎有资格申请 ' : '看来您的帐户有资格申请 ',
                shade: theme === 'dark' ? 'light' : 'dark'
            },
            {
                text: '全额付款贷款',
                shade: 'dark',
                color: theme === 'dark' ? null : '#2cb333'
            },
            {
                text: '!',
                shade: theme === 'dark' ? 'light' : 'dark',
                lineBreak: true
            },
            {
                text: '单击此处了解更多',
                shade: 'dark',
                color: '#2cb333'
            },
            {
                text: '有关将闲置资产转化为收入的信息',
                shade: theme === 'dark' ? 'light' : 'dark'
            }
        ],
        bannerTextMobileSummary: [
            { shade: 'light', text: 'This account is eligible to apply for' },
            { shade: 'dark', text: ' \nFully Paid Lending! ' },
            { shade: 'light', text: 'Tap here to start turning idle assets into income.' }
        ],
        bannerTextMobileAccount: [
            { shade: 'light', text: 'This account is eligible to apply for' },
            { shade: 'dark', text: ' \nFully Paid Lending! ' },
            { shade: 'light', text: 'Tap here to start turning idle assets into income.' }
        ],
        applyNow: '现在申请',
        learnMore: '了解更多'
    },
    fplModal: {
        headerText: [
            {
                text: '准备好将闲置资产转化为收入了吗?',
                shade: 'dark',
                lineBreak: true
            },
            {
                text: '解锁全额支付的证券借贷',
                shade: 'dark'
            }
        ],
        eligibilityText: '您的保证金账户中至少需要有 50,000 美元的净值才有资格',
        aboutText: '证券借贷是将证券从一方向另一方出借的行为。这种做法通常允许借款人在市场上出售并持有空头头寸。',
        revenueStreamText: [
            {
                text: '新的收入来源：',
                shade: 'dark',
                lineBreak: true
            },
            {
                text: '通过全额支付证券借贷，您只需借出您已拥有的证券即可增加您的投资组合回报。',
                shade: 'light'
            }
        ],
        maintainOwnershipText: [
            {
                text: '维持所有权：',
                shade: 'dark',
                lineBreak: true
            },
            {
                text: '您仍将保留对投资的完全所有权，这意味着您的收入将继续根据市场状况而变化，借款人将在抵押贷款期间支付所有股息和利息。尽管它可能作为现金替代而不是股息，这可能会产生税收影响。',
                shade: 'light'
            }
        ],
        loansAreSecureText: [
            {
                text: '贷款安全：',
                shade: 'dark',
                lineBreak: true
            },
            {
                text: '当证券出借时，借款人向您提供所借证券全部价值的抵押品。',
                shade: 'light'
            }
        ],
        howItWorksTitle: '全额支付证券借贷如何运作',
        howItWorksText: 'StoneX 管理贷款流程，从连接贷款人和借款人到安排抵押品托管，再到贷款期限后结算费用。',
        bulletPointsText: [
            '贷方在正利率环境下获得现金投资回报',
            '贷款人通过现金或证券抵押的贷款赚取一些收入',
            '根据贷款人的要求召回证券或贷款人出售证券',
            '借款人在抵押贷款期间偿还所有支付的股息/利息',
            '客户当天即可获得抵押',
            '抵押品的维持与借出资产的价值相关',
            '借款人持有借出证券附带的任何投票权'
        ],
        invitedToFpl: `诚邀您参加全额付款贷款`,
        fullyPaidLending: '全额支付贷款',
        maximizeProfits: `通过全额支付证券借贷来借出您的股票，从而最大化潜在利润 - 将闲置资产转化为收入。`,
        addFunds: '增加资金'
    },
    fplProfile: {
        applyForFPL: '申请全额缴付证券借贷',
        fplProfileLink: '全额支付贷款',
        fplProfileBannerText: (theme?: AppliedAppTheme) => [
            {
                text: '通过借出您的股票来最大化潜在利润',
                shade: theme === 'dark' ? 'light' : 'dark'
            },
            {
                text: '全额支付证券借贷',
                shade: theme === 'dark' ? 'dark' : null,
                color: theme === 'dark' ? null : '#2cb333'
            },
            {
                text: '!',
                shade: theme === 'dark' ? 'light' : 'dark'
            }
        ],
        learnMore: 'Learn More',
        pending: {
            header: '全额偿还贷款 - 待处理',
            body: '申请已提交'
        },
        enabled: {
            header: '全额偿还贷款 - 已启用',
            body: `您已为此账户启用全额偿还证券借贷。`
        }
    },
    fplOnboarding: {
        welcome: {
            title: '申请全额支付证券借贷',
            text: '在您的投资组合中赚取股票利息。随时保留所有权/出售股票。在股票出借期间，您将无法投票。您的保证金账户中至少需要$50,000的权益才能符合资格。'
        },
        fplInfo: {
            title: '选择符合条件的账户',
            text: '所选保证金账户必须至少有$50,000的权益'
        },
        agreements: {
            title: '适用于您的账户的重要协议',
            requiredClick: '您需要仔细阅读此页面上的所有内容，以及下面所需文件的每一页，通过点击文件标题以访问其内容。在完成本部分之前，请仔细阅读所有文件。',
            subtitle: (): ShadedTextList => [
                { text: '您需要仔细阅读此页面上的所有内容，以及通过点击' },
                { text: '下面的文件标题来访问其内容的所有所需文件。在完成本部分之前，请仔细阅读所有文件。' }
            ],
            clientCertTitle: '客户认证',
            loanAgreementText: (): ShadedTextList => [
                { text: '勾选此框即表示出借方同意本文中的条款和条件，包括以下内容：', lineBreak: 'double' },
                { text: '1. 您保证金账户中的证券和您尚未全额支付的任何证券，连同所有相关的所有权权益，可能会被借给Stonex Financial或出借给其他人；和' },
                { text: '2. 本协议在第29段，即本协议第11页开始的预先争议仲裁条款。', lineBreak: 'double' },
                { text: '贷款费用的确定', shade: 'dark', lineBreak: true },
                { text: '贷款费用将是借款人因借出方的证券减少而获得和收取的新收益的百分比。' },
                { text: '贷款费用每日计息，包括证券被转移至借款人的日期，但不包括证券被归还给出借方的日期。贷款费用将自动' },
                { text: '每月划入出借方的账户。' }
            ],
            loanAgreementPDFUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/FPL/SNEX_Fully%20Paid%20Master%20Securities%20Loan%20Agreement%2020231219X.pdf',
            loanAgreementPDFTitle: '全额支付主要证券贷款协议',
            fplAccountControlAgreementText: (): ShadedTextList => [
                { text: '勾选此框即表示出借方同意本文中的条款和条件，包括以下内容：', lineBreak: 'double' },
                { text: '独家控制通知', shade: 'dark', lineBreak: true },
                { text: '有担保方特此（i）通知保管人，担保方正在行使对押品的独家控制权（在ACA中定义）；（ii）指示保管人停止' },
                { text: '执行客户发起的有关押品的指示；以及（iii）向保管人声明并保证本独家控制通知是合法的并且得到了适用协议' },
                { text: '（客户与有担保方之间的协议）的授权。保管人无义务、责任或权限确定本独家控制通知（或其条款）是否妥善，即使客户' },
                { text: '提出异议或指示保管人不要执行此独家控制通知。' }
            ],
            fplAccountControlAgreementPDFUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/FPL/SNEX_FPL%20Account%20Control%20Agreement%2020231227X.pdf',
            fplAccountControlAgreementPDFTitle: '全额支付借贷账户控制协议',
            axosAcknowledgementText:
                '几天后，您将收到 Axos 发来的一封电子邮件，要求您通过 DocuSign 完成申请。选中此框即表示您确认，如果未完成 Axos 申请（该申请将发送到您存档的电子邮件地址），则无法处理您的帐户审批。'
        },
        disclosures: {
            title: '适用于您的账户的重要披露',
            requiredClick: '您需要仔细阅读此页面上的所有内容，以及点击文档标题以访问其内容的所需披露文件。在完成披露部分之前，请仔细阅读所有文件。',
            subtitle: '在提交之前，您需要仔细阅读此页面上的所有内容',
            scrollTitle: '重要特点和风险',
            scrollSubtitle: 'StoneX全额支付证券借贷计划',
            importantCharAndRiskAgreementPDFUrl:
                'https://snex1storage.blob.core.windows.net/$web/docs/FPL/SNEX_FPL%20Important%20Characteristics%20and%20Risks%20202401117X.pdf',
            importantCharAndRiskAgreement: '全额支付借贷重要特点和风险协议',
            importantCharAndRiskAgreementText: (): ShadedTextList => [
                { text: '勾选此框即表示出借方确认收到StoneX Financial Inc.的披露文件，题为' },
                { text: '"全额支付证券借贷计划，重要特点和风险"' }
            ]
        },
        submitting: {
            title: '提交您的批准申请',
            completedTitle: '申请已提交',
            loadingMessage: (): ShadedTextList => [{ text: '请等待我们提交您加入全额支付借贷的请求。' }],
            successMessage: (): ShadedTextList => [
                { text: '请等待我们提交您加入全额支付借贷的请求。', shade: 'dark', lineBreak: true },
                { text: '预计批准等待时间为2个工作日', shade: 'light' }
            ],
            axosMessage: '请留意 Axos 发送的电子邮件',
            errorMessage: '提交请求时出现了问题。'
        }
    },
    loggedOutScreen: {
        title: '您已注销',
        body: '您可以关闭此浏览器窗口，或者如果您错误地注销，您可以单击下面的按钮重新登录。'
    },
    mobileFplOnboarding: {
        apply: {
            title: '全额支付证券借贷申请',
            account: '账户',
            selectAccount: '选择账户',
            incomeGenerationTitle: '收入生成',
            incomeGenerationDesc: '从现有的股票投资组合中生成额外收入，无需出售您的资产。',
            ownershipRetentionTitle: '所有权保留',
            ownershipRetentionDesc: '保留股票的所有权和潜在的资本收益，同时赚取借款测试。',
            riskAdjustedReturnsTitle: '风险调整回报',
            riskAdjustedReturnsDesc: '在风险最小且无需额外投资的情况下增加投资组合回报。',
            votingTitle: '投票',
            votingDesc: '在股票借出期间，您将无法投票。',
            eligibilityTitle: '资格',
            eligibilityDesc: '您至少需要50,000美元的净资产才有资格'
        },
        disclosures: {
            title: '协议',
            masterSecuritiesLoan: '全额支付的主要证券贷款协议',
            lendingAccountControl: '全额支付的贷款账户控制协议',
            lendingImportantCharacteristicsAndRisks: '全额支付的贷款重要特性和风险协议',
            reviewAccept: '审查并接受我们的披露和协议',
            reviewDisclosures: '审查披露',
            disclosuresAccepted: '已接受披露！',
            acceptContinue: '接受并继续',
            pressFinishToCreate: '按完成以创建您的帐户',
            finish: '完成',
            missingData: '看起来我们仍然缺少一些数据',
            finishLater: '稍后完成'
        },
        flowStage: {
            submittedTitle: '已提交您的审批申请',
            submittedSubtitle: '预计批准需要2个工作日。请密切关注来自Axos的电子邮件！',
            loadingTitle: '请等待我们准备您的FPL账户',
            failedTitle: '看起来我们无法提交您的账户审批'
        },
        acknowledgements: {
            title: '承认',
            message:
                '您将在几天内收到Axos的电子邮件，以通过DocuSign完成申请。通过下面的接受，您承认如果不完成将发送到您的注册电子邮件地址的Axos申请，您的账户批准无法处理。',
            accept: '接受确认'
        }
    },
    mobileNavBar: {
        home: '首页',
        cash: '现金',
        trade: '贸易',
        orders: '订单'
    },
    oneProNetwork: {
        discoverMore: '发现更多',
        insights: '见解',
        latestPosts: '最新帖子',
        enableOneProNetwork: '启用 One Pro 网络'
    }
};

export const Snex1MandarinTsxStub = null;

// @ts-strict-ignore
import { getWeekOfMonth, isSameYear } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import ptBR from 'date-fns/locale/pt-BR';
import { ShadedTextUtils } from 'phoenix/assets/lang/ShadedTextUtils';
import { AppliedAppTheme } from 'phoenix/constants/AppliedAppTheme';
import { PremiumProducts } from 'phoenix/constants/PremiumProducts';
import { GetPriceFormatInfo } from 'phoenix/hooks/UsePriceFormatInfo';
import { AssetClass } from 'phoenix/models/AssetClasses/AssetClass';
import { GetAssetClassForSecurity } from 'phoenix/models/AssetClasses/useAssetClass';
import { TransferRequest } from 'phoenix/models/TransferRequest';
import { CryptoSymbol } from 'phoenix/redux/models/Crypto/CryptoSymbol';
import { FundingLimits } from 'phoenix/redux/models/Funding/FundingLimits';
import { FundingSource } from 'phoenix/redux/models/Funding/FundingSource';
import { SecurityMetadata } from 'phoenix/redux/models/Securities/SecurityMetadata';
import { Environment } from '../../constants';
import { IndustryGroups } from '../../constants/IndustryGroups';
import { SecurityTypes } from '../../constants/SecurityTypes';
import { OrderTypes } from '../../constants/Trade';
import { Account } from '../../redux/models/Accounts/Account';
import { SnexDocument } from '../../redux/models/Documents/SnexDocument';
import { FundingManagementLimits } from '../../redux/models/Funding/FundingManagementLimits';
import { FuturesSymbol } from '../../redux/models/Futures/FuturesSymbol';
import { OptionSymbol } from '../../redux/models/Options/OptionSymbol';
import { Order } from '../../redux/models/Orders/Order';
import { OptionsPutCallAbbreviation, OrderType, TradeableSecurityType, TradeRequest } from '../../redux/models/Trading/TradeRequest';
import { Transaction } from '../../redux/models/Transactions/Transaction';
import { SpokenJoin } from '../../util/ArrayMutations';
import { SafeFormat, SafeFormatLocale, SafeFormatToNow } from '../../util/DateFormatting';
import { DetermineOrderType } from '../../util/DetermineOrderType';
import { FormatNumber } from '../../util/FormatNumber';
import { DecodeSecurityMasterType } from '../../util/IsMutualFund';
import { QualifiedId } from '../../util/QualifiedId';
import { disclosures, proDisclosures } from './disclosures';
import { ActionSummaryProps, getActionSummary } from './helpers';
import { Dark, Light, ShadedTextList, Snex1LanguagePack } from './Snex1LanguagePack';

const DelayedParagraph = [{ text: 'Update frequency: 15-min delay' }];
const nums = ['zero', 'um', 'dois', 'três', 'quatro', 'cinco', 'seis', 'sete', 'oito', 'nove', 'dez'];

export const Snex1Portuguese: Snex1LanguagePack = {
    funding: {
        delete: {
            title: 'Deletar conta',
            message: 'Tem certeza de que deseja remover esta conta?',
            submitButton: 'Remover conta',
            cancelButton: 'Não, mantenha a conta'
        }
    },
    errorBoundary: {
        title: 'Opa!',
        sorryMessage: 'Desculpe! Parece que algo deu errado.',
        oopsSomethingWentWrong: 'Ops, algo deu errado.',
        tryAgain: 'Tente novamente',
        contactSupport: 'Entre em contato com o suporte',
        onboardingErrorOccurred: 'Um erro ocorreu',
        onboardingTryAgainOrContactSupport: 'Tente novamente ou contacte o suporte se o problema persistir'
    },
    assetClass: {
        futures: 'Futuros',
        cryptocurrency: 'Criptomoeda',
        crypto: 'Criptografia',
        futuresOptions: 'Opções de Futuros',
        future: 'Futuro',
        equitiesOption: 'Opção sobre Ações',
        otcOption: 'Opção OTC',
        etf: 'ETF',
        mutualFund: 'Fundo de Investimento',
        oilGasReit: 'REIT de Petróleo e Gás',
        escrowReceiptsLetterGuaranty: 'Recibo de Depósito / Carta de Garantia',
        treasury: 'Tesouro',
        municipleBond: 'Obrigação Municipal',
        corporateUit: 'UIT Corporativa',
        municipalUit: 'UIT Municipal',
        unit: 'Unidade',
        preciousMetals: 'Metais Preciosos',
        commonStock: 'Ação Comum',
        annuity: 'Anuidade',
        miscellaneous: 'Diversos',
        offShoreMutualFund: 'Fundo Mútuo Offshore',
        brokerage: 'Ações'
    },
    welcome: {
        skipTour: 'Pular passeio',
        showMeAround: 'Mostre-me o local!',
        pickLang: 'Escolha o idioma com o qual você se sente mais confortável',
        successText: 'Sua conta foi criada com sucesso!',
        loadingText: 'Verificando o status da sua conta...',
        errorText: `Ocorreu um problema ao localizar sua conta. Pode levar alguns minutos para que sua conta seja inicializada. Se não estiver disponível após 10 minutos, entre em contato com nosso suporte em`,
        noAccountsText:
            'Parece que sua conta ainda não está pronta. Pode levar alguns minutos para que sua conta seja inicializada. Se não estiver disponível após 10 minutos, entre em contato com nosso suporte em'
    },
    webTos: {
        title: 'Aceita termos e Condições',
        url: 'https://snex1storage.blob.core.windows.net/$web/docs/StoneX_Client_TC.pdf',
        linkText: 'Veja os Termos e Condições',
        agreeButtonLabel: 'Aceito'
    },
    general: {
        at: 'em',
        around: 'ao redor de',
        above: 'acima de',
        below: 'abaixo de',
        to: 'a',
        ai: 'IA',
        languageNameEnglish: 'Portuguese',
        languageNameLocal: 'Português',
        dateFnsLocale: ptBR,
        localeShort: 'pt',
        localeLong: 'pt-BR',
        submit: 'Enviar',
        continue: 'Continuar',
        acceptRateAndSubmit: 'Aceitar avaliação e enviar',
        acceptTermsAndSubmit: 'Aceite os termos e envie',
        submitting: 'Enviando...',
        loading: 'Carregando',
        cancel: 'Cancelar',
        news: (showMI?: boolean) => `Notícias${showMI ? ' e Inteligência de Mercado' : ''}`,
        open: 'Abertas',
        done: 'Feito',
        none: 'Nenhum',
        dismiss: 'Liberar',
        confirmAndContinue: 'Confirmar e Continuar',
        contactSupport: 'Entre em contato com o suporte',
        writtenInteger0Thru10: (n) => (n >= 0 && n <= 10 ? nums[n] : FormatNumber.toCommas(n)),
        unavailable: 'Indisponível',
        search: 'Procurar',
        trade: 'Troca',
        dateUnknown: 'Data desconhecida',
        unknown: 'Ignorado',
        browseDataUnavailable: 'Desculpe, os dados de navegação estão indisponíveis no momento.',
        unableToAuthenticate: 'Desculpe, não foi possível autenticar você',
        yesLeave: 'Sim, sair',
        noStayHere: 'Não, fique aqui',
        returningToStonex: 'Retornando ao StoneX One',
        aboutToReturnToStonex: 'Você está prestes a retornar ao StoneX One. Tem certeza?',
        months: ['Janeiro', 'fevereiro', 'Março', 'abril', 'Posso', 'junho', 'julho', 'agosto', 'setembro', 'Outubro', 'novembro', 'dezembro'],
        monthsShort: ['jan', 'fev', 'mar', 'abr', 'pos', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        quantity: 'Quantidade',
        strikes: 'Greves',
        shares: (n = 0) => (Math.abs(n) === 1 ? 'a ação' : 'Ações'),
        fundShares: (n = 0) => (Math.abs(n) === 1 ? 'a ação' : 'Ações'),
        contracts: (n = 0) => (Math.abs(n) === 1 ? 'Contrato' : 'Contratos'),
        bonds: (n = 0) => (Math.abs(n) === 1 ? 'hipotecar' : 'Títulos'),
        coins: (n = 0) => (Math.abs(n) === 1 ? 'Moeda' : 'Moedas'),
        unit: (symbol: string, quantity = 0, typeHint: string = null) => {
            if (FuturesSymbol.IsFuturesSymbol(symbol)) return Snex1Portuguese.general.contracts(quantity);
            if (OptionSymbol.IsOptionSymbol(symbol) || DecodeSecurityMasterType(typeHint) === 'option') return Snex1Portuguese.general.contracts(quantity);
            if (CryptoSymbol.IsCryptoSymbol(symbol)) return Snex1Portuguese.general.coins(quantity);
            const torchType = typeHint;
            if (torchType === 'Fixed Income' || torchType === 'Debt') return Snex1Portuguese.general.bonds(quantity);
            return Snex1Portuguese.general.shares(quantity);
        },
        symbolName: (
            symbol: string,
            optionVariant: 'extra-short' | 'short' | 'long' | 'no-exp' | 'symbol-putcall' = 'short',
            quantity = 1,
            secMeta: SecurityMetadata = null
        ) => {
            const optSym = new OptionSymbol(symbol);

            if (optSym.isOption) {
                const { underlyingSymbol, expDate } = optSym;
                const strike = secMeta?.strikePrice || optSym.strike;
                const term = getWeekOfMonth(expDate, { weekStartsOn: 6 }) !== 3 ? 'semanal' : 'mensal';
                const putness = Snex1Portuguese.general.options.putCallWord(optSym.putCall);
                const strikeFormat = secMeta ? GetPriceFormatInfo(symbol, secMeta.tickSize) : undefined;
                const strikeStr = FormatNumber.toMoneyOpt2(strike, strikeFormat?.moneyFormatOptions);
                const { exp } = Snex1Portuguese.general.options;
                const locale = Snex1Portuguese.general.dateFnsLocale;

                switch (optionVariant) {
                    case 'long':
                        return `${underlyingSymbol} ${SafeFormatLocale({ date: expDate, formatString: 'dd MMMM yyyy', locale })} ${term} ${strikeStr} ${putness}${
                            quantity === 1 ? '' : 's'
                        }`;
                    case 'no-exp':
                        return `${underlyingSymbol} ${strikeStr} ${putness}${quantity === 1 ? '' : 's'}`;
                    case 'symbol-putcall':
                        return `${optSym.underlyingSymbol} ${putness}`;
                    case 'extra-short': {
                        const dateFmt = isSameYear(expDate, new Date()) ? 'MM/dd' : 'MM/dd/yy';
                        return `${optSym.underlyingSymbol} ${strikeStr}${optSym.putCall} ${SafeFormatLocale({ date: expDate, formatString: dateFmt, locale })}`;
                    }
                    case 'short':
                    default:
                        return `${underlyingSymbol} ${strikeStr} ${putness}, ${exp} ${SafeFormatLocale({ date: expDate, formatString: 'MM/dd/yyyy', locale })}`;
                }
            }

            return QualifiedId.RemovePrefix(symbol);
        },
        spokenJoin: (items: string[]) => {
            if (!items?.length || !items[0].length) return '';
            const lastWord = items[items.length - 1][0];
            const and = /^(hi|i)/i.test(lastWord) ? 'e' : 'e';
            return SpokenJoin(items, and);
        },
        isShort: 'Esta é uma posição curta',
        buyingPower: 'Poder de compra',
        availableBalance: 'Saldo disponível',
        pendingApproval: 'Aprovação pendente',
        pending: 'Pendente',
        maintenanceCall: 'Chamada de manutenção',
        maintenanceCallForAccounts: 'Chamada de manutenção para uma ou mais contas',
        maintenanceCallTooltip:
            'Uma chamada de margem ocorre quando o valor dos títulos em uma conta de corretagem cai abaixo de um determinado nível, conhecido como margem de manutenção, exigindo que o titular da conta deposite dinheiro adicional ou títulos para atender aos requisitos de margem.',
        fedCallTooltip:
            'Quando um investidor compra ações e não tem patrimônio suficiente na conta para atender ao requisito de patrimônio de 50%, uma chamada de margem alimentada é acionada.',
        dayTradingFlag: 'Esta conta foi marcada para day trading.',
        fplEligible: 'Esta conta é elegível para inscrição em empréstimos totalmente pagos',
        fplEnabled: 'Esta conta está inscrita em Empréstimo Totalmente Pago',
        ninetyDayRestriction: {
            description: 'Um cliente pode ser colocado em restrição de 90 dias pelas razões abaixo:',
            forAccounts: 'Restrição de 90 dias para uma ou mais contas',
            list: [
                'Não pagamento de uma obrigação REG T',
                'Qualquer atividade que não seja depósito em dinheiro durante a extensão (liquidação, cancelamento, etc)',
                'Cavalgada grátis',
                'Três violações de boa fé em um período de 12 meses',
                'Não pagamento de uma chamada de day trade'
            ],
            secondParagraph:
                'Enquanto na restrição de 90 dias, um cliente está restrito a abrir transações apenas com fundos liquidados. Um cliente pode ser impedido de abrir transações quando não houver fundos liquidados insuficientes. Negociações que excedam fundos liquidados também podem estar sujeitas a cancelamento.',
            title: 'Restrição de 90 dias',
            withDate: (date) => `Restrição de 90 dias ${date ? `expira ${SafeFormat(date, 'MM/dd/yyyy')}` : ''}`
        },
        options: {
            call: 'Chamar',
            exp: 'Exp',
            put: 'Por',
            putCallWord: (putCall: OptionsPutCallAbbreviation) => {
                switch (putCall) {
                    case 'C' as OptionsPutCallAbbreviation:
                        return Snex1Portuguese.general.options.call;
                    case 'P' as OptionsPutCallAbbreviation:
                        return Snex1Portuguese.general.options.put;
                    default:
                        return Snex1Portuguese.general.unknown;
                }
            },
            adjustedOptionsSubtext: (deliverableCount: number) => {
                return `${deliverableCount} ações por Contrato`;
            }
        },
        productTypes: {
            'offshore-mutual-fund': { singular: 'fundo mútuo', plural: 'fundos mútuos' },
            'mutual-fund': { singular: 'fundo mútuo', plural: 'fundos mútuos' },
            adr: { singular: 'ADR', plural: 'ADRs' },
            crypto: { singular: 'criptomoeda', plural: 'criptomoedas' },
            equity: { singular: 'equidade', plural: 'ações' },
            etf: { singular: 'ETF', plural: 'ETFs' },
            future: { singular: 'futuro', plural: 'futuros' },
            option: { singular: 'opção', plural: 'opções', upperSingular: 'Opção' },
            unknown: { singular: 'segurança', plural: 'seguros' }
        },
        assetClassLabels: {
            equities: 'Ações',
            futures: 'Futuros',
            cryptos: 'Criptomoedas'
        },
        sorrySomethingWentWrong: 'Desculpe, algo deu errado',
        notApplicable: 'N/A',
        learnMore: 'Saber mais',
        expiresSoon: 'Expira em breve',
        expiresToday: 'Expira hoje',
        expiresTomorrow: 'Expira amanhã',
        daily: 'Diário',
        oneTime: 'Um tempo',
        repeatDaily: 'Repita diariamente',
        numberBought: '# Comprado',
        numberSold: '# Vendido',
        avgBuyPrice: 'Preço médio de compra',
        avgSellPrice: 'Preço médio de venda',
        topDayActivity: 'Atividade principal do dia',
        ascending: 'Ascendente',
        descending: 'descendente'
    },
    webRegistration: {
        verifySMSCodeTitle: 'Verifique com seu telefone',
        enterCodeAndLogin: 'Digite seu código abaixo e vamos começar!',
        verifySMSCodeSubtitle: (phone: string) => `Envie um código via SMS para ${phone}`,
        messageChargesAlert: 'Taxas de mensagens da operadora podem ser aplicadas',
        sendingSMSCode: 'Validando código',
        invalidSMSCode: 'Código de verificação SMS inválido',
        resentSMSCode: 'Reenviar código de verificação por SMS',
        getSMSCode: 'Receba um código por SMS',
        submitSMSCode: 'Verifique o código',
        alternateMFA: 'Escolha um método de MFA diferente',
        welcome: 'Bem-vindo!',
        getAnAccount: 'Vamos obter uma conta StoneX One',
        getUserInfo: 'Vamos pegar seu nome e e-mail',
        nextButton: 'Próximo',
        firstName: 'Primeiro nome',
        lastName: 'Sobrenome',
        phoneNumber: 'Número de telefone',
        verifyEmail: 'Verificar e-mail',
        emailVerified: 'Email verificado!',
        email: 'E-mail',
        confirmEmail: 'Confirmar e-mail',
        userExists: (email: string) => `O usuário com e-mail ${email || ''} já existe.`,
        personalInfo: 'Informação pessoal',
        usCitizenQuestion: 'Você é um cidadão dos EUA?',
        yes: 'Sim',
        no: 'Não',
        usCitizenAnswer: 'Eu sou um cidadão dos EUA',
        nonUSCitizenAnswer: 'Eu não sou cidadão dos EUA',
        usCitizenAlert: 'Atualmente, você deve ser um cidadão dos EUA para abrir uma conta',
        createPassword: 'Vamos criar uma senha',
        createUser: 'Create User',
        passwordRequirement: 'A senha precisa ter 14 caracteres ou mais',
        password: 'Senha',
        confirmPassword: 'Confirme sua senha',
        verifyYourEmail: 'Vamos verificar seu e-mail',
        sendingVerificationCode: 'Estamos enviando um código de verificação para o e-mail fornecido anteriormente.',
        enterInBoxBelow: 'Por favor, insira-o na caixa abaixo.',
        invalidVerificationCode: 'Código de verificação inválido',
        resentVerificationCode: 'Reenviar o Código de Verificação',
        verificationCode: 'Código de Verificação',
        submit: 'Enviar',
        resendCode: 'Reenviar código',
        completeTitle: 'Registro concluído',
        completeSubtitle: 'Clique no botão abaixo e faça login no StoneX One',
        login: 'Conecte-se',
        alreadyExists: 'Usuário existente encontrado',
        wouldYouLikeToLogin: 'Gostaria de fazer login no StoneX One?',
        steps: {
            welcome: 'Bem-vindo',
            userInfo: 'Informação de usuário',
            personalInfo: 'Informação pessoal',
            setupPassword: 'Senha de configuração',
            enrollMFA: 'Inscrever-se no MFA',
            verifyCreate: 'Verificar e-mail',
            login: 'Conecte-se'
        },
        errors: {
            required: 'Field is required',
            userInfo: {
                invalidPhone: 'Número de telefone inválido.',
                invalidEmail: 'Invalid email',
                emailDoesNotMatch: 'Email and confirmEmail do not match',
                emailExists: 'Email already exists'
            },
            password: {
                passwordsDoNotMatch: 'Password entries do not match',
                invalidPassword: 'Passwords must be at least 14 characters'
            }
        }
    },
    appReview: {
        yes: 'Sim',
        no: 'Não',
        enjoyingStoneX: 'Está gostando do StoneX One?',
        rateApp: 'Gostaria de avaliar o aplicativo?',
        later: 'Mais tarde',
        noThanks: 'Não, obrigado',
        feedback: 'Obrigado pelo seu feedback. Gostaria de falar com o suporte por chat?'
    },
    connectionStatus: {
        title: 'Sem conexão',
        message: 'Parece que você não está conectado à Internet.',
        retryConnection: 'Tentar conexão novamente'
    },
    premiumModal: {
        addTheseTools: 'Adicione estas ferramentas ao seu arsenal:',
        ready: 'Pronto para ganhar essa vantagem extra?',
        unlock: 'Desbloqueie a análise como nenhuma outra.',
        upgradeToday: 'Atualize para Premium hoje',
        deepAnalysis: {
            title: 'Acesso à análise profunda',
            content: 'Navegue pelos mercados com dados de mercado em tempo real e insights exclusivos da empresa combinados em uma visão abrangente e singular.'
        },
        multiCharts: {
            title: 'Vários gráficos, uma tela',
            content: 'Realize análises técnicas em vários gráficos em uma única visualização. Analise e compare padrões para títulos variados em uma única visualização.'
        },
        streamingNews: {
            title: 'Transmissão de notícias',
            content: 'Mova-se quando o mercado se mover com notícias de streaming. Novos artigos aparecerão no topo de seus fluxos de notícias.'
        },
        alerts: {
            title: 'Alertas de preço',
            content: 'Mantenha o controle dos títulos mais importantes para você com alertas personalizados. Nunca perca uma oportunidade.',
            isAbove: 'está acima',
            isAboveOrEqual: 'está acima ou igual a',
            isBelow: 'está abaixo',
            isBelowOrEqual: 'está abaixo ou igual a'
        },
        miPremium: {
            title: 'Inteligência de mercado',
            content:
                'Notícias, dados e comentários sobre todos os nossos mercados com perspectivas diretas de corretores e analistas. Inclui insights acionáveis ​​para mantê-lo um passo à frente!'
        }
    },

    marketTimeBadge: {
        open: 'O mercado de ações dos EUA está aberto',
        premarket: 'Horário pré-mercado para as ações dos EUA',
        holiday: 'Feriado de Negociação',
        postmarket: 'Horário pós-mercado para as ações dos EUA',
        closed: 'O mercado de ações dos EUA está encerrado',
        loading: 'Carregando'
    },

    blankViews: {
        holdingsTable: {
            main: 'Nenhuma posição encontrada',
            secondary: 'Tente alterar seus filtros',
            notFundedMain: 'Bem-vindo à tela de posições',
            notFundedSecondary: 'Depois de ter sua conta financiada, você encontrará suas posições e saldos aqui',
            notFundedAction: 'Conta do fundo'
        },
        documents: {
            main: (documentType: string) => `Você atualmente não tem ${documentType}`,
            secondary: (documentType: string) => `${documentType} futuros serão encontrados aqui`
        },
        transactions: {
            main: 'Parece que você não tem transações',
            secondary: 'Experimente alguns outros filtros ou volte mais tarde'
        },
        projectedIncome: {
            main: 'Nenhuma receita projetada encontrada para esta conta',
            secondary: 'Tente outra conta ou volte mais tarde'
        },
        tradeHistory: {
            main: 'Parece que você não tem nenhuma negociação registrada que corresponda aos seus critérios de pesquisa',
            secondary: 'Procure um título e faça uma negociação para vê-lo aqui'
        },
        messages: {
            main: 'Nenhuma mensagem encontrada',
            secondary: 'Você encontrará todas as mensagens que recebeu aqui. Volte mais tarde para novidades!'
        }
    },
    premiumScreen: {
        createAlert: (symbol: string) => `Criar um alerta para ${symbol}`,
        deepAnalysisAlt: 'Visualização da tela de análise profunda',
        returnToSummary: 'Voltar ao Resumo',
        subHeader: 'Você acabou de melhorar seu jogo. Use os botões abaixo para conferir seus novos superpoderes.',
        viewCharts: (symbol: string, symbol2: string) => `Comparar ${symbol} y ${symbol2}`,
        viewChartsAlt: 'Visualização de vários gráficos',
        viewDeepAnalysis: (symbol: string) => `Veja a análise profunda da ${symbol}`,
        viewMi: (symbol: string) => `Veja o conteúdo de Inteligência de Mercado para ${symbol} agora!`,
        viewMiAlt: 'Imagens de commodities futuras',
        welcomeHeader: (name: string) => `Bem-vindo ao ${name}!`,
        checkout: {
            title: 'Confira',
            changePlan: 'Alterar Plano',
            nextBilled: (date: Date) => `Você será cobrado em ${format(date, 'dd MMM, yyyy')}`,
            cancelAnytime: 'Cancele quando quiser',
            offerTerms: 'Os termos da oferta se aplicam',
            details: {
                title: 'Importante',
                body: (productName: string) => `Os pagamentos de ${productName} são feitos automaticamente por meio de sua conta StoneX.`,
                multipleAccounts:
                    ' Como você tem várias contas, precisará nos informar qual conta deseja usar. Faça isso abaixo (você sempre pode alterar isso mais tarde):'
            },
            selectAccount: (hasMultipleAccounts?: boolean) => (hasMultipleAccounts ? 'Selecione uma conta de corretagem para debitar' : 'Conta da corretora para débito'),
            buttonText: 'Iniciar assinatura'
        },
        selectProduct: {
            goPremium: 'Torne-se Premium',
            or: 'Ou',
            pickYourPackage: 'Escolha seu pacote',
            perMonth: 'por mês',
            comingSoon: 'Em breve!',
            comingSoonToStonexOne: 'Em breve no StoneX One:',
            select: 'Selecionar',
            bestValue: 'Melhor valor!',
            jumbotron: {
                ourBestDeal: 'Nosso melhor negócio!',
                title: [
                    // [
                    // { text: 'StoneX One Premium', weight: 'bold', size: 'title' },
                    // { text: ' and ', size: 'title' },
                    // { text: 'Market Intelligence', weight: 'bold', size: 'title' },
                    // { text: ' in one convenient bundle.', size: 'title' }
                    // ]
                ],
                perMonthAbbr: '/mês',
                moreOptions: 'Mais opções',
                getStarted: 'Iniciar'
            },
            products: {
                sx1Premium: 'Prêmio StoneX One',
                miPremium: 'Prêmio Inteligência de mercado',
                sx1miBundle: 'Pacote StoneX One + MI'
            },
            features: {
                deepAnalysis: {
                    title: 'Análise profunda',
                    subtitle: 'Insiders, chamadas de ganhos e muito mais!'
                },
                multiChart: { title: 'Vários gráficos' },
                customAlerts: { title: 'Alertas personalizados' },
                miPremiumArticles: { title: 'Pesquisa de commodities premium' }
            }
        }
    },

    managePremiumScreen: {
        premium: 'Premium',
        havePremiumTitle: 'You have StoneX One Premium!',
        havePremiumSubtitle: 'Rock on!',
        dontHavePremiumTitle: "You don't have Premium",
        dontHavePremiumSubtitle: 'Unlock analysis like no other.',
        features: {
            earningsCalls: 'Listen to recent and historical earnings calls for all equities, with transcripts.',
            financials:
                'Gain access to financials like sentiment analysis, balance sheets, income statements, cash flow reports, projected revenue, dividend history, and more for all stocks.',
            institutions: 'See which institutions, funds, and leaders are trading the most shares for any company.'
        },
        startForJust: (price: string | number) => `Start for just $${price}/mo.`,
        startPremiumNow: 'Start Premium Now!',
        howPaymentsAreMade: 'Payments are made directly from your StoneX account, no card required.',
        paymentAccount: 'Payment Account',
        selectPaymentAccount: 'Select Payment Account',
        nextBill: 'Next Bill',
        contactSupportForBillingHistory: 'Please contact suppory with inquiries about your billing history',
        contactSupport: 'Contact Support',
        cancelMyPremium: 'Cancel My Premium',
        cancelAreYouSure: 'Are you sure you want to cancel?',
        cancelYouCanUncancel: (ex: Date) => `You can choose to undo your cancelation anytime before ${SafeFormat(ex, 'MMMM d')} to keep your premium.`,
        yesCancelMyPremium: 'Yes, Cancel My Premium',
        noKeepMyPremium: 'No, Keep My Premium',
        premiumEnd: 'Premium End',
        canceledTitle: (ex: Date) => `Your premium will end on ${SafeFormat(ex, 'MMMM d')}`,
        canceledSubtitle: 'Undo your cancelation below to keep your premium',
        undoCancelation: 'Undo Cancelation'
    },

    weather: {
        sectionTitle: 'Clima',
        current: {
            chanceOfPrecipitation: 'Precipitação',
            dewPoint: 'Ponto de condensação da água',
            humidity: 'Umidade',
            visibility: 'Visibilidade',
            feelsLike: 'Parece',
            airPressure: 'Pressão do ar',
            wind: 'Vento',
            gusts: 'Rajadas',
            sprayCondition: 'Pulverização'
        },
        hourly: {
            hour: 'Hora',
            climate: 'Clima',
            rainChance: 'Possibilidade de Chuva',
            rainAmount: 'Quantidade de chuva',
            temperature: 'Temperatura',
            windGust: 'Rajada de vento'
        }
    },
    misc: {
        backToTorch: 'Ver versão anterior',
        selectAnAccount: 'Selecione uma conta',
        manageAccountApplications: 'Manage Account Applications',
        account: 'Conta',
        allAccounts: 'Todas as contas',
        showFewer: 'Mostrar menos',
        showLess: 'Mostre menos',
        showXMore: (n: number) => `Mostrar mais ${n}`,
        showMore: 'Mostre mais',
        accounts: (n: number) => `Conta${n === 1 ? '' : 's'}`,
        heldBy: 'mantido por',
        addAccountButton: 'Adicionar Conta',
        addAccount: 'Adicionar conta de patrimônio',
        addEquitiesAccount: 'Adicionar conta de ações',
        addFuturesAccount: 'Adicionar conta de futuros',
        noResults: 'Sem resultados',
        noMoreResults: 'Sem mais resultados',
        errorLoadingResults: 'Erro ao carregar resultados',
        tryAgain: 'Tente novamente',
        today: 'Hoje',
        ranges: {
            oneDay: '1D',
            twentyFourHours: '24h',
            fiveDay: '5D',
            oneWeek: '1W',
            oneMonth: '1M',
            threeMonths: '3M',
            sixMonths: '6M',
            oneYear: '1Y',
            fiveYear: '5Y',
            all: 'ALL'
        },
        search: {
            noResults: 'Nenhum resultado encontrado, tente outra pesquisa',
            placeholder: 'Procure um segurança...'
        },
        news: {
            noTitle: 'Sem título',
            noSummaryAvailable: 'Resumo indisponível'
        },
        tradeTypeButton: {
            buyToOpen: 'compre para abertas',
            sellToOpen: 'vender para abertas',
            sellToClose: 'vender para fechar',
            buyToClose: 'compre para fechar',
            buy: 'Comprar',
            sell: 'Vender',
            shortSell: 'Venda a descoberto',
            buySell: 'Compra-Venda',
            sellBuy: 'Venda-Compra',
            liquidate: 'Liquidar',
            liquidatePosition: (securityId: string) => `Ou você pode liquidar sua posição, que venderá todas as suas ações em ${securityId}.`
        },
        chatWithSupport: 'Converse conosco',
        showVolume: 'Mostrar volume',
        showWorkingOrders: 'Mostrar ordens de serviço',
        loggingOut: 'Desconectando você...',
        amountEstimate: 'Este valor é uma estimativa. Seu pedido será executado ao melhor preço possível',
        newIPO: 'Novas IPOs no horizonte.',
        offeringPrice: 'Preço de oferta:',
        screenNotExist: 'Esta tela não existe.',
        goHomeScreen: 'Vá para a tela inicial!',
        stopAllStreaming: 'Parar todas as transmissões'
    },

    disclosureMessages: {
        tax: 'A StoneX One não fornece consultoria tributária.',
        finra: 'StoneX © 2021. Contas mantidas pela StoneX Financial Inc., membro FINRA/SIPC e subsidiária integral da StoneX Group Inc.',
        delayedQuotes: 'As cotações estão com 15 minutos de atraso.',
        glancePreviousClose: 'Alteração a partir do fechamento anterior',
        projectedOrderCost: 'Custo do pedido projetado com base no preço limite; o custo real pode variar',
        topMovers: `As listas SETORS e MOVERS são geradas usando dados provenientes de provedores de dados de terceiros.
        Uma lista de empresas em um setor de negócios específico pode exibir uma empresa se os dados de origem indicarem que a empresa obtém uma parte de suas receitas do setor relevante.
        A lista é fornecida apenas para fins informativos e não é um conselho de investimento ou uma recomendação para comprar, manter ou vender qualquer título.
        As listas não são recomendações personalizadas para você ou qualquer outro cliente, e os títulos listados podem não ser adequados para você.
        Você não deve comprar ou vender qualquer título sem primeiro determinar que é apropriado para seu portfólio ou estratégia de investimento.
        A StoneX One não oferece nenhuma garantia ou garantia relativa à precisão, pontualidade ou integridade de qualquer informação de terceiros, e o fornecimento dessas informações não constitui uma recomendação.`,
        totalPnL: 'Este valor reflete o lucro/prejuízo não realizado para esta posição'
    },

    header: {
        alerts: 'Alertas',
        tip: 'Dica',
        trySearch: 'Tente pesquisar sua segurança favorita',
        summary: 'Resumo',
        positions: 'Portfólio',
        banking: 'Caixa',
        messages: 'Mensagens',
        profile: 'Perfil',
        help: 'Ajuda',
        legalMore: 'Legal e mais',
        goPaperless: 'Fique sem papel',
        documents: 'Documentos',
        tradeHistory: 'Pedidos',
        fixedPlus: 'Fixed+',
        transactions: 'Transações',
        projectedIncome: 'Receita projetada',
        disclosures: 'Divulgações',
        logOut: 'Sair',
        buyingPower: 'Poder de compra',
        portfolioValue: 'Valor do portfólio',
        holdings: 'Participações',
        upcomingEarnings: 'Próximos ganhos',
        upcomingIpos: 'IPOs',
        searchForAnything: 'Pesquise qualquer coisa para negociar...',
        addToList: 'Adicionar à lista',
        exploreMarkets: 'Explorar os mercados',
        optionsHint: 'para opções',
        chatWithUs: 'Converse conosco',
        emailUs: 'Envia-nos um email',
        resetTours: 'Ver tour',
        verifyResetTours: 'Tem certeza de que deseja redefinir o progresso do passeio?',
        amex: 'Experimente o AMEX hoje!',
        helpPopup: {
            contactUs: 'Fale Conosco',
            liveChat: 'Chat ao vivo'
        }
    },

    notFoundSceen: {
        header: 'Parece que esta página não existe...',
        securityHeader: 'Parece que essa segurança não existe...',
        otherOptions: 'Aqui estão algumas outras opções que você pode gostar',
        portfolioButton: 'Meu portfolio',
        positionsButton: 'Minhas posições',
        mobile: {
            wrongTurn: 'Desculpe, parece que fizemos uma curva errada!',
            backToStoneX: 'Voltar para StoneX'
        }
    },

    watchlistScreen: {
        items: (numItems?: number) => `${numItems} ${numItems === 1 ? 'item' : 'itens'}`,
        notFound: {
            primary: 'Parece que esta lista de observação não existe',
            secondary: "Selecione outra lista de observação ou crie uma clicando no ' + ' à direita"
        },
        noItems: {
            primary: 'Parece que esta lista está vazia',
            secondary: (name: string) => `Todos os itens adicionados ao '${name}' aparecerão aqui.`
        },
        marketCap: {
            header: 'O que é posição atual?'
        },
        noLists: {
            noListTitle: 'Sem listas',
            noListSubtitle: 'Fique de olho em seus títulos favoritos com uma lista. Crie sua primeira lista abaixo.',
            createNewList: 'Criar Nova Lista',
            addToList: 'Adicionar uma lista',
            emptyListTitle: 'Parece que esta lista está vacía',
            emptyListSubtitle: 'Acompanhe suas empresas favoritas adicionando-as a esta lista.',
            addMore: 'Adicione mais'
        },
        defaultDescription: 'Adicionar uma descrição da lista de observação',
        createNewWatchlist: 'Criar nova lista de observação',
        addToWatchlist: (qsi: string) => `Add ${qsi} To Watchlist`,
        editWatchlist: 'Editar nome da lista de observação',
        createWatchlist: 'Criar lista de observação',
        watchlistName: 'Nome da lista de observação',
        create: 'Crio',
        update: 'Atualizar',
        topStories: 'Principais Notícias'
    },

    portfolioScreen: {
        skipToWatchlists: 'Pular para listas de interesses',
        portfolio: 'Portfólio',
        buyingPower: {
            buyingPower: 'Poder De Compra',
            cashAvailable: 'Dinheiro Disponível',
            marginCashAvailable: 'Margem Dinheiro Disponível',
            marginAvailable: 'Margem Disponível',
            nyseExcess: 'Excesso da NYSE',
            startUsingMargin: 'Comece a usar a margem',
            totalAvailableForTrading: 'Total disponível para negociação',
            whichAccountToAddMargin: 'Em qual conta você deseja adicionar margem?',
            initialMargin: 'Margem inicial',
            netLiquidity: 'Liquidez Líquida'
        },
        summary: {
            allAccounts: 'Todas as contas',
            assetsHeldAway: 'Ativos retidos',
            buyingPower: 'Poder de Compra',
            cash: 'Dinheiro',
            cashAndEquivalents: 'Equivalentes em dinheiro',
            initialMargin: 'Margem Inicial',
            longMarketValue: 'Posição atual longo',
            longOptionValue: 'Valor da opção longa',
            totalAccountValue: 'Valor total da conta',
            netLiq: 'Valor Líquido de Liquidação',
            netOptionValue: 'Valor líquido da opção',
            openPnL: 'P&L aberto',
            realizedPnL: 'P&L (realizado) de hoje',
            shortMarketValue: 'Posição atual curto',
            shortOptionValue: 'Valor da opção curta',
            summary: 'Resumo',
            totalPnL: 'Lucros e perdas totais',
            unrealizedPnL: 'P&L aberto (não realizado)',
            viewProjectedIncome: 'Visualizar receita projetada'
        },
        portfolioBreakdown: {
            cashAndEquivalents: 'Equivalentes em dinheiro',
            equities: 'Ações',
            futures: 'Futuros',
            stocks: 'Ações',
            mutualFunds: 'Fundos mútuos',
            fixedIncome: 'Renda Fixa',
            rightsAndWarrants: 'Direitos e Garantias',
            options: 'Opções',
            uits: 'UITs',
            others: 'Outros',
            preciousMetals: 'Metais Preciosos',
            entertainment: 'Entretenimento',
            miscellaneous: 'Diversos',
            telecommunications: 'Telecomunicações',
            broadcastingRadioAndTv: 'Radiodifusão - Rádio e TV',
            automobileAndTrucks: 'Radiodifusão - Rádio e TV',
            instrumentation: 'Instrumentação',
            notGrouped: 'Não agrupado',
            investmentCompanies: 'Empresas de Investimento',
            portfolioBreakdown: 'Carteira',
            holdingsBreakdown: 'Composição das Participações',
            contractsBreakdown: 'Detalhamento de Contratos',
            accountBreakdown: 'Detalhamento da conta',
            shares: 'Ações',
            breakdown: 'Discriminação',
            sectorExposure: 'Exposição do setor',
            top10holdings: 'As 10 principais participações',
            investing: 'Investindo',
            representingAssets: (number: string) => `Representando ${number} do Ativo Total`
        },
        sectors: {
            sectors: 'Setores',
            asOf: (date: Date) => `A partir de ${SafeFormat(date, 'dd MMM yyyy, hh:mm bb')}`,
            customerDiscretionary: 'Consumidor discricionário',
            healthCare: 'Assistência médica',
            technology: 'Tecnologia',
            realEstate: 'Imobiliária',
            consumerStaples: 'Bens de consumo',
            utilities: 'Serviços de utilidade pública',
            communicationServices: 'Serviços de comunicação',
            financials: 'Finanças',
            materials: 'Materiais',
            industrials: 'Industriais',
            energy: 'Energia',
            addToWatchlist: 'Adicionar à lista de observação',
            noSecurities: 'Sem Títulos',
            emptyList: 'Parece que esta lista está vazia.'
        },
        topMovers: {
            futuresTopMovers: 'Principais Movimentadores do Mês da Frente',
            topMovers: 'Principais Movimentadores',
            topGainers: 'Principais ganhadores',
            topLosers: 'Principais perdedores',
            unavailable: 'Dados indisponíveis',
            topMoversUnavailable: 'Os principais impulsionadores estão indisponíveis no momento',
            frontMoversUnavailable: 'Os principais líderes do mercado não estão disponíveis no momento',
            topGainersUnavailable: 'Os principais ganhadores não estão disponíveis no momento',
            topLosersUnavailable: 'Os principais perdedores não estão disponíveis no momento'
        },
        glance: {
            title: 'Termos de investimento',
            portfolioValue: 'Valor do portfólio',
            accountValue: 'Valor da conta',
            amountInvested: 'Valor investido',
            amountInvestedInfo:
                'Este valor inclui as posições de títulos em aberto de sua conta de corretagem, bem como o caixa e a base de custos de sua Conta de Futuros.',
            todaysGainLoss: 'Ganho/Perda de hoje',
            todaysGainLossInfo: 'Este cálculo não inclui quaisquer posições de Futuros.',
            unrealizedGainLoss: 'Ganho/Perda Aberto',
            unrealizedGainLossInfo: 'G/P não realizado atual de todas as posições abertas.',
            buyingPower: 'Poder de compra',
            cash: 'Dinheiro',
            asOfPreviousClose: '(a partir do fechamento anterior)',
            lastUpdated: (updated: string) => `Ultima atualização ${updated}`
        },
        sidebar: {
            stocks: 'Ações',
            mutualFunds: 'Fundos mútuos',
            fixedIncome: 'Renda Fixa',
            options: 'Opções',
            shares: 'Ações',
            watchlists: 'Lista de observação',
            sortBy: 'Ordenar por',
            price: 'Preço',
            value: 'Valor',
            display: 'Tela',
            delete: 'Excluir',
            add: 'Adicionar',
            edit: 'Editar Detalhes...',
            expires: (date: Date) => `${SafeFormat(date, 'dd MMM yyyy')} Expira`,
            strike: 'Greve'
        },
        warnings: {
            pleaseCheckLater: 'Por favor, volte mais tarde para mais desenvolvimentos sobre este problema.',
            systemBulletin: 'Boletim do Sistema',
            resolvingIssues: 'Estamos resolvendo um incidente que afeta a disponibilidade do nosso ',
            system: 'sistema',
            systems: 'sistemas',
            weAreResolvingIssues: 'Estamos resolvendo um incidente no sistema.',
            theseSystems: 'esses sistemas',
            theSystem: 'o sistema',
            incidentFirstReported: ' Este incidente foi relatado pela primeira vez ',
            statusEvaluated: (systems: string) => ` O status de ${systems} foi avaliado pela última vez `
        },
        totalPortfolioValue: 'Valor total da carteira',
        asOfDate: 'Data de início',
        commonStock: 'Ação ordinaria',
        preferredStock: 'Ações preferenciais',
        newListName: 'Nome da nova lista',
        create: 'Crio',
        documents: 'Documentos',
        readyToStart: (isBeta: boolean) => (isBeta ? 'Pronto para começar?' : 'Pronto para começar a negociar?'),
        depositFunds: 'Fundos de depósito',
        getStarted: 'Comece colocando sua primeira negociação.',
        myPositions: 'Minhas posições',
        topStories: 'Melhores histórias'
    },
    accountScreen: {
        accountBreakdown: 'Carteira',
        summaryDeficientChartPoints:
            'Atualmente, o gráfico de resumo não inclui um ou mais valores de suas contas. Isso não tem impacto em suas contas reais ou poder de compra.',
        accountDeficientChartPoints:
            'Atualmente, o gráfico de contas não possui dados suficientes para apresentar. Isso não tem impacto em sua conta real ou poder de compra.',
        pending: {
            title: (plural?: boolean) => (plural ? 'Contas com aprovação pendente' : 'Aprovação pendente de conta'),
            info: (plural?: boolean) =>
                `Sua ${
                    plural ? 'contas estão' : 'conta está'
                } em processo de aprovação de um de nossos especialistas. Verifique novamente mais tarde para ver se esse status foi atualizado.`,
            extrasHeader: 'Enquanto você espera',
            explore: 'Explorar os mercados',
            getHelp: 'Obtenha ajuda de nossa equipe de suporte'
        },
        viewDetails: 'Ver Detalhes',
        noAssetBreakdownAvailable: 'Nenhum detalhamento de ativos disponível para esta conta.',
        noIndustryBreakdownAvailable: 'Nenhum detalhamento por indústria disponível para esta conta.',
        byAsset: 'Por Ativo',
        byIndustry: 'Por Indústria',
        buyingPower: {
            initialMargin: 'Margem inicial',
            netLiquidity: 'Liquidez Líquida'
        },
        myAccountStatusApplication: {
            docsRequired: {
                title: 'Documentos necessários',
                info: 'Precisamos de alguns documentos seus para processar sua inscrição.'
            },
            inProgress: {
                title: 'Aplicação em análise',
                info: 'Sua conta está sendo revisada por um de nossos especialistas. Volte mais tarde para ver se sua conta foi aprovada.'
            },
            rejected: {
                title: 'Aplicação rejeitada',
                info: 'Seu pedido de conta foi rejeitado. Visualize o aplicativo da sua conta ou entre em contato com nossa equipe de suporte abaixo.'
            },
            verificationFailed: {
                title: 'Falha na verificação da conta',
                info: 'Não conseguimos verificar algumas informações em nosso aplicativo. Visualize o aplicativo da sua conta ou entre em contato com nossa equipe de suporte abaixo.'
            },
            submitted: {
                title: 'Aplicação submetida',
                info: 'A solicitação de sua conta foi enviada! Volte mais tarde para ver se sua conta foi aprovada.'
            },
            viewApplication: 'Ver o status do aplicativo'
        }
    },

    positionsScreen: {
        account: 'Conta',
        allIndustries: 'Todas as indústrias',
        allTypes: 'Todos os tipos',
        averageCost: 'Média Custo',
        change: 'Variação',
        changePercent: 'Variação %',
        closed: 'Fechadas',
        cost: 'Custo',
        date: 'Encontro: Data',
        downloadAll: 'Baixar tudo',
        downloadClosed: 'Baixar posições fechadas',
        downloadClosedFileName: () => `posições-fechadas-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`,
        downloadFilename: () => `posições-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`,
        hide: 'Esconder',
        longAmount: 'Quantidade longa',
        marketValue: 'Posição Atual',
        marketValueFull: 'Posição Atual',
        name: 'Nome',
        noOpenLots: 'Não há lotes de impostos abertos disponíveis para esta posição.',
        notGrouped: 'Não agrupado',
        open: 'Abertas',
        openPnL: 'P/L Aberto',
        openPnLPercent: 'P/L Aberto %',
        positions: 'Portfólio',
        price: 'Preço',
        quantity: 'Quantidade',
        realizedPnlFor: (thingItsFor: string) => `Lucros e perdas realizados para ${thingItsFor}`,
        rollPosition: 'Rolar posição',
        shortAmount: 'Valor Curto',
        show: 'Mostrar',
        showClosedLots: 'Mostrar lotes fiscais fechadas',
        taxLotPreviousDayDisclosure: 'Os valores de custo e ganho e perda são calculados usando o custo ajustado quando disponível.',
        taxLotsFor: (thingItsFor: string) => `Lotes fiscais para ${thingItsFor}`,
        taxYear: 'Ano fiscal',
        term: 'Prazo',
        todaysGainLoss: 'G/L Hoje',
        todaysGainLossPercent: 'G/L Hoje %',
        todaysGainLossTab: 'Ganho/Perda de hoje',
        totalCost: 'Custo total',
        totalGainLoss: 'G/L Total',
        totalGainLossPercent: '% G/L Total',
        totalGainLossTab: 'Ganho/Perda Total',
        type: 'Tipo',
        unrealizedProfitLoss: 'Lucro/Perda Não Realizado',
        sortBy: 'Ordenar por',
        industryGroups: {
            [IndustryGroups.cashAndEquivalents]: 'Equivalentes em dinheiro',
            [IndustryGroups.automotiveAndTrucks]: 'Automóveis / Caminhões',
            [IndustryGroups.broadcastingRadioAndTv]: 'Transmissão - Rádio e TV',
            [IndustryGroups.conglomerates]: 'Conglomerados',
            [IndustryGroups.metalsAndMining]: 'Metais e Mineração',
            [IndustryGroups.miscellaneous]: 'Diversos',
            [IndustryGroups.mutualFunds]: 'Fundos mútuos',
            [IndustryGroups.consumerProducts]: 'Produtos de consumo',
            [IndustryGroups.commercialPaper]: 'Papel comercial',
            [IndustryGroups.treasuryBillsFlat]: 'Bilhetes do Tesouro - Plano',
            [IndustryGroups.corporateBonds]: 'Títulos corporativos',
            [IndustryGroups.mortgageBackedBonds]: 'Títulos garantidos por hipoteca',
            [IndustryGroups.govermentBonds]: 'Títulos do Governo',
            [IndustryGroups.municipalBonds]: 'Títulos Municipais',
            [IndustryGroups.municipalBondsPut]: 'Títulos Municipais - Put',
            [IndustryGroups.preferredStocks]: 'Ações preferenciais',
            [IndustryGroups.aerospace]: 'Aeroespacial',
            [IndustryGroups.advertising]: 'Anúncio',
            [IndustryGroups.airTransport]: 'Transporte aéreo',
            [IndustryGroups.aluminum]: 'Alumínio',
            [IndustryGroups.autoParts]: 'Autopeças',
            [IndustryGroups.banks]: 'Bancos',
            [IndustryGroups.beveragesBrewers]: 'Bebidas - Cervejeiros',
            [IndustryGroups.beveragesDistillers]: 'Bebidas - Destilarias',
            [IndustryGroups.beveragesSoftDrinks]: 'Bebidas - Refrigerantes',
            [IndustryGroups.biotechnology]: 'Biotecnologia',
            [IndustryGroups.brokerageFirms]: 'Corretoras',
            [IndustryGroups.buildingMaterials]: 'Materiais de construção',
            [IndustryGroups.businessServices]: 'Serviços prestados às empresas',
            [IndustryGroups.cement]: 'Cimento',
            [IndustryGroups.chemicalDiversified]: 'Química - Diversificada',
            [IndustryGroups.chemicalSpecialties]: 'Especialidades Químicas',
            [IndustryGroups.coal]: 'Carvão',
            [IndustryGroups.copper]: 'Cobre',
            [IndustryGroups.containersMetalGlass]: 'Recipientes Metal / Vidro',
            [IndustryGroups.containersPlastic]: 'Recipientes - Plástico',
            [IndustryGroups.cosmetics]: 'Cosméticos',
            [IndustryGroups.dataProcessing]: 'Processamento de dados',
            [IndustryGroups.drugs]: 'Drogas',
            [IndustryGroups.electricEquipment]: 'Equipamento eletrico',
            [IndustryGroups.ermergingGrowth]: 'Crescimento emergente',
            [IndustryGroups.entertainment]: 'Entretenimento',
            [IndustryGroups.finance]: 'Finança',
            [IndustryGroups.fertilizers]: 'Fertilizantes',
            [IndustryGroups.foods]: 'Alimentos',
            [IndustryGroups.foodServiceAndVending]: 'Serviço de alimentação e vendas',
            [IndustryGroups.forestProducts]: 'Produtos Florestais',
            [IndustryGroups.gaming]: 'Jogos',
            [IndustryGroups.healthCareFacilities]: 'Unidades de Saúde',
            [IndustryGroups.homeFurnishings]: 'Mobília doméstica',
            [IndustryGroups.hospitalSupplies]: 'Materiais Hospitalares',
            [IndustryGroups.hotelsMotels]: 'Hotéis e motéis',
            [IndustryGroups.householdAppliances]: 'Electrodomésticos',
            [IndustryGroups.industrialServices]: 'Serviços Industriais',
            [IndustryGroups.instrumentation]: 'Instrumentação',
            [IndustryGroups.insurance]: 'Seguro',
            [IndustryGroups.investmentCompanies]: 'Empresas de investimento',
            [IndustryGroups.leisureTime]: 'Tempo de lazer',
            [IndustryGroups.machinery]: 'Maquinário',
            [IndustryGroups.machineTools]: 'Máquinas-ferramentas',
            [IndustryGroups.manufacturing]: 'Fabricação',
            [IndustryGroups.metalsFabricating]: 'Metais - Fabricação',
            [IndustryGroups.mobileHomes]: 'Casas móveis',
            [IndustryGroups.officeEquipment]: 'Equipamento de escritório',
            [IndustryGroups.oilAndGas]: 'Gás de petróleo',
            [IndustryGroups.oilService]: 'Serviço de Petróleo',
            [IndustryGroups.paper]: 'Papel',
            [IndustryGroups.photography]: 'Fotografia',
            [IndustryGroups.printingAndPublishing]: 'Impressão e publicação',
            [IndustryGroups.railroads]: 'Ferrovias',
            [IndustryGroups.realEstate]: 'Imobiliária',
            [IndustryGroups.restaurants]: 'Restaurantes',
            [IndustryGroups.retailStores]: 'Lojas de varejo',
            [IndustryGroups.retailFoodChains]: 'Varejo - Cadeias Alimentares',
            [IndustryGroups.retailDrugChains]: 'Varejo - Redes de Drogas',
            [IndustryGroups.retailMerchandising]: 'Varejo - Merchandising',
            [IndustryGroups.savingsAndLoan]: 'Poupança e Empréstimo',
            [IndustryGroups.shoes]: 'Sapato',
            [IndustryGroups.soaps]: 'Sabonetes',
            [IndustryGroups.steels]: 'Aços',
            [IndustryGroups.telecommunications]: 'Telecomunicações',
            [IndustryGroups.textiles]: 'Têxteis',
            [IndustryGroups.tiresAndRubber]: 'Pneus e Borracha',
            [IndustryGroups.tobacco]: 'Tabaco',
            [IndustryGroups.toys]: 'Brinquedos',
            [IndustryGroups.transportation]: 'Transporte',
            [IndustryGroups.trucking]: 'Caminhões',
            [IndustryGroups.utilitiesElectric]: 'Utilidades - Elétrica',
            [IndustryGroups.utilitiesGas]: 'Utilitários - Gás',
            [IndustryGroups.utilitiesOther]: 'Utilitários - Outros',
            [IndustryGroups.options]: 'Opções'
        },
        closedPositions: {
            parentHeader: {
                symbolId: 'Símbolo / ID',
                description: 'Descrição',
                totalQuantity: 'Quantidade total',
                totalCost: 'Custo total',
                totalProceeds: 'Receitas totais',
                totalRealizedGainLoss: 'Ganho/Perda Total Realizado',
                totalRealizedGainLossPercent: 'Ganho/Perda Total Realizado %'
            },
            childHeader: {
                adjustedCost: 'Custo ajustado',
                closeDate: 'Data de encerramento',
                closePrice: 'Fechar Preço',
                cost: 'Custo',
                exchange: 'Intercâmbio',
                openDate: 'Data aberta',
                price: 'Preço',
                priceOf: (qsi: string) => `Preço de ${qsi}`,
                proceeds: 'Receitas',
                quantity: 'Quantidade',
                realizedGainLoss: 'Ganho/Perda Realizado',
                realizedGainLossPercent: '% de ganho/perda realizado',
                realizedGl: 'Ganho/perda realizado',
                side: 'Lado',
                term: 'Prazo',
                totalCost: 'Custo total',
                tradeDate: 'Data de negociação'
            }
        }
    },
    bankingScreen: {
        errors: {
            ainsIdentity: {
                title: 'Não foi possível verificar a identidade.',
                description: 'Lamentamos, mas não foi possível verificar sua identidade. Entre em contato conosco abaixo e faremos o possível para resolver as coisas.'
            },
            ainsMax: 'Desculpe, você atingiu o número máximo permitido de contas bancárias vinculadas. Remova um para continuar.',
            ainsMaxVoid: 'Desculpe, a remoção da conta bancária está limitada a uma remoção a cada duas semanas. Por favor, tente novamente mais tarde.',
            betaDowntimeWindow: 'As transferências não estão disponíveis das 17:00 às 20:00 EST.',
            transfersUnavailable: 'Transferências não podem ser enviadas neste momento.',
            insufficientWithdrawalBalanceTitle: 'Fundos insuficientes',
            insufficientWithdrawalBalance:
                'Você não tem fundos suficientes para a transferência solicitada. Se você vendeu recentemente uma ou mais posições, pode ser necessário esperar até o próximo dia de negociação para que seus fundos sejam liquidados e fiquem disponíveis para transferência.',
            noAccountsAvailable: {
                title: 'Nenhuma conta disponível',
                description: 'Desculpe, mas não há contas disponíveis para você adicionar. Entre em contato conosco abaixo e faremos o possível para resolver as coisas.'
            },
            pendingAccount: 'Conta pendente',
            retrievingAccountsFailed: {
                title: 'Desculpe, ocorreu um erro ao recuperar suas contas. Tente novamente mais tarde ou ',
                actionTitle: 'entre em contato com o suporte.'
            }
        },
        nextVoidAllowed: (nextVoid: Date) => `Você pode excluir esta conta em ${format(nextVoid, 'DD/MM/YYYY')}.`,
        maxReached: (maxAccounts: number) => `Máximo de ${maxAccounts} contas atingido.`,
        fundingError: {
            title: 'Um erro ocorreu',
            subtitle: 'Tente novamente ou entre em contato conosco para obter assistência',
            tryAgain: 'Tente novamente'
        },
        tryAgainIn: (dateForUnlock: Date) => `Tente novamente ${SafeFormatToNow(dateForUnlock)}`,
        tryAgainTomorrow: 'Tente novamente amanhã',
        tryAgainOnMonday: 'Tente novamente na segunda-feira',
        dontSeeAccount: 'Não vê a conta que deseja adicionar? Entre em contato conosco abaixo e resolveremos as coisas.',
        history: 'História',
        transactionHistory: 'Histórico de transações',
        requests: 'Solicitações de',
        disclaimer:
            'As informações acima são para informações suplementares e apenas para fins informativos. Consulte seu extrato diário para obter o registro oficial de sua conta.',
        transferFunds: 'Transferir Fundos',
        depositVerb: 'Depósito',
        withdrawVerb: 'Retiradas',
        depositNoun: 'Depósito',
        withdrawalNoun: 'Cancelamento',
        fundsWillBeCredited: 'Os fundos serão creditados na sua conta bancária nos próximos dias.',
        fundsWillBeDebited: 'Seu fundo estará disponível em breve.',
        from: 'A partir de',
        to: 'Para',
        amount: 'Montante',
        review: 'Análise',
        reviseTransfer: 'Revisar transferência',
        depositsLocked: (days: number) =>
            days === 1
                ? 'Depósitos bloqueados devido a muitas tentativas malsucedidas. Tente novamente amanhã ou entre em contato com o suporte.'
                : `Depósitos bloqueados devido a muitas tentativas malsucedidas. Tente novamente em ${days} dias ou entre em contato com o suporte.`,
        withdrawLocked: (days: number) =>
            days === 1
                ? 'Retiradas bloqueados devido a muitas tentativas malsucedidas. Tente novamente amanhã ou entre em contato com o suporte.'
                : `Retiradas bloqueados devido a muitas tentativas malsucedidas. Tente novamente em ${days} dias ou entre em contato com o suporte.`,
        cashHeader: 'Caixa',
        depositFunds: 'Deposite fundos diretamente na sua conta StoneX de uma conta bancária vinculada.',
        withdrawFunds: 'Retirar fundos da sua conta StoneX para uma conta bancária vinculada.',
        selectLinkedBankAcct: 'Selecione a conta bancária vinculada que você gostaria de usar.',
        retrievingBankAcct: 'Recuperando suas contas bancárias...',
        linkBankAcctWith: 'Vincular conta bancária com',
        amountMayNotExceed: (limit: number) => `O valor não pode exceder ${limit}.`,
        amountMustBeAtleast: (limit: number) => `O valor deve ser pelo menos ${limit}.`,
        dailyLimitText: (limits) => [
            Light('Limite diário restante '),
            Dark(FormatNumber.toMoney(limits?.remainingClearance)),
            Light(' do '),
            Dark(FormatNumber.toMoney(limits?.dailyMaximum))
        ],
        dailyMaximumText: (limits: FundingLimits, isDeposit: boolean) => [
            Light('Limite diário restante '),
            Dark(FormatNumber.toMoney(isDeposit ? limits?.remainingDepositAmount : limits?.remainingWithdrawAmount)),
            Light(' do '),
            Dark(FormatNumber.toMoney(isDeposit ? limits?.maxDailyDepositAmount : limits?.maxDailyWithdrawAmount))
        ],
        dailyLimitText2: (limits) => {
            const cented = (money: number) => FormatNumber.toMoney(money).replace(/\.00$/, '');
            return `${cented(limits.transferredToday)} de ${cented(limits.dailyMaximum)} transferidos hoje.`;
        },
        minimumAllowedDepositText: (isDeposit: boolean, minAllowedDeposit: number | null) => {
            const minAllowedDepositText =
                !!isDeposit && !!minAllowedDeposit ? [Light(' ( '), Dark(minAllowedDeposit ? FormatNumber.toMoney(minAllowedDeposit) : null), Light(' mínimo )')] : [];
            return minAllowedDepositText;
        },
        managementLimitText: (limits: FundingManagementLimits | null) => {
            if (!limits) return '';
            if (!limits.canAdd) {
                if (!limits.canVoid) {
                    return 'Para adicionar ou remover contas vinculadas, entre em contato com o suporte';
                } else {
                    return 'Para adicionar uma conta vinculada, entre em contato com o suporte';
                }
            } else if (!limits.canVoid) {
                return 'Para remover uma conta vinculada, entre em contato com o suporte';
            } else return '';
        },
        fundingAvailableBalance: (availableBalance?: number) => [Light('Disponível para retirada: '), Dark(FormatNumber.toMoneyOpt2(availableBalance))],
        amountToTransfer: (transferDirection?: 'deposit' | 'withdraw') => (transferDirection === 'deposit' ? 'Valor a depositar' : 'Quantidade à sacar'),
        amountExceedsLimit: 'O valor excede o limite diário',
        amountExceedsBalance: 'O valor excede o saldo bancário',
        amountExceedsAccountBalance: 'O valor excede o saldo conta',
        accountIsPending: 'A conta está pendente',
        plaidReauthNeeded: 'É necessária nova autenticação com Plaid',
        plaidAuthenticationBroken: 'Autenticação Plaid quebrada, entre em contato com o suporte',
        addYourBankAccount: 'Adicione sua conta bancária',
        addABankAccount: 'Adicionar uma conta bancária',
        addASandboxBankAccount: 'Adicionar uma conta bancária Sandbox',
        back: 'Voltar',
        pleaseWait: 'Por favor, aguarde...',
        validatingTransfer: 'Validando transferência',
        thereWasAProblem: 'Havia um problema',
        unknownError: {
            title: 'Algo inesperado deu errado.',
            subtitle: 'Entre em contato com o suporte.'
        },
        fundingNotAllowed: {
            title: 'Financiamento não é permitido.',
            subtitle: 'Entre em contato com o suporte se achar que recebeu isso por engano.'
        },
        failedDailyFundingLimitCheck: {
            title: 'Limites diários excedidos.',
            subtitle: 'Entre em contato com o suporte se achar que recebeu isso por engano.'
        },
        cosmosPlaidAccessFailed: {
            title: 'Falha ao recuperar os dados do Plaid.',
            subtitle: 'Entre em contato com o suporte ou tente novamente.'
        },
        plaidBalanceCheckFailed: {
            title: 'Não foi possível verificar os fundos disponíveis.',
            subtitle: 'Entre em contato com o suporte se achar que recebeu isso por engano.'
        },
        cosmosPlaidLinkIdMatchFailed: {
            title: 'Erro de pesquisa de conta vinculada.',
            subtitle: 'Entre em contato com o suporte ou tente novamente.'
        },
        plaidIdentityInfoFailed: {
            title: 'Falha ao recuperar os dados bancários do Plaid.',
            subtitle: 'Entre em contato com o suporte ou tente novamente.'
        },
        likeKindNameCheckFailed: {
            title: 'Não foi possível verificar a propriedade da conta.',
            subtitle: 'Entre em contato com o suporte.'
        },
        previouslyLinkedAccountIdLookupFailed: {
            title: 'Falha ao vincular a conta Plaid à conta StoneX One.',
            subtitle: 'Entre em contato com o suporte ou tente novamente.'
        },
        failedToLinkAccount: {
            title: 'Falha ao vincular a conta Plaid à conta StoneX One.',
            subtitle: 'Entre em contato com o suporte ou tente novamente.'
        },
        transferWindowClosed: {
            title: 'O sistema de transferência está em manutenção.',
            subtitle: 'Entre em contato com o suporte se achar que recebeu isso por engano.'
        },
        transferFailed: {
            title: 'Falha na transferência.',
            subtitle: 'Entre em contato com o suporte ou tente novamente.'
        },
        transferRequestFailed: 'Request failed',
        submittedRequestSuccessfully: 'Request submitted',
        asyncInfo: 'You will be notified once this request is processed. Please do not logout until this request has processed.',
        depositsLockedError: {
            title: 'Depósitos bloqueados',
            subtitle: 'Muitas tentativas falhas.'
        },
        minimumDepositNotMet: {
            title: (amt: number) => `Valor mínimo de depósito ${FormatNumber.toMoneyOpt2(amt)} não cumprido.`,
            subtitle: 'Entre em contato com o suporte se achar que recebeu isso por engano.'
        },
        maximumAccountsReached: {
            title: 'Não foi possível adicionar a conta. Número máximo de contas atingido.',
            subtitle: 'Entre em contato com o suporte se achar que recebeu isso por engano.'
        },
        ainsInstructionsInactive: {
            title: 'Não foi possível recuperar as informações da conta bancária para transferência.',
            subtitle: 'Entre em contato com o suporte.'
        },
        maximumDepositsReached: {
            title: 'Depósitos bloqueados',
            subtitle: (amt: number, days: number) =>
                days === 1 ? `Apenas ${amt} depósitos podem ser feitos por dia.` : `Apenas ${amt} depósitos podem ser feitos a cada ${days} dias.`
        },
        maximumWithdrawalsReached: {
            title: 'Retiradas bloqueadas',
            subtitle: (amt: number, days: number) =>
                days === 1 ? `Apenas ${amt} saque pode ser feito por dia.` : `Apenas ${amt} saque pode ser feito a cada ${days} dias.`
        },
        pleaseTryAgain: 'Por favor, tente novamente',
        submittedSuccessfully: 'Transferência enviada!',
        operationSubmittedSuccessfully: (transfer) => {
            const operation = (() => {
                switch (transfer.transferDirection) {
                    case 'deposit':
                        return 'Deposit';
                    case 'withdraw':
                        return 'Withdrawal';
                    default:
                        return '';
                }
            })();

            return `${operation} Submitted`;
        },
        startAnotherTransfer: 'Iniciar outra transferência',
        returnToSummary: 'Voltar ao Resumo',
        bankingUnavailable: 'Banco indisponível',
        pleaseComeBackLaterOrContactUs:
            'Lamentamos, mas o banco não está disponível no momento. Tente novamente mais tarde ou entre em contato conosco abaixo e resolveremos o problema.',
        depositUnavailableExplanation:
            'Lamentamos, mas o depósito não está disponível no momento. Tente novamente mais tarde ou entre em contato conosco abaixo e resolveremos o problema.',
        depositUnavailableDueToTimeExplanation:
            'Desculpe, o depósito não está disponível entre 14h e 8h EST. Tente novamente fora desse horário ou entre em contato conosco abaixo e resolveremos o problema.',
        depositUnavailableDueToTimeExplanationShort: 'O depósito não está disponível entre 17:00 e 18:00 EST',
        depositUnavailableDueHolidaysExplanationShort: 'O depósito não está disponível durante os feriados comerciais',
        withdrawUnavailableExplanation:
            'Lamentamos, mas a retirada não está disponível entre 14h e 8h EST. Tente novamente fora desse horário ou entre em contato conosco abaixo e resolveremos o problema.',
        withdrawUnavailableExplanationShort: 'A retirada não está disponível entre 14:00 e 07:00 EST',
        withdrawUnavailableWeekendsExplanation: 'A retirada não está disponível durante os fins de semana',
        withdrawUnavailableHolidaysExplanation: 'A retirada não está disponível durante os feriados',
        accountPending: 'Your selected StoneX account is pending approval',
        billPay: 'Pagamento de contas',
        creditCardDirect: 'Cartão de crédito direto',
        wiringInstructions: 'Instruções de fiação',
        viewWiringInstructions: 'Ver instruções de fiação',
        wiringInstructionsPdfUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/en-US/WiringInstructions.pdf',
        verificationStatus: (status) => (status.includes('pending') ? 'verificação pendente' : 'Falha na verificação'),

        // Mobile
        selectAccount: 'Selecionar conta',
        forAddedSecurity: 'Para maior segurança, você será solicitado a inserir novamente suas credenciais bancárias',
        cannotAddLimitReachedTitle: 'Não é possível adicionar outra conta',
        cannotOpenPlaid: 'Não é possível abrir o Plaid, nenhum token de link fornecido',
        cannotAddLimitReachedExplainer: 'Apenas duas contas bancárias podem ser vinculadas por vez',
        linkAcctContactSupport: 'Para adicionar uma conta vinculada, entre em contato com o suporte',
        confirmText: (transfer: Partial<TransferRequest>, snexAccount: Account, fundingSource?: FundingSource) => {
            const isDeposit = transfer.transferDirection === 'deposit';
            const extPreposition = isDeposit ? 'from' : 'into';
            const snexPreposition = isDeposit ? 'into' : 'from';
            const source = fundingSource || transfer.fundingSource;

            return [
                { text: `You are ${isDeposit ? 'depositing' : 'withdrawing'}`, shade: 'light' },
                { text: FormatNumber.toMoneyOpt2(transfer.transferAmount), shade: 'dark' },
                { text: `${extPreposition} your`, shade: 'light' },
                { text: transfer.fundingSource?.name, shade: 'dark' },
                { text: 'account ending in', shade: 'light' },
                { text: `****${transfer?.fundingSource?.mask}`, shade: 'dark' },
                { text: `${snexPreposition}`, shade: 'light' },
                { text: snexAccount?.nickname || snexAccount?.name, shade: 'dark' }
            ];
        },
        lost: {
            title: 'Não encontrado',
            subtitle: 'Parece que fizemos uma curva errada! Tente novamente e, se o problema persistir, entre em contato conosco.',
            startOver: 'Tente novamente'
        },

        limitReached: 'Limite alcançado',
        linkYourBankAccount: 'Vincule sua conta bancária',
        limitReachedExplainer: (limits: FundingManagementLimits): ShadedTextList => {
            const maxLinked = limits?.maxLinkedCount;
            const maxVoid = limits?.maxVoidCount;
            const voidDays = limits?.maxVoidFrequencyDays;

            const s = (n: number) => (n === 1 ? '' : 's');
            const num = Snex1Portuguese.general.writtenInteger0Thru10;

            const addRule: ShadedTextList = [
                {
                    text: 'Como medida de segurança, você só tem permissão para adicionar ',
                    shade: 'light'
                },
                {
                    text: `${num(maxLinked)} conta${s} bancária${s(maxLinked)}`,
                    shade: 'dark'
                },
                { text: '. ', shade: 'light' }
            ];

            const voidRule: ShadedTextList = [
                {
                    text: 'Esteja ciente de que você também só pode remover ',
                    shade: 'light'
                },
                { text: `${num(maxVoid)} conta${s(maxVoid)}`, shade: 'dark' },
                { text: ' a cada ', shade: 'light' },
                { text: `${num(voidDays)} dia${s(voidDays)}`, shade: 'dark' },
                { text: '. ', shade: 'light' }
            ];

            const contactSupport: ShadedTextList = [
                {
                    text: 'Se você deseja adicionar ou remover uma conta, entre em contato com o suporte abaixo.',
                    shade: 'light'
                }
            ];
            return [...addRule, ...voidRule, ...contactSupport];
        },
        limitReachedExplainerV2: (maxAdd: number, maxVoid: number, voidDays: number): ShadedTextList => [
            { text: 'As a security measure, you are only allowed to add', shade: 'light' },
            { text: `${maxAdd} bank account${maxAdd === 1 ? '' : 's'}`, shade: 'dark' },
            { text: '. Be advised that you may also only remove ', shade: 'light' },
            { text: `${maxVoid} account${maxVoid === 1 ? '' : 's'}`, shade: 'dark' },
            { text: ' every ', shade: 'light' },
            { text: `${voidDays} day${voidDays === 1 ? '' : 's'}`, shade: 'dark' },
            { text: '. If you wish to add or remove an account, please reach out below.', shade: 'light' }
        ],
        youMayLinkNMoreAccounts: (n) => {
            if (n <= 0) return 'Você não pode vincular mais contas bancárias';
            return `Você pode adicionar mais ${n} ${n === 1 ? 'conta bancária' : 'contas bancárias'}`;
        },
        linkYourAccountToAddFunds: 'Vincule sua conta bancária para adicionar fundos à sua conta StoneX.',
        authenticateWithPlaid: 'Autenticar com Plaid',
        noBankAccounts: 'Sem contas bancárias',
        linkAnotherBankAccount: 'Vincular outra conta bancária',

        acats: {
            acatsComingSoon: {
                title: 'Transferências de conta em breve!',
                subtitle: 'Entre em contato conosco se tiver alguma dúvida.'
            },
            infoIcon: 'Rejeições ACATS - Causas mais comuns',
            transferAccount: 'Transferir conta',
            introText:
                'Transfira dinheiro e investimentos de outra corretora para sua conta StoneX One, total ou parcialmente. Envie sua transferência em minutos e veja seus ativos transferidos aparecerem em sua conta em 2-3 semanas.',
            from: 'A partir de',
            accountNumber: 'Número de conta',
            accountTitle: 'Titulo da conta',
            accountSubtitle: 'Legenda da conta',
            to: 'Para',
            start: 'Começar',
            continue: 'Continuar',
            skip: 'Pular',
            back: 'Voltar',
            submit: 'Enviar',
            selectTransferType: 'Selecione o tipo de transferência',
            fullTransfer: 'Transferência completa',
            fullTransferDescription: 'Transfira todo o dinheiro e posições de sua conta externa para sua conta StoneX One',
            partialTransfer: 'Transferência Parcial',
            partialTransferDescription: 'Selecione o dinheiro e as posições que você gostaria de transferir para sua conta StoneX One',
            cashOptions: 'Opções em dinheiro',
            transferAllCash: 'Transferir todo o dinheiro',
            transferNoCash: 'Transferência sem dinheiro',
            transferCustomCash: 'Transferir valor personalizado',
            positions: 'Posições',
            transferNoPositions: 'Transferência sem posições',
            addPosition: 'Adicionar posições',
            positionAccountType: 'tipo de conta',
            cash: 'Caixa',
            margin: 'Margem',
            all: 'Todo',
            custom: 'Personalizado',
            or: 'Ou',
            positionQuantity: 'Quantidade',
            done: 'Feito',
            transferringAllCash: 'Todo dinheiro',
            transferringNoneCash: 'Sem dinheiro',
            transferringCustomCash: (amount: number) => `Transferir ${FormatNumber.toMoney(amount)}`,
            transferringNoPositions: 'Nenhuma posição',
            confirmTransfer: (type: any) => {
                if (type === 'Cheio' || type === 'Parcial') {
                    return `confirme ${type} Transferir`;
                } else return 'Confirmar transferência';
            },
            transferringFull: 'Todos os ativos serão transferidos para sua conta StoneX One.',
            assets: 'Bens',
            noAssetsSelected: {
                title: 'Não é possível enviar transferência; nenhum recurso selecionado',
                subtitle:
                    'Para concluir sua transferência, volte e adicione dinheiro e/ou posições à sua transferência ou altere sua transferência para uma transferência completa',
                switchToFull: 'Variação para transferência completa'
            },
            submitting: {
                title: 'Enviando sua transferência',
                subtitle: 'Por favor, aguarde...'
            },
            success: {
                title: 'Transferência enviada!',
                subtitle: 'Sua conta deve ser transferida nos próximos 14 a 21 dias úteis.'
            },
            failure: {
                title: 'Algo deu errado',
                subtitle: 'Entre em contato conosco e vamos resolver as coisas.'
            },
            returnToSummary: 'Voltar ao Resumo'
        }
    },

    newVersionScreen: {
        newVersion: 'Nova versão',
        nowAvailable: 'Agora disponível',
        updateAndSee: 'Atualize-se e veja como podemos proporcionar uma melhor experiência para sua jornada financeira!',
        update: 'Atualizar',
        notNow: 'Agora não',
        accountInfoNotShared: 'Quando você atualizar, as informações da sua conta não serão compartilhadas com entidades de terceiros.',
        privacyPolicyLearnMore: 'Saiba mais sobre nossa política de privacidade.'
    },

    messagesScreen: {
        messages: 'Mensagens',
        noMessages: 'Sem mensagens',
        errorFetchingMessages: 'Desculpe, não foi possível receber suas mensagens no momento',
        viewAll: 'Ver tudo',
        read: 'Leitura',
        messageId: 'ID da mensagem',
        stoneXOneTeam: 'StoneX One Team',
        unknownSender: 'Remetente desconhecido',
        backToAllMessages: 'Voltar para todas as mensagens',
        deleteAll: 'Excluir tudo',
        noMessagesTitle: 'Suas mensagens aparecerão aqui',
        noMessagesSubtitle: 'Sua caixa de entrada está vazia. Depois de receber as mensagens, elas aparecerão aqui.',
        newMessage: 'Nova mensagem'
    },

    profileScreen: {
        profile: 'Perfil',
        logOut: 'Sair',
        copyClipBoard: 'Copiar para a área de transferência',
        emailSettings: 'Configurações de e-mail',
        settings: 'Definições',
        about: 'Sobre',
        build: 'Forma',
        version: 'Versão',
        snex1Premium: 'StoneX One Premium',
        deactivate: 'Desativar Assinatura',
        errorDuplicateValue: 'O valor inserido corresponde ao valor registrado. Nenhuma ação adicional necessária.',
        tooltip: {
            oneProNetwork:
                'One Pro Network oferece análises aprofundadas, alertas de mesa em tempo real e vídeos informativos sobre ações, commodities, moedas e macrotendências, todos preparados por analistas StoneX. Essas informações abrangentes permitem que você tome decisões comerciais mais informadas.'
        },
        tabs: {
            personalInfo: 'Informação pessoal',
            accounts: 'Contas',
            paperless: 'Sem papel',
            local: 'Local'
        },
        themes: {
            dark: 'Escuro',
            light: 'Luz',
            system: 'Preferência do Sistema',
            marketHours: 'Horário do Mercado de Ações dos E.U.A.'
        },
        personalInfo: {
            tabs: {
                profilePicture: 'Foto do perfil',
                personalDetails: 'Detalhes pessoais',
                preferences: 'Preferências',
                notifications: 'Configurações de notificação',
                taxInformation: 'Informação de impostos',
                premiumSubscription: 'Sua assinatura'
            },
            viewPersonalInfo: {
                title: 'Detalhes pessoais',
                name: 'Nome',
                email: 'E-mail',
                birthDate: 'Data de nascimento'
            },
            loginForm: {
                signup: 'Inscrever-se',
                next: 'Próximo',
                code: 'Código',
                codeSent: 'Código enviado',
                phoneNumber: 'Número de telefone',
                tosAndPrivacyConsentText: [
                    { text: 'Ao fazer login, você concorda com nossos ', shade: 'light' },
                    { text: 'Termos de Serviço', shade: 'dark', id: 'tos' },
                    { text: ' e ', shade: 'light' },
                    { text: 'Política de Privacidade', shade: 'dark', id: 'privacy' },
                    { text: '.', shade: 'light' }
                ],
                verificationCodeSentMessage: 'Enviamos um código de verificação para seu endereço de e-mail. Por favor, insira o código aqui.',
                confirmPassword: 'Confirme a Senha',
                logIn: 'Conecte-se',
                startTradingMessage: 'Começar a negociar',
                getStarted: 'Iniciar',
                wantMore: 'Quer mais?',
                startTrading: (qsi: string) => `Comece a negociar ${qsi}`,
                couldNotLogIn: 'Desculpe, houve um problema ao fazer seu login',
                couldNotResetYourPassword: 'Desculpe, não foi possível redefinir sua senha',
                username: {
                    email: 'E-mail'
                },
                confirmEmployeeEmail: {
                    title: 'Aviso de login do funcionário',
                    subtitle:
                        'Os funcionários da StoneX são incentivados a usar um e-mail não corporativo para acessar o StoneX One. Se continuar, você será direcionado ao nosso portal de login online.',
                    androidInstructionsTitle: 'Observação para usuários do Android',
                    androidInstructions:
                        'Os usuários do Android são aconselhados a copiar o código de verificação do aplicativo Okta Verify antes de continuar, optar por inserir um código quando solicitado e colar o código quando solicitado.',
                    continueAnyway: 'Continue de qualquer maneira'
                },
                confirmSignup: {
                    newUserTitle: 'Bem-vindo ao StoneX One!',
                    newUserSubtitle: (email: string) => `Parece que não temos o e-mail ${email} em nosso sistema, continuar se inscrevendo?`,
                    returningUserTitle: 'Bem-vindo de volta ao StoneX One!',
                    returningUserSubtitle: `Parece que você não conseguiu completar o registro quando tentou se inscrever anteriormente. Precisaremos que você reinsira algumas informações e verifique seu e-mail. Você gostaria de continuar?`
                },
                password: {
                    forgotPassword: 'Esqueceu sua senha',
                    resetPassword: 'Redefinir senha',
                    recoverAccount: 'Recuperar a conta',
                    password: 'Senha'
                },
                mfa: {
                    title: 'Verificação multifatorial',
                    sendingCodePleaseWait: 'Enviando código, aguarde',
                    mfaCodeSendMessage: 'Enviamos um código de verificação para o seu número de telefone. Por favor insira o código aqui.',
                    pleaseEnterYourPhone: 'Precisamos do seu número de telefone para autenticá-lo. Por favor, digite seu número de telefone abaixo.'
                },
                bioauth: {
                    biometrics: 'Biometria',
                    whatBiometricsAre: 'Gostaria de utilizar um fator biométrico (impressão digital, rosto, etc.) para acessar sua conta?',
                    allUnlocksTitle: 'Todos os Desbloqueios',
                    allUnlocksDescription: 'Solicitaremos seu fator biométrico sempre que você iniciar o StoneX One',
                    verificationOnlyTitle: 'Somente verificação',
                    verificationOnlyDescription: 'Solicitaremos seu fator biométrico somente quando precisarmos verificar sua identidade',
                    disableTitle: 'Desativar', // Variant for config screen
                    disableDescription: 'Limparemos seu perfil biométrico do StoneX One e nunca solicitaremos seu fator biométrico',
                    enableTitle: 'Habilitar biometria',
                    enableDescription: 'Autenticação biométrica será usada para acessar StoneX One',
                    notDesiredTitle: 'Não, não use biometria',
                    notDesiredDescription: 'O acesso não será protegido com biometria (não recomendado)',
                    bioEnabledTitle: 'Biometria habilitada',
                    bioEnabledDescription: 'Sua conta está protegida com autenticação biométrica',
                    bioDisabledTitle: 'Biometria desativada',
                    bioDisabledDescription: 'O StoneX One não usará autenticação biométrica ao tentar fazer login (não recomendado)',
                    notpermitted: {
                        title: 'Biometria não ativada',
                        message: 'Ative a biometria nas configurações para ativar o login com identificação facial ou impressão digital.',
                        link: 'Habilitar',
                        dismiss: 'Agora não'
                    },
                    disableBiometrics: 'Desativar Biometria',
                    yesUpdateBiometrics: 'Sim, atualizar Biometria',
                    passwordUpdateSuccess: 'Senha atualizada com sucesso!',
                    confirmReenableBioAuth: 'Gostaria de reativar o login biométrico com a sua nova senha?'
                },
                country: {
                    countryOfCitizenship: 'País de cidadania'
                },
                passwordReset: {
                    resetSent: 'Mensagem de Recuperação Enviada',
                    instructions: 'Enviamos um e-mail com mais etapas para ajudá-lo a recuperar o acesso à sua conta.',
                    returnToLogin: 'Retornar para Login'
                },
                whiteLabelAlert: {
                    title: 'Nenhuma conta encontrada',
                    message: 'Entre em contato com seu representante de vendas ou com o suporte se você acreditar que isso é um erro.',
                    dismiss: 'Liberar'
                }
            },
            profile: 'Perfil',
            firstName: 'Primeiro nome',
            lastName: 'Último nome',
            emailAddress: 'Endereço de e-mail',
            language: 'Linguagem',
            theme: 'Tema',
            themeDescription: 'A preferência do sistema seguirá o tema do seu dispositivo e o horário do mercado ficará escuro quando o mercado não estiver aberto.',
            oneClickTrading: 'Negociação com um clique',
            oneClickTradingDescription:
                'A negociação com um clique permite que você faça uma negociação com um clique sem ter que revisar a negociação. Você pode desativar a negociação com um clique para continuar a ver a tela de revisão antes de fazer uma negociação.',
            advancedChartTradeTicket: 'StoneX One Advanced Chart Trade Ticket',
            advancedChartTradeTicketDescription:
                'Habilite o tíquete de negociação padrão StoneX One no Gráfico Avançado para que você possa fazer pedidos para quaisquer ativos nos gráficos avançados, incluindo moedas criptográficas, pedidos de quantidades fracionárias ou pedidos de moedas criptográficas na denominação de dólar americano.',
            oneProNetwork: 'Uma Rede Pro',
            oneProNetworkDescription:
                'One Pro Network oferece análises aprofundadas, alertas de mesa em tempo real e vídeos informativos sobre ações, commodities, moedas e macrotendências, todos preparados por analistas StoneX. Essas informações abrangentes permitem que você tome decisões comerciais mais informadas.',
            futuresStrikes: 'Greves da Cadeia de Opções de Futuros',
            optionsChainStrikes: 'Greves em cadeia de opções',
            systemDefault: 'Sistema padrão',
            dockSidePanel: 'Encaixar pedidos abertos e lista de observação',
            tradingViewTicker: 'Barra de ticker',
            tradingViewTickerDescription:
                'A barra de ticker permite que você tenha uma visão horizontal de estatísticas importantes de instrumentos, com tecnologia do TradingView. Inclui o preço mais recente dos símbolos, além da alteração diária. Alguns preços podem sofrer atrasos de 15 minutos ou mais.',
            dockSidePanelDescription:
                'O painel lateral de ordens abertas e lista de observação permite que você visualize suas ações e opções abertas, fundos mútuos e ordens de futuros, bem como sua lista de observação. Observe que isso não aparecerá nas telas Resumo ou Conta.',
            timezone: 'Fuso horário',
            edit: 'Editar',
            smsNumber: 'Número de telefone SMS',
            email: 'E-mail',
            smsPhone: 'Telefone SMS',
            notificationsDescription: 'Informe-nos como e quando você prefere que entremos em contato para mensagens não essenciais abaixo.',
            sendEmail: 'Mande-me um email',
            sendText: 'Me envie um texto',
            addPhoneNumber: 'Adicionar número de telefone',
            verificationNeeded: 'Verificação Necessária',
            profilePictureDisclaimer: (maxMb: number) =>
                `Sua foto de perfil selecionada ficará visível publicamente e está sujeita aos nossos termos de uso. Selecione uma imagem menor que ${maxMb} MB no tamanho.`,
            removeProfilePicture: 'Remover imagem atual',
            imageTooLarge: (maxMb: number) => `Desculpe, sua imagem é muito grande. Selecione uma imagem menor que ${maxMb}MB`,
            unexpectedUploadError: 'Ocorreu um problema inesperado ao atualizar sua foto',
            idType: {
                idType: 'Tipo de ID',
                alienId: 'Identificação estrangeira',
                governmentId: 'Identidade Governamental, Registro Geral',
                passport: 'Passaporte',
                ssn: 'Número da Segurança Social',
                taxIdEin: 'CNPJ/EIN dos EUA'
            },
            mdcVerify: {
                email: {
                    title: 'Atualizar e-mail',
                    inputText: 'Digite seu novo endereço de e-mail abaixo.',
                    tryDifferent: 'Use um endereço de e-mail diferente',
                    successText: 'Endereço de e-mail verificado e atualizado!'
                },
                phone: {
                    title: 'Atualizar telefone',
                    phone: 'Telefone',
                    phoneNumber: 'Número de telefone',
                    inputText: 'Digite seu novo número de telefone abaixo.',
                    tryDifferent: 'Usar um número de telefone diferente',
                    successText: 'Número de telefone verificado e atualizado!'
                },
                pin: 'PIN',
                badPin: 'Desculpe, esse PIN não é reconhecido',
                resendPin: 'Reenviar PIN de verificação',
                unableToSendPin: 'Não foi possível entregar seu código',
                unableToSendPinInstructions: (num) =>
                    `Parece que seu número de telefone optou por não receber nossas mensagens de texto. Por favor, envie START para ${FormatNumber.toPhone(
                        num
                    )} e tente novamente.`,
                tryAgain: 'Tente novamente',
                unexpectedError: 'Desculpe, encontramos um erro inesperado',
                alreadyHaveAVerificationCode: 'Já tem um PIN de verificação?',
                clickHereToEnterYourCode: 'Clique aqui para inserir seu PIN',
                updateButton: 'Atualizar',
                verifyButton: 'Verificar',
                pinSentPleaseEnter: 'PIN de verificação enviado. Digite o PIN que você recebeu abaixo.',
                toRecieveTextNotifications: 'Para receber notificações por texto, adicione seu número de telefone.',
                addPhoneNumber: 'Adicionar número de telefone'
            },
            taxId: 'CPF',
            dateOfBirth: 'Data de nascimento',
            birthYear: 'Ano de Nascimento',
            birthMonth: 'Mês',
            birthDay: 'Dia',
            whyDoWeNeedThis: 'Por que nós precisamos disso?',
            taxInfoExplainer:
                'Usamos seu ID e data de nascimento para determinar seu relacionamento com diferentes contas, permitindo que você gerencie suas preferências de entrega sem papel e financie suas contas. Essas informações também são usadas para verificar sua propriedade de contas adicionais. Sem essas informações, não poderemos fornecer a você acesso total à conta.',
            toUpdateThisInformation: 'Para atualizar essas informações, entre em contato com o Suporte StoneX One',
            okay: 'OK',
            save: 'Salvar',
            saving: 'Salvando...',
            saved: 'Salvou!',
            mdcControls: {
                notificationSettings: 'Configurações de notificação',
                notificationSettingsToolTips: {
                    missingPhone: 'Adicione um número de telefone válido para ativar este método de entrega',
                    invalidEventOrDeliveryMethod: 'As mensagens para este evento e método de entrega não estão disponíveis'
                },
                email: 'E-mail',
                sms: 'SMS',
                smsSettings: 'Configurações de SMS',
                notifications: 'Notificações',
                pushNotifications: 'Notificações via push',
                events: {
                    newOrderIsPlaced: 'Novo pedido foi feito',
                    orderIsFilled: 'O pedido está preenchido',
                    orderIsPartiallyFilled: 'O pedido está parcialmente preenchido',
                    orderIsCanceled: 'O pedido foi cancelado',
                    orderIsRejected: 'O pedido foi rejeitado',
                    orderIsModified: 'Pedido é modificado',
                    orderIsStopped: 'Order Is Stopped',
                    orderIsSuspended: 'Order Is Suspended',
                    orderIsOpenForBidding: 'Order Is Open For Bidding',
                    customAlertFired: 'Alerta personalizado é acionado',
                    optionExpiringSoon: 'Opção prestes a expirar'
                }
            },

            managePremium: {
                cancel: 'Cancel Premium Subscription',
                canceled: (exp: string) => `You have canceled your subscription. You will have access to premium until ${exp}.`,
                cancelModalText: (exp: string) =>
                    `Are you sure you want to cancel premium?\n\nYou can choose to undo your cancelation any time before ${exp} to keep your premium.`,
                confirmCancelation: 'Confirm Cancelation',
                get: 'Get Premium',
                loading: 'One Moment Please...',
                pitch: `Listen to earnings calls, check up your favorite stocks' top investors, and analyze the full financial profile of thousands of financial products for just ${FormatNumber.toMoney(
                    PremiumProducts.premium.price
                )} per month.`,
                reactivate: 'Reactivate / Manage',
                subscribed: (exp: string) =>
                    `Congratulations! You are subscribed to StoneX One Premium. Your next bill of ${FormatNumber.toMoney(
                        PremiumProducts.premium.price
                    )} will be issued on ${exp}`
            }
        },
        accounts: {
            accounts: 'Contas',
            top: 'Topo',
            addNewAccount: 'Adicionar nova conta',
            accountApplicationStatus: 'Status do aplicativo da conta',
            accountInformation: {
                accountInformation: 'Informação da conta',
                acctHolderInformation: 'Informações do titular da conta',
                accountName: 'Nome da conta',
                address: 'Endereço',
                altPhone: 'Telefone Alternativo',
                dateOfBirth: 'Data de nascimento',
                email: 'Endereço de e-mail',
                foreignId: 'Identificação estrangeira',
                homePhone: 'Telefone residencial',
                taxId: 'CPF'
            },
            registration: {
                registration: 'Cadastro',
                accountType: 'tipo de conta',
                lastStatement: 'Última declaração',
                institutionCode: 'Código da Instituição',
                restrictionCode: 'Código de restrição',
                accountStatus: 'Status da Conta',
                accountOpened: 'Conta aberta'
            },
            setup: {
                setup: 'Configuração',
                marginAgreementOnFile: 'Contrato de margem em arquivo',
                fplAgreementsOnFile: 'Acordos FPL em arquivo',
                principalPayments: 'Pagamentos principais',
                dividendReinvest: 'Reinvestimento de dividendos',
                cashStandingInstructions: 'Instruções de caixa',
                marginStandingInstructions: 'Instruções de Margem Permanente',
                dividendStandingInstructions: 'Instruções Permanentes de Dividendos',
                calls: 'Chamados',
                puts: 'Coloca',
                price: 'Preço',
                noOptionsContracts: 'Nenhum contrato de opção está disponível para este título no momento.',
                optionsLevel: {
                    optionsLevel: 'Permissões de negociação de opções',
                    disallowed: 'Não permitido',
                    level1: 'Chamadas Cobertas', // Covered calls
                    level2: 'Compra de chamadas + colocações', // Purchase Calls + Puts
                    level3: 'Spreads / Travessas Longas', // Spreads / Long Straddles
                    level4: 'Escrita de ações', // Equity Put Writing
                    level5: 'Escrita de chamada descoberta + escrita de colocação de índice / straddles curtos', // Uncovered call writing + Index put writing / short straddles
                    level6: 'Opções de índice descobertas' // Uncovered index options
                }
            },
            investmentProfile: {
                investmentProfile: 'Perfil de investimento',
                investmentObjective: 'Objetivo de Investimento',
                netWorth: 'Patrimônio líquido',
                federalTaxBracket: 'Suporte de imposto federal',
                liquidityNeeds: 'Necessidades de Liquidez',
                sourceOfFunds: 'Fonte de renda',
                occupation: 'Ocupação',
                stockExperience: 'Experiência em Estoque',
                bondExperience: 'Experiência de vínculo',
                optionExperience: 'Experiência de opção',
                mutualFundExperience: 'Experiência de Fundos Mútuos',
                annuityExperience: 'Experiência de anuidade',
                investmentTimeHorizon: 'Horizonte de tempo de investimento'
            },
            accountDetails: {
                title: 'Detalhes da conta',
                type: 'Tipo',
                accountNumber: 'Número de conta',
                accountName: 'Nome da conta',
                rename: 'Renomear',
                startMargin: 'Comece a usar a margem',
                optionsTrading: 'Negociação de Opções',
                optionsEnabled: 'Opções habilitadas',
                optionsLevelsForPendingAcct: 'Assim que sua conta for aprovada, você terá acesso à negociação de opções.',
                youveEnabledOptions: 'Você ativou opções para esta conta.',
                optionsNotEnabledDesc:
                    'Esta conta não está habilitada para negociação de opções. A negociação de opções permite que você negocie contratos se achar que o preço de um ativo aumentará ou diminuirá.',
                marginAgreementAddedDesc:
                    'Parece que você adicionou um contrato de margem a esta conta. A margem dá acesso a mais estratégias de opções, ativos especulativos como futuros e muito mais.',
                accountDoesNotHaveMarginDesc:
                    'Esta conta não possui um contrato de margem registrado. A margem dá acesso a mais estratégias de opções, ativos especulativos como futuros e muito mais.',

                corporateActionDesc: 'As ações corporativas são ações tomadas por uma empresa que impactam diretamente o valor dos acionistas.',
                corporateActionsAvailable: 'Ações Corporativas Disponíveis',
                corporateActions: 'Ações corporativas',
                viewCorporateActions: 'Ver Ações Corporativas',
                noCorporateActionsAvailable: 'Nenhuma ação corporativa disponível',
                enableOptions: 'Ativar negociação de opções',
                gotoAccount: 'Ir para conta',
                closeAccount: 'Fechar conta',
                contactUsToCloseAccount: 'Entre em contato conosco para fechar sua conta',
                options: 'Opções',
                margin: 'Margem',
                changeOptionLevel: 'Alterar Nível de Opção',
                marginEnabled: 'Margem Ativada',
                unableToFindInformation: 'Parece que não conseguimos encontrar informações para sua conta. Me desculpe por isso!',
                ohNo: 'Oh não!',
                execute: 'Você pode executar o seguinte:',
                useButtonModify: 'Use o botão acima para modificar.',
                renameAccount: 'Renomear conta',
                cancel: 'Cancelar',
                general: {
                    general: 'Em geral',
                    dateOpened: 'Data de abertura',
                    lastStatement: 'Última declaração',
                    status: 'Status',
                    restrictionCode: 'Código de restrição'
                }
            }
        },
        paperless: {
            goPaperless: 'Fique sem papel',
            paperless: 'Sem papel',
            confirms: 'Confirma',
            stonexCommunications: 'Comunicações StoneX',
            investorRelations: 'Relações com Investidores',
            prospectus: 'Prospecto',
            statements: 'Afirmações',
            taxDocuments: 'Documentos fiscais',
            goCompletelyPaperless: 'Vá completamente sem papel',
            goPaperlessLine1:
                'Pronto para ficar totalmente sem papel? Pressione o botão acima para receber todos os documentos da sua conta por e-mail em vez de por correio.',
            goPaperlessLine2: 'Ou, personalize as opções de entrega de suas contas abaixo.',
            completelyPaperlessExplain: 'Receba todos os documentos por e-mail e ajude o meio ambiente ao mesmo tempo.',
            allMail: 'Todo o correio',
            allEDelivery: 'Todos os e-mails',
            allElectronic: 'Todos Eletrônicos',
            mail: 'Correspondência',
            edelivery: 'o email',
            paperlessButton: 'Você está 100% sem papel!',
            customizeOptions: 'Personalize as opções de entrega de suas contas abaixo.',
            youArePaperless: (percent: string) => `Você está ${percent} sem papel`,
            paperlessPopup: {
                title: 'Por favor, confirme',
                message: 'Isto irá atualizar toda a sua contas para não entregar mais o seu documentos pelo correio. Continuar?',
                confirm: 'Sim, vá sem papel'
            }
        },

        bioauth: {
            biometrics: 'Biometria',
            whatBioauthIs: 'O login biométrico permite que você faça login rapidamente usando fatores biométricos, como seu rosto ou impressão digital.',
            bioauthIsCurrentlyOnOff: (on: boolean) => [
                { text: 'O login biométrico está ', shade: 'light' },
                { text: on ? 'ativado ' : 'desativado ', shade: 'dark' },
                { text: 'no momento.', shade: 'light' }
            ],
            clearMyDataAndTurnBioauthOff: 'Limpar meus dados de login e desativar o login biométrico',
            turnBioauthOn: 'Ativar login biométrico',

            whatBiounlockIs:
                'Se você tiver o login biométrico ativado, poderá impedir que outras pessoas usem seu login exigindo seu fator biométrico para desbloquear o StoneX One',
            biounlockIsCurrentlyOnOff: (on: boolean) => [
                { text: 'O desbloqueio biométrico está ', shade: 'light' },
                { text: on ? 'ativado ' : 'desativado ', shade: 'dark' },
                { text: 'no momento.', shade: 'light' }
            ],
            turnBiounlockOff: 'Desative o desbloqueio biométrico',
            turnBiounlockOn: 'Ativar desbloqueio biométrico',

            errors: {
                unableToLogYouIn: 'Desculpe, não foi possível fazer seu login.',
                somethingWentWrong: 'Desculpe, algo deu errado.',
                couldNotVerifyYourIdentity: 'Desculpe, não foi possível verificar sua identidade.'
            },
            verifyYourLoginToActivate: 'Verifique seu login para ativar a biometria',
            verify: 'Verificar',
            email: 'E-mail',
            password: 'Senha'
        },

        changePassword: {
            changeFailedTitle: 'Atualização falhou',
            changeFailedMessage: 'Lamentamos, mas não foi possível atualizar sua senha. Tente novamente ou entre em contato com o suporte abaixo',
            validationFailedTitle: 'Atualização falhou',
            validationFailedMessage:
                'Algumas entradas estão faltando ou são inválidas. Por favor, certifique-se de ter digitado sua senha existente e de que digitou sua senha correctamente nas duas vezes.',
            authenticationFailedTitle: 'Não é possível confirmar a identidade',
            authenticationFailedMessage: 'A senha atual que você digitou está incorreta',
            tryAgain: 'Tente novamente',
            contactSupport: 'Entre em contato com o suporte',
            changePassword: 'Alterar a senha',
            forgotPassword: 'Esqueceu sua senha',
            currentPassword: 'Senha atual',
            newPassword: 'Nova Senha',
            confirmPassword: 'Confirme sua senha',
            updatePassword: 'Atualizar senha',
            passwordMinLength: (minLength: number) => `A senha deve ter pelo menos ${minLength} caracteres`,
            passwordLetter: 'A senha deve conter pelo menos uma letra',
            passwordRequirementsMessage: (minLength: number) =>
                `A senha deve ter no mínimo ${minLength} caracteres e conter pelo menos uma letra maiúscula, uma letra minúscula e um número.`,
            cannotReuseOldPassword: 'A nova senha não deve corresponder à senha existente',
            newPasswordConfirmMismatch: 'Nova senha e confirmação não correspondem',
            passwordRequirements: 'Requisitos de senha',
            passwordRecoveryRequirements: (minLength: number) => [
                `Deve ter no mínimo ${minLength} caracteres`,
                'Deve conter no mínimo uma letra maiúscula',
                'Deve conter no mínimo um número'
            ]
        }
    },
    documentsScreen: {
        documents: 'Documentos',
        filters: 'Filtros',
        name: 'Nome',
        date: 'Data',
        account: 'Conta',
        preview: 'Visualizar',
        download: 'Download',
        share: 'Compartilhar',
        moreActions: 'Mais ações',
        viewDocument: 'Ver Documento',
        allAccounts: 'Todas as contas',
        downloading: 'Downloading...',
        saveToDownloads: 'Salvar em downloads',
        documentNotFound: 'Não há arquivo para esta data.',
        docTypes: {
            taxDocuments: 'Documentos Fiscais',
            intlPublicForms: 'INTL Formulários Públicos',
            ifsNewAccts: 'IFS NEWACCTS',
            marginRequirements: 'Requisitos de Margem do Cliente',
            tradeConfirmations: 'Confirmações comerciais',
            checks: 'Verificações',
            accountStatements: 'Extratos de conta',
            accountNotices: 'Avisos de conta',
            detailedVariationAnalysis: 'Análise de variação detalhada',
            moneyOptionReport: 'Relatório de opções de dinheiro',
            memoStatement: 'Declaração de memorando',
            monthlyDiscretionaryAccounts: 'Contas discricionárias mensais',
            annualStatement: 'Extrato anual',
            quaterlyStatement: 'Extrato trimestral',
            monthlyStatementExcel: 'Extrato Mensal Excel',
            monthlyStatement: 'Extrato mensal',
            dailyStatement: 'Extrato Diário',
            fullyPaidEarnings: 'Ganhos totalmente pagos',
            fullyPaidLendingConfirms: 'Confirma empréstimos totalmente pagos',
            fullyPaidLendingPositions: 'Posições de empréstimo totalmente pagas'
        },
        downloadToasts: {
            savingUnavailableTitle: 'Salvando indisponível',
            savingUnavailableDesc: 'Desculpe, salvar não está disponível no seu dispositivo.',
            permissionDenied: 'Permissão negada',
            allowStonexSaveFiles: 'Por favor, permita que StoneX One salve arquivos em seu dispositivo.',
            fileSavedTitle: 'Arquivo salvo',
            fileSavedInDevice: (foldername: string) => `Seu documento foi salvo na pasta ${foldername} do seu dispositivo`,
            savingErrorTitle: 'Erro ao salvar',
            problemDownloading: 'Desculpe, ocorreu um problema ao baixar seu arquivo.'
        },
        docNames: {
            statement: (doc: SnexDocument) => `${doc.accountNumber} ${SafeFormat(doc.documentDate, 'MMMM')} Declaração`,
            check: (doc: SnexDocument) => doc.name,
            misc: (doc: SnexDocument) => doc.name,
            taxes: (doc: SnexDocument) => `${doc.accountNumber} ${SafeFormat(doc.documentDate, 'yyyy')} Documento Fiscal`
        },
        symbol: 'Símbolo',
        type: 'Tipo',
        noDocumentsTitle: 'Nenhum documento encontrado',
        noDocumentsDescription: 'Não encontramos nenhum documento que corresponda aos filtros abaixo.',
        searchDocuments: 'Pesquisar documentos',
        goPaperless: 'Fique sem papel',
        modal: {
            loading: 'Recuperando Documento...',
            untitled: 'Documento sem título'
        }
    },

    tradeHistory: {
        tradeHistory: 'Histórico de pedidos',
        filters: 'Filtros',
        account: (accountNumber?: string) => (accountNumber ? `Conta #${accountNumber}` : 'Conta'),
        allAssetClasses: 'Todos os produtos',
        equities: 'Ações e Opções',
        futures: 'Futuros e Opções',
        futuresOptions: 'Opções de Futuros',
        mutualFunds: 'Fundos Mútuos',
        allAccounts: 'Todas as contas',
        futuresTimeSpreads: 'Spreads de Futuros',
        cryptos: 'Criptomoedas',
        invalidFilters: {
            popoverHeader: 'Filtros Inválidos',
            popoverBody: 'Alguns dos seus filtros se cancelam mutuamente. Revise seus filtros e tente a pesquisa novamente.',
            popoverActionLabel: 'Ok'
        },
        status: {
            status: 'Status',
            assetClass: 'produtos',
            all: 'Todo',
            allStatuses: 'Todos os status',
            openAll: 'Todos abertos',
            closed: 'Fechadas',
            closedAll: 'Todos fechadas',
            verification: 'Verificação',
            created: 'Criado',
            open: 'Abertas/Trabalhando',
            route: 'Rota',
            routed: 'Encaminhado',
            cancel: 'Cancelar',
            canceled: 'Cancelado',
            filled: 'Preenchidos',
            rejected: 'Rejeitado',
            deleted: 'Excluído',
            expired: 'Expirado',
            confirmed: 'Confirmado',
            unknown: 'Desconhecido',
            pending: 'Pendente',
            completed: 'Concluído',
            cancelled: 'Cancelado',
            cancelPending: 'Cancelar pendente',
            cancelPartialFill: 'Parcialmente cancelado',
            allocated: 'Alocado',
            preAllocated: 'Pré-alocado'
        },
        orders: 'Pedidos',
        symbol: 'Símbolo',
        downloadTooltip: 'Baixar tudo',
        accountTitle: 'Cuenta',
        fetchingError: 'Desculpe! Tivemos alguns problemas para buscar seu histórico de trocas.',
        noOrdersFound: 'Nenhum pedido encontrado',
        noOrdersFoundDescription: 'Não encontramos nenhum pedido correspondente aos filtros abaixo',
        downloadFilename: () => `histórico -comercial-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`
    },
    transactionsScreen: {
        tooltips: {
            partialResults: 'Resultados parciais retornados'
        },
        account: 'Conta',
        transactions: 'Transações',
        symbolId: 'Símbolo',
        date: 'Data',
        type: 'Tipo',
        anyType: 'Qualquer tipo',
        amount: 'Montante',
        description: 'Descrição',
        noTransactionsTitle: 'Nenhuma transação disponível',
        noTransactionsDesc: 'Suas negociações, transferências, dividendos, juros e todas as outras atividades da conta aparecerão aqui.',
        sendingOrder: 'Enviando Pedido..',
        tradeDescription: (m: Transaction) => {
            const verb = (() => {
                if (m.action?.toUpperCase() === 'COMPRAR') return 'Comprado';
                else if (m.action?.toUpperCase() === 'VENDER') return 'Vendido';
                else return m.action;
            })();
            const unit = Snex1Portuguese.general.unit(m.symbol, m.quantity, m.securityType);
            const decimalPlaces = `${m?.price}`.split('.')[1]?.length;
            return [SecurityTypes.Futures, SecurityTypes.FuturesOptions].includes(m?.securityType)
                ? `${verb} ${FormatNumber.toDecimal(m.quantity, 3)} ${m.description}`
                : `${verb} ${FormatNumber.toDecimal(m.quantity, 3)} ${unit} do ${m.symbol} @ ${FormatNumber.toLocaleDecimal({
                      decimalPlaces,
                      value: m.price
                  })}`;
        },
        expand: 'Expandir',
        action: 'Açao',
        quantity: 'Quantidade',
        price: 'Preço',
        baseCurrency: 'Moeda base',
        accountType: {
            accountType: 'tipo de conta',
            cash: 'Caixa',
            longMargin: 'Margem (Grandes)',
            shortMargin: 'Margem (Curto)',
            annuitiesAway: 'Anuidades ausentes',
            stockLoan: 'Empréstimo de Ações',
            tefraWithhold: 'Retenção TEFRA',
            whenIssued: 'Quando emitido'
        },
        securityType: 'Tipo de segurança',
        asOfDate: 'Data de início',
        tradeDate: 'Data de negociação',
        settleDate: 'Data de liquidação',
        principal: 'Capital',
        interest: 'Interesse',
        cancelStatus: 'Status de cancelamento',
        rebillStatus: 'Status de cobrança',
        controlNumber: 'Número de controle',
        securityNumber: 'Número de segurança',
        security: 'Segurança',
        exportAll: (accountNumber: string) => `Exportar todas as transações para ${accountNumber}`,
        filename: (accountNumber: string) => `transações-${accountNumber}-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`,
        filters: {
            filters: 'Filtros',
            sort: 'Ordenar',
            date: 'Data'
        },
        processDate: 'Data processada',
        netAmount: 'Valor líquido',
        defaultSort: 'Classificação padrão',
        ascending: 'Ascendente',
        descending: 'descendente',
        search: 'Procurar',
        all: 'Todo',
        swipeUpSubmit: 'Deslize para cima para enviar',
        activityType: {
            tradeActivity: 'Atividade comercial',
            cashAndEquivalents: 'Equivalentes em Caixa',
            dividendsAndInterest: 'Dividendos e Juros',
            options: 'Opções',
            receiveAndDeliver: 'Receber e entregar',
            exerciseAndExpiration: 'Exercício e expiração',
            stockLoans: 'Empréstimo de ações',
            misc: 'Diversos',
            deposit: 'Depósito',
            withdrawal: 'Cancelamento',

            trade: 'Troca',
            cash: 'Caixa',
            dividentInterest: 'Dividendo / Juros',
            exerciseExpire: 'Exercício / Vencimento',
            receiveDeliever: 'Receber / Entregar',
            receiveDelieverAbbrev: 'Rec / Entr',
            funding: 'Financiamento',
            other: 'Outro'
        }
    },
    projectedIncomeScreen: {
        projectedIncome: 'Receita projetada',
        symbol: 'Símbolo',
        saveFilter: 'Salvar filtro',
        reset: 'Redefinir',
        accountNumber: 'Número de conta',
        viewAll: 'Ver tudo',
        pleaseSelectAnAccount: 'Selecione uma conta para ver sua receita projetada',
        noDataAvailable: 'Não há dados disponíveis para a conta selecionada',
        name: 'Nome',
        income: 'Renda',
        yield: 'Colheita',
        paymentDate: 'Data de pagamento',
        nextPaymentDate: 'Próximo pagamento',
        maturityDate: 'Data de vencimento',
        frequency: {
            frequency: 'Frequência',
            semiAnnually: 'Semi anualmente',
            quarterly: 'Trimestral',
            monthly: 'Por mês',
            annually: 'Anualmente'
        },
        projectedTotal: 'Total projetado',
        projectedFor: (qsi: string) => `Projetado para ${qsi}`,
        couponRate: 'Taxa de cupom',
        type: 'Tipo',
        downloadTooltip: 'Baixe o Icome projetado',
        downloadFilename: () => `renda projetada-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`,
        overall: 'No geral',
        selectAnAccountEmptyTitle: 'Sua renda projetada será exibida aqui. Selecione uma conta para visualizar.',
        incomeBreakdown: 'Detalhamento da renda',
        totalProfits: 'Total de lucros para o período:',
        noIncomeForMonth: 'Nenhuma receita projetada para',
        selectAnotherPeriod: 'Selecione outro período no gráfico acima para visualizar sua receita projetada.',
        noIncomeProjection: 'Nenhuma projeção de renda disponível',
        noPositionSecurity: 'Parece que esta conta não tem posição em nenhum título que pague dividendos.'
    },
    disclosuresPage: {
        disclosures: 'Divulgações',
        documentContent: {
            pricingDisclosure: [
                'As posições são precificadas a partir da última negociação. Se um título de ações não foi negociado hoje, as informações de preço são baseadas no melhor preço de oferta do dia útil anterior. O lance ou pedido atual pode ser diferente do preço exibido'
            ],
            projectedIncomeDisclosure: [
                'A receita projetada e a rentabilidade projetada para certos tipos de títulos podem incluir um retorno do principal ou ganhos de capital, caso em que a receita projetada e a rentabilidade projetada seriam exageradas.',
                'A Receita Projetada e o Rendimento Projetado são estimativas e a receita e o rendimento reais podem ser menores ou maiores do que os valores estimados',
                'Projected Yield reflects only the income generated by an investment. It does not reflect changes in its price, which may fluctuate.'
            ],
            marketIntelligenceDisclosure: [
                {
                    text: 'Nada aqui contido deve ser interpretado como conferindo ao Assinante ou a terceiros qualquer licença ou direito ilimitado, por implicação, preclusão ou de outra forma, sob qualquer lei (seja lei comum ou lei estatutária), regra ou regulamento, incluindo, sem limitação, aqueles relacionados a direitos autorais ou outros direitos de propriedade intelectual.'
                },
                {
                    text: 'Este relatório e seu conteúdo não devem ser considerados aconselhamento financeiro. Este relatório e seu conteúdo são disponibilizados ao Assinante para fins informativos e não substituem a obtenção de aconselhamento profissional de uma pessoa, empresa ou corporação qualificada, quando necessário. A exatidão, integridade, adequação ou pontualidade deste relatório e seu conteúdo não são garantidos ou garantidos. O Assinante reconhece que o relatório e seu conteúdo podem ser produtos de trabalho utilizando, incorporando ou referindo-se a informações de terceiros.'
                },
                {
                    text: 'Qualquer uso não autorizado deste relatório e seu conteúdo é totalmente proibido. Este relatório e seu conteúdo são para uso pelo Assinante apenas de acordo com sua assinatura e não pode copiar, modificar, reproduzir, republicar, distribuir, exibir ou transmitir para fins comerciais, sem fins lucrativos ou públicos, todo ou qualquer parte dele, e qualquer outra finalidade em seu benefício ou em benefício de terceiros. Para evitar dúvidas, o Assinante não pode usar este relatório e qualquer um de seus conteúdos para transmitir qualquer comunicação falsa, enganosa, fraudulenta ou ilegal.'
                },
                {
                    text: 'O StoneX Group Inc. se isenta de qualquer responsabilidade pela confiança, uso deste relatório e seu conteúdo para qualquer finalidade decorrente da assinatura, uso do Assinante e de terceiros.'
                }
            ]
        },
        documentTitles: {
            termsAndConditions: 'Termos e Condições',
            pricingDisclosure: 'Divulgação de preços',
            generalDisclosures: 'Divulgação geral',
            cryptoDisclosure: 'Divulgação de Criptomoedas',
            projectedIncomeAndYieldDisclosure: 'Renda projetada & rendimento',
            marketIntelligenceDisclosure: 'Divulgação de Market Intelligence'
        }
    },
    securityScreen: {
        premarket: 'Pré-mercado',
        postmarket: 'Pós-mercado',
        afterhours: 'Depois do expediente',
        advancedChart: 'Gráfico Avançado',
        notFoundHeader: (qsi: string) => `${qsi} Not Found`,
        notFoundSubText: 'Parece que não encontramos nenhuma informação sobre este símbolo.',
        searchForSomethingElse: 'Procure por outra coisa',
        advancedDomAndChart: 'DOM e gráfico avançados',
        deepAnalysis: 'Análise Profunda',
        tradeOptions: 'Opções de comércio',
        tradeFuturesSpreads: 'Negocie espalhars de futuros',
        enableOptionTrading: 'Ativar negociação de opções',
        changeOptionLevel: 'Alterar nível de opção',
        addToList: 'Adicionar à lista',
        notOnAnyOfYourLists: 'Não está em nenhuma das suas listas',
        lineChart: 'Gráfico de linha',
        candlestick: 'Gráfico de velas',
        showVolume: 'Mostrar Volume',
        tomorrow: 'amanhã',
        today: 'hoje',
        security: 'Segurança',
        securities: 'Títulos',
        viewAll: 'Ver tudo',
        viewAllSpreads: 'Ver todos os spreads',
        viewFewer: 'Ver menos',
        viewMore: 'Veja mais',
        viewLess: 'Ver menos',
        chartDataComingSoon: 'Dados gráficos em breve',
        problemLoadingSecuritiesFor: (qsi: string) => `Ocorreu um problema ao carregar títulos relacionados para ${qsi}`,
        noRelatedSecuritiesFor: (qsi: string) => `Nenhum título relacionado está disponível para ${qsi}`,
        about: {
            about: (symbol: string) => 'Sobre',
            noDescriptionAvailable: 'Nenhuma descrição disponível',
            readMore: 'Leia mais',
            readLess: 'Leia menos',
            ceo: 'CEO',
            employees: 'Funcionários',
            headquarters: 'Sede',
            website: 'Local na rede Internet',
            exchange: 'Intercâmbio',
            open: 'Aberto',
            unavailable: 'Indisponível',
            whitepaper: 'Papel branco',
            reddit: 'Reddit',
            coin: 'Criptomoeda'
        },
        stats: {
            stats: 'Estatísticas',
            specifications: 'Especificações',
            bid: 'Oferta',
            ask: 'Preguntar',
            open: 'Aberto',
            close: 'Fechar',
            previousClose: 'Fechamento anterior',
            previousSettle: 'Liquidação Anterior',
            high: 'Alta',
            low: 'Baixo',
            previousVolume: 'Volume anterior',
            volume: 'Volume',
            volume24h: 'Volume (24h)',
            averageVolume: 'Volume médio',
            averageVolume7d: 'Vol. médio (7d)',
            startTime: 'Hora de início',
            stopTime: 'Tempo de parada',
            endTime: 'Fim do tempo',
            contractSize: 'Tamanho do contrato',
            week52High: 'Alta de 52 semanas',
            week52Low: 'Baixa de 52 semanas',
            marketCap: 'Posição Atual',
            peRatio: 'Relação P/E',
            ytdChange: 'Mudança no acumulado do ano',
            tickSize: 'Tamanho do tick',
            tickPrice: 'Preço do Tick',
            initialMargin: 'Margem inicial',
            dayTradeMargin: 'Margem Day Trade',
            dayTradeMarginToolTip: 'A margem Day Trade está disponível das 7h45 às 15h30, horário central',
            maintenanceMargin: 'Margem de Manutenção',
            changePercent: 'Change %',
            low52: '52 Baixo',
            high52: '52 Alto'
        },
        ratings: {
            analystRatings: 'Classificações de analistas',
            asOf: 'A partir de',
            noRatingsAvailable: 'Sem classificações de analistas disponíveis',
            analystRatingExplain: (secName: string, action) => `Em média, os analistas avaliam atualmente ${secName} como um ${action}`,
            analystRatingExplainShaded: (secName: string, action: string) => {
                return [
                    {
                        shade: 'light',
                        text: 'Em média, os analistas avaliam atualmente'
                    },
                    { shade: 'dark', text: secName },
                    { shade: 'light', text: 'como um' },
                    { shade: 'dark', text: action }
                ];
            },
            aConsensusOf: (totalNumberOfRatings: number, consensusDate: string | Date) => [
                { text: 'Com base no consenso de ', shade: 'light' },
                {
                    text: `${FormatNumber.toCommas(totalNumberOfRatings)} Avaliação${totalNumberOfRatings === 1 ? '' : 's'}`,
                    shade: 'dark'
                },
                { text: ' reunido ', shade: 'light' },
                {
                    text: SafeFormat(consensusDate, 'dd MMM yyyy'),
                    shade: 'dark'
                }
            ],
            noRatingsDataAvailble: (secName: string) => `Não há classificações disponíveis para ${secName} no momento.`,
            problemLoadingRatings: (secName: string) => `Houve um problema ao carregar as classificações para ${secName}`,
            noRatingsAvailableFor: (secName: string) => `Nenhuma classificação está disponível para ${secName}`,
            buy: 'Comprar',
            sell: 'Vender',
            hold: 'Espera',
            totalRatings: 'Classificações Totais'
        },
        earningsEstimates: {
            earningsAndEstimates: 'Lucros & Estimativas',
            actual: 'Real',
            estimated: 'Estimado',
            actualEPS: 'Real EPS',
            estimatedEPS: 'Estimado EPS',
            quarter: (quarterNumber: number) => `Q${quarterNumber}`,
            noData: (securityName?: string) => `Não há dados de ganhos disponíveis para ${securityName || 'essa segurança'} neste momento.`,
            problemLoadingEarnings: (securityName: string) => `Ocorreu um problema ao carregar a linha do tempo de ganhos para ${securityName}`,
            noEarningsTimeline: (securityName: string) => `Nenhuma linha do tempo de ganhos está disponível para ${securityName}`
        },
        options: {
            options: 'Opções',
            optionsTitle: (symbol: string) => `${symbol} Opções`,
            optionsSublabel: 'Negociar opções de compra e venda',
            contracts: 'Contratos',
            value: 'Valor',
            todaysGainLoss: "Hoje's G/L",
            totalGainLoss: 'Total G/L',
            account: 'Conta',
            exp: 'Exp',
            contractsLong: (quantity: number) => `${FormatNumber.toCommas(quantity)} ${Snex1Portuguese.general.contracts(quantity)} longo`,
            contractsShort: (quantity: number) => `${FormatNumber.toCommas(quantity)} ${Snex1Portuguese.general.contracts(quantity)} curto`,
            unknownExpiration: 'Expiração desconhecida',
            contract: (n: number) => Snex1Portuguese.general.contracts(n),
            timeSpreadsTitle: (symbol: string) => `${symbol} Spreads de tempo`,
            timeSpreadsSublabel: 'Comprar e vender contratos em diferentes datas de expiração',
            noTimeSpreadsSublabel: 'Os spreads de tempo não estão disponíveis para esse contrato'
        },
        history: {
            history: 'História',
            tradeHistory: 'História do comércio',
            orderHistory: 'Ver histórico de pedidos',
            viewMoreHistory: 'Ver mais história'
        },
        related: {
            related: 'Relacionado',
            relatedEtfs: 'ETFs relacionados',
            noRelatedStocks: 'Sem títulos relacionados'
        },
        holdings: {
            account: 'Conta',
            accountsHolding: 'Contas em posse',
            acct: (number: string) => `Acct #${QualifiedId.Format(number)}`,
            assetsInFunds: '% Ativos no Fundo',
            averageCost: 'Custo Médio',
            cost: 'Custo',
            marketValue: 'Posição Atual',
            marketValueTooltip: 'Esses valores são baseados no dia anterior. Quaisquer pedidos preenchidos hoje não são levados em consideração',
            marketValueTooltipFutures: 'O valor de mercado é seus contratos de propriedade vezes o custo médio.',
            portfolio: 'Portfólio',
            portfolioDiversity: 'Alocação de portfólio',
            positionPrice: 'Preço de Posição',
            quantity: 'Quantidad',
            share: (n: number) => Snex1Portuguese.general.shares(n),
            shares: 'Ações',
            showAllAccounts: 'Mostre todas as contas',
            todaysReturn: 'P/L de hoje',
            topHoldings: 'Principais Participações',
            totalReturn: 'Retorno Total',
            viewMore: 'Ver mais',
            yourMarketValue: 'Seu Posição Atual',
            yourPosition: 'Sua Posição',
            buyMore: 'Compre mais',
            shortMore: 'Corto mais',
            sell: 'Vender',
            buy: 'Comprar',
            buyCover: 'Comprar Capa',
            sellCover: 'Vender Capa',
            coverPosition: 'Cobrir posição',
            sellQsi: (qsi: string) => `Vender ${qsi}`,
            noTopHoldingAvailable: (security) => `Nenhuma das principais participações está disponível para ${security} no momento`,
            openPnL: 'P/L Aberto',
            openPnLPercent: 'P/L Aberto %'
        },
        openOrders: 'Pedidos em aberto',
        news: {
            news: 'Notícias',
            marketIntelligence: 'Market Intelligence',
            andNews: 'E notícias',
            newsAndMI: 'Notícias e MI',
            newsUnavailable: 'Notícias indisponíveis no momento',
            noNewsFor: (symbol?: string) => `Nenhuma notícia está disponível para ${symbol || 'esta segurança'}`
        },
        crypto: {
            comingSoon: 'Negociação de criptografia em breve',
            marketComparison: {
                title: 'Comparação de Mercado',
                pastYear: 'Comparação de Mercado'
            },
            socialActivity: {
                title: 'Atividade Social',
                twitterFol: 'Seguidores do Twitter',
                redditFol: 'Seguidores do Reddit',
                githubFol: 'Seguidores do Github'
            },
            popularity: 'Popularidade',
            circulatingSupply: 'Fornecimento Circulante',
            totalSupply: 'Fornecimento Total',
            maxSupply: 'Fornecimento Máximo',
            coins: 'Moedas'
        }
    },

    sectorScreen: {
        sectors: 'Setores',
        sectorNames: {
            consumerDiscretionary: 'Consumidor discricionário',
            healthCare: 'Assistência médica',
            technology: 'Tecnologia',
            realEstate: 'Imobiliária',
            consumerStaples: 'Bens de consumo',
            utilities: 'Serviços de utilidade pública',
            communicationServices: 'Serviços de comunicação',
            financials: 'Finanças',
            materials: 'Materiais',
            industrials: 'Industriais',
            energy: 'Energia'
        },
        symbol: 'Símbolo',
        name: 'nome',
        price: 'Preço',
        change: 'Alterar',
        changePercent: 'Alterar Porcentagem',
        marketCap: 'Capacidade de mercado',
        item: 'item',
        items: 'itens',
        securityChangeSelector: {
            rising: 'Ascendente',
            falling: 'Queda'
        },
        marketCapSelector: {
            large: 'Grande',
            mid: 'Meio',
            small: 'Pequena',
            micro: 'Micro',
            nano: 'Nano',
            andBelow: 'e abaixo'
        }
    },

    fundScreen: {
        minimumInvestmentNotMet: (minInvestment) => `Investimento mínimo não atingido: ${FormatNumber.toMoneyOpt2(minInvestment, { showDecimals: false })}`,
        profile: {
            about: 'Sobre',
            noDescription: 'Nenhuma descrição disponível',
            category: 'Categoria',
            inceptionDate: 'Data de início',
            indexTracked: 'Índice rastreado',
            netAssetValue: 'Valor líquido do ativo',
            totalAssets: 'Total de ativos',
            beta: 'Beta',
            deferredLoad: 'Carga Diferida',
            expenseRatio: 'Relação de despesas',
            fee12b1: 'Taxa 12B1',
            holdingsTurnover: 'Rotatividade de participações',
            frontLoad: 'Carga frontal',
            family: 'Família de fundos',
            manager: 'Gestor de Fundos',
            maxRedepmtionFee: 'Taxa máxima de resgate',
            minimumInvestment: 'Investimento Mínimo',
            minimumInitialInvestment: 'Investimento inicial mínimo',
            aum: 'AUM',
            averageVolume: 'Volume médio',
            companySymbol: 'Empresa',
            priceToBook: 'Preço para reservar',
            priceToEarnings: 'Preço para ganhos',
            website: 'Local na rede Internet',
            open: 'Aberto',
            benchmark: 'Referência',
            stats: 'Estatísticas',
            bid: 'Bid',
            ask: 'Ask'
        },
        countryExposure: {
            countryExposureTitle: 'Exposição ao País',
            noExposureDataAvailable: 'Sem dados de exposição disponíveis',
            country: 'País',
            exposure: 'Exposição',
            countries: 'Países',
            noCountryExposure: 'Nenhum dado de exposição do país disponível'
        },
        sectorExposure: {
            sectorExposureTitle: 'De que é feito este fundo?',
            noSectorExposure: 'Nenhum dado de exposição do setor disponível',
            sectors: 'Setores'
        },
        // Offshore mutual fund stats
        Alternative: 'Alternativa',
        Equity: 'Equidade',
        FixedIncome: 'Renda Fixa',
        Guaranteed: 'Garantida',
        Hedge: 'Cobertura',
        High: 'Alto',
        Low: 'Baixo',
        Medium: 'Médio',
        MoneyMarket: 'Mercado Monetário',
        MultiAsset: 'Múltiplos ativos',
        Others: 'Outro',
        RealEstate: 'Imobiliária',
        Sector: 'Setor',
        SpanishDomestic: 'Doméstico Espanhol',
        Traditional: 'Tradicional',
        active: 'Ativo',
        assetClass: 'Classe de ativo',
        cutoffTimeRedemptions: 'Tempo limite (resgates)',
        cutoffTimeRedemptionsDescription: 'Indica o horário limite para resgates do fundo, mostrado no Horário Padrão Europeu.',
        cutoffTimeSubscriptions: 'Tempo limite (assinaturas)',
        cutoffTimeSubscriptionsDescription: 'Indica o horário limite para subscrições do fundo, indicado no Horário Padrão Europeu.',
        fundCountry: 'País do Fundo',
        fundCurrency: 'Moeda do Fundo',
        fundGroup: 'Grupo de fundos',
        fundGroupDescription: 'Indica o nome do grupo de fundos, conforme atribuído pelo AllFunds Bank.',
        fundHouse: 'Casa do Fundo',
        fundHouseCountry: 'País da Casa do Fundo',
        fundHouseDescription: 'Lida com a liquidação do fundo com diferentes classes. Inclui Agentes de Transferência, que lidam com transferências de fundos.',
        geographicArea: 'Área geográfica',
        geographicZone: 'Zona Geográfica',
        inceptionDate: 'Data de início',
        inceptionDateDescription: 'A data em que o fundo iniciou suas operações.',
        maximumAmount: 'Valor máximo',
        maximumAmountDescription: 'Indica o valor máximo permitido para subscrições do fundo.',
        minAdditionalAmount: 'Valor adicional mínimo',
        minAdditionalAmountDescription: 'Indica o valor mínimo permitido para subscrições posteriores do fundo.',
        minInitialAmount: 'Valor mínimo inicial',
        minInitialAmountDescription: 'Indica o valor mínimo permitido para a subscrição inicial do fundo.',
        notActive: 'Não ativo',
        preNotificationDaysRedemptions: 'Dias de Pré-Notificação (Resgates)',
        preNotificationDaysRedemptionsDescription: 'Indica o número de dias de pré-notificação que o fundo requer para resgates do fundo.',
        preNotificationDaysSubscriptions: 'Dias de pré-notificação (assinaturas)',
        preNotificationDaysSubscriptionsDescription: 'Indica o número de dias de pré-notificação que o fundo requer para subscrições do fundo.',
        redemptionsByAmount: 'Resgates por valor',
        redemptionsByAmountDescription: 'Indica se resgates são permitidos em dólares.',
        redemptionsByShares: 'Resgates por Ações',
        redemptionsBySharesDescription: 'Indica se os resgates são permitidos por um valor de ações.',
        riskCategory: 'Categoria de risco',
        riskCategoryDescription: 'Com base na classificação SRRI do fundo, indica a categoria de risco, que se baseia nos riscos de perdas ou lucros.',
        settlementDaysRedemptions: 'Dias de liquidação (resgates)',
        settlementDaysRedemptionsDescription: 'Indica o número de dias de liquidação padrão para resgates do fundo.',
        settlementDaysSubscriptions: 'Dias de liquidação (assinaturas)',
        settlementDaysSubscriptionsDescription: 'Indica o número de dias de liquidação padrão para subscrições do fundo.',
        status: 'Status',
        subAssetClass: 'Subclasse de ativo',
        subscriptionsByAmount: 'Assinaturas por valor',
        subscriptionsByAmountDescription: 'Indica se assinaturas são permitidas por um valor em dólares.',
        subscriptionsByShares: 'Assinaturas por Ações',
        subscriptionsBySharesDescription: 'Indica se são permitidas subscrições por valor de ações.',
        totalSettlementDaysRedemptions: 'Total de dias de liquidação (resgates)',
        totalSettlementDaysRedemptionsDescription:
            'Indica o número total de dias de liquidação para resgates do fundo, que também inclui o número de dias de pré-notificação exigidos pelo fundo.',
        totalSettlementDaysSubscriptions: 'Total de dias de liquidação (assinaturas)',
        totalSettlementDaysSubscriptionsDescription:
            'Indica o número total de dias de liquidação para subscrições do fundo, que também inclui o número de dias de pré-notificação exigidos pelo fundo.',
        transferableFund: 'Fundo Transferível',
        transferableFundDescription: 'Indica se o fundo é transferível (elegível para trapaso), especificamente para o mercado espanhol.',
        typeOfFund: 'Tipo de Fundo',
        volatilityRange: 'Faixa de volatilidade',
        volatilityRangeDescription:
            'Com base na classificação SRRI do fundo, indica a faixa de volatilidade do fundo, que é uma medida do nível de risco associado à flutuação do preço do fundo.',

        srri: 'Indicador Sintético de Risco e Recompensa',
        potentiallyLowerReward: 'Recompensa potencialmente inferior',
        lowerRisk: 'Menor Risco',
        higherRisk: 'Risco mais alto',
        potentiallyHigherReward: 'Recompensa potencialmente maior',
        potentialRiskVsReward: 'Risco Potencial vs Recompensa',
        riskIndicator: 'Indicador de Risco',
        risk: 'Risco'
    },
    deepAnalysisScreen: {
        viewFull: {
            statement: 'Ver declaração completa',
            balanceSheet: 'Ver Balanço Completo',
            cashFlow: 'Ver fluxo de caixa completo',
            viewAll: 'Ver todo'
        },
        deepAnalysis: 'Análise Profunda',
        quarterly: 'Trimestral',
        annual: 'Anual',
        showMore: 'Mostre mais',
        showLess: 'Mostre menos',
        showAll: 'Mostre tudo',
        financials: 'Finanças',
        loadingFinancials: 'Carregando Finanças...',
        sentimentAnalysis: 'Análise de sentimentos',
        noFinancialsFoundFor: (s) => `Nenhuma financeira encontrada para ${s}`,
        noDataFoundFor: (s) => `Nenhum dado encontrado para ${s}`,
        fundamentals: {
            fundamentals: 'Fundamentos',
            projectedRevenue: 'Receita Projetada',
            dividends: 'Dividendos'
        },
        balanceSheet: {
            balanceSheet: 'Balanço Patrimonial',
            reportDate: 'Data do relatório',
            filingType: 'Tipo de arquivamento',
            fiscalDate: 'Data Fiscal',
            fiscalQuarter: 'Bairro Fiscal',
            fiscalYear: 'Ano Fiscal',
            fixedAssets: 'Ativo permanente',
            currency: 'Moeda',
            currentCash: 'Dinheiro atual',
            shortTermInvestments: 'Investimentos de curto prazo',
            receivables: 'Recebíveis',
            inventory: 'Inventário',
            otherCurrentAssets: 'Outros ativos atuais',
            currentAssets: 'Ativos correntes',
            longTermInvestments: 'Investimentos de longo prazo',
            propertyPlantEquipment: 'Equipamentos de Plantas Imobiliárias',
            propertyPlantEquipmentShort: 'Propriedade / Fábrica / Equipa.',
            goodwill: 'Boa vontade',
            intangibleAssets: 'Ativos Intangíveis',
            otherAssets: 'Outros Ativos',
            totalAssets: 'Total de ativos',
            accountsPayable: 'Contas a pagar',
            accountsReceivable: 'Contas a receber',
            currentLongTermDebt: 'Dívida atual de longo prazo',
            otherCurrentLiabilities: 'Outros Passivos Correntes',
            totalCurrentLiabilities: 'Total do passivo circulante',
            longTermDebt: 'Dívida de Longo Prazo',
            otherLiabilities: 'Outros Passivos',
            minorityInterest: 'Interesse minoritário',
            totalLiabilities: 'Passivo Total',
            commonStock: 'Ação ordinaria',
            retainedEarnings: 'Lucros acumulados',
            treasuryStock: 'Ações em Tesouraria',
            capitalSurplus: 'Excedente de Capital',
            shareholderEquity: 'Patrimônio líquido',
            netTangibleAssets: 'Ativos Tangíveis Líquidos',
            longTermLiabilities: 'Passivos de longo prazo'
        },
        incomeStatement: {
            incomeStatement: 'Declaração de renda',
            reportDate: 'Data do relatório',
            filingType: 'Tipo de arquivamento',
            fiscalDate: 'Data Fiscal',
            fiscalQuarter: 'Trimestre Fiscal',
            fiscalYear: 'Ano fiscal',
            currency: 'Moeda',
            totalRevenue: 'Rendimento total',
            costOfRevenue: 'Custo de receita',
            grossProfit: 'Lucro bruto',
            researchAndDevelopment: 'Pesquisa e desenvolvimento',
            sellingGeneralAndAdmin: 'Vendo Geral e Administrador',
            operatingExpense: 'Despesa operacional',
            operatingIncome: 'Receita Operacional',
            otherIncomeExpenseNet: 'Outras Receitas Despesas Líquidas',
            ebit: 'EBIT',
            interestIncome: 'Rendimentos de juros',
            interestIncomeNonOperating: 'Receita de Juros Não Operacional',
            interestExpense: 'Despesas com juros Receita não operacional',
            pretaxIncome: 'Renda antes dos impostos',
            incomeTax: 'Imposto de Renda',
            minorityInterest: 'Interesse minoritário',
            netIncome: 'Resultado líquido',
            netIncomeBasic: 'Lucro Líquido Básico'
        },
        cashFlow: {
            cashFlow: 'Fluxo de caixa',
            capitalExpenditures: 'Despesas de capital',
            cashChange: 'Mudança em dinheiro',
            cashFlowFinancing: 'Financiamento de fluxo de caixa',
            changesInInventories: 'Alterações nos estoques',
            changesInReceivables: 'Mudanças nos Recebíveis',
            currency: 'Moeda',
            depreciation: 'Depreciação',
            dividendsPaid: 'Dividendos pagos',
            exchangeRateEffect: 'Efeito da taxa de câmbio',
            filingType: 'Tipo de arquivamento',
            fiscalDate: 'Data Fiscal',
            fiscalQuarter: 'Trimestre Fiscal',
            fiscalYear: 'Ano fiscal',
            investingActivityOther: 'Atividade de Investimento Outros',
            investments: 'Investimentos',
            netBorrowings: 'Empréstimos Líquidos',
            netIncome: 'Resultado líquido',
            otherFinancingCashFlows: 'Outros fluxos de caixa de financiamento',
            reportDate: 'Data do relatório',
            symbol: 'Símbolo',
            totalInvestingCashFlows: 'Fluxos de caixa de investimento total'
        },
        earningsCalls: {
            earningsCalls: 'Chamadas de ganhos',
            noEarningsCallsAvailable: 'Nenhuma chamada de ganhos disponível',
            play: 'Jogar',
            playing: 'Jogando',
            translateEarningsCall: (str) => str.replace('Earnings call', 'Chamadas de ganhos')
        },
        investors: {
            investors: 'Investidores',
            insiders: 'Insiders',
            institutions: 'Instituições',
            funds: 'Fundos',
            insideInvestors: 'Investidores Internos',
            institutionalInvestors: 'Investidores institucionais',
            fundInvestors: 'Investidores de fundos'
        },
        insiderRoster: {
            insiderRoster: 'Lista interna',
            noInsidersFound: (symbol: string) => `Nenhuma lista interna encontrada para ${symbol}`,
            shares: 'Ações',
            asOf: 'A partir de'
        },
        topInsiders: 'Principais Insiders',
        advancedStats: {
            advancedStats: 'Estatísticas avançadas',
            beta: 'Beta',
            totalCash: 'Dinheiro total',
            currentDebt: 'Dívida Atual',
            revenue: 'receita',
            grossProfit: 'Lucro bruto',
            totalRevenue: 'Rendimento total',
            ebitda: 'EBITDA',
            revenuePerShare: 'Receita por ação',
            revenuePerEmployee: 'Receita por funcionário',
            debtToEquity: 'Dívida em capital',
            profitMargin: 'Margem de lucro',
            enterpriseValue: 'Valor da empresa',
            enterpriseValueToRevenue: 'Valor da empresa para receita',
            priceToSales: 'Preço para vendas',
            priceToBook: 'Preço para reservar',
            forwardPERatio: 'Proporção P/E a Frente',
            pegRatio: 'Razão PEG',
            peHigh: 'P/E Alto',
            peLow: 'P/E Baixo',
            week52HighDate: 'Data máxima de 52 semanas',
            week52LowDate: 'Data mínima de 52 semanas',
            week52HighDateSplitAdjustOnly: 'Somente ajuste de divisão de data máxima de 52 semanas',
            week52LowDateSplitAdjustOnly: 'Somente ajuste de divisão de data baixa de 52 semanas',
            putCallRatio: 'Colocar taxa de chamada'
        },
        priceTarget: {
            priceTarget: 'Preço-alvo',
            averagePriceTarget: 'Meta de preço médio',
            range: 'Alcance',
            low: 'Baixo',
            median: 'Mediano',
            high: 'Alto',
            average: 'Média'
        },
        otherInvestors: {
            insititutionalInvestors: 'Investidores Institucionais',
            fundInvestors: 'Investidores de fundos',
            holder: 'Suporte',
            shares: 'Ações',
            asOf: 'A partir de',
            value: 'Valor'
        },
        splits: {
            splits: 'Divisões',
            noSplitsOnRecord: (symbol: string) => `Nenhuma divisão registrada para ${symbol}`,
            exDate: 'Data Ex',
            from: 'A partir de',
            to: 'Para',
            ratio: 'Razão',
            reverse: 'Reverter',
            translateDescription: (desc: string) => (desc === 'Ordinary Shares' ? 'Ações ordinárias' : desc)
        },
        estimatedRevenue: {
            estimatedRevenue: 'Receita estimada',
            noData: (symbol: string) => `Não há estimativas de receita disponíveis para ${symbol}`,
            avg: 'Média',
            high: 'Alto',
            low: 'Baixo',
            nAnalysts: '# Analistas',
            numberAnalysts: 'Número Analistas',
            period: 'Período',
            revenueAvg: 'Média de receita',
            revenueHigh: 'Alta receita',
            revenueLow: 'Baixa receita'
        },
        dividends: {
            dividends: 'Dividendos',
            noData: (symbol: string) => `Não há dividendos disponíveis para ${symbol}`,
            mostRecentDividend: 'Dividendo mais recente',
            dividendForDate: 'Dividendo para Data',
            amount: 'Montante',
            currency: 'Moeda',
            declaredDate: 'Data declarada',
            exDate: 'Data Ex',
            flag: 'Bandeira',
            frequency: 'Frequência',
            paymentDate: 'Data de pagamento',
            recordDate: 'Data da gravação',
            symbol: 'Símbolo',
            date: 'Encontro'
        },
        AccountsPayable: 'Contas a Pagar',
        AccumulatedMinorityInterest: 'Participação Minoritária Acumulada',
        BalanceSheetItemType: 'Tipo de item de balanço',
        BookValuePerShare: 'Valor contábil por ação',
        CapitalExpenditures: 'Despesas de Capital',
        CashDividendsPaid: 'Dividendos em dinheiro pagos',
        CashShortTermInvestments: 'Investimentos de curto prazo em dinheiro',
        ChangeInCapitalStock: 'Variação no capital social',
        ChangeInLongTermDebt: 'Mudança na dívida de longo prazo',
        ChangesInWorkingCapital: 'Mudanças no capital de giro',
        CommonDividends: 'Dividendos Comuns',
        ConsolidatedNetIncome: 'Lucro Líquido Consolidado',
        CostOfGoodsSold: 'Custo dos produtos vendidos',
        DecreaseInDeposits: 'Diminuição nos depósitos',
        DecreaseInLoans: 'Diminuição nos empréstimos',
        DeferredTaxAssets: 'Ativos fiscais diferidos',
        DeferredTaxLiabilities: 'Passivos fiscais diferidos',
        DeferredTaxesInvestmentTaxCredit: 'Crédito fiscal de investimento de impostos diferidos',
        DepreciationDepletionAmortizationCashFlow: 'Depreciation Depletion Amortization Cash Flow',
        EBITDA: 'EBITDA',
        EBITOperatingIncome: 'EBIT Lucro Operacional',
        EPSBasicBeforeExtraordinaries: 'EPS Basic Antes de Extraordinários',
        EPSDilutedBeforeUnusualExpense: 'EPS diluído antes da despesa incomum',
        EPSFullyDiluted: 'EPS totalmente diluído',
        EquityInEarningsOfAffiliatesIncome: 'Lucro de patrimônio líquido de renda de afiliados',
        ExchangeRateEffect: 'Efeito da taxa de câmbio',
        ExtraordinaryItem: 'Item Extraordinário',
        ForeignCurrencyAdjustment: 'Ajuste de Moeda Estrangeira',
        FreeCashFlow: 'Fluxo de caixa livre',
        FundsFromOperations: 'Fundos de Operações',
        GrossIncome: 'Renda Bruta',
        IncomeStatementItem: 'Item da demonstração de resultados',
        IncomeTaxPayable: 'Imposto de Renda a Pagar',
        IncomeTaxes: 'Impostos de Renda',
        IncomeTaxesPayable: 'Impostos de Rendimentos Pagáveis',
        IncreaseInDeposits: 'Aumento nos depósitos',
        IncreaseInLoans: 'Aumento nos empréstimos',
        IntangibleAssets: 'Ativos Intangíveis',
        InterestExpense: 'Despesa de juros',
        Inventories: 'Inventários',
        InvestmentTaxCredits: 'Créditos de Imposto de Investimento',
        IssuanceOfLongTermDebt: 'Emissão de dívida de longo prazo',
        IssuanceOrReductionOfDebtNet: 'Emissão ou redução de dívida líquida',
        LongTermDebt: 'Dívida de longo prazo',
        LongTermNoteReceivable: 'Nota a receber de longo prazo',
        MinorityInterestExpense: 'Despesa com juros minoritários',
        MiscellaneousFunds: 'Fundos Diversos',
        NetAssetsFromAcquisitions: 'Ativos líquidos de aquisições',
        NetChangeInCash: 'Variação líquida em dinheiro',
        NetFinancingCashFlow: 'Fluxo de caixa de financiamento líquido',
        NetIncomeAvailableToCommonBasic: 'Lucro líquido disponível para Common Basic',
        NetIncomeCashFlow: 'Fluxo de caixa do lucro líquido',
        NetIncomeContinuingOperations: 'Operações Continuadas de Lucro Líquido',
        NetInvestingCashFlow: 'Fluxo de caixa de investimento líquido',
        NetOperatingCashFlow: 'Fluxo de caixa operacional líquido',
        NetPropertyPlantEquipment: 'Equipamento de planta de propriedade líquida',
        NonEquityReserves: 'Reservas não patrimoniais',
        NonOperatingIncomeExpense: 'Despesa de receita não operacional',
        OperatingLeaseExpense: 'Despesa de arrendamento operacional',
        OtherAfterTaxAdjustments: 'Outros ajustes após impostos',
        OtherAssets: 'Outros ativos',
        OtherCurrentAssets: 'Outros ativos circulantes',
        OtherCurrentLiabilities: 'Outros Passivos Circulantes',
        OtherFinancingActivitiesUses: 'Outras utilizações de atividades de financiamento',
        OtherFinancingActivity: 'Outra atividade de financiamento',
        OtherFunds: 'Outros Fundos',
        OtherLiabilities: 'Outras responsabilidades',
        OtherOperatingExpense: 'Outras despesas operacionais',
        OtherProceedsfromStockOptions: 'Outros rendimentos de opções de ações',
        OtherSources: 'Outras fontes',
        OtherUses: 'Outros usos',
        PreferredDividends: 'Dividendos Preferenciais',
        PreferredDividendsCashFlow: 'Fluxo de caixa de dividendos preferenciais',
        PreferredStockCarryingValue: 'Valor contábil das ações preferenciais',
        PretaxIncome: 'Renda antes dos impostos',
        ProceedsFromStockOptions: 'Produtos de opções de ações',
        ProvisionForRisksCharges: 'Provisão para encargos de riscos',
        PurchaseOfInvestments: 'Compra de Investimentos',
        PurchaseOrSaleOfInvestments: 'Compra ou Venda de Investimentos',
        ReductionOfLongTermDebt: 'Redução da dívida de longo prazo',
        RepurchaseOfCommonPreferredStock: 'Recompra de ações ordinárias preferenciais',
        ResearchAndDevelopment: 'Pesquisa e Desenvolvimento',
        SaleOfCommonPreferredStock: 'Venda de ações preferenciais ordinárias',
        SaleOfFixedAssetsBusinesses: 'Venda de negócios de ativos fixos',
        SaleOrMaturityOfInvestments: 'Venda ou vencimento de investimentos',
        SalesOrRevenue: 'Vendas ou Receita',
        SellingGeneralAdministrativeExpenses: 'Despesas Administrativas Gerais de Venda',
        ShortTermDebt: 'Dívida de curto prazo',
        ShortTermReceivables: 'Contas a receber de curto prazo',
        StockOptionCompensationExpense: 'Despesa de remuneração de opções de ações',
        TangibleBookValuePerShare: 'Valor contábil tangível por ação',
        TotalAssets: 'Ativos Totais',
        TotalCommonEquity: 'Total de patrimônio comum',
        TotalCurrentAssets: 'Total de Ativo Circulante',
        TotalCurrentLiabilities: 'Passivo Circulante Total',
        TotalEquity: 'Patrimônio Total',
        TotalInvestmentsAndAdvances: 'Total de Investimentos e Adiantamentos',
        TotalLiabilities: 'Passivos Totais',
        TotalLiabilitiesStockholdersEquity: 'Total Passivo Patrimônio Líquido',
        TotalShareholdersEquity: 'Patrimônio Total de Acionistas',
        UnusualExpense: 'Despesa Incomum'
    },

    // Option List Screen
    optionListScreen: {
        ask: 'Perguntar',
        askValue: 'Pedir Valor',
        bid: 'Oferta',
        bidValue: 'Valor do Lance',
        breakEven: 'Empatar',
        buy: 'Comprar',
        calendarSpreads: 'Spreads de calendário',
        call: 'Chamar',
        change: 'Variação',
        changePercent: 'Variação %',
        close: 'Fechar',
        contractsYouSelect: 'Os contratos que você selecionar aparecerão aqui.',
        expirationDate: 'Data de expiração',
        high: 'Alto',
        impliedVolatility: 'Volatilidade implícita',
        intrinsicValue: 'Valor intrínseco',
        lastPrice: 'Último Preço',
        low: 'Baixo',
        open: 'Aberto',
        openInterest: 'Interesse aberto',
        optionChain: 'Cadeia de Opções',
        price: 'Último preço',
        put: 'Por',
        sell: 'Vender',
        sharePrice: 'Compartilhar preços',
        strikePrice: 'Preço de exercício',
        theGreeks: 'Os gregos',
        disclaimer: '15 minutos atrasado',
        optionGreeksTooltipIntro:
            'Opção Os gregos são usados ​​para medir o risco de uma opção e para avaliar a sensibilidade de uma opção às variáveis ​​que compõem esse risco — As variáveis ​​são representadas pelas letras gregas Delta, Gamma, Theta, Vega e Rho.',
        optionGreeksTooltipDelta: 'Delta Δ: Taxa de variação por US$ 1 de título subjacente',
        optionGreeksTooltipGamma: 'Gama Γ: Taxa de variação de Δ',
        optionGreeksTooltipTheta: 'Theta Θ: Taxa de variação de preço ao longo do tempo',
        optionGreeksTooltipVega: 'Vega ν: Taxa de variação entre o preço da opção e a volatilidade implícita do título subjacente',
        optionGreeksTooltipRho: 'Rho ρ: Taxa de variação entre o valor de uma opção e uma variação de 1% na taxa de juros',
        optionGreeksDelayed: 'Atraso de 15 minutos',
        toBreakEven: 'Para empatar',
        tradeFutures: 'Negociar Futuros',
        tradeOptions: 'Opções de negociação',
        tradeStock: 'Negociar ações',
        viewMore: 'Veja mais',
        viewOptionChain: 'Ver cadeia de opções',
        volume: 'Volume',
        floatingButtonText: (qsi: string) => `${qsi} Cadeia de Opções`
    },

    // A Piece of Logic in src/components/TradeTicket/Option/OptionTradeInputPage.tsx(83,20) prevents this from being inside optionListScreen
    greeks: {
        delta: {
            text: 'Delta',
            symbol: 'Δ',
            tooltip: 'Taxa de variação por US$ 1 de título subjacente.'
        },
        gamma: {
            text: 'Gamma',
            symbol: 'Γ',
            tooltip: 'Taxa de variação de Δ.'
        },
        theta: {
            text: 'Theta',
            symbol: 'Θ',
            tooltip: 'Taxa de variação de preço ao longo do tempo.'
        },
        vega: {
            text: 'Vega',
            symbol: 'v',
            tooltip: 'Taxa de variação entre o preço da opção e a volatilidade implícita do título subjacente.'
        },
        rho: {
            text: 'Rho',
            symbol: 'ρ',
            tooltip: 'Taxa de variação entre o valor de uma opção e uma variação de 1% na taxa de juros.'
        }
    },

    enableOptionsScreen: {
        enableOptions: 'Ativar opções',
        title: 'Parece que você não tem acesso às opções!',
        getStartedWithOptions: 'Comece a negociar opções selecionando uma conta abaixo',
        noOptionsEnabled: 'Você não tem opções habilitadas em sua conta. Você gostaria de?',
        enableOptionsTrading: 'Sim, habilitar negociação de opções',
        notNow: 'Agora não',
        noTradingOptionsEnabled: 'Você não tem opções de negociação habilitadas em nenhuma de suas contas. Selecione uma conta abaixo para começar.'
    },

    optionContractScreen: {
        expiresOnDate: (date: Date) => `Expira ${SafeFormat(date, 'dd MMM yyyy')}`,
        expiresSoon: 'Expira em breve',
        symbolLastQuote: (symbol: string) => `${symbol} Última cotação`,
        tradeUnderlying: (symbol: string) => `Troca ${symbol}`,
        viewAllOptions: 'Ver todas as opções',
        viewMoreHistory: 'Ver mais histórico',
        viewMoreDetails: 'Ver mais detalhes',
        history: 'História',
        addLeg: 'Adicionar perna',
        removeLeg: 'Remover perna',
        stats: {
            stats: 'Estatísticos',
            bid: 'Bid',
            ask: 'Ask',
            previousClose: 'Anterior Fechar',
            breakEven: 'Empatar',
            open: 'Aberto',
            close: 'Fechar',
            high: 'Alto',
            low: 'Baixo',
            volume: 'Volume',
            openInterest: 'Interesse aberto',
            impliedVolatility: 'Volatilidade implícita',
            intrinsicValue: 'Valor intrínseco'
        }
    },

    futuresContractScreen: {
        baseContract: 'Contrato base',
        expiresDate: (date: string | Date) => `Expira ${SafeFormat(date, 'dd MMM yyyy')}`,
        upgradeToPremium: 'Atualize para o Market Intelligence Premium',
        viewMoreContracts: 'Ver mais contratos',
        domNotInSession: {
            title: (symbol: string) => `${symbol} não está em sessão`,
            message: 'Devido a este contrato não estar em sessão, o Livro de Ofertas não está disponível no momento.'
        }
    },
    advancedChartScreen: {
        notifications: {
            orderNotPlaced: 'Pedido não colocado',
            invalidSecurity: 'Símbolo de segurança inválido',
            invalidAccount: 'Número de conta inválido',
            shortSellNotAllowed: 'Short sell não permitido no gráfico avançado'
        }
    },
    tradeTicket: {
        adjustedOptionsDisclaimer: 'Devido ao risco de liquidez e liquidação das opções ajustadas, só podemos permitir o fechamento de ordens.',
        betaMaintenanceHours: 'Fora do horário comercial - Negociação indisponível das 12h às 1h59 EST, domingo a sábado',
        cancelEquityOrdersHours:
            'Os pedidos de ações só podem ser cancelados fora do horário de manutenção do sistema / horários fora de expediente, que são das 00:00 às 01:59 EST, de domingo a sábado. Por favor, tente cancelar novamente mais tarde.',
        cancelEquityOptionsOrdersHours:
            'Os pedidos de opções de ações só podem ser cancelados fora do horário de manutenção do sistema / horários fora de expediente, que são das 00:00 às 01:59 EST, de domingo a sábado. Por favor, tente cancelar novamente mais tarde.',
        modifyEquitiesDayOrdersHours:
            'Os pedidos diários de ações só podem ser editados durante o horário das 08:00 às 15:59 EST, de segunda a sexta-feira. Se você deseja alterar este pedido agora, por favor, cancele e envie um novo pedido.',
        modifyEquitiesOptionsDayOrdersHours:
            'Os pedidos diários de opções de ações só podem ser editados durante o horário das 08:00 às 15:59 EST, de segunda a sexta-feira. Se você deseja alterar este pedido agora, por favor, cancele e envie um novo pedido.',
        modifyEquitiesGtcOrdersHours:
            'Os pedidos de ações Good Until Canceled só podem ser editados durante o horário das 08:00 às 16:59 EST, de segunda a sexta-feira. Se você deseja alterar este pedido agora, por favor, cancele e envie um novo pedido.',
        modifyEquitiesOptionsGtcOrdersHours:
            'Os pedidos de opções de ações Good Until Canceled só podem ser editados durante o horário das 08:00 às 16:59 EST, de segunda a sexta-feira. Se você deseja alterar este pedido agora, por favor, cancele e envie um novo pedido.',
        timeFrames: 'Períodos',
        oneClickTradingTitle: 'Negocie em um clique',
        position: 'Posição',
        sellAll: 'Vender tudo',
        buyAll: 'Compre tudo',
        oneClickTradingDescription:
            'Ignore esta tela na próxima vez que fizer uma negociação. Não faremos você revisar a negociação antes de colocá-la quando você optar por negociar com um clique\n\nVocê pode desativá-la acessando "Preferências" na seção "Perfil".',
        misc: {
            setQuantity: 'Definir quantidade',
            noChangesToReview: 'Nenhuma alteração para revisar',
            startTradingOptions: 'Comece a negociar opções',
            securityUnavailable: (symbol: string) => `Desculpe, ${symbol} não está disponível para negociação StoneX One.`,
            enableOptionsTradingNow: 'Ativar negociação de opções agora',
            createFuturesAccount: 'Crie uma conta de futuros',
            createEquitiesAccount: 'Crie uma conta de ações',
            accountPending: 'Esta conta está com aprovação pendente.',
            applicationPending: 'Aplicação Pendente',
            notAvailableToTrade: 'Ainda não está disponível para negociação StoneX One',
            noPricing: 'Nenhuma informação de preço disponível.',
            noPricingCall: 'Ligue para 1-866-443-5847 para obter assistência.',
            cannotEdit: 'Não é possível editar',
            orderExtendedHoursError: (orderType: string) => {
                switch (orderType.toLowerCase()) {
                    case 'market':
                        return 'As ordens de mercado estão indisponíveis durante as negociações em horário estendido';
                    case 'stop':
                        return 'Ordens stop são ordens indisponíveis durante o horário estendido de negociação';
                    case 'stoplimit':
                        return 'Ordens de limite de parada são ordens indisponíveis durante o horário estendido de negociação';
                }
            },
            extendedHoursMustBeChecked: 'A caixa de horário estendido deve ser marcada para fazer pedidos durante o horário estendido',
            badTimeInForce: 'A expiração do pedido selecionado não pode ser usada neste momento',
            badTimeForStrat: 'Não pode ser usado neste momento',
            premarketRequired: 'Somente ordens com execução pré-mercado habilitada são permitidas no momento',
            postmarketRequired: 'Somente ordens com execução pós-mercado habilitada são permitidas no momento',
            notAvailableToEdit: 'Não é possível editar o pedido no momento',
            isHoliday: 'A negociação não está disponível durante os feriados do mercado',
            isUnderMaintenance: 'Atualmente fora do horário comercial. Por favor, tente novamente mais tarde.',
            overnightMarginCost: 'Custo de margem noturna',
            notInSession: (s, e) => `As horas de negociação para este título são ${SafeFormat(s, 'h:mm bb')} - ${SafeFormat(e, 'h:mm bb z')}`,
            notInSessionOptions: (s, e, tz) =>
                `As negociações de opções só podem ser realizadas entre ${SafeFormat(s, 'h:mm bb')} às ${SafeFormat(e, 'h:mm bb z')} ${tz}`,
            notInSessionOptionsSymbolic: (s, e, tz) => `As negociações de opções só podem ser realizadas entre ${s} às ${s} ${tz}`,
            notInSessionFutures: (s, e) =>
                `O horário de negociação para este contrato futuro é ${s ? format(utcToZonedTime(s, 'America/Chicago'), 'h:mm bb') : '---'} às ${
                    e
                        ? format(utcToZonedTime(e, 'America/Chicago'), 'h:mm bb z', {
                              timeZone: 'America/Chicago'
                          })
                        : '---'
                }`,
            notInSessionFuturesOptions: (s, e) =>
                `O horário de negociação para este contrato futuro é ${s ? format(utcToZonedTime(s, 'America/Chicago'), 'h:mm bb') : '---'} às ${
                    e
                        ? format(utcToZonedTime(e, 'America/Chicago'), 'h:mm bb z', {
                              timeZone: 'America/Chicago'
                          })
                        : '---'
                }`,
            notInSessionShortSell: `Pedidos de venda a descoberto só podem ser feitos entre 9h30 e 16h00 EST, de segunda a sexta-feira.`,
            invalidEditTimeAdvisory: 'Os pedidos não podem ser editados entre 5:00pm EST - 7:56am EST.',
            minimumNotMetTitle: 'Investimento Inicial Mínimo não atingido',
            minimumNotMetSubtext: (minimumInvestment: number): string =>
                `Este fundo requer um Investimento Inicial Mínimo de ${FormatNumber.toMoneyOpt2(minimumInvestment)}.`,
            closedToNewInvestors: 'Este fundo está fechado para novos investidores',
            residualSharesWillBeSettled:
                'As ações fracionárias restantes serão exibidas como pendentes e serão liquidadas automaticamente em até dois dias após a transação.',

            isWeekend: 'A negociação para este título não está disponível no fim de semana',
            notInSessionGeneral: 'Desculpe, esta segurança não está disponível no momento',
            securityTypeUnavailable: (type: TradeableSecurityType | 'loading') => {
                switch (type || 'unknown') {
                    case 'loading':
                        return 'Carregando';
                    case 'unknown':
                        return 'Desculpe, títulos deste tipo não podem ser negociados atualmente';
                    default:
                        return `Desculpe, ${Snex1Portuguese.general.productTypes[type]?.plural || 'títulos deste tipo'} não podem ser negociados no momento`;
                }
            },
            validatingOrder: 'Validando pedido...'
        },

        input: {
            retry: 'Tentar novamente',
            cancel: 'Cancelar',
            notes: 'Notas',
            tradeNotPermitted: 'Comércio Não Permitido',
            orderNotAllowed: 'Ordem Não Permitida',
            cannotShort: 'Não pode encurtar',
            invalidQuantity: 'Quantidade inválida',
            strategy: 'Estratégia',
            x1pAlgo: 'X1P Algo',
            care: 'Cuidado',
            careDescription: 'Pedido não retido',
            vwap: 'VWAP',
            vwapDescription: 'Volume-Weighted Average Price',
            twap: 'TWAP',
            twapDescription: 'Time-Weighted Average Price',
            pov: 'POV',
            povDescription: 'Percent of Volume',
            sor: 'SOR',
            sorDescription: 'Smart Order Routing',
            applyForMargins: 'Solicitar Margens',
            unknownTitle: (isError: boolean) => (isError ? 'Erro Desconhecido' : 'Aviso Desconhecido'),
            unknownMessage: (isError: boolean) => (isError ? 'Desculpe, mas ocorreu um erro desconhecido' : 'Desculpe, mas ocorreu um aviso desconhecido'),

            tradeNow: 'Negocie agora',
            unitName: (symbol) => (FuturesSymbol.IsFuturesSymbol(symbol) || OptionSymbol.IsOptionSymbol(symbol) ? 'contract' : 'share'),
            futures: {
                enforceFractional: 'Valor fracionário inválido',
                enforceTickSize: (tickSize: number) => `Price must be in increments of ${tickSize}.`,
                numberPerContract: (formattedNumber) => `${formattedNumber} por contrato`,
                side: 'Lado'
            },
            action: {
                buy: 'Comprar',
                sell: 'Vender',
                tabFontSize: 20
            },
            buyingPower: 'Poder de compra',
            marketValue: 'Posição atual',
            buyingPowerAdvisory: (t) => `Você não tem fundos suficientes para comprar ${t.quantity} ação(ões) de ${t.securityId}.`,
            addFundsToContinue: 'Adicione fundos para continuar',
            depositFunds: 'Fundos de depósito',
            dismiss: 'Dispensar',
            shares: 'Ações',
            tradeWholeShares: 'Negocie ações inteiras',
            tradeDollarAmount: 'Negocie uma quantia em dólar',
            securityCannotBeTraded: 'Este título não pode ser negociado nesta unidade.',
            bid: 'Bid',
            ask: 'Ask',
            mid: 'Mid',
            vol: 'Vol',
            held: (symbol) => `${Snex1Portuguese.general.unit(symbol, 2)} Mantido`,
            options: {
                breakEven: 'Empatar',
                credit: 'Crédito',
                debit: 'Débito',
                even: 'Até',
                last: 'Durar',
                netAsk: 'Pergunta líquida',
                netBid: 'Lance líquido',
                midpoint: 'Ponto médio',
                underlyingShares: 'Ações Subjacentes',
                contracts: 'Contratos',
                sharesPerContract: (n: number) => `${FormatNumber.toCommas(n)} ações por contrato`,
                actionToOpenClose: (t, openClose) => `${t.action} para ${openClose === 'Open' ? 'Abrir' : 'Fechar'}`,
                unlimited: 'Ilimitado',
                maxGain: 'Ganho Máximo',
                maxLoss: 'Perda máxima',
                enableOptionsTrading: 'Ativar negociação de opções',
                upgradeOptionsLevel: 'Atualize seu nível de opções'
            },
            advisories: {
                notice: 'Perceber',
                immediateExecution: {
                    orderPriceError: (priceName: 'limit' | 'stop', priceIs: 'above-market' | 'below-market') =>
                        `A ordem será executada imediatamente, uma vez que o ${priceName} preço é ${
                            priceIs === 'above-market' ? 'acima de' : 'abaixo'
                        } preço de mercado.`,
                    stopLimitPriceError: (priceIs: 'above-stop' | 'below-stop') =>
                        `Sua ordem pode ser executada imediatamente assim que o preço de parada for atingido, pois o preço limite é ${
                            priceIs === 'above-stop' ? 'acima de' : 'abaixo'
                        } o preço de parada.`
                },
                insufficientValue: {
                    notEnoughBuyingPower: 'Não há poder de compra suficiente',
                    notEnoughMarketValue: 'Não segura o suficiente',
                    depositFunds: 'Fundos de depósito',
                    dismiss: 'Dispensar'
                },
                invalidStopOrder: {
                    buy: 'Uma ordem de parada de compra requer um preço de parada maior que o preço de mercado atual.',
                    sell: 'Uma ordem de parada de venda requer um preço de parada menor que o preço de mercado atual.'
                },
                amountIsEstimate: {
                    mainText: 'Este valor é uma estimativa.',
                    subText: 'Seu pedido será executado com o melhor preço possível.'
                },
                notionalValueExceeded: {
                    mainText: 'Valor teórico máximo excedido',
                    subText:
                        'O custo total estimado de sua negociação excede o valor máximo de negociação configurado em seu perfil. Entre em contato com o Suporte para obter mais informações.'
                }
            },
            dollars: 'Dólares',
            limitPrice: 'Preço Limite',
            stopPrice: 'Preço de parada',
            stopLimitPrice: 'Preço limite de parada',
            marketPrice: 'Preço de mercado',
            expiration: 'Expiração',
            orderType: {
                limit: 'Limite',
                loc: 'LOC',
                market: 'Mercado',
                moc: 'MOC',
                stop: 'Pare',
                stoplimit: 'Limite de parada'
            },
            reviewAction: (t) => `Análise ${t.action}`,
            reviewEdit: (t) => `Análise ${t.action} Edição do pedido`,
            reviewTrade: 'Revise o comércio',
            buySymbol: (symbolName) => `Comprar ${symbolName}`,
            sellSymbol: (symbolName) => `Vender ${symbolName}`,
            sellAll: (t, amount) => `Vender tudo ${FormatNumber.toDecimal(amount, 3)} ${Snex1Portuguese.general.unit(t.securityId, amount)}`,
            selectAnAccount: 'Selecione uma conta',
            timeInForce: 'Tempo em vigor',
            timesInForce: {
                day: 'Bom para o dia',
                gtc: 'Bom até ser cancelado',
                fok: 'Preencher ou Matar',
                ioc: 'Imediato ou Cancelar'
            },
            advancedRouting: {
                notHeld: 'Não retido'
            },
            dvp: 'DVP',
            extendedHours: {
                GTXPre: 'Permitir que esta ordem seja executada durante o horário estendido',
                GTXPost: 'Permitir que esta ordem seja executada durante o horário estendido',
                nte: 'Permitir que esta ordem seja executada durante o horário estendido'
            },
            totalCost: 'Custo total',
            estimatedSymbolAmount: (symbol: string) => `Total estimado de ${symbol}`,
            estimatedTotalCredit: 'Crédito total estimado',
            estimatedTotalCost: 'Custo total estimado',
            totalGain: 'Crédito total',
            estimatedTotalGain: 'Crédito estimado',
            error: 'Algo não está certo',
            warning: 'Por favor confirme',
            resubmit: 'Repetir',
            submitAnyway: 'Continuar',
            continue: 'Continuar',
            submitShort: 'Continuar pedido curto',
            reviseOrder: 'Revisar Pedido'
        },

        reinvestmentOptions: {
            reinvestmentOptions: 'Opções de Reinvestimento',
            instructions: (t) =>
                `Por favor, indique qual dos seguintes retornos você gostaria que fosse reinvestido em sua posição com ${t.securityId}. Estas serão suas preferências de reinvestimento para esta e todas as compras futuras de ${t.securityId} feito por conta ${t.accountNumber}, e não pode ser alterado posteriormente.`,
            mutualFundInstructions: (t) =>
                `Por padrão, as distribuições de fundos mútuos são aplicadas a uma conta à vista como saldo de crédito gratuito. Se preferir reinvestir dividendos e/ou distribuições de ganhos de capital, você precisa optar por fazê-lo abaixo. Quaisquer preferências de reinvestimento definidas serão aplicadas imediatamente e serão aplicadas a todas as compras futuras de ${t.securityId} feito por conta ${t.accountNumber}. Você pode alterar suas preferências de reinvestimento entrando em contato com o atendimento ao cliente. Observe que as distribuições de fundos mútuos são tributáveis independentemente das preferências de reinvestimento. Quero reinvestir na segurança o seguinte:`,
            offshoreCashInstructions: (accountNumber: string, isin: string) =>
                `As distribuições para este fundo mútuo offshore são automaticamente aplicadas à sua conta como dinheiro (um saldo de crédito livre). Isto será aplicado imediatamente e se aplicará a todas as compras futuras de ${isin} feitas através da conta ${accountNumber}. Ao continuar com este pedido, você entende que a preferência por este fundo mútuo offshore será em dinheiro.`,
            offshoreReinvestInstructions: (accountNumber: string, isin: string) =>
                `As distribuições para este fundo mútuo offshore são automaticamente aplicadas à sua conta como um reinvestimento. Isto será aplicado imediatamente e se aplicará a todas as compras futuras de ${isin} feitas através da conta ${accountNumber}. Ao continuar com este pedido, você entende que a preferência por este fundo mútuo offshore será definida para reinvestir.`,
            review: 'Análise',
            dividends: 'Dividendos',
            shortTermCapitalGains: 'Ganhos de Capital de Curto Prazo',
            longTermCapitalGains: 'Ganhos de Capital de Longo Prazo',
            editButtonText: 'Editar opções de reinvestimento',
            saveButton: 'Salvar preferências',
            reinvest: 'Reinvestir',
            mutualFundsDistribution: 'As distribuições de fundos mútuos são tributáveis independentemente das preferências de reinvestimento',
            usePreferences: 'Usar Preferências',
            shortTermsGains: 'Ganhos de Curto Prazo',
            longTermGains: 'Ganhos de Longo Prazo',
            shortTerm: 'Curto prazo',
            longTerm: 'Longo prazo',
            reinvestScreenDesc: (
                symbol: string,
                accountNumber: string
            ) => `Você pode alocar distribuições de ${symbol} para uma conta em dinheiro como saldo de crédito gratuito, ou reinvesti-las novamente em ${symbol}.
            Estas preferências serão usadas para todas as transações ${symbol} feitas através da conta ${accountNumber} assim que você colocar isso
            comércio, e pode ser alterado a qualquer momento.`
        },

        review: {
            confirmTrade: 'confirme',
            gtxDisclaimer:
                'Negociação durante as sessões de negociação em horário estendido (incluindo a sessão pré-mercado (de segunda a sexta-feira, das 8h00 às 9h30 ET) e a sessão pós-mercado (segunda a sexta-feira, das 16h00 às 17h00 ET) , em cada caso sujeito a horários de negociação de feriados), carrega riscos únicos, como maior volatilidade de preços, menor liquidez, spreads de compra/venda mais amplos e menor visibilidade de mercado, e pode não ser apropriado para todos os investidores. A StoneX One não pode garantir que as ordens de horário estendido serão executadas ao melhor preço para qualquer título ou que tais ordens, incluindo ordens de mercado, serão executadas. Ao solicitar acesso para inserir ordens e inserir qualquer ordem durante o horário estendido de negociação no StoneX One, incluindo em qualquer plataforma de negociação StoneX One ou plataforma móvel, você reconhece que entende os riscos de negociar durante as sessões de negociação em horário estendido.',
            offshoreDisclaimer:
                'Os tempos limite, períodos de liquidação e períodos de pré-notificação do Fundo Mútuo Offshore variam de acordo com o fundo. Você receberá o primeiro NAV disponível emitido pelo fundo. Consulte o prospecto do fundo para saber os prazos limite, taxas do fundo e outras informações importantes antes de investir. Os pedidos de fundos mútuos offshore não podem ser cancelados ou modificados através do sistema depois de enviados. Entre em contato com o suporte para solicitar um cancelamento ou modificação.',
            equities: (priceFormatter, trade, currentMarketPrice, totalCost, limitPrice, stopPrice) => {
                const symbol = Snex1Portuguese.general.symbolName(trade.securityId, 'long');
                const formattedSymbol = Dark(symbol);
                const formattedLimitPrice = Dark(priceFormatter(limitPrice));
                const formattedStopPrice = Dark(priceFormatter(stopPrice));
                const formattedTotalOrderPrice = Dark(`$${totalCost.toFixed(2)}`);
                const formattedCurrentMarketPrice = Dark(priceFormatter(currentMarketPrice));

                const isOnCloseOrder = [OrderTypes.loc, OrderTypes.moc].includes(trade?.orderType);
                if (trade.liquidate) {
                    return [Light('Tu es'), Dark('vendendo todas as suas participações em'), formattedSymbol, Light('no'), Dark('preço de mercado'), Light('.')];
                }

                const tifQualifierLogic = [
                    {
                        rule: trade?.timeInForce?.toUpperCase() === 'GTXPRE' || trade?.extendedTimeInForce?.toUpperCase() === 'GTXPRE',
                        value: 'entre as 8h e as 17h, horário padrão do leste (ET)'
                    },
                    {
                        rule: trade?.timeInForce?.toUpperCase() === 'NTE' || trade?.extendedTimeInForce?.toUpperCase() === 'NTE',
                        value: 'durante la sesión posterior al mercado extendida de hoy (4:00 p. m. - 5:00 p. m. EST)'
                    },
                    {
                        rule: (trade?.timeInForce?.toUpperCase() === 'DAY' || isOnCloseOrder) && !trade?.extendedTimeInForce,
                        value: 'hoje'
                    },
                    {
                        rule: true, // default to GTC
                        value: 'nos próximos 180 dias'
                    }
                ];

                const tifQualifier = tifQualifierLogic.find((x) => x.rule).value;

                // if equities apply extended time in force check to tif qualifier
                const actionWording = trade.action === 'Buy' ? 'comprando' : 'vendendo';
                const actionWordVerb = trade.action === 'Buy' ? 'comprar' : 'vender';
                const actionWordNoun = trade.action === 'Buy' ? 'ordem de compra' : 'ordem de venda';
                const xUnits = `${FormatNumber.toCommas(trade.quantity)} ${Snex1Portuguese.general.unit(trade?.securityId, trade?.quantity)?.toLowerCase()}`;
                const unit = Snex1Portuguese.general.unit(trade?.securityId, 1)?.toLowerCase();

                switch (true) {
                    case [OrderTypes.market, OrderTypes.moc].includes(trade?.orderType as OrderType): {
                        const perShare = isNaN(currentMarketPrice) ? '.' : `por ${unit}.`;
                        return [
                            Light('Tu es'),
                            Dark(`${actionWording} ${FormatNumber.toCommas(trade.quantity)}`),
                            Light(`${Snex1Portuguese.general.unit(trade.securityId, trade.quantity)?.toLowerCase()} de`),
                            formattedSymbol,
                            Light('no'),
                            Dark('preço de mercado'),
                            ...(isNaN(currentMarketPrice) ? [] : [Light('Atualmente'), formattedCurrentMarketPrice]),
                            Light(perShare)
                        ];
                    }
                    case [OrderTypes.limit, OrderTypes.loc].includes(trade.orderType):
                        return [
                            Light('Se o preço de'),
                            formattedSymbol,
                            Light('atinge'),
                            formattedLimitPrice,
                            Light(`${tifQualifier}, você vai colocar um`),
                            Dark(actionWordNoun),
                            Light('por'),
                            Dark(xUnits),
                            Light('por'),
                            formattedTotalOrderPrice,
                            Light('ou melhor.')
                        ];

                    case trade.orderType === 'stop':
                        return [
                            Light('Se o preço de'),
                            formattedSymbol,
                            Light('atinge'),
                            formattedStopPrice,
                            Light(`${tifQualifier}, você vai colocar um`),
                            Dark(actionWordNoun),
                            Light('por'),
                            Dark(xUnits),
                            Light('no'),
                            Dark('preço de mercado'),
                            Light('.')
                        ];

                    case trade.orderType === 'stoplimit':
                        return [
                            Light('Se o preço de'),
                            formattedSymbol,
                            Light('atinge'),
                            formattedStopPrice,
                            Light(`${tifQualifier}, você vai colocar um`),
                            Dark(`${actionWordVerb} ${xUnits}`),
                            Light('por'),
                            formattedTotalOrderPrice,
                            Light('por'),
                            formattedLimitPrice,
                            Light('ou melhor.')
                        ];
                }
            },
            tifQualifier(trade, formattedSymbol) {
                const isOnCloseOrder = [OrderTypes.loc, OrderTypes.moc].includes(trade?.orderType);
                if (trade.liquidate) {
                    return [Light('Tu es'), Dark('vendendo todas as suas participações em'), Dark(formattedSymbol), Light('no'), Dark('preço de mercado'), Light('.')];
                }

                const tifQualifierLogic = [
                    {
                        rule: trade?.timeInForce?.toUpperCase() === 'GTXPRE' || trade?.extendedTimeInForce?.toUpperCase() === 'GTXPRE',
                        value: 'entre as 8h e as 17h, horário padrão do leste (ET)'
                    },
                    {
                        rule: trade?.timeInForce?.toUpperCase() === 'NTE' || trade?.extendedTimeInForce?.toUpperCase() === 'NTE',
                        value: 'durante la sesión posterior al mercado extendida de hoy (4:00 p. m. - 5:00 p. m. EST)'
                    },
                    {
                        rule: (trade?.timeInForce?.toUpperCase() === 'DAY' || isOnCloseOrder) && !trade?.extendedTimeInForce,
                        value: 'hoje'
                    },
                    {
                        rule: true, // default to GTC
                        value: 'nos próximos 180 dias'
                    }
                ];

                return tifQualifierLogic.find((x) => x.rule).value;
            },
            actionText: (trade: Partial<TradeRequest>, assetClass?: AssetClass, secMeta?: SecurityMetadata) => {
                return {
                    actionWording: trade.action === 'Buy' ? 'comprando' : 'vendendo',
                    actionWordVerb: trade.action === 'Buy' ? 'comprar' : 'vender',
                    actionWordNoun: trade.action === 'Buy' ? 'ordem de compra' : 'ordem de venda',
                    xUnits: `${FormatNumber.toCommas(trade.quantity)} ${Snex1Portuguese.general.unit(trade?.securityId, trade?.quantity)?.toLowerCase()}`,
                    unit: Snex1Portuguese.general.unit(trade?.securityId, 1)?.toLowerCase()
                };
            },
            equitiesV2: (assetClass, trade, secMeta, currentMarketPrice, totalCost, limitPrice?, stopPrice?): ShadedTextList => {
                const symbol = Snex1Portuguese.general.symbolName(trade.securityId, 'long');
                const formattedSymbol = Dark(symbol);
                const formattedLimitPrice = Dark(assetClass.formatPrice(limitPrice, secMeta));
                const formattedStopPrice = Dark(assetClass.formatPrice(stopPrice, secMeta));
                const formattedTotalOrderPrice = Dark(assetClass.formatPrice(totalCost, secMeta));
                const formattedCurrentMarketPrice = Dark(assetClass.formatPrice(currentMarketPrice, secMeta));

                const tifQualifier = Snex1Portuguese.tradeTicket.review.tifQualifier(trade, formattedSymbol);
                if (typeof tifQualifier !== 'string') return tifQualifier;

                const { actionWording, actionWordVerb, actionWordNoun, xUnits, unit } = Snex1Portuguese.tradeTicket.review.actionText(trade);

                const nonMarketTradeText = () => {
                    const isLimit = [OrderTypes.limit, OrderTypes.loc].includes(trade?.orderType as OrderType);
                    const isStop = trade.orderType === 'stop';
                    const isStopLimit = trade.orderType === 'stoplimit';
                    let text = [Light('Se o preço de'), formattedSymbol, Light('atinge')];

                    isStop || isStopLimit ? text.push(formattedStopPrice) : text.push(formattedLimitPrice);

                    text.push(Light(`${tifQualifier}, você vai colocar um`));

                    if (isStopLimit) {
                        return text.concat([Dark(`${actionWordVerb} ${xUnits}`), Light('por'), formattedLimitPrice, Light('ou melhor.')]);
                    } else text = text.concat([Dark(actionWordNoun), Light('por'), Dark(xUnits)]);

                    if (isLimit) return text.concat([Light('por'), formattedTotalOrderPrice, Light('ou melhor.')]);
                    else return text.concat([Light('no'), Dark('preço de mercado'), Light('.')]);
                };

                if ([OrderTypes.market, OrderTypes.moc].includes(trade?.orderType as OrderType)) {
                    const perShare = isNaN(currentMarketPrice) ? '.' : `por ${unit}.`;
                    return [
                        Light('Tu es'),
                        Dark(`${actionWording} ${FormatNumber.toCommas(trade.quantity)}`),
                        Light(`${Snex1Portuguese.general.unit(trade.securityId, trade.quantity)?.toLowerCase()} de`),
                        formattedSymbol,
                        Light('no'),
                        Dark('preço de mercado'),
                        ...(isNaN(currentMarketPrice) ? [] : [Light('Atualmente'), formattedCurrentMarketPrice]),
                        Light(perShare)
                    ];
                } else return nonMarketTradeText();
            },
            sharesV2: (assetClass, trade, secMeta, price, sharePrice?): ShadedTextList => {
                const creditDebit = {
                    Credit: Snex1Portuguese.tradeTicket.input.options.credit,
                    Debit: Snex1Portuguese.tradeTicket.input.options.debit,
                    Even: Snex1Portuguese.tradeTicket.input.options.even
                }[trade?.leg2DebitCredit];
                const symbol = Snex1Portuguese.general.symbolName(trade.securityId, 'long');

                const tifQualifier = Snex1Portuguese.tradeTicket.review.tifQualifier(trade, symbol);
                if (typeof tifQualifier !== 'string') return tifQualifier;

                const { actionWording, actionWordVerb, actionWordNoun, xUnits, unit } = Snex1Portuguese.tradeTicket.review.actionText(trade, assetClass, secMeta);

                const isMultiLeg = !!trade?.leg2Quantity;
                const leg2ActionWordNoun = trade?.leg2Action === 'Buy' ? 'ordem de compra' : 'ordem de venda';
                const leg2Sym = Snex1Portuguese.general.symbolName(trade?.leg2SecurityId, 'long');

                const nonMarketNonMultiLegText = () => {
                    const isLimit = [OrderTypes.limit, OrderTypes.loc].includes(trade.orderType);
                    const isStopLimit = trade.orderType === 'stoplimit';

                    let text = [Light('Se o preço de'), Dark(symbol), Light('atinge'), Dark(assetClass.formatPrice(trade.orderPrice, secMeta))];

                    if (isStopLimit) {
                        return text.concat([
                            Light(`${tifQualifier}, você colocará uma ordem limitada para`),
                            Dark(`${actionWordVerb} ${xUnits}`),
                            Light('por'),
                            Dark(assetClass.formatPrice(trade.stopLimitPrice, secMeta)),
                            Light('ou melhor.')
                        ]);
                    } else text = text.concat([Light(`${tifQualifier}, você vai colocar um`), Dark(actionWordNoun), Light('por'), Dark(xUnits)]);

                    if (isLimit) return text.concat([Light('por'), Dark(assetClass.formatPrice(price, secMeta)), Light('ou melhor.')]);
                    else return text.concat([Light('no'), Dark('preço de mercado'), Light('.')]);
                };
                const isEven = trade.orderType === 'even';
                const formattedPrice = isEven ? '' : `${assetClass.formatPrice(price || 0, secMeta)} `;

                const forPrice = trade?.orderType === 'even' ? 'até dinheiro' : `${formattedPrice} de ${creditDebit} líquido`;

                switch (true) {
                    case isMultiLeg:
                        return [
                            Light('Você está colocando um'),
                            Dark(`${actionWordNoun}`),
                            Light('por'),
                            Dark(`${FormatNumber.toCommas(trade.quantity)}`),
                            Light(`${Snex1Portuguese.general.unit(trade.securityId, trade.quantity)?.toLowerCase()} de`),
                            Dark(symbol),
                            Light('e um'),
                            Dark(`${leg2ActionWordNoun}`),
                            Light('por'),
                            Dark(`${FormatNumber.toCommas(trade.leg2Quantity)}`),
                            Light(`${Snex1Portuguese.general.unit(trade.leg2SecurityId, trade.leg2Quantity)?.toLowerCase()} de`),
                            Dark(leg2Sym),
                            ...(trade?.orderType === 'market' ? [Light('no'), Dark('preço de mercado'), Light('.')] : [Light('para '), Dark(forPrice)])
                        ];
                    case [OrderTypes.market, OrderTypes.moc].includes(trade?.orderType as OrderType): {
                        const sharePriceFigure = 'preço de mercado';
                        const perShare = isNaN(sharePrice) ? '.' : `por ${unit}.`;
                        return [
                            Light('Tu es'),
                            Dark(`${actionWording} ${assetClass.formatQuantity(trade.quantity, secMeta)}`),
                            Light(`${Snex1Portuguese.general.unit(trade.securityId, trade.quantity)?.toLowerCase()} de`),
                            Dark(symbol),
                            Light('no'),
                            Dark(sharePriceFigure),
                            ...(isNaN(sharePrice) ? [] : [Light('Atualmente'), Dark(assetClass.formatPrice(sharePrice, secMeta))]),
                            Light(perShare)
                        ];
                    }
                    default:
                        return nonMarketNonMultiLegText();
                }
            },
            dollarsV2: (assetClass, trade, secMeta, sharePrice?): ShadedTextList => {
                const symbol = Snex1Portuguese.general.symbolName(trade.securityId);
                const { actionWording } = Snex1Portuguese.tradeTicket.review.actionText(trade);
                const sharePriceFigure = 'preço de mercado';
                return [
                    Light(`Tu es ${actionWording}`),
                    Dark(assetClass.formatPrice(trade.quantity, secMeta)),
                    Light('de'),
                    Dark(symbol),
                    Light('no'),
                    Dark(sharePriceFigure),
                    ...(isNaN(sharePrice) ? [] : [Light('Atualmente'), Dark(`${assetClass.formatPrice(sharePrice, secMeta)}`)]),
                    Light('.')
                ];
            },
            futuresV2: (assetClass, trade, secMeta): ShadedTextList => {
                const format = (value: number) => assetClass.formatPrice(value, secMeta);
                const type = trade.orderType === 'stoplimit' ? 'stop-limit' : trade.orderType;
                const action = Snex1Portuguese.tradeTicket.review.actionText(trade).actionWordVerb;
                const symbol = Snex1Portuguese.general.symbolName(trade.securityId, 'short', trade.quantity, secMeta);
                const s = trade.quantity === 1 ? '' : 's';

                const base: ShadedTextList = [
                    { text: 'Você está realizando um', shade: 'light' },
                    { text: `${type} ${action}`, shade: 'dark', assetClass: 'futures' },
                    { text: 'pedido para', shade: 'light' },
                    { text: `${trade.quantity} ${symbol}`, shade: 'dark', assetClass: 'futures' },
                    { text: `contrato${s}`, shade: 'light' }
                ];

                const suffGtc: ShadedTextList = [{ text: ', válido até cancelamento', shade: 'light' }];
                const suffPeriod: ShadedTextList = [{ text: '.', shade: 'light' }];
                const suffPrice = (price: number): ShadedTextList => [
                    { text: 'a', shade: 'light' },
                    { text: format(price), shade: 'dark', assetClass: 'futures' }
                ];
                const suffStopLimit = (stop: number, limit: number): ShadedTextList => [
                    { text: 'com um stop de', shade: 'light' },
                    { text: format(stop), shade: 'dark', assetClass: 'futures' },
                    { text: 'e um limite de', shade: 'light' },
                    { text: format(limit), shade: 'dark', assetClass: 'futures' }
                ];

                const parts = [
                    base,
                    type === 'limit' || type === 'stop' ? suffPrice(trade.orderPrice) : null,
                    type === 'stop-limit' ? suffStopLimit(trade.orderPrice, trade.stopLimitPrice) : null,
                    trade.timeInForce === 'GTC' ? suffGtc : null,
                    suffPeriod
                ].filter((x) => !!x);

                return ShadedTextUtils.combine(parts);
            },
            futuresSpreads: (assetClass, trade, secMeta): ShadedTextList => {
                const format = (value: number) => assetClass.formatPrice(value, secMeta);
                const type = trade.orderType === 'stoplimit' ? 'stop-limit' : trade.orderType;
                const futuresSym = new FuturesSymbol(trade.securityId);
                const inverseAction = futuresSym.nearMonthDirection === '-';
                const { actionWordVerb: action } = Snex1Portuguese.tradeTicket.review.actionText(trade);

                const fullSymbol = Snex1Portuguese.general.symbolName(trade.securityId, 'short', trade.quantity, secMeta);
                const firstSymbol = futuresSym.nearMonthContract.slice(2);
                const secondSymbol = futuresSym.farMonthContract.slice(2);

                const s = trade.quantity === 1 ? '' : 's';

                const firstActioning = (trade?.action === 'Buy' && !inverseAction) || (trade?.action === 'Sell' && inverseAction) ? 'comprando' : 'vendendo';
                const secondActioning = (trade?.action === 'Buy' && !inverseAction) || (trade?.action === 'Sell' && inverseAction) ? 'vendendo' : 'comprando';

                const suffPeriod: ShadedTextList = [{ text: '.', shade: 'light' }];

                const base: ShadedTextList = [
                    { text: 'Você está realizando um', shade: 'light' },
                    { text: `${type} ${action}`, shade: 'dark', assetClass: 'futures' },
                    { text: 'pedido para', shade: 'light' },
                    { text: `${trade.quantity} ${fullSymbol}`, shade: 'dark', assetClass: 'futures' },
                    { text: `contrato${s}`, shade: 'light' }
                ];

                const symbolExplanation: ShadedTextList = [
                    { text: 'Você está', shade: 'light' },
                    { text: `${firstActioning} ${firstSymbol}`, shade: 'dark', assetClass: 'futures' },
                    { text: 'e', shade: 'light' },
                    { text: `${secondActioning} ${secondSymbol}`, shade: 'dark', assetClass: 'futures' },
                    ...suffPeriod
                ];

                const suffGtc: ShadedTextList = [{ text: ', válido até cancelamento.', shade: 'light' }];
                const suffPrice = (price: number): ShadedTextList => [
                    { text: 'a um preço de', shade: 'light' },
                    { text: format(price), shade: 'dark', assetClass: 'futures' }
                ];
                const suffStopLimit = (stop: number, limit: number): ShadedTextList => [
                    { text: 'com um stop de', shade: 'light' },
                    { text: format(stop), shade: 'dark', assetClass: 'futures' },
                    { text: 'e um limite de', shade: 'light' },
                    { text: format(limit), shade: 'dark', assetClass: 'futures' }
                ];

                const flattenedArray = () => {
                    const parts = [base];
                    if (type === 'limit' || type === 'stop') parts.push(suffPrice(trade.orderPrice));
                    if (type !== 'stop-limit' && trade.timeInForce !== 'GTC') parts.push(suffPeriod);
                    if (type === 'stop-limit') parts.push(suffStopLimit(trade.orderPrice, trade.stopLimitPrice));
                    if (type === 'stop-limit' && trade.timeInForce !== 'GTC') parts.push(suffPeriod);
                    if (trade.timeInForce === 'GTC') parts.push(suffGtc);
                    parts.push(symbolExplanation);

                    return parts.flat(1);
                };

                return flattenedArray();
            },
            cryptos: (assetClass, trade, secMeta, price, sharePrice): ShadedTextList => {
                const symbol = Snex1Portuguese.general.symbolName(trade.securityId, 'long');

                const tifQualifier = Snex1Portuguese.tradeTicket.review.tifQualifier(trade, symbol);
                if (typeof tifQualifier !== 'string') return tifQualifier;

                const { actionWordVerb, actionWording } = Snex1Portuguese.tradeTicket.review.actionText(trade);

                const isDollars = trade?.quantityQualifier === 'EvenDollar';

                if (trade.orderType === 'market') {
                    return [
                        Light('Você está fazendo um'),
                        isDollars
                            ? Dark(`${actionWording} ${assetClass.formatPrice(trade?.quantity)} ${symbol}`, '', 'crypto')
                            : Dark(`${actionWording} ${trade?.quantity} ${symbol}`, '', 'crypto'),
                        Light('ao'),
                        Dark('preço de mercado', '', 'crypto'),
                        Light('atualmente'),
                        Dark(assetClass.formatPrice(sharePrice, secMeta), '', 'crypto'),
                        Light('por criptomoeda.')
                    ];
                } else {
                    const isStopLimit = trade.orderType === 'stoplimit';
                    const isStop = trade.orderType === 'stop';
                    const tif = isStop ? 'gtc' : trade?.timeInForce?.toLowerCase();
                    const expandedTif = Snex1Portuguese.tradeTicket.input.timesInForce[tif].toLowerCase();

                    if (isStopLimit) {
                        return [
                            Light('Se o preço de'),
                            Dark(symbol, '', 'crypto'),
                            Light('atingir'),
                            Dark(assetClass.formatPrice(trade.orderPrice, secMeta), '', 'crypto'),
                            Light(`${tifQualifier}, você colocará uma ordem limite para`),
                            isDollars
                                ? Dark(`${actionWordVerb} ${assetClass.formatPrice(trade?.quantity)} de ${symbol}`, '', 'crypto')
                                : Dark(`${actionWordVerb} ${trade?.quantity} ${symbol}`, '', 'crypto'),
                            Light('por'),
                            Dark(assetClass.formatPrice(trade.stopLimitPrice, secMeta), '', 'crypto'),
                            Light('ou melhor.')
                        ];
                    } else if (isStop) {
                        return [
                            Light('Você está colocando uma'),
                            Dark(`ordem de ${actionWordVerb}`, '', 'crypto'),
                            Light('para'),
                            isDollars
                                ? Dark(`${assetClass.formatPrice(trade?.quantity)} de ${symbol}`, '', 'crypto')
                                : Dark(`${trade?.quantity} ${symbol}`, '', 'crypto'),
                            Light('a um preço de'),
                            Dark(assetClass.formatPrice(trade.orderPrice, secMeta), '', 'crypto'),
                            Light(`ou melhor,`),
                            Dark(`${expandedTif}.`, '', 'crypto')
                        ];
                    } else {
                        return [
                            Light('Você está fazendo um'),
                            Dark(`pedido ${actionWordVerb} com limite`, '', 'crypto'),
                            Light('de'),
                            isDollars ? Dark(`${assetClass.formatPrice(trade?.quantity)} ${symbol}`, '', 'crypto') : Dark(`${trade?.quantity} ${symbol}`, '', 'crypto'),
                            Light('a um preço de'),
                            Dark(assetClass.formatPrice(price, secMeta), '', 'crypto'),
                            Light(`de melhor`),
                            Dark(`${expandedTif}.`, '', 'crypto')
                        ];
                    }
                }
            },
            reinvestmentOptions: {
                willBeCashed: (labels: string[]) => [Light(`${SpokenJoin(labels)} será depositado em sua conta como`), Dark('dinheiro'), Light('.')],
                willBeReinvested: (labels: string[]) => [Light(`${SpokenJoin(labels)} vai ser`), Dark('reinvestido'), Light('.')],
                allWillBeCashed: [Light('Todas as devoluções serão depositadas na sua conta como'), Dark('dinheiro'), Light('.')],
                allWillBeReinvested: [Light('Todos os retornos serão'), Dark('reinvestido'), Light('.')]
            }
        },

        receipt: {
            tradeReceipt: 'Recibo Comercial',
            orderSummary: 'Resumo do pedido',
            orderType: {
                orderType: 'Tipo de pedido',
                marketOrder: 'Ordem de Mercado',
                mocOrder: 'Pedido MOC',
                limitOrder: 'Ordem Limitada',
                locOrder: 'Pedido LOC',
                limitOrderMinMax: (sell: boolean) => `Negocie a ${sell ? 'minimum' : 'maximum'} preço ou melhor`,
                stopOrder: 'Parar ordem',
                stopLimitOrder: 'Ordem de limite de parada',
                marketOrderDesc: 'Negocie imediatamente ao melhor preço disponível',
                limitOrderDesc: 'Negocie a um preço ou melhor',
                stopOrderDesc: 'Acionar uma negociação de mercado a um preço de parada fixo',
                stopLimitOrderDesc: 'Acionar uma negociação de limite a um preço de parada fixo',
                tradeCantBePlaced: 'Este tipo de negociação não pode ser colocado para este tipo de título',
                setStop: 'Definir Parada',
                setLimit: 'Definir limite',
                setStopLimit: 'Definir limite de parada',
                buyShareMaxPrice: 'Compre ações a um preço máximo ou inferior.',
                sellSharesMaxPrice: 'Vender ações a um preço mínimo ou superior.',
                triggerMarket: (orderAction: string) => `Acione um mercado ${orderAction} a um preço de parada fixo.`,
                triggerLimit: (orderAction: string) => `Acione um limite ${orderAction} a um preço de parada fixo.`
            },
            marketPrice: 'Preço de mercado',
            limitPrice: 'Preço Limite',
            stopPrice: 'Preço de parada',
            stopLimitPrice: 'Preço limite de parada',
            numberOfUnits: (symbol: string) => `Número de ${Snex1Portuguese.general.unit(symbol, 0)}`,
            orderSubmitted: 'Pedido Enviado',
            checkForStatusUpdatesUnderPositions: 'Verifique se há atualizações de status nas posições',
            submitAnotherOrder: 'Enviar outro pedido',
            submittingOrder: 'Enviando Pedido',
            pleaseWait: 'Por favor, aguarde...',
            youMayClickAway: 'Você pode clicar agora, nós o informaremos quando as coisas acontecerem.',
            thereWasAProblem: 'Havia um problema',
            pleaseTryAgain: 'Por favor, tente novamente',
            estimatedTotalCost: 'Custo total estimado',
            estimatedTotalGain: 'Ganho Total Estimado',
            estimatedTotalCredit: 'Crédito total estimado',
            estimatedTotalDebit: 'Débito total estimado',
            leg1: 'Perna 1',
            leg2: 'Perna 2',
            timeInForce: 'Tempo em vigor'
        },
        afterHours: {
            disclosure: {
                title: 'Aviso de sessão de negociação em horário estendido',
                shortDisclosure: 'A negociação durante as sessões de negociação em horário estendido acarreta riscos únicos.',
                longDisclosure: [
                    { shade: 'dark', text: 'Negociação durante sessões de negociação em horário estendido' },
                    {
                        shade: 'light',
                        text: '(incluindo a Sessão Pré-mercado (de segunda a sexta-feira, das 8h00 às 9h30 ET) e a sessão After-Market (de segunda a sexta-feira, das 16h00 às 17h00 ET), em cada caso sujeito a horário de comércio de feriados)'
                    },
                    { shade: 'dark', text: ', carrega riscos únicos,' },
                    {
                        shade: 'light',
                        text: ' como maior volatilidade de preços, menor liquidez, spreads de compra/venda mais amplos e menor visibilidade de mercado, e podem não ser apropriados para todos os investidores.\n\nStoneX Não se pode garantir que as ordens de horário estendido serão executadas com o melhor preço para qualquer determinado segurança ou que tais ordens, incluindo ordens de mercado, serão executadas. Ao solicitar acesso para inserir ordens e inserir qualquer ordem durante o horário estendido de negociação no StoneX One, inclusive em qualquer plataforma de negociação StoneX One ou plataforma móvel, você reconhece que entende os riscos de negociar durante as sessões de negociação em horário estendido.'
                    }
                ]
            }
        },
        shortSales: {
            acceptance: (shares, rate) => {
                return [Light('Você está vendendo '), Dark(`${shares} ação`), Light('a uma'), Dark(`taxa de ${rate}%`), Light('.')];
            },
            thisWillShortYou: (quantityShort: number, rate: number) => {
                return [
                    Light('Esta negociação fará com que você venda '),
                    Dark(`${quantityShort} açõe${quantityShort === 1 ? '' : 's'}`),
                    Light('a uma taxa de'),
                    Dark(FormatNumber.toPercent(rate)),
                    Light('.')
                ];
            },
            disclosureTitle: 'Taxas de Empréstimo de Ações',
            disclosure: (isExpanded) =>
                isExpanded
                    ? 'As taxas de empréstimo de ações exibidas são indicativas e podem não representar as taxas reais pagas para emprestar o título. As taxas de empréstimo indicativas são criadas pela StoneX Financial Inc. com base em vários fatores e fontes múltiplas. A taxa real de empréstimo de ações paga pelo empréstimo de um título pode variar significativamente da taxa indicativa com base nas condições de mercado para esse título no momento do empréstimo. Ligue para a Central de Empréstimos de Valores Mobiliários em (212) 379-5450 se tiver alguma dúvida.'
                    : 'As taxas de empréstimo de ações exibidas são indicativas e podem não representar as taxas reais pagas para emprestar o título...',
            viewMore: 'Veja mais',
            viewLess: 'Ver menos'
        },
        offshore: {
            disclosure: {
                title: 'Informações importantes sobre negociação de fundos mútuos offshore',
                shortDisclosure: 'Informações importantes sobre negociação de fundos mútuos offshore.',
                longDisclosure: [
                    {
                        shade: 'dark',
                        text: 'Os tempos limite, períodos de liquidação e períodos de pré-notificação do Fundo Mútuo Offshore variam de acordo com o fundo. Você receberá o primeiro NAV disponível emitido pelo fundo. Consulte o prospecto do fundo para saber os prazos limite, taxas do fundo e outras informações importantes antes de investir. Os pedidos de fundos mútuos offshore não podem ser cancelados ou modificados através do sistema depois de enviados. Entre em contato com o suporte para solicitar um cancelamento ou modificação.'
                    }
                ]
            }
        },
        crypto: {
            disclosure: {
                title: 'Informações sobre Comércio e Contas de Criptomoedas',
                shortDisclosure:
                    'O comércio de criptomoedas envolve risco significativo e pode resultar na perda do seu capital investido. Por favor, negocie com responsabilidade.',
                longDisclosure: [
                    {
                        text: 'Todas as atividades de comércio de criptomoedas facilitadas através do StoneX One Pro são realizadas via ETANA, um fornecedor de serviços terceirizado de confiança. Esteja ciente de que, ao negociar criptomoedas, você está interagindo diretamente com a ETANA, e todas as operações são executadas em seus sistemas.',
                        lineBreak: 'double'
                    },
                    {
                        text: '\n\nAlém disso, suas contas de criptomoedas são abertas e mantidas na ETANA. Isso significa que a ETANA é responsável por manter, proteger e gerenciar seus ativos de criptomoedas. O StoneX One Pro serve como um canal para os serviços da ETANA, proporcionando acesso ao ambiente de negociação.',
                        lineBreak: 'double'
                    },
                    {
                        text: '\n\nPor favor, observe os seguintes pontos chave:',
                        lineBreak: 'double'
                    },
                    {
                        text: '\n1.'
                    },
                    {
                        shade: 'dark',
                        text: 'Execução de Todas as Operações'
                    },
                    {
                        text: ': Todas as operações de criptomoedas iniciadas no StoneX One Pro são executadas pela ETANA. Não executamos nenhuma operação em nossos sistemas.',
                        lineBreak: true
                    },
                    {
                        text: '\n2.'
                    },
                    {
                        shade: 'dark',
                        text: 'Gestão de Contas'
                    },
                    {
                        text: ': Suas contas de criptomoedas, incluindo todos os depósitos e retiradas, são gerenciadas pela ETANA.',
                        lineBreak: true
                    },
                    {
                        text: '\n3.'
                    },
                    {
                        shade: 'dark',
                        text: 'Segurança e Custódia'
                    },
                    {
                        text: ': A ETANA é responsável pela segurança e custódia de seus ativos de criptomoedas. A StoneX Financial não mantém nem assegura diretamente nenhum ativo de criptomoedas.',
                        lineBreak: true
                    },
                    {
                        text: '\n4.'
                    },
                    {
                        shade: 'dark',
                        text: 'Taxas e Encargos'
                    },
                    {
                        text: ': Quaisquer taxas ou encargos relacionados ao comércio de criptomoedas serão determinados e cobrados pela ETANA. Consulte a tabela de taxas da ETANA para obter informações detalhadas.',
                        lineBreak: true
                    },
                    {
                        text: '\n5.'
                    },
                    {
                        shade: 'dark',
                        text: 'Supervisão Regulatória'
                    },
                    {
                        text: ': A ETANA opera sob seu próprio quadro regulatório. É essencial revisar os termos de serviço, a política de privacidade e qualquer divulgação regulatória fornecida pela ETANA.',
                        lineBreak: 'double'
                    },
                    {
                        text: '\n\nAo usar o StoneX One Pro para o comércio de criptomoedas, você reconhece e aceita esses termos. É importante realizar sua própria diligência e entender os riscos envolvidos no comércio de criptomoedas. Para quaisquer problemas ou perguntas relacionadas às suas contas ou operações de criptomoedas, entre em contato diretamente com a ETANA.',
                        lineBreak: 'double'
                    },
                    {
                        shade: 'dark',
                        text: '\n\nAviso'
                    },
                    {
                        text: ': O comércio de criptomoedas envolve risco significativo e pode resultar na perda do seu capital investido. Por favor, negocie com responsabilidade.'
                    }
                ]
            }
        }
    },

    orders: {
        multipleOrders: 'Múltiplos pedidos',
        accountNumber: 'Número de conta',
        account: 'Conta',
        acct: 'Conta',
        contractSymbol: 'Símbolo do contrato',
        dateAndTime: 'Data e hora',
        filledAmount: 'Quantidade preenchida',
        fillPrice: 'Preencher preço',
        onClose: 'Perto',
        order: 'Ordem',
        openOrders: 'Pedidos em aberto',
        tradeHistory: 'Histórico de comércio',
        orderHistory: 'Histórico de pedidos',
        tradeConfirmation: 'Ver confirmações de negociação',
        expirationDate: 'Data de validade',
        true: 'Verdadeira',
        spreadSymbol: 'Símbolo de propagação',
        buySide: 'Vender lado',
        sellSide: 'Lado de compra',
        fees: 'Taxas',
        completedDate: 'Data de conclusão',
        averageTradePrice: 'Preço médio',
        actionSummary: (props: ActionSummaryProps): string => {
            const action = Snex1Portuguese.orders.typedAction(props.order);
            const marketPriceText = props?.format === 'short' ? 'MP' : 'market price';
            return getActionSummary({ ...props, action, marketPriceText });
        },
        stratValue: (strat) => {
            switch (strat) {
                case 'Care':
                    return 'Care';
                case 'SOR':
                    return 'SOR';
                case 'VWAP':
                    return 'VWAP';
                case 'TWAP':
                    return 'TWAP';
                case 'POV2':
                    return 'POV 2%';
                case 'POV5':
                    return 'POV 5%';
                case 'POV10':
                    return 'POV 10%';
                case 'POV15':
                    return 'POV 15%';
                case 'POV20':
                    return 'POV 20%';
                case 'POV25':
                    return 'POV 25%';
            }
        },

        actionSummary2: (o, opt, hideMoneySymbol) => {
            const act = Snex1Portuguese.orders.typedAction(o);
            const q = Snex1Portuguese.orders.quantityPriceSummary2(o, opt, hideMoneySymbol);
            switch (opt?.format) {
                case 'short':
                    return `${act} ${q}`;
                case 'long':
                default:
                    return `${act} ${q}`;
            }
        },

        quantityPriceSummary2: (o, opt, hideMoneySymbol) => {
            const ac = GetAssetClassForSecurity(o.symbol);
            const orderType = DetermineOrderType(o);
            if (o.quantityQualifier === 'EvenDollar') {
                return `${FormatNumber.toMoney(o?.orderQuantity)}`;
            }

            const amt: string = (() => {
                const fmt = (n: number): string => FormatNumber.toMoney(n, undefined, hideMoneySymbol ? 'never' : undefined, true, opt?.decimalPlaces || 2);
                const mp = opt?.format === 'short' ? 'MP' : 'market price';
                switch (orderType) {
                    case 'limit':
                    case 'stoplimit':
                        return fmt(o.limitPrice);
                    case 'stop':
                        return fmt(o.stopPrice);
                    case 'market':
                        return mp;
                    case 'even':
                        return 'até';
                }
            })();
            return `${ac.formatOrderQuantity(o)} @ ${amt}`;
        },

        fullActionTypeToOpenOrClose: (o) => {
            switch (o.action?.toLowerCase()) {
                case 'buytoopen':
                    return 'Buy to open';
                case 'buytoclose':
                    return 'Buy to close';
                case 'selltoopen':
                    return 'Sell to open';
                case 'selltoclose':
                    return 'Sell to close';
                case 'sellshort':
                    return 'Short sell';
                default:
                    return o.action;
            }
        },
        numberFilledOfTotal: (o) =>
            typeof o.leavesQuantity === 'number'
                ? `${FormatNumber.toDecimal(o.orderQuantity - o.leavesQuantity)} of ${FormatNumber.toDecimal(o.orderQuantity)} filled`
                : '',
        action: (a, secType) => {
            const actions = {
                buytoopen: secType !== 'futures' ? 'comprar para abrir' : 'compre',
                buytoclose: secType !== 'futures' ? 'comprar para fechar' : 'compre',
                selltoopen: secType !== 'futures' ? 'vender para abrir' : 'vender',
                selltoclose: secType !== 'futures' ? 'vender para fechar' : 'vender',
                sellshort: 'short sell',
                buytocover: 'buy to cover',
                selltocover: 'sell to cover'
            };

            const actionInput = (a || '')?.toLowerCase();
            if (actionInput === 'fullliquidation') return 'full liquidation';
            const action = actions[actionInput] || actionInput;

            return action;
        },
        multiLegAction: (o) => {
            const actionInput = (o?.action || '')?.toLowerCase();
            const leg2Input = (o?.complexOrders?.[0]?.action || '')?.toLowerCase();
            return actionInput === leg2Input
                ? `${Snex1Portuguese.orders.action(actionInput)} 2`
                : `${Snex1Portuguese.orders.action(actionInput)} 1, ${Snex1Portuguese.orders.action(leg2Input)} 1`;
        },
        typedAction: (o, secType) => {
            const orderType = DetermineOrderType(o);

            const orderTypeLabel = (() => {
                switch (orderType) {
                    case 'market':
                        return 'mercado';
                    case 'limit':
                        return 'limite';
                    case 'stop':
                        return 'Pare';
                    case 'stoplimit':
                        return 'limite de parada';
                    case 'even':
                        return 'até';
                }
            })();

            return `${orderTypeLabel} ${Snex1Portuguese.orders.action(o.action, secType)}`;
        },
        orderSetup: {
            orderSetup: 'Configuração do pedido',
            expires: 'Expira',
            orderType: 'Tipo de pedido',
            quantityType: 'Tipo de quantidade',
            notional: 'Nocional',
            algorithm: 'Estratégia Algoritmo',
            stratPercent: 'Porcentagem de Estratégia',
            sellAll: 'Vender tudo',
            done: 'Feito',
            discardTitle: 'Descartar',
            discardChanges: 'Descartar alterações',
            discardChangesMsg: 'Você tem alterações não salvas. Tem certeza de que deseja descartar suas alterações?',
            stayHere: 'Fique aqui',

            action: 'Action',
            actionBottomSheet: {
                buySell: 'Compra-Venda',
                sellBuy: 'Venda-Compra',
                buyTheEarlierAndSellTheLater: 'Compre o contrato anterior e venda o contrato posterior',
                sellTheEalierAndBuyTheLater: 'Venda o contrato anterior e compre o contrato posterior',
                buying: 'Comprando',
                selling: 'Vendendo'
            },

            expiresBottomsheet: {
                expires: 'Expira',
                goodThruTitle: 'Bom dia',
                goodThruSubtitle: 'A ordem será válida até o final do dia de negociação atual.',
                goodTillCancelled: 'Bom até Cancelado (GTC)',
                goodTillCancelltedSubtitle: 'O pedido será válido até o cancelamento, por no máximo 180 dias.',
                dayOrdersUnavailable: 'As encomendas do dia não estão disponíveis neste momento',
                gtcOrdersUnavailable: 'Ordens GTC não estão disponíveis no momento',
                premarketUnavailable: 'Pedidos de pré-mercado não estão disponíveis neste momento',
                postMarketUnavailable: 'Ordens pós-mercado não estão disponíveis neste momento',

                preMarketExecution: 'Execução de pré-mercado',
                preMarketActive: 'A encomenda estará ativa e poderá ser executada entre as 8:00 e as 17:00 horas EST',
                postMarketExcution: 'Execução pós-mercado',
                postMarketActive: 'A ordem estará ativa apenas durante a sessão da tarde de hoje e poderá ser executada entre 16h e 17h EST.',
                goodTilCancelActive: 'O pedido ficará ativo até ser cancelado, por no máximo 180 dias.',
                goodThruDayActive: 'A ordem estará ativa até o final do dia de negociação atual.',

                fillOrKillExecution: 'Fill Or Kill',
                fillOrKillActive: 'Fill entire order immediately or cancel it'
            }
        },
        quantity: 'Quantidad',
        commission: 'Comissão',
        stopPrice: 'Preço de parada',
        limitPrice: 'Preço Limite',
        totalCost: 'Custo total',
        orderTotalImpactLabel: (action: 'Buy' | 'Sell' | string) => {
            const regex = /sell/i; // This will match any variation of 'Sell'
            const containsSell = regex.test(action);
            return containsSell ? 'Crédito Total' : 'Custo Total';
        },
        placed: 'Colocado',
        swipeUpTrade: 'Deslize para cima para negociar',
        by: (who: string) => `by ${who}`,
        timeInForce: 'Tempo em vigor',
        gtxPre: 'Pré',
        gtxPost: 'Pós',
        isin: 'ISIN',
        strategy: 'Estratégia',
        averagePrice: 'Preço Médio',
        actionLabel: 'Ação',

        placedBy: 'Colocado por',
        securityName: 'Nome de segurança',
        contactRepToChange: 'Para cancelar ou fazer alterações em seu pedido, entre em contato com o Suporte.',
        cannotBeCanceled: 'O pedido não pode ser cancelado ou modificado',
        lockedForOpen: 'O pedido está bloqueado para o aberto',
        orderNumber: 'Número do pedido',
        orderNumbers: 'Números de pedidos',
        cancelingOrder: 'Cancelamento de pedido',
        orderCanceled: 'Pedido cancelado',
        orderModified: 'Pedido modificado',
        orderFilled: 'Pedido preenchido',
        orderPartiallyFilled: 'Pedido parcialmente preenchido',
        orderRejected: 'Pedido Rejeitado',
        orderStopped: 'Order Stopped',
        orderSuspended: 'Order Suspended',
        orderBiddingOpen: 'Bidding Open for Order',
        orderPlaced: 'Pedido feito',
        orderId: 'Código do pedido',
        cancelOrder: 'Cancelar pedido',
        editOrder: 'Editar pedido',
        edit: 'Editar',
        cancel: 'Cancelar',
        orderAgain: 'Peça novamente',
        cannotCancelEditOrder: (action: string) => {
            return action === 'edit' ? 'Não é possível editar o pedido' : 'Não é possível cancelar o pedido';
        },
        cannotCancelEditSublabel: (action: string) => {
            return action === 'edit' ? 'Este pedido não pode ser editado neste momento' : 'Este pedido não pode ser cancelado neste momento';
        },
        contactLabel: (useSupport: boolean) => {
            return useSupport ? 'Contactar suporte' : 'Contactar representante';
        },
        contactSubLabel: (onlyEdit: boolean, useSupport: boolean, editAndCancel?: boolean) => {
            return `Este pedido só pode ser ${onlyEdit ? 'editado' : editAndCancel ? 'editado ou cancelado' : 'cancelado'} contactando ${
                useSupport ? 'o Suporte' : 'seu representante'
            }`;
        },
        partialFill: (symbol, filled, quantity) => {
            const unit = OptionSymbol.IsOptionSymbol(symbol) ? 'Contratos' : 'Ações';
            return `${filled} de ${quantity} ${unit} preenchidos`;
        },
        fills: {
            sequenceNumber: 'Número de Sequência de Preenchimento',
            price: 'Preço de preenchimento',
            quantity: 'Quantidade',
            timestamp: 'Carimbo de data e hora'
        },
        statuses: {
            open: 'Aberto',
            working: 'Trabalhando',
            pending: 'Pendente',
            completed: 'Concluído',
            filled: 'Preenchido',
            cancelled: 'Cancelado',
            cancelPending: 'Cancelar pendente',
            cancelPartialFill: 'Parcialmente cancelado',
            allocated: 'Alocado',
            preAllocated: 'Pré-alocado',
            created: 'Criado',
            expired: 'Expirado',
            route: 'Rota',
            routed: 'Encaminhado',
            verification: 'Verificação',
            change: 'Variação',
            notProcessed: 'Não processado',
            processed: 'Processado',
            confirmed: 'Confirmado',
            cancel: 'Cancelar',
            canceled: 'Cancelado',
            rejected: 'Rejeitado',
            unknown: 'Desconhecido',
            deleted: 'Excluído'
        }
    },

    messages: {
        subjectByPayload: {
            'System:AdminBroadcast': 'Anúncio',
            'System:AdminDirect': 'Mensagem do administrador',
            'System:Welcome': 'Receber!',
            'CustomAlert:Default': 'Alerta',
            'OrderUpdate:New': 'Pedido aberto',
            'OrderUpdate:Filled': 'Pedido preenchido',
            'OrderUpdate:Rejected': 'Pedido rejeitado',
            'OrderUpdate:Canceled': 'Pedido cancelado',
            defaultOrderUpdate: 'Atualização do pedido'
        }
    },

    tours: {
        stepsBadgePreposition: 'de',
        lastStepButtonText: 'Feito',
        portfolioScreen: {
            chart: [[{ text: 'Acompanhe o seu ' }, { text: 'portfólio', weight: 'bold' }, { text: ' usando o gráfico de portfólio' }]],
            watchlist: [
                [
                    {
                        text: 'As posições de todas as suas contas são exibidas aqui'
                    }
                ],
                [
                    { text: 'Você também pode' },
                    {
                        text: 'personalize sua lista de observação',
                        weight: 'bold'
                    },
                    { text: '.' }
                ]
            ],
            depositFunds: [
                [
                    { text: 'Clique aqui para' },
                    { text: 'fundos de depósito', weight: 'bold' },
                    {
                        text: '. Depois de depositar fundos, você pode começar a negociar!'
                    }
                ]
            ],
            search: [
                [
                    {
                        text: 'Pesquise ações, fundos mútuos ou qualquer outra coisa!'
                    }
                ]
            ],
            profile: [
                [
                    { text: 'Clique na imagem do perfil aqui para ver o seu ' },
                    {
                        text: 'configurações de conta, transações e muito mais',
                        weight: 'bold'
                    },
                    { text: '!' }
                ]
            ]
        },
        stockScreen: {
            candlestick: [
                [{ text: 'Quer um' }, { text: 'castiçal', weight: 'bold' }, { text: ' gráfico?' }],
                [
                    {
                        text: 'Clique neste ícone para alternar entre um gráfico de linha e de velas.'
                    }
                ]
            ],
            advancedChart: [
                [
                    { text: 'Nosso ' },
                    { text: 'gráfico avançado', weight: 'bold' },
                    {
                        text: ' dá acesso a um arsenal de ferramentas de gráficos e a capacidade de '
                    },
                    { text: 'negocie diretamente do gráfico', weight: 'bold' },
                    { text: '.' }
                ]
            ],
            addToWatchlist: [[{ text: 'Quer ficar de olho nessa segurança? ' }, { text: 'Adicione-o às suas Watchlist', weight: 'bold' }, { text: '.' }]],
            orderType: [
                [{ text: 'Modifique o ' }, { text: 'tipo de pedido', weight: 'bold' }, { text: ' clicando no ícone de lápis aqui.' }],
                [{ text: '(mercado, limite, parada, etc.)' }]
            ],
            optionsTrading: [[{ text: 'Você pode acessar o ' }, { text: 'cadeia de opções', weight: 'bold' }, { text: ' para esta segurança clicando aqui.' }]]
        },
        premiumStockScreen: {
            advancedChart: [
                [{ text: 'Multichart em uma única tela' }],
                [{ text: 'Clique aqui para realizar' }, { text: 'análise técnica', weight: 'bold' }, { text: ' em uma única vista!' }]
            ],
            alerts: [
                [{ text: 'Defina alertas rápidos' }],
                [
                    { text: 'Clique no ícone do sino para configurar ' },
                    { text: 'gatilhos', weight: 'bold' },
                    { text: ' baseado em' },
                    {
                        text: 'movimentos de preços em tempo real',
                        weight: 'bold'
                    },
                    { text: '. Seja o primeiro a saber!' }
                ]
            ],
            deepAnalysis: [
                [{ text: 'Mergulhe com dados' }],
                [
                    {
                        text: 'Clique aqui para obter uma visão abrangente que combina a visão da empresa com dados de mercado em tempo real.'
                    }
                ]
            ],
            streamingNews: [
                [{ text: 'Você tem novo em tempo real!' }],
                [
                    {
                        text: 'A notícia mais recente aparece no topo no momento em que é publicada.'
                    }
                ]
            ]
        },
        tickerBar: {
            tradingViewMarquee: [[{ text: '在股票行情栏上，水平浏览指数、商品等。 ' }], [{ text: '点击符号了解更多信息。您可以从您的个人资料设置中打开/关闭此功能' }]]
        }
    },

    mobileTours: {
        summaryScreen: {
            watchlist: {
                text: 'Deslize para cima para ver e gerenciar suas listas de observação',
                name: 'lista de observação'
            }
        },
        securityScreen: {
            alerts: {
                text: 'Toque aqui para gerenciar seus alertas para esta segurança',
                name: 'Alertas'
            },
            chartToggle: {
                text: 'Alterne entre visualizações de gráfico (linha/castiçal) simplesmente tocando aqui',
                name: 'Alternar gráfico'
            },
            advancedChart: {
                text: 'Toque aqui para abrir o gráfico avançado, com recursos como indicadores na ponta dos dedos',
                name: 'Gráfico Avançado'
            },
            trade: {
                text: 'Deslize para cima a partir daqui para abrir o ticket de negociação e começar a negociar!',
                name: 'Negociação'
            }
        },
        navigateToursAlert: {
            title: 'Ver Passeios',
            message: 'Navegue pelo aplicativo para ver os passeios.'
        }
    },

    newFeatures: {
        advancedOptionTrading: {
            title: 'Negociação Avançada de Opções',
            subtitle: 'A negociação de opções multi-leg envolve a execução de várias operações de opções de uma vez para criar uma estratégia específica.',
            item1Title: 'Duas Operações em Uma',
            item1Subtitle:
                'A negociação de opções multi-leg envolve a execução de várias operações de opções simultaneamente. Rolar posições cobertas e longas existentes em um único ticket de negociação. Criar spreads e straddles.',
            item2Title: 'Controle de Risco-Retorno',
            item2Subtitle: 'Ao combinar opções, você pode criar perfis específicos de risco-retorno.',
            item3Title: 'Teste Suas Previsões de Mercado',
            item3Subtitle:
                'Diferentes estratégias de opções podem ser empregadas para aumentar ou proteger sua carteira com base em suas previsões sobre movimentos de preço, mudanças na volatilidade ou depreciação do tempo.'
        },
        cryptoTrading: {
            title: 'Criptomoedas agora estão disponíveis!',
            subtitle: 'Inicie sua jornada: Compre e venda criptomoedas com facilidade e abrace o futuro das finanças!',
            item1Title: 'Execução de operações',
            item1Subtitle:
                'Todas as operações de criptomoedas iniciadas no StoneX One Pro são executadas pela ETANA. Não executamos nenhuma operação em nossos sistemas.',
            item2Title: 'Gestão de contas',
            item2Subtitle: 'Suas contas de criptomoedas, incluindo todos os depósitos e saques, são geridas pela ETANA.',
            item3Title: 'Segurança e custódia',
            item3Subtitle:
                'A ETANA é responsável pela segurança e custódia dos seus ativos de criptomoedas. A StoneX Financial não detém nem assegura diretamente nenhum ativo de criptomoedas.'
        }
    },

    miniplayer: {
        loading: (title: string | null) => `Carregando ${title || 'meios de comunicação'}...`,
        noTranscriptAvailable: 'Nenhuma transcrição disponível',
        noMediaAvailable: 'Nenhuma mídia disponível',
        viewTranscript: 'Ver transcrição'
    },

    modals: {
        done: 'Feito',
        appIdle: {
            modalHeader: 'Você está ocioso!',
            noticedInactivity: 'Percebemos que você está inativo há algum tempo',
            logoutInSeconds: (seconds: number) => `Se nenhuma ação for tomada, você será desconectado ${seconds} segundos.`,
            logout: 'Sair',
            stay: 'Fique'
        },
        addManyToListContent: {
            title: 'Adicione uma segurança usando a pesquisa acima',
            button: 'Adicionar à lista de observação',
            addSecurity: 'Adicionar títulos'
        },
        addToWatchlist: {
            addSymbolToWatchlist: (symbol: string) => `Adicionar ${symbol} à lista de interesses`,
            createAList: 'Crie uma lista',
            newListName: 'Nome da nova lista',
            create: 'Criar',
            creating: 'Criando...'
        },
        viewWatchlistDetails: 'Ver detalhes da lista de observação',
        deleteWatchlist: {
            deleteThisList: 'Excluir esta lista',
            areYouSure: (listName: string) => [
                [
                    {
                        text: 'Tem certeza de que deseja excluir permanentemente '
                    },
                    { text: listName, weight: 'bold' },
                    { text: '? Isto não pode ser desfeito.' }
                ]
            ],
            areYouSureShaded: (listName: string) => [
                {
                    text: 'Tem certeza de que deseja excluir permanentemente ',
                    shade: 'light'
                },
                { text: listName, shade: 'dark' },
                { text: '? Isto não pode ser desfeito.', shade: 'light' }
            ],
            yesDeleteList: (listName: string) => `Yes, delete ${listName}`,
            delete: 'Excluir',
            deleting: 'Excluindo...'
        },
        editWatchlistDetails: {
            editList: (listName: string) => `Edit ${listName}`,
            name: 'Nome da lista de observação...',
            description: 'Descrição da lista de observação...',
            save: 'Salve',
            saving: 'Salvando...'
        },
        deleteAllMessages: {
            label: 'Excluir todas as mensagens',
            body: 'Tem certeza de que deseja excluir todas as mensagens?',
            no: 'Não',
            yes: 'Sim'
        },
        cancelOrder: {
            orderCancelled: 'Pedido cancelado!',
            cancelOrder: 'Cancelar pedido',
            cancelOrderFailed: 'Falha no cancelamento do pedido!',
            retry: 'Tente novamente ou entre em contato com o suporte se o problema persistir.',
            areYouSure: 'Você tem certeza que quer cancelar seu pedido?',
            cancelLabel: 'Não importa',
            ctrlKeyTip: [
                [
                    { text: 'Gorjeta:', weight: 'bold' },
                    { text: ' Segure o ' },
                    { text: ' Ctrl ', weight: 'bold' },
                    { text: ' or ' },
                    { text: ' Command ⌘ ', weight: 'bold' },
                    {
                        text: ' tecla enquanto cancela um pedido para ignorar esta confirmação '
                    }
                ]
            ],
            cancel: 'Confirmar cancelamento',
            retryCancel: 'Tentar cancelar novamente',
            canceling: 'Cancelando...'
        },
        rename: {
            rename: 'Renomear',
            save: 'Salve',
            saving: 'Salvando...'
        },
        errorModal: {
            unknownError: 'Ocorreu um erro desconhecido'
        },
        existingAccountModal: {
            cannotRemoveAcctTitle: 'Não é possível remover a conta',
            cannotRemoveAcctDesc: 'Desculpe, as contas só podem ser removidas uma vez a cada seis meses. Entre em contato com o suporte se quiser removê-lo mais cedo.',
            cannotRemoveAcctConfirm: 'Confirme',
            removeLinkedAcctTitle: 'Remover conta vinculada',
            removeLinkedAcctConfirm: (name: string) => `Tem certeza de que deseja remover ${name} como uma conta vinculada`,
            yesRemoveAcct: 'Sim, remover conta',
            noKeppAcct: 'Não, manter conta',
            loadingYourAcct: 'Carregando suas contas...',
            voidingAcct: 'Anulando conta...',
            addAnAcct: 'Adicionar uma conta'
        },
        linkedAccountsModal: {
            retrivingLinkableAcct: 'Recuperando suas contas vinculáveis...',
            linkingAcctWait: 'Vinculando sua conta, por favor aguarde...'
        },
        plaidModal: {
            plaidCompleted: 'Plaid completado sem devolver uma conta',
            overlimitAccounts: 'Desculpe, você não pode adicionar uma conta neste momento.',
            holdTight: 'Segure firme, adicionando sua conta',
            verifyingBankAuth: 'Verificando sua autorização bancária...'
        }
    },

    toasts: {
        error: 'Erro',
        sessionExpired: 'Sessão expirada',
        logInAgain: 'Faça login novamente',
        orderSubmitted: 'Pedido Enviado!',
        onboardingSuccess: () => {
            return 'Parabéns! Sua conta está sendo processada e aparecerá em breve.';
        },
        onboardingSuccessTitle: (accountNumber) => {
            return `Sua conta de futuros (${accountNumber}) está sendo criada.`;
        },
        orderSubmittedContent: (trade: Partial<Order>) =>
            `Seu pedido para ${trade?.action?.toUpperCase()} ${trade?.orderQuantity} ${Snex1Portuguese.general.unit(trade?.symbol, trade?.orderQuantity)} of ${
                trade?.symbol
            } foi submetido (${trade?.orderNumber})`,
        orderUpdateTitle: (update) => {
            const name = update.symbol ? Snex1Portuguese.general.symbolName(update.symbol) : update.symbol || update.securityName || update.securityNumber;
            return name ? `Order Update: ${name}` : 'Atualização do pedido';
        },
        orderUpdate: (update, message) => {
            const action = (() => {
                switch (update.action) {
                    case 'Buy':
                        return 'Comprar';
                    case 'Sell':
                        return 'vender';
                    case 'BuyToOpen':
                        return 'compre para abertas';
                    case 'SellToOpen':
                        return 'vender para abertas';
                    case 'BuyToClose':
                        return 'compre para fechar';
                    case 'SellToClose':
                        return 'vender para fechar';
                    default:
                        return 'troca';
                }
            })();

            const statusPhrase = (() => {
                switch (update.orderStatus) {
                    case 'Allocated':
                        return 'foi alocado';
                    case 'Verification':
                        return 'está sendo verificado';
                    case 'Created':
                        return 'foi criado';
                    case 'Open':
                        return FuturesSymbol.IsFuturesSymbol(update.symbol) ? 'is now working' : 'agora está aberto';
                    case 'Route':
                        return 'está pronto para ser encaminhado';
                    case 'Routed':
                        return 'foi roteado';
                    case 'Cancel':
                        return 'está pronto para o cancelamento';
                    case 'Canceled':
                        return 'foi cancelado';
                    case 'Filled':
                        return 'Foi preenchido';
                    case 'Rejected':
                        return 'foi rejeitado';
                    case 'Deleted':
                        return 'foi deletado';
                    case 'Expired':
                        return 'expirou';
                    case 'Pending':
                        return 'pendente';
                    case 'Completed':
                        return 'Completou';
                    case 'Cancel Pending':
                        return 'está pendente de cancelamento';
                    case 'Pre-Allocated':
                        return 'foi pré-alocado';
                    case 'Cancel Partial Fill':
                    case 'Unknown':
                    default:
                        return `foi atualizado para o status "${update.orderStatus}"`;
                }
            })();

            const sym = update.symbol ? Snex1Portuguese.general.symbolName(update.symbol) : update.securityName || update.securityNumber;

            if (message.payloadType === 'OrderUpdate:Rebook') {
                return `Sua edição ${sym} pedido já está em vigor`;
            }

            if (update.orderQuantity === null) {
                return `Seu pedido ${update.action ? `para ${action}` : 'por'} ${sym} ${message?.data['filledAt']}${statusPhrase} (${update.orderNumber})`;
            }
            return `Seu pedido ${update.action ? `para ${action}` : 'por'} ${update.orderQuantity} ${Snex1Portuguese.general.unit(
                update.symbol,
                update.orderQuantity
            )} de ${sym} ${statusPhrase} (${update.orderNumber})`;
        }
    },

    infoIconHelpText: {
        client: {
            aboutSecurity: [
                [
                    {
                        text: 'Certos dados fundamentais, de mercado ou outras informações são fornecidas direta ou indiretamente por provedores de dados terceirizados e podem variar de outras fontes.'
                    }
                ]
            ],
            aboutSrri: [
                [
                    {
                        text: 'O Indicador Sintético de Risco e Recompensa (ou SRRI) é uma parte significativa do Documento de Informações Fundamentais ao Investidor (ou KIID). O SRRI é utilizado para indicar o nível de risco de um fundo OICVM formulando um número de 1 a 7, onde 1 indica um risco menor e 7 indica um risco mais elevado.'
                    }
                ]
            ],
            acatTransfer: [
                [
                    {
                        text: 'A rejeição de uma solicitação de transferência ACATS normalmente é iniciada por ação do corretor de entrega, uma vez que o corretor teve a oportunidade de revisar a solicitação e confirmar os detalhes da conta a ser transferida. No caso de certos avisos de rejeição (ou seja, categorias 1-5 e 10 abaixo), o processo ACATS oferece ao corretor receptor (IB) uma janela de 24 horas dentro da qual as informações revisadas podem ser transmitidas e após o qual a solicitação de transferência exigirá reenvio pelo cliente. Durante esse período de 24 horas, o IB tentará entrar em contato com o cliente transferidor para tentar conciliar quaisquer discrepâncias que causem o aviso de rejeição inicial. As rejeições gerais se enquadram nas seguintes categorias:'
                    }
                ],
                [{ text: 'Rejeições pelo Corretor de Entrega:' }],
                [
                    {
                        text: '1. Incompatibilidade de CPF/CNPJ - o número de identificação do contribuinte do cliente conforme fornecido pelo corretor receptor não concorda com o dos registros do corretor entregador.'
                    }
                ],
                [
                    {
                        text: '2. Incompatibilidade de Título da Conta - o título da conta do cliente, conforme fornecido pelo corretor de recebimento, não concorda com os registros do corretor de entrega.'
                    }
                ],
                [
                    {
                        text: '3. Documentação necessária - o corretor de entrega exige documentação adicional da conta para processar uma solicitação de transferência (por exemplo, documentos legais de morte ou casamento.'
                    }
                ],
                [
                    {
                        text: '4. Conta Simples - a conta não possui ativos transferíveis.'
                    }
                ],
                [
                    {
                        text: '5. Número de conta inválido - o número de conta do cliente fornecido pelo corretor de recebimento não é o livro do membro que entrega.'
                    }
                ],
                [
                    {
                        text: '6. Duplicado - o corretor de entrega já está recebendo uma solicitação de transferência envolvendo o mesmo corretor de recebimento e conta do cliente.'
                    }
                ],
                [
                    {
                        text: '7. Conta em Distribuição ou Transferência - outra transferência ACATS ou outra distribuição de ativos da conta diretamente para o cliente já está em andamento.'
                    }
                ],
                [
                    {
                        text: '8. Cliente rescindido - o cliente enviou uma solicitação por escrito para cancelar a solicitação de transferência.'
                    }
                ],
                [
                    {
                        text: '9. Assinatura de autorização ausente - a solicitação de transferência requer uma assinatura adicional de cliente e/ou custodiante.'
                    }
                ],
                [{ text: 'Rejeições pela Corretora Receptora:' }],
                [
                    {
                        text: '10. Violação de Crédito - o resultado da transferência se efetivada seria colocar a conta em déficit de margem e sujeita a liquidação forçada.'
                    }
                ]
            ],
            buyingPower: [
                [
                    {
                        text: 'Seu poder de compra é a quantidade total de dinheiro na conta disponível para comprar títulos mais toda a margem disponível.'
                    }
                ],
                DelayedParagraph
            ],
            topMoversAndSectors: [
                [
                    {
                        text: 'As listas de setores e principais movimentadores/ganhadores/perdedores são geradas usando dados provenientes de provedores de dados de terceiros. Uma lista de empresas em um setor de negócios específico pode exibir uma empresa se os dados de origem indicarem que a empresa obtém uma parte de suas receitas do setor relevante. A lista é fornecida apenas para fins informativos e não é um conselho de investimento ou uma recomendação para comprar, manter ou vender qualquer título. As listas não são recomendações personalizadas para você ou qualquer outro cliente, e os títulos listados podem não ser adequados para você. Você não deve comprar ou vender qualquer título sem primeiro determinar que é apropriado para seu portfólio ou estratégia de investimento. A StoneX não oferece nenhuma garantia ou garantia relativa à exatidão, pontualidade ou integridade de qualquer informação de terceiros, e o fornecimento dessas informações não constitui uma recomendação.'
                    }
                ],
                DelayedParagraph
            ],
            news: [
                [
                    {
                        text: 'Notícias, comentários e eventos são de fontes de terceiros não afiliadas à StoneX. StoneX não endossa ou adota seu conteúdo. A StoneX não garante que as informações fornecidas sejam precisas, completas ou oportunas e não oferece nenhuma garantia quanto aos resultados obtidos com seu uso.'
                    }
                ]
            ],
            portfolioValue: [
                [
                    {
                        text: 'O valor de sua carteira é o valor total de mercado de todas as posições na conta, incluindo saldos em dinheiro, menos quaisquer saldos devedores pendentes e qualquer valor necessário para cobrir posições de opções curtas que estejam dentro do dinheiro.'
                    }
                ],
                DelayedParagraph
            ]
        },
        one: {
            aboutSecurity: [
                [
                    {
                        text: 'Certos dados fundamentais, de mercado ou outras informações são fornecidos direta ou indiretamente por provedores de dados terceirizados e podem variar de outras fontes.'
                    }
                ]
            ],
            aboutSrri: [
                [
                    {
                        text: 'O Indicador Sintético de Risco e Recompensa (ou SRRI) é uma parte significativa do Documento de Informações Fundamentais ao Investidor (ou KIID). O SRRI é utilizado para indicar o nível de risco de um fundo OICVM formulando um número de 1 a 7, onde 1 indica um risco menor e 7 indica um risco mais elevado.'
                    }
                ]
            ],
            acatTransfer: [
                [
                    {
                        text: 'The rejection of an ACATS transfer request is typically initiated by action of the delivering broker once that broker has had an opportunity to review the request and confirm the details of the account to be transferred. In the case of certain rejection notices (i.e., categories 1-5 and 10 below), the ACATS process affords the receiving broker (IB) a 24-hour window within which revised information may be transmitted and after which time the transfer request will require resubmission by the client. During this 24-hour window, IB will attempt to contact the transferring client in an effort to reconcile any discrepancies causing the initial rejection notice. Rejections general fall into the following categories:'
                    }
                ],
                [{ text: 'Rejections by the Delivering Broker:' }],
                [
                    {
                        text: "1. Social Security Number/Tax ID Mismatch - the client taxpayer identification number as provided by the receiving broker does not agree with that of the delivering broker's records."
                    }
                ],
                [
                    {
                        text: "2. Account Title Mismatch - the client account title as provided by the receiving broker does not agree with that of the delivering broker's records."
                    }
                ],
                [
                    {
                        text: '3. Documentation Needed - the delivering broker requires additional account documentation in order to process a transfer request (e.g., death or marriage legal documents.'
                    }
                ],
                [
                    {
                        text: '4. Account Flat - account holds no transferable assets.'
                    }
                ],
                [
                    {
                        text: "5. Invalid Account Number - the client account number as provided by the receiving broker is not the delivering member's books."
                    }
                ],
                [
                    {
                        text: '6. Duplicate - delivering broker is already in receipt of a transfer request involving the same receiving broker and client account.'
                    }
                ],
                [
                    {
                        text: '7. Account in Distribution or Transfer - another ACATS transfer or other distribution of account assets directly to the client is already underway.'
                    }
                ],
                [
                    {
                        text: '8. Client Rescinded - client has submitted written request to cancel transfer request.'
                    }
                ],
                [
                    {
                        text: '9. Missing Authorization Signature - transfer request require an additional client and/or custodian signature.'
                    }
                ],
                [{ text: 'Rejections by the Receiving Broker:' }],
                [
                    {
                        text: '10. Credit Violation - the result of the transfer if effected would be to place the account in margin deficit and subject to forced liquidation.'
                    }
                ]
            ],
            buyingPower: [
                [
                    {
                        text: 'Seu poder de compra é a quantidade total de dinheiro na conta disponível para comprar títulos mais toda a margem disponível.'
                    }
                ]
            ],
            topMoversAndSectors: [
                [
                    {
                        text: 'As listas de setores e principais movimentadores/ganhadores/perdedores são geradas usando dados provenientes de provedores de dados de terceiros. Uma lista de empresas em um setor de negócios específico pode exibir uma empresa se os dados de origem indicarem que a empresa obtém uma parte de suas receitas do setor relevante. A lista é fornecida apenas para fins informativos e não é um conselho de investimento ou uma recomendação para comprar, manter ou vender qualquer título. As listas não são recomendações personalizadas para você ou qualquer outro cliente, e os títulos listados podem não ser adequados para você. Você não deve comprar ou vender qualquer título sem primeiro determinar que é apropriado para seu portfólio ou estratégia de investimento. A StoneX não oferece nenhuma garantia ou garantia relativa à exatidão, pontualidade ou integridade de qualquer informação de terceiros, e o fornecimento dessas informações não constitui uma recomendação.'
                    }
                ]
            ],
            news: [
                [
                    {
                        text: 'Notícias, comentários e eventos são de fontes de terceiros não afiliadas à StoneX. StoneX não endossa ou adota seu conteúdo. A StoneX não garante que as informações fornecidas sejam precisas, completas ou oportunas e não oferece nenhuma garantia quanto aos resultados obtidos com seu uso.'
                    }
                ]
            ],
            portfolioValue: [
                [
                    {
                        text: 'O valor de sua carteira é o valor total de mercado de todas as posições na conta, incluindo saldos em dinheiro, menos quaisquer saldos devedores pendentes e qualquer valor necessário para cobrir posições de opções curtas que estejam dentro do dinheiro.'
                    }
                ]
            ],
            marketCap: [
                [
                    {
                        text: 'A capitalização de mercado é o valor estimado das ações em circulação de uma empresa. Dependendo do tamanho desse valor, as empresas são classificadas nas categorias Grande, Médio, Pequeno, Micro e Nano.'
                    }
                ]
            ]
        }
    },

    errors: {
        imageUploadError: 'Não foi possível carregar a imagem. Certifique-se de que o arquivo é uma imagem e menos de 10 MB.',
        accessRestricted: 'Acesso Restrito',
        loginDead: 'Oi! Vemos que você está tentando entrar no StoneX One!\nEntre em contato com o e-mail abaixo para ativar sua conta.',
        loginDeadWithDetail: (getIntoWhat: string, email: string): string =>
            `Olá! Vimos que está a tentar aceder a ${getIntoWhat}!\nEntre em contacto com o apoio técnico através das opções abaixo para obter a sua conta de utilizador com o e-mail ${email} ativado.`,
        noDataFound: 'Nenhum dado encontrado',
        trade: {
            generalTitle: 'Havia um problema',
            generalSubmit: 'Não conseguimos fazer seu pedido. Se você achar que chegou a isso por engano, entre em contato com o suporte.',
            generalVerify: 'Não foi possível validar seu pedido. Se você achar que chegou a isso por engano, entre em contato com o suporte.',
            restrictedError: 'Desculpe, a negociação foi restrita para este título.',
            cantVerify: 'Desculpe, não foi possível verificar seu pedido.',
            dimeProduct: 'Sorry, this product is only quoted in ten-cent increments. Please adjust your price to a multiple of ten cents and resubmit.',
            pennyProduct: 'Sorry, this product is only quoted in penny increments. Please adjust your price to a multiple of one cent and resubmit.',
            nickelProduct: 'Desculpe, este produto só é cotado em incrementos de cinco centavos. Ajuste seu preço para um múltiplo de cinco centavos e reenvie.',
            securityOutOfSession: 'Desculpe, este produto não pode ser negociado a esta hora do dia',
            accountRestricted: 'Esta conta foi restringida. Entre em contato conosco para reativar.',
            securityUnavailable: 'Desculpe, a negociação para este produto não está disponível em StoneX One.',
            fundTradingDenied: 'Desculpe, você não tem permissão para negociar fundos mútuos.',
            basicTradingDenied: 'Desculpe, você não tem permissão para negociar.',
            noBuy: 'Desculpe, a compra não é permitida para esta negociação',
            noOptionPosition: 'A quantidade de um pedido fechado não deve exceder a quantidade atualmente mantida',
            optionAgreement: 'Desculpe, sua conta não tem permissão para negociar opções.',
            optionLevel: 'Desculpe, sua conta não tem permissão para negociar opções dessa maneira.',
            optionPermission: 'Desculpe, você não tem permissão para negociar opções.',
            offHours: 'Desculpe, a negociação está fora do horário comercial. Por favor, tente novamente mais tarde.',
            generalNotAvailable: 'Não está disponível para negociação',
            notAvailable: (product: string) => `Desculpe, ${product} não está disponível para negociação no StoneX One`,
            sellStopLessThanMarket: 'Uma ordem de parada de venda requer um preço de parada menor que o preço de mercado atual.',
            buyStopGreaterThanMarket: 'Uma ordem de parada de compra requer um preço de parada maior que o preço de mercado atual.',
            sxDenied: 'Você não tem permissão para negociar. Se você acha que chegou a esta mensagem por engano, entre em contato com o suporte.',
            shortNotAllowed: 'A venda a descoberto não é permitida para este título.',
            thisIsAShortOrder: 'Você está vendendo ações que não possui atualmente. Este é um pedido curto.',
            adjustedCloseOnly: 'Desculpe, as opções ajustadas não podem ser abertas.',
            adjustedCloseOnlyMessage: 'Devido ao risco de liquidez e liquidação, só permitimos ordens de fechamento para posições de opções apertadas.',
            insufficientShortQuantity: (qty: number): string => {
                const sharePlurality = qty === 1 ? `Há 1 compartilhamento` : `São ${qty} compartilhamentos`;
                const sharesAvailable = qty === 0 ? `Não há ações` : sharePlurality;
                const reduceQuantityText = qty > 1 ? ' Por favor reduzca la cantidad solicitada.' : '';
                return `A quantidade disponível é insuficiente para autorizar esta solicitação. ${sharesAvailable}  disponíveis.${reduceQuantityText}`;
            },
            mutualFundQrpOnly:
                'A compra deste Fundo Mútuo dos EUA só está disponível para planos de reforma qualificados. Se achar que chegou a esta página por engano, contacte o Apoio ao Cliente.',
            mutualFundNoDomesticOnForeign:
                'As ordens de fundos mútuos dos EUA não são permitidas em contas estrangeiras. Se achar que chegou a esta página por engano, contacte o Apoio ao Cliente.',
            mutualFundNoForeignOnDomestic:
                'As ordens de Fundos Mútuos Offshore não são permitidas em contas domésticas. Se achar que chegou a esta página por engano, contacte o Apoio ao Cliente.'
        },
        alerts: {
            duplicateRuleMessage: 'Você já definiu uma regra de alerta com este conjunto de condições.',
            duplicateRuleTitle: 'Duplicar Regra de Alerta',
            somethingWentWrongMessage: 'Desculpe, algo deu errado. Por favor, tente novamente mais tarde.',
            unableToVerifyCode: 'Não foi possível verificar seu código, tente novamente.',
            temporarilyUnavailable: 'Temporariamente Indisponível',
            alertsTemporarilyUnavailableMessage:
                'Estamos atualizando nosso serviço de alertas. Infelizmente, os alertas ficarão fora do ar por algumas semanas. Quando os alertas estiverem de volta, será necessário criar seus alertas novamente.'
        }
    },

    warnings: {
        pretradeDisclosures: {
            cryptos:
                'Ao prosseguir com o comércio de criptomoedas em nossa plataforma, você reconhece que leu, entendeu e aceitou esta declaração de divulgação e concorda com os termos e condições estabelecidos pela ETANA para o comércio de criptomoedas e a gestão de contas.\n\nSe você tiver alguma dúvida ou precisar de mais esclarecimentos sobre esta declaração de divulgação, entre em contato com nossa equipe de suporte.'
        },
        trade: {
            cannotAfford: (t: Partial<TradeRequest>, ac?: AssetClass) => {
                const isDollar = t?.quantityQualifier === 'EvenDollar';
                const q = isDollar ? ac?.formatPrice(t.quantity) : ac?.formatQuantity(t.quantity);

                switch (t.action) {
                    case 'Buy': {
                        const unit = Snex1Portuguese.general.unit(t.securityId, t.quantity)?.toLowerCase();
                        const commonSentence = `Você não tem fundos suficientes para comprar ${q}`;
                        const unitSentence = isDollar ? `de ${QualifiedId.RemovePrefix(t.securityId)}.` : `${unit} de ${QualifiedId.RemovePrefix(t.securityId)}.`;
                        return `${commonSentence} ${unitSentence}`;
                    }
                    case 'Sell': {
                        const units = Snex1Portuguese.general.unit(t.securityId, 2)?.toLowerCase();
                        const unit = Snex1Portuguese.general.unit(t.securityId, t.quantity)?.toLowerCase();
                        const showUnit = isDollar && ac?.family === 'cryptos' ? `${q}` : `${q} ${unit}`;

                        return `Você não tem o suficiente ${units} vender ${showUnit} de ${QualifiedId.RemovePrefix(t.securityId)}.`;
                    }
                    default:
                        return 'Desculpe, você não pode pagar este comércio.';
                }
            },
            shortsNotPermittedWhenLong: (t: Partial<TradeRequest>) => {
                return 'Notamos que você atualmente mantém uma posição longa. Feche sua posição longa se quiser vender a descoberto.';
            },
            shortsNotPermittedWhenAccountNotMargin: (t: Partial<TradeRequest>, ac?: AssetClass) => {
                const cannotAfford = Snex1Portuguese.warnings.trade.cannotAfford(t, ac);
                return `${cannotAfford}\n\nA venda a descoberto de ações não é permitida em uma conta à vista, por favor revise suas posições e modifique seu pedido.`;
            },
            buyExceedsShortsHeld: (t: Partial<TradeRequest>) => {
                return 'Notamos que você atualmente mantém uma posição curta. Por favor, feche sua posição curta se você gostaria de negociar por muito tempo.';
            },
            shortNotPermittedAccountValue: (t: Partial<TradeRequest>) => {
                return 'Você não tem permissão para realizar venda a descoberto em uma conta de margem com patrimônio inferior a $2.000. Por favor, reveja sua ordem ou deposite fundos.';
            },
            invalidQuantity: 'Não oferecemos suporte à negociação deste título com a quantidade especificada, revise sua quantidade.',
            invalidQuantityOptions: 'Não oferecemos suporte à quantidade de opções inserida. Por favor, revise seu pedido.',
            insufficientQuantity: 'A quantidade disponível é insuficiente para autorizar esta solicitação. Para obter mais informações, entre em contato com o Suporte.',
            minimumNotMet: 'Desculpe, seu pedido não atende ao investimento mínimo exigido deste fundos.',
            possibleDuplicate: 'Já existe uma ordem aberta semelhante a esta.',
            orderGreaterThanPosition: 'A ordem é maior que a posição total. Por favor, revise o pedido antes de encaminhar.',
            accountChangedToMargin: 'Tipo de conta alterado para margem.',
            accountChangedToCash: 'Tipo de conta alterado para dinheiro.',
            foreignAcctDomesticFunds: 'A conta selecionada é uma conta estrangeira e não pode negociar fundos mútuos domésticos.',
            optionLevel: 'Seu nível de opção atual não permite que você execute esta negociação.',
            nextDayOrder: 'Este pedido será feito amanhã.',
            continue: 'Tem certeza de que deseja enviar este pedido?',
            optionsLowVolumeWarning: 'Há baixa atividade/interesse neste pedido. Seu pedido pode não ser atendido.',
            isShort:
                'Você está vendendo ações que não possui atualmente. Esta é uma ordem curta. Por favor, confirme se esta é a negociação esperada que você deseja fazer.',
            cutoffTime:
                'Este pedido já passou do prazo limite para o fundo. Este é um pedido para o dia seguinte e receberá o primeiro NAV disponível emitido pelo fundo.',
            acknowledgeTitle: 'Reconhecer e Confirmar'
        }
    },

    footer: {
        global: {
            copyrightLine: [
                [
                    {
                        text: 'Verifique o histórico da StoneX Financial Inc, ou de um de seus profissionais de investimento em'
                    },
                    {
                        text: "FINRA's BrokerCheck",
                        url: 'https://brokercheck.finra.org/'
                    },
                    { text: '.  © ' },
                    { text: new Date().getFullYear().toString() },
                    {
                        text: ' StoneX Group Inc. Todos os direitos reservados. '
                    }
                ]
            ],
            disclosures,
            disclosuresByEnvironement: (env: Environment) => (env === 'pro' ? proDisclosures : disclosures),
            marketDataDisclosure: 'Dados de mercado fornecidos pela Xignite',
            importantDisclosures: 'Informações importantes de divulgação',
            termsOfUse: 'Termos de uso',
            privacy: 'Privacidade',
            systemRequirements: 'Requisitos de sistema',
            openAnAccount: 'Abra uma conta',
            chatWithSupport: 'Converse com o suporte'
        },
        disclosurePdfUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/en-US/DisclosuresFull.pdf'
    },

    mobileUi: {
        bottomBar: {
            summary: 'Resumo',
            cash: 'Caixa',
            browse: 'Navegar',
            messages: 'Mensagens'
        }
    },

    alerts: {
        addCondition: 'Adicionar condição',
        saveAlert: 'Salvar alerta',
        updateAlert: 'Alerta de atualização',
        editExistingAlert: 'Editar alerta existente',
        createAlert: 'Criar novo alerta',
        createAnAlertToSeeItHere: 'Crie um alerta para vê-lo aqui.',
        interpretConditions: (field: 'latestPrice' | 'changePercent' | 'Last', operator: '>' | '>=' | '<' | '<=', valueNumberString: string) => {
            const { fieldLabel, formattedValue } = (() => {
                switch (field) {
                    case 'Last':
                    case 'latestPrice':
                        return {
                            fieldLabel: 'Preço',
                            formattedValue: FormatNumber.toMoney(Number(valueNumberString))
                        };
                    case 'changePercent':
                        return {
                            fieldLabel: 'Alteração percentual de preço',
                            formattedValue: FormatNumber.toPercent(Number(valueNumberString))
                        };
                    default:
                        return {
                            fieldLabel: 'Value',
                            formattedValue: FormatNumber.toCommas(Number(valueNumberString))
                        };
                }
            })();
            const op = (() => {
                switch (operator) {
                    case '>':
                        return 'Maior que';
                    case '>=':
                        return 'Melhor que ou igual a';
                    case '<':
                        return 'Menor que';
                    case '<=':
                        return 'menos que ou igual a';
                    default:
                        return 'atinge';
                }
            })();

            return `${fieldLabel} ${op} ${formattedValue}`;
        },
        priceAlert: 'Alerta de preço',
        youDontHaveAlerts: 'Parece que você não tem nenhum alerta!',
        mutualFundsUnavailable: 'Os alertas não estão disponíveis para fundos',
        futuresUnavailable: 'Alertas indisponíveis para contratos futuros',
        createAlertSheet: {
            selectCondition: 'Selecione uma condição',
            change: 'Variação %',
            latestPrice: 'Último Preço',
            priceAlert: 'Alerta de preço',
            createAlert: 'Criar Alerta',
            updateAlert: 'Atualizar Alerta',
            deleteAlert: 'Excluir alerta',
            alert: 'Alerta',
            updateAlertFor: (symbol: string) => (symbol ? `Update Alert for ${symbol}` : 'Update Alert'),
            target: 'Alvo',
            searchForSecurity: 'Procure um segurança...',
            chooseSecurity: 'Escolha uma segurança',
            greaterThan: 'Maior que',
            greaterThanEqual: 'Melhor que ou igual a',
            lessThan: 'Menor que',
            lessThanEqual: 'Menos que ou igual a',
            alertsTitle: 'Alertas',
            newAlert: 'Novo Alerta',
            searchSecurity: 'Pesquisar',
            createNewAlertRule: 'Criar nova regra de alerta',
            noAlertsYet: 'Nenhum alerta ainda',
            editSymbol: 'Editar símbolo',
            repeatDaily: 'Repita diariamente',
            instructions: {
                createAlertBtn: 'Crie sua primeira regra de alerta com o botão abaixo!',
                lookUpSecurity: 'Procure uma segurança para criar sua primeira regra de alerta!',
                getNotified: 'Seja notificado em tempo real quando o preço de um título mudar.'
            }
        }
    },
    equitiesMarginOnboarding: {
        title: 'Você está adicionando margem à sua conta',
        agreementNotAllowed: 'Pedimos desculpas, parece que não podemos adicionar um acordo de margem a esta conta.',
        contactSupport: 'Se você achar que isso é inesperado, entre em contato com nossa equipe de suporte.',
        byChoosing:
            'Ao optar por adicionar margem à sua conta, você está solicitando autorização para pegar dinheiro emprestado da StoneX usando seus ativos como garantia.',
        understandRisk:
            'É importante que você compreenda totalmente os riscos envolvidos no uso da margem, seja para negociar títulos com margem ou usar o patrimônio da sua conta de margem para outros fins.',
        agreementDescription:
            'O Acordo de Margem é um contrato entre você e o corretor. Este acordo permite que você compre títulos com fundos emprestados. Revise o Acordo de Margem antes de negociar com margem.',
        close: 'Fechar',
        agreeAndContinue: 'Concordar e Continuar',
        reviewAgreement: 'Revisar Acordo de Margem',
        submitted: 'Tudo pronto! Seu Acordo de Margem foi adicionado à sua conta',
        failed: 'Parece que não conseguimos adicionar o Acordo de Margem à sua conta'
    },
    futuresOnboarding: {
        sfi: 'Comerciante de comissão de futuros da SFI',
        stepLabels: [
            'Bem-vindo!',
            'Informações de contato',
            'Informações Pessoais',
            'Informações da conta',
            'Informações sobre emprego',
            'Experiência de Negociação',
            'Afiliações',
            'Informações Regulatórias',
            'Informações Financeiras',
            'Revisar',
            'Contratos',
            'Certificação'
        ],
        submitMessage: 'Por favor, espere enquanto temos sua conta de futuros pronta...',
        submitMessageTitle: 'Criando sua nova conta de futuros',
        successMessage: (account: string) => `Sua conta de futuros (${account}) está sendo criada.`,
        errorMessage: 'Algo deu errado.',
        tryAgain: 'Por favor, tente novamente ou entre em contato conosco e nós vamos resolver as coisas',
        okButton: 'Okey',
        closeButton: 'Fechar',
        accountDelayMessage: 'Parabéns! Sua conta de futuros está sendo processada e aparecerá em breve.',
        openAccount: 'Abra uma conta de futuros',
        openAccountNotAllowed: 'Entre em contato com seu representante para obter assistência',
        openDocumentToEnable: 'Abra o documento para habilitar',
        openAccountComingSoon: 'Abra uma conta de futuros em breve!',
        welcome: (name: string) => `Bem-vindo  ${name}!`,
        hasReachedMax: 'Você já integrou uma conta de Futuros no StoneX One. Clique no botão abaixo para retornar à tela inicial do StoneX One.',
        missingRequiredData: 'Está faltando dados necessários',
        nextButton: 'Próximo',
        returnHome: 'Voltar para casa',
        disabledNextText: 'Por favor, preencha os itens necessários acima antes de continuar',
        submitButton: 'Enviar',
        goBackButton: 'Voltar',
        yesButton: 'Sim',
        noButton: 'Não',
        welcomeSubtitle: 'Vamos te dar uma conta de futuros',
        contactInfo: {
            title: 'Vamos obter suas informações de contato',
            name: 'Nome',
            firstName: 'Nome First Nome',
            lastName: 'Sobrenome',
            email: 'E-mail',
            phone: 'Telefone',
            street: 'Rua',
            streetAddress: 'Endereço da Rua',
            street2: 'Apt#, Prédio#, etc...',
            city: 'Cidade',
            state: 'Estado ou Região',
            country: 'País',
            zip: 'Nada',
            error: 'Deve ter pelo menos 21 anos de idade'
        },
        personalInfo: {
            title: 'Informações Pessoais',
            citizenQuestion: 'Você é um cidadão americano?',
            citizenYes: 'Eu sou um cidadão americano.',
            citizenNo: 'Eu não sou um cidadão americano.',
            ssnQuestion: 'Você tem um número da Previdência Social ou ITIN?',
            ssnYes: 'Eu tenho um número de segurança social ou ITIN',
            ssnNo: 'Eu não tenho um número de segurança social ou ITIN',
            ssn: 'Número da Previdência Social ou ITIN',
            country: 'País de cidadania',
            dob: 'Data de nascimento',
            governmentId: 'Número de identificação emitido pelo governo'
        },
        accountInfo: {
            title: 'Vamos coletar as informações da sua conta',
            accountType: 'Individual',
            accountTypeSubtitle: 'Uma conta padrão com apenas um proprietário',
            sourceOfFunds: 'Fonte de fundos',
            intendedInitialDeposit: 'Depósito inicial pretendido',
            expectedMonthlyTrading: 'Atividade de negociação mensal esperada',
            sourceOfFundsOptions: [
                { label: 'Corretor', value: 'Broker' },
                { label: 'Presente', value: 'Gift' },
                { label: 'Herança', value: 'Inheritance' },
                { label: 'Seguro', value: 'Insurance' },
                { label: 'Acordo Judicial', value: 'Legal Settlement' },
                { label: 'Pensão', value: 'Pension' },
                { label: 'Aposentadoria', value: 'Retirement' },
                { label: 'Venda', value: 'Sale' },
                { label: 'Salário', value: 'Wages' }
            ],
            intendedInitialDepositOptions: [
                { label: '0 - $1,000', value: 0 },
                { label: '$1,001 - $10,000', value: 1000 },
                { label: '$10,001 - $50,000', value: 10000 },
                { label: '$50,001 - $100,000', value: 50000 },
                { label: '$100,001 - $250,000', value: 100000 },
                { label: '$250,001 - $500,000', value: 250000 },
                { label: '$500,001 - $1,000,000', value: 500000 },
                { label: '$1,000,001 - $5,000,000', value: 1000000 },
                { label: '$5,000,000 or more', value: 5000000 }
            ],
            expectedMonthlyTradingOptions: [
                { label: '0 - 100', value: 0 },
                { label: '101 - 1,000', value: 100 },
                { label: '1,001 - 10,000', value: 1000 },
                { label: '10,001 - 50,000', value: 10000 },
                { label: '50,000 or more', value: 50000 }
            ]
        },
        employmentInfo: {
            title: 'Informações sobre emprego',
            status: 'Situação de emprego',
            employedTitle: 'Empregado',
            employedSubtitle: 'Atualmente, trabalho por uma empresa.',
            studentTitle: 'Autônomo',
            studentSubtitle: 'Atualmente sou autônomo.',
            unemployedTitle: 'Outro',
            unemployedSubtitle: 'Eu não estou atualmente empregado e eu não estou aposentado',
            retiredTitle: 'Aposentado',
            retiredSubtitle: 'Estou aposentado.',
            employerAddress: {
                title: 'Endereço do empregador',
                country: 'País',
                street: 'Linha de endereço 1',
                street1: 'Linha de endereço 2',
                city: 'Cidade',
                state: 'Estado',
                zip: 'Nada'
            },
            years: 'Anos com empregador',
            employerDetailsTitle: 'Detalhes do empregador',
            occupationTitle: 'Ocupação',
            name: 'Nome do empregador',
            jobTitle: 'Cargo',
            industryTitle: 'Indústria',
            jobFunctionTitle: 'Função de trabalho',
            primarySourceOfIncomeTitle: 'Qual é sua principal fonte de renda?',
            incomeOptions: [
                { label: 'Corretor', value: 'Broker' },
                { label: 'Presente', value: 'Gift' },
                { label: 'Herança', value: 'Inheritance' },
                { label: 'Seguro', value: 'Insurance' },
                { label: 'Acordo Judicial', value: 'Legal Settlement' },
                { label: 'Pensão', value: 'Pension' },
                { label: 'Aposentadoria', value: 'Retirement' },
                { label: 'Venda', value: 'Sale' },
                { label: 'Salário', value: 'Wages' },
                { label: 'Wages', value: 'Wages' }
            ],
            occupationOptions: [
                {
                    label: 'Accountant/Auditor/Bookeeper',
                    value: 'Contador/Auditor/Bookeeper'
                },
                {
                    label: 'Admin/Office Manager',
                    value: 'Administrador/Gerente de Escritório'
                }
            ],
            industryOptions: [],
            jobFunctionOptions: []
        },
        tradingExperience: {
            title: 'Conte-nos sobre sua experiência de negociação anterior',
            experienceYears: [
                { label: 'menos de 1', value: '0' },
                { label: '1 to 2', value: '1' },
                { label: '2 to 3', value: '2' },
                { label: '3 ou mais', value: '3' }
            ],
            commoditiesTitle: 'Experiência em Negociação de Commodities',
            commoditiesQuestion: 'Você tem experiência de negociação de commodities?',
            commoditiesYears: 'Anos de experiência em commodities',
            securitiesQuestion: 'Você tem experiência em negociação de títulos?',
            securitiesYears: 'Anos de experiência em valores mobiliários',
            otcTitle: 'Experiência em Negociação OTC',
            otcQuestion: 'Você tem experiência de negociação over-the counter (OTC) de negociação?',
            otcYears: 'Anos de experiência em OTC',
            professionalTrader: 'Você se qualifica como profissional conforme definido pelo CME para Market Data?',
            professionalTraderUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/Futures-Market-Data-Subscription-Terms.pdf',
            professionalTraderPdfLabel: 'Termos de assinatura de dados de mercado futuro',
            tradingExperienceTitle: 'Experiência em Negociação',
            doTradingExperience: 'Você tem experiência em negociação?',
            yearsExperience: 'Anos de experiência',
            professionalTraderTitle: 'Trader Profissional',
            commodityExchangeAct: 'Lei de Bolsa de Mercadorias',
            governmentRelations: 'Relações Governamentais',
            bankruptcy: 'Falência',
            legalDisputes: 'Disputas Legais',
            closedCommodityAccounts: 'Contas de Mercadorias Fechadas'
        },
        affiliationsInfo: {
            title: 'Apenas algumas perguntas rápidas',
            politicalOrGovernment:
                'Você ou qualquer membro de sua família próxima é um funcionário político atual em qualquer ramo do governo ou afiliado a uma empresa do governo, além do governo dos EUA?',
            employeeOfStoneX:
                'Você ou algum membro de sua família imediata é funcionário ou está relacionado a um funcionário da Divisão FMC da StoneX Financial Inc. (“FCM”) ou de suas subsidiárias?',
            regulatoryMember: 'Você está registrado na Associação Nacional de Futuros ("NFA") ou em qualquer outro órgão regulador ou membro de uma bolsa?',
            regulatoryBody: 'Órgão regulador',
            registrationNumber: 'Número de inscrição',
            employeeName: 'Nome do funcionário',
            employeeRelationship: 'Relação',
            nfaBody: 'Nome do órgão NFA',
            nfaRegNumber: 'Número de registro NFA'
        },
        regulatoryInfo: {
            title: 'Vamos reunir algumas informações regulatórias',
            registrationExemption: 'Você está operando de acordo com uma isenção de registro sob a Lei de Câmbio de Mercadorias?',

            bankruptcy: 'Você foi objeto de um processo de falência, concordata ou ação similar?',
            disputeOrArbitration: 'Você já esteve em uma ação judicial, arbitral ou reparação relacionada a uma conta de mercadorias?',
            unsatisfiedDebitBalance: 'Você já fechou uma conta com saldo de débito não identificado em uma empresa de commodities?'
        },
        financialInfo: {
            title: 'Informações Financeiras',
            error: 'As finanças não atendem aos requisitos para uma conta futura',
            page2Title: 'Continuação das Informações Financeiras',
            annualRevenue: 'Receita anual de todas as fontes em Dólares Americanos',
            annualRevenueOptions: [
                { label: 'Below $25,000', value: '0' },
                { label: '$25,000 - $50,000', value: '0.025' },
                { label: '$50,000 - $100,000', value: '0.05' },
                { label: '$100,000 - $250,000', value: '0.1' },
                { label: '$250,000 - $500,000', value: '0.25' },
                { label: '$500,000 - $1,000,000', value: '0.5' },
                { label: '$1,000,000 - $5,000,000', value: '1' },
                { label: 'Maior do que $5,000,000', value: '5' }
            ],
            netWorth: 'Patrimônio líquido total em Dólares Americanos',
            netWorthOptions: [
                { label: 'Below $100,000', value: '0' },
                { label: '$100,000 - $500,000', value: '0.1' },
                { label: '$500,000 - $1,000,000', value: '0.5' },
                { label: '$1,000,000 - $5,000,000', value: '1' },
                { label: 'Maior do que $5,000,000', value: '5' }
            ],
            workingCapital: 'Capital de giro disponível em Dólares',
            workingCapitalOptions: [
                { label: 'Below $25,000', value: '0' },
                { label: '$25,000 - $100,000', value: '0.025' },
                { label: '$100,000 - $250,000', value: '0.1' },
                { label: '$250,000 - $500,000', value: '0.25' },
                { label: '$500,000 - $1,000,000', value: '0.5' },
                { label: '$1,000,000 - $5,000,000', value: '1' },
                { label: 'Maior do que $5,000,000', value: '5' }
            ],
            annualRevenueShort: 'Receita anual',
            totalNetWorth: 'Patrimônio líquido total',
            availableWorkingCapital: 'Capital de trabalho disponível'
        },
        review: {
            title: 'Vamos revisar suas informações',
            subTitle: 'Estamos quase terminando! Vamos olhar as respostas que você deu para ter certeza de que tudo está preciso',
            personal: 'Pessoal',
            account: 'Conta',
            funding: 'Financiamento',
            trustedContact: 'Contato de Confiança',
            addTrustedContact: 'Adicionar Contato de Confiança',
            tradingInfo: 'Informações de Negociação',
            regulatoryInfo: 'Informações Regulatórias',
            stoneXAffiliation: 'Afiliação à StoneX',
            nfaAffiliation: 'Afiliação à NFA',
            jobTitle: 'Cargo',
            employer: 'Empregador',
            yearsWithEmployer: 'Anos com o Empregador',
            employment: 'Emprego',
            neitherStoneXAffiliation:
                'Nem você, nem ninguém de sua família imediata, nem ninguém da mesma residência é/são funcionário(s) ou agente(s) da StoneX Group Inc. ou de uma empresa afiliada à StoneX.',
            neitherNfaAffiliation:
                'Nem você, nem ninguém de sua família imediata, nem ninguém da mesma residência é/são empregado(s) ou associado(s) a uma bolsa de valores, uma firma membro de uma bolsa de valores, FINRA ou um distribuidor de títulos municipais.',
            notice: 'Aviso',
            noticeDescription: 'As contas de futuros são criadas sob o Corretor de Comissão de Futuros da SFI.',
            employedStatus: (employedStatus: string) => {
                return [Light('Você está'), Dark(`${employedStatus}`), Light('.')];
            },
            accountOpening: (accountType: string) => {
                return [Light('Você está abrindo uma conta '), Dark(`${accountType}`), Light('.')];
            },
            exemptUnderCommodityExchangeAct: (areNot: string) => {
                areNot = areNot === 'are' ? 'Você está' : 'Você não está';
                return `${areNot} operando de acordo com uma isenção de registro nos termos da Lei de Bolsa de Mercadorias.`;
            },
            relatedToGovernment: (areNot: string) => {
                areNot = areNot === 'are' ? 'é' : 'não é';
                return `Você ou qualquer membro de sua família imediata ${areNot} um funcionário político atual em qualquer ramo do governo ou afiliado a uma empresa de propriedade do governo, exceto o Governo dos Estados Unidos.
                `;
            },
            hasBankruptcy: (haveNot: string) => {
                haveNot = haveNot === 'have' ? 'tem' : 'não tem';
                return `Você ${haveNot} sido objeto de um processo de falência, administração judicial ou ação semelhante.`;
            },
            hasLegalDisputeWithCommodities: (haveNot: string) => {
                haveNot = haveNot === 'have' ? 'teve' : 'não teve';
                return `Você ${haveNot} envolvimento em uma disputa legal, arbitragem ou ação de reparação relacionada a uma conta de commodities.`;
            },
            hasClosedCommodityAccountWithDebitBalance: (haveNot: string) => {
                haveNot = haveNot === 'have' ? 'fechou' : 'não fechou';
                return `Você ${haveNot} uma conta com saldo devedor insatisfeito em uma empresa de commodities.`;
            },
            isProffesionalTrader: (areNot: string) => {
                areNot = areNot === 'are' ? 'é' : 'não é';
                return `Você ${areNot} um trader profissional.`;
            }
        },
        agreements: {
            arbitration: 'Arbitragem',
            accept: 'Eu aceito',
            doNotAccept: 'Eu não aceito',
            acceptArbitration: 'Você aceita o acordo de arbitragem? (Opcional)',
            arbitrationAgreement: 'Declaro e garanto que li o "Acordo de Arbitragem" e entendo suas disposições aqui, e estou fazendo a seguinte escolha:',
            pageTitle: 'Documentos importantes aplicáveis ​​à sua conta',
            titleText:
                'É necessário que você analise cuidadosamente todo o conteúdo desta página e cada um dos documentos necessários abaixo, clicando nos títulos dos documentos abaixo para acessar seu conteúdo. Por favor, leia todos os documentos cuidadosamente antes de completar a seção de Certificação do Cliente.',
            part1Title: 'Parte 1: Declarações de Privacidade',
            part2Title: 'Parte 2: Divulgações de risco de opções de futuros e futuros',
            part3Title: 'Parte 3: Acordos de conta de opções de futuros e futuros',
            part4Title: 'Parte 4: Termos de dados de mercado de opções de futuros e futuros',
            clientCertTitle: 'Certificação do cliente',
            acceptAndContinueText: "Ao clicar no botão 'Aceitar e continuar' abaixo:",
            W9title: 'Certificação do Formulário W-9 do IRS',
            perjuryCertTitle: 'Sob pena de perjúrio, certifico que:',
            perjuryCertItem1: 'Sou cidadão dos EUA ou outra pessoa dos EUA',
            perjuryCertItem2: 'é o meu número de identificação fiscal correto',
            perjuryCertItem3: 'Não estou sujeito à retenção na fonte',
            perjuryCertItem4:
                'O código FATCA inserido neste formulário (se houver) indicando que o beneficiário está isento de relatórios FATCA está correto. (Como uma instituição financeira dos EUA que mantém sua conta nos EUA, não solicitamos um Código FATCA e este item não é aplicável).',
            clientAcknowledgementTitle: 'Reconhecimento do Cliente',
            clientAcknowledgementItem1: [
                [
                    {
                        text: 'Declaro que li e compreendi todos os documentos apresentados neste '
                    },
                    { text: '“Parte 1: Declarações de Privacidade”', weight: 'bold' },
                    {
                        text: ', e ainda represento e garanto que as informações que forneci em conexão com a abertura desta conta são verdadeiras '
                    },
                    {
                        text: 'e correto, e que a Divisão FCM da StoneX Financial Inc. e StoneX Markets LLC, respectivamente, estão contando com o '
                    },
                    {
                        text: 'informações aqui contidas como base para estabelecer uma ou mais contas de Cliente. Eu certifico, represento e garanto que todos '
                    },
                    {
                        text: 'informações, incluindo finanças auditadas ou não auditadas, ou Candidatos que preencham as informações de renda e patrimônio líquido '
                    },
                    {
                        text: 'neste Aplicativo de Conta e todas as outras informações fornecidas são verdadeiras e precisas de acordo com o Anti-Money '
                    },
                    { text: 'Políticas e procedimentos de lavagem. ' }
                ]
            ],
            clientAcknowledgementItem2: [
                [
                    {
                        text: 'Declaro e garanto que li e compreendi todos os documentos apresentados neste '
                    },
                    {
                        text: '“Parte 2: Divulgações de risco de opções de futuros e futuros” ',
                        weight: 'bold'
                    },
                    {
                        text: 'incluindo a Divulgação de Risco de Futuros e Opções Negociadas em Bolsa, '
                    },
                    {
                        text: 'Divulgação de Risco de Opções de Commodities e todo o conteúdo dos documentos no Documento de Divulgações Adicionais.'
                    }
                ]
            ],
            clientAcknowledgementItem3: [
                [
                    {
                        text: 'Declaro e garanto que li e compreendi o '
                    },
                    {
                        text: '“Contrato do cliente de futuros e opções negociadas em bolsa da StoneX Financial Inc.” ',
                        weight: 'bold'
                    },
                    {
                        text: 'em sua totalidade, e concorda em ficar vinculado aos Termos e Condições nele contidos, conforme podem ser alterados de tempos em tempos em'
                    },
                    { text: 'acordo com seus termos.' }
                ]
            ],
            clientAcknowledgementItem4: [
                [
                    {
                        text: 'Declaro e garanto que li e compreendi o '
                    },
                    { text: '“Contrato de Usuário da Plataforma” ', weight: 'bold' },
                    {
                        text: 'em sua totalidade e concordo em estar vinculado aos termos e condições aos quais estou sujeito com relação ao acesso e '
                    },
                    { text: 'caso contrário, usando a Plataforma' }
                ]
            ],
            clientAcknowledgementItem5: [
                [
                    {
                        text: 'ELEIÇÃO DE ARBITRAGEM PARA DISPUTAS RELACIONADAS A CONTAS FUTURAS: ',
                        weight: 'bold'
                    },
                    {
                        text: 'Declaro e garanto que li o “Contrato de Arbitragem” ',
                        weight: 'bold'
                    },
                    {
                        text: 'e entender suas disposições aqui contidas, e estou fazendo a seguinte escolha:',
                        weight: 'bold'
                    }
                ]
            ],
            clientAcknowledgementItem6: [
                [
                    {
                        text: 'As informações a seguir se aplicam aos clientes que optam por transferências eletrônicas via Automated Clearing House (ACH): ',
                        weight: 'bold'
                    },
                    {
                        text: 'Eu autorizo ​​a Divisão FCM da StoneX Financial Inc. (“FCM”) na medida em que o Cliente estabeleça uma conta '
                    },
                    {
                        text: 'com o FCM, para iniciar uma entrada de crédito de pagamento e depósito pré-arranjados (PPD) do consumidor por meio da compensação automatizada '
                    },
                    {
                        text: 'Transferência doméstica (ACH) para minha conta designada, selecionada e autenticada por mim por meio da Plataforma, realizada em um '
                    },
                    {
                        text: 'instituição financeira depositária, e debitar essa conta de tempos em tempos para satisfazer qualquer “Chamada de Margem” ou outro déficit em '
                    },
                    {
                        text: 'minha conta FCM de acordo com o Contrato do Cliente FCM.'
                    },
                    {
                        text: 'Eu reconheço que a origem de transações ACH para minha conta pela FCM deve cumprir as disposições da lei dos EUA. '
                    },
                    {
                        text: 'Reconheço ainda que o valor de todos os débitos executados de acordo com esta autorização pode variar, mas cada débito deve '
                    },
                    {
                        text: 'igual ao valor da “Chamada de Margem” devida ou outro déficit (conforme determinado no Contrato do Cliente FCM) mais quaisquer taxas aplicáveis. '
                    }
                ]
            ],
            clientAcknowledgementItem7: [
                [
                    { text: 'Declaro e garanto que li e compreendi o' },
                    { text: '“Termos de assinatura de dados do mercado futuro”', weight: 'bold' },
                    { text: 'em sua totalidade e concordo em estar vinculado aos termos e condições aos quais estou sujeito com relação ao acesso e' },
                    { text: 'caso contrário, usando a Plataforma' }
                ]
            ],
            arbitrationAcceptText: 'Aceito a cláusula de arbitragem',
            arbitrationDeclineText: 'Eu recuso a cláusula de arbitragem',
            IRSnote: 'O IRS não exige o seu consentimento para qualquer disposição deste documento além das certificações necessárias para evitar a retenção na fonte.',
            acceptAndContinueButton: 'Aceitar e continuar'
        },
        documents: {
            privacyPolicy: 'Política de Privacidade da StoneX e Lei de Privacidade da StoneX',
            antiMoneyLaundering: 'Procedimentos contra lavagem de dinheiro',
            commoditiesFuturesRiskDisclosure: 'Divulgação de Risco da Comissão de Negociação de Futuros de Commodities',
            commoditiesOptionsRiskDisclosure: 'Declaração de divulgação de opções',
            addlDisclosures: 'Documento de divulgações adicionais, incluindo o seguinte:',
            subText: {
                nfaRiskDisclosure: 'Divulgação de risco adicional da NFA',
                addlRiskDisclosure: 'Divulgação Adicional de Risco',
                materialConflicts: 'Divulgação de Conflitos de Interesse Relevantes',
                electronicTrading: 'Negociação Eletrônica e Divulgação de Roteamento de Ordens',
                noticeToOmnibus: 'Aviso para contas coletivas sobre restrições de acesso para partes sancionadas',
                underlyingOrSpot: 'Divulgação de produto de moeda virtual subjacente ou à vista',
                clearedSwaps: 'Divulgação de swaps compensados'
            },
            stoneXCustomerAgreement: 'Contrato do cliente de futuros e opções negociadas em bolsa da StoneX Financial Inc.',
            arbitrationAgreement: 'Acordo de Arbitragem',
            platformAgreement: 'Contrato de Usuário da Plataforma',
            virtualCurrencyRiskDisclosure: 'Declaração de Divulgação de Risco de Moeda Virtual',
            marketDataSubscriptionTerms: 'Termos de assinatura de dados do mercado futuro',
            blobStorageUrls: {
                privacyPolicies: 'https://snex1storage.blob.core.windows.net/$web/docs/StoneX-Privacy-Policies.pdf',
                commodityFuturesTradingComissionRiskDisclosure:
                    'https://snex1storage.blob.core.windows.net/$web/docs/Commodity-Futures-Trading-Commission-Risk-Disclosure-202210X.pdf',
                optionsDisclosureStatement: 'https://snex1storage.blob.core.windows.net/$web/docs/Options-Disclosure-Statement-202210X.pdf',
                acknowledgementOfAdditionalDisclosures: 'https://snex1storage.blob.core.windows.net/$web/docs/Acknowledgement-of-Additional-Disclosures-202210X.pdf',
                virtualCurrencyRiskStatements: 'https://snex1storage.blob.core.windows.net/$web/docs/NFA-CFTC-Virtual-Currency-Risk-Statements-202210X.pdf',
                futuresAndExchangeTradedOptionsCustomerAgreement:
                    'https://snex1storage.blob.core.windows.net/$web/docs/Futures-and-Exchange-Traded-Options-Customer-Agreement-SFI_FCM_202210X.pdf',
                platformUserAgreement: 'https://snex1storage.blob.core.windows.net/$web/docs/Platform-User-Agreement-SFI_FCM_202210X.pdf',
                arbitrationAgreement: 'https://snex1storage.blob.core.windows.net/$web/docs/Arbitration-Agreement-SFI_FCM_202210X.pdf',
                futuresMarketDataSubscriptionTerms: 'https://snex1storage.blob.core.windows.net/$web/docs/Futures-Market-Data-Subscription-Terms.pdf'
            }
        },
        submit: {
            title: 'Termos e Acordos'
        }
    },
    mediantSSO: {
        buttonText: 'Ações corporativas'
    },
    equitiesOnboarding: {
        // Look at fieldTranslations
        yes: 'Sim',
        no: 'Não',
        firstName: 'Nome First Nome',
        lastName: 'Sobrenome',
        email: 'E-mail',
        continue: 'Continuar',
        showIssues: 'Mostrar problemas',
        are: 'são',
        areNot: 'não são',
        openAccount: 'Abra uma conta de negociação de ações',
        personalInfo1: {
            title: 'Qual é o seu nome e data de nascimento?',
            birthDate: 'Data de nascimento'
        },
        contactInfo: {
            title: 'Como entramos em contato com você?',
            // email
            validationMessage: 'O número de telefone deve ter 10 dígitos'
        },
        personalInfo2: {
            title: 'Sua Cidadania',
            label: 'Você é um cidadão dos Estadus Unidos?',
            yesCitizen: 'Sim, sou cidadão americano',
            noCitizen: 'Não, eu não sou cidadão americano',
            ssnHeader: 'SSN ou CPF deve ter 9 dígitos',
            ssnOr: 'SSN ou ITIN',
            alert: "We're sorry, however StoneX One access is currently only available for US citizens with a social security number.",
            desc: 'O acesso ao StoneX One está atualmente disponível apenas para cidadãos dos Estados Unidos com um número de seguro social.'
        },
        chooseAccount: {
            title: 'Escolha sua conta',
            description: 'Pressione e segure um tipo de conta para obter mais detalhes',
            individual: 'Individual',
            individualDescription:
                'Uma conta tributável com apenas um proprietário, onde você pode investir em ações, títulos, ETFs e fundos mútuos. Você pode solicitar negociação de margem e opções. Esta conta não tem distribuições obrigatórias e você pode depositar e retirar desta conta sempre que desejar.',
            individualForeign: 'Individual Foreign',
            individualForeignDescription:
                'Uma conta com apenas um proprietário, onde você pode investir em ações, títulos, ETFs e fundos mútuos. Você pode solicitar negociação de margem e opções. Esta conta não tem distribuições obrigatórias e você pode depositar e retirar desta conta sempre que desejar.',
            rothIRA: 'Roth IRA',
            rothIRADescription:
                'Uma Conta de Aposentadoria Individual que permite que você contribua com dólares após impostos. Embora não haja benefícios fiscais no ano corrente, suas contribuições e quaisquer ganhos em investimentos são isentos de impostos, e você pode retirá-los sem impostos e multas após os 59 anos e meio e quando a conta estiver aberta por cinco anos.',
            traditionalIRA: 'Traditional IRA',
            traditionalIRADescription:
                'Uma Conta de Aposentadoria Individual que permite a você contribuir com dólares antes dos impostos e quaisquer ganhos em investimentos são impostos diferidos até a retirada durante a aposentadoria. Certos limites de contribuição, penalidades de retirada antecipada e distribuições mínimas exigidas são aplicáveis ​​a esta conta.'
        },
        addressInfo: {
            title: 'Onde você mora?',
            // streetAddress, City, State, Zip are all done in futuresOnboarding
            secondary: 'Apt #, Bldg #, etc.'
        },
        employmentOptions: {
            title: 'Qual é a sua situação profissional?',
            employment: 'Emprego',
            employmentStatus: {
                employed: 'Empregado',
                youAreEmployed: 'empregado',
                unemployed: 'Desempregado',
                youAreUnemployed: 'desempregado',
                student: 'Aluno',
                youAreStudent: 'um estudante',
                retired: 'Aposentado',
                youAreRetired: 'aposentado'
            },
            employmentInfo: {
                title: 'Informação de emprego',
                occupation: 'Ocupação',
                employer: 'Employer',
                jobTitle: 'Empregador',
                jobFunction: 'Job Function',
                industry: 'Função de trabalho',
                employerPhone: 'Telefone do Empregador',
                yearsEmployed: 'Anos empregado'
            },
            employmentAddress: 'Endereço de trabalho',
            occupations: {
                accountingOrAuditing: 'Contabilidade ou Auditoria',
                advertisingOrMarketingSVCE: 'Publicidade ou Marketing (SVCE)',
                civilService: 'Serviço civil',
                it: 'Tecnologia da Informação',
                domesticHelp: 'Ajuda doméstica',
                education: 'Educação',
                trainingEmployment: 'Treinamento e Emprego',
                engineering: 'Engenharia',
                executiveManagement: 'Gestão executiva',
                finance: 'Finança',
                insurance: 'Seguro',
                doctor: 'Doutor',
                dental: 'Dental',
                nurse: 'Enfermeiro',
                socialServices: 'Serviços sociais',
                homemaker: 'Dono de casa',
                hospitality: 'Hospitalidade',
                tourism: 'Turismo',
                transport: 'Transporte',
                humanResources: 'Recursos Humanos',
                manufacturing: 'Manufatura',
                military: 'Militares',
                adminSecty: 'Admin. seita',
                customerService: 'Atendimento ao Cliente',
                other: 'Outro',
                salesOrRetail: 'Vendas ou Varejo',
                science: 'Ciência',
                agent: 'Agente',
                banker: 'Banqueiro',
                business: 'Negócios',
                policeFireLaw: 'Polícia, Bombeiros, Lei',
                attorneyJudgeLegal: 'Advogado, Juiz, Jurídico',
                politician: 'Político'
            },
            jobFunction: {
                creditCollections: 'Cobrança de créditos',
                financialPlanning: 'Planejamento financeiro',
                financialAccounting: 'Contabilidade Financeira',
                payroll: 'Folha de pagamento',
                procurement: 'Compras',
                riskManagement: 'Gerenciamento de riscos',
                tax: 'Imposto',
                treasury: 'Tesouraria',
                businessManagement: 'Gestão de negócios',
                adminManager: 'Administrador e Gerente',
                executiveAssistant: 'Assistente executivo',
                officeClerks: 'Escriturários',
                counseling: 'Aconselhamento',
                recruiting: 'Recrutamento',
                training: 'Treinamento',
                applicationsSystems: 'Aplicações e Sistemas',
                businessSystems: 'Sistemas de negócios',
                databaseAdmin: 'Administrador de banco de dados',
                ITArchitecture: 'Arquitetura de TI',
                ITProjectManagement: 'Gerenciamento de Projetos de TI',
                networkServices: 'serviços de rede',
                softwareDevelopment: 'Desenvolvimento de software',
                systemsSupport: 'Suporte de sistemas',
                technicalServices: 'Serviços técnicos',
                webServices: 'Serviços web',
                generalCounsel: 'Conselho Geral',
                legalServices: 'Serviços legais',
                legalSupport: 'Suporte legal',
                audioVisual: 'Audiovisual',
                communications: 'Comunicações',
                designServices: 'Serviços de design',
                marketing: 'Marketing',
                mediaRelations: 'Relações com a mídia',
                publications: 'Publicações',
                writingEditorial: 'Redação e Editoração',
                other: 'Outro'
            },
            industries: {
                accounting: 'Contabilidade',
                advertisingMarketing: 'Publicidade e Marketing',
                aerospaceDefense: 'Aeroespacial e Defesa',
                architectureDesign: 'Arquitetura e Design',
                automotive: 'Automotivo',
                childcare: 'Puericultura',
                communicationsTelecommunications: 'Comunicações e Telecomunicações',
                constructionCarpentryLandscaping: 'Construção e Carpintaria',
                education: 'Educação',
                energy: 'Energia',
                engineering: 'Engenharia',
                financialServices: 'Serviços financeiros',
                gamingCasinoCardClub: 'Jogos, Casino, Clube de cartas',
                governmentPublicAdministration: 'Governo e Administração Pública',
                healthcareMedicalServices: 'Serviços médicos e de saúde',
                hotelHospitality: 'Hotelaria e Hotelaria',
                informationTechnology: 'Tecnologia da Informação',
                insurance: 'Seguro',
                legalServicesPublicSafety: 'Serviços Jurídicos e Segurança Pública',
                manufacturing: 'Manufatura',
                mediaEntertainment: 'Mídia e Entretenimento',
                nonProfitCharity: 'Sem fins lucrativos e de caridade',
                printingPublishing: 'Impressão e publicação',
                realEstate: 'Imobiliária',
                restaurantFoodService: 'Restaurante e Food Service',
                transportation: 'Transporte',
                travel: 'Viagem',
                other: 'Outro'
            }
        },
        funding: {
            title: 'Qual é a sua fonte de financiamento?',
            // Source and estimatedLiqNet already completed in futures onboarding.
            sourceIncome: {
                title: 'Fonte de renda',
                wages: 'Remunerações', // all
                sale: 'Oferta',
                inheritance: 'Herança',
                gift: 'Presente',
                insurance: 'Seguro',
                legalSettlement: 'Acordo Legal',
                pension: 'Pensão',
                retirement: 'Aposentadoria',
                broker: 'Corretor'
            },
            estimatedLiquidNet: {
                title: 'Patrimônio Líquido Estimado',
                a: 'Menos de $50,000', // MoneyOptions reference MoneyOptions.ts
                b: '$50,000 - $99,999',
                c: '$100,000 - $199,999',
                d: '$200,000 - $499,999',
                e: '$500,000 - $999,999',
                f: '$1 milhão - 2,49 milhões',
                g: '$2,5 milhões +'
            }
        },
        trustedContact: {
            title: 'Contato de confiança',
            description: 'Gostaria de adicionar um contato confiável?',
            contactInfo: {
                title: 'Trusted Contact Information',
                relationship: 'Informações de contato confiáveis',
                phoneNumber: 'Número de telefone',
                contactsEmailOpt: 'E-mail do contato (opcional)',
                addAddress: 'Gostaria de adicionar o endereço do contato confiável?'
            },
            addressTitle: 'Endereço de contato confiável'
        },
        stoneXAffiliation: {
            title: 'Afiliação StoneX',
            titleInfo: 'Informações de afiliação da StoneX',
            description:
                'Você é, ou alguém da sua família imediata ou mora na mesma casa, funcionário ou agente do StoneX Group Inc. ou de uma empresa afiliada à StoneX?',
            position: 'Cargo ocupado na StoneX',
            relationToEmployee: 'Relacionamento com funcionário',
            affiliatedEntity: 'Entidade afiliada',
            affiliationName: 'Nome de afiliação da StoneX',
            affiliationRelationship: 'Relação de afiliação',
            relationship: {
                child: 'Criança',
                self: 'Auto',
                spouse: 'Cônjuge',
                other: 'Outro'
            },
            entity: 'Entidade da StoneX afiliada à',
            affiliate: {
                stnx: 'STNX',
                infa: 'INFA',
                sswm: 'SSWM',
                ssia: 'SSIA'
            },
            affiliation: {
                // FirstName
                // LastName
                areYou: 'Você é',
                you: 'Você',
                areOtherUsBrokers: 'Você é afiliado a um corretor registrado nos Estados Unidos que não seja membro do setor?',
                areAccreditedMember: 'Você é um investidor credenciado pelo membro da indústria?',
                areRegisteredAdvisor: 'Você é afiliado a um consultor de investimentos registrado nos Estados Unidos.',
                areOfficerOwnsTenPercent: 'Você é um executivo, diretor ou possui 10% ou mais ações de uma empresa pública.',
                areEmployeeOfStonex: 'Você é um funcionário ou pessoa associada da StoneX Financial Inc. ou de uma de suas empresas de apresentação.',
                areNaturalPersonOrEntityWithoutTaxId: 'Você é uma pessoa física ou jurídica que não possui um identificador fiscal dos Estados Unidos.',
                otherUsBrokers: 'afiliado a um corretor registrado nos Estados Unidos que não seja o membro da indústria.',
                accreditedMember: 'um investidor credenciado pelo membro da indústria.',
                registeredAdvisor: 'afiliado a um consultor de investimentos registrado nos EUA.',
                officerOwnsTenPercent: 'um executivo, diretor ou proprietário de 10% ou mais ações de uma empresa pública.',
                employeeOfStonex: 'um funcionário ou pessoa associada da StoneX Financial Inc. ou uma de suas empresas de apresentação.',
                naturalPersonOrEntityWithoutTaxId: 'uma pessoa física ou jurídica que não possui um identificador fiscal dos Estados Unidos.'
            }
        },
        industryAffiliation: {
            title: 'Afiliação da indústria',
            description:
                'Você é, ou alguém da sua família imediata ou mora na mesma casa, empregado ou associado a uma bolsa de valores, uma firma membro de uma bolsa de valores, FINRA ou um corretor de valores mobiliários municipal?',
            // yes
            // no
            firmName: 'Nome da Empresa',
            sec144: 'Você é, ou alguém da sua família imediata ou que mora na mesma casa, um responsável pela formulação de políticas, diretor, acionista de 10 por cento ou considerado afiliado de uma empresa de capital aberto para fins da Regra 144 da SEC?',
            companyName: 'Nome da Empresa',
            ticker: 'Símbolo da empresa ou CUSIP'
        },
        review: {
            title: 'Análise',
            personal: 'Pessoal',
            // firstName
            // lastName
            // email
            // Phone Number
            countryOfCitizenship: 'País de cidadania', // repeated
            ssnItin: 'SSN / ITIN',
            account: 'Conta',
            accountDescription: 'Você está abrindo um ', // you are opening an
            employment: 'Emprego', // Employment
            employmentDescription: 'Você é', // you are
            funding: 'Financiamento', // Funding
            // sourceofIncome and type (use funding above)
            // estimated net worth and amount (use funding above)
            // trusted contact
            // firstName from sharedTranslations
            // lastName from sharedTranslations
            // phoneNumber
            relationship: 'Relação',
            noStoneXAffiliation: '',
            address: 'Endereço',
            // firmName
            sec144Name: 'Regra SEC 144 Nome da empresa', // SEC Rule 144 Company Name
            sec144Ticker: 'SEC Rule 144 Company Ticker', // SEC Rule 144 Company Ticker
            notPolicyMaker:
                'Nem você, nem ninguém em sua família imediata, nem ninguém que viva na mesma casa é/é um oficial de formulação de políticas, diretor, acionista de 10% ou considerado afiliado de uma empresa de capital aberto para fins da Regra 144 da SEC .',
            notEmployed:
                'Nem você, nem ninguém em sua família imediata, nem ninguém na mesma casa é/são empregados ou associados a uma bolsa de valores, uma firma membro de uma bolsa de valores, FINRA ou um distribuidor municipal de valores mobiliários.'
        },
        agreements: {
            title: 'Adicionar Beneficiário Não-Objetivo',
            description:
                'De acordo com a regulamentação, a StoneX Financial Inc divulga o nome e o endereço do proprietário da conta para as empresas ou emissores de valores mobiliários que detêm. Essas empresas ou emissores solicitam essas informações caso precisem entrar em contato com os acionistas sobre comunicações importantes para os acionistas.',
            label: 'Você está optando por não liberar essas informações para essas empresas e/ou emissores? (Opcional)',
            optIn: 'Opt In - Compartilhe minhas informações conforme necessário',
            optOut: 'Desativar - Não compartilhe minhas informações'
        },
        disclosures: {
            title: 'Divulgações e Acordos',
            reviewAccept: 'Revise e aceite nossas divulgações e acordos',
            reviewDisclosures: 'Revise as divulgações',
            disclosuresAccepted: 'Divulgações aceitas!',
            acceptContinue: 'Aceitar e continuar',
            pressFinishToCreate: 'Pressione Concluir para criar sua conta',
            finish: 'Terminar',
            missingData: 'Parece que ainda faltam alguns dados',
            finishLater: 'Terminar depois'
        }
    },
    equitiesOptionsOnboarding: {
        gotIt: 'Já o tenho',
        whatAreOptions: {
            title: 'O que são opções?',
            atItsCore:
                'Na sua essência, a negociação de opções é como obter um "bilhete" que lhe dá o direito, mas não a obrigação, de comprar ou vender uma ação a um preço específico, dentro de um período de tempo específico',
            analogy: 'Vamos utilizar uma analogia simples:',
            imagine: `Imagine que vê anunciado um concerto da sua banda favorita no próximo mês e não tem a certeza se pode ir. Em vez de comprar o bilhete de imediato, paga uma pequena taxa por uma "opção" de comprar esse bilhete ao preço atual em qualquer altura antes do concerto.`
        },
        financialInformation: {
            title: 'Informações financeiras',
            annualIncome: 'Rendimento anual',
            estNw: 'Estimativa do património',
            estLiquidNw: 'Estimativa do património líquido',
            selectAnnualIncome: 'Selecionar rendimento anual',
            selectEstNw: 'Selecionar património estimado',
            selectLiquidNw: 'Selecionar património líquido estimado'
        },
        investmentExperience: {
            title: 'Experiência de investimento',
            0: '0 Anos',
            1: '0-2 Anos',
            2: '2-5 Anos',
            3: '5+ Anos',
            stocksAndBonds: 'Acções e obrigações',
            mutualFunds: 'Fundos mútuos',
            options: 'Opções',
            annuities: 'Anuidades',
            altInvestments: 'Investimentos alternativos',
            selectNumYears: 'Selecionar o número de anos'
        },
        requestOptionsLevel: {
            title: 'Pedir um nível de opções',
            description:
                'Uma vez que a negociação de opções acarreta diferentes níveis de risco, a negociação de opções está classificada em vários "níveis" ou "escalões". Solicitamos que seja aprovado para um nível de negociação específico com base na sua experiência, situação financeira e compreensão dos riscos associados.'
        },
        riskTolerance: {
            title: 'Tolerância ao risco',
            description: 'Prima longamente cada opção para saber mais',
            conservative: 'Conservador',
            moderate: 'Moderado',
            aggressive: 'Agressivo',
            speculative: 'Especulativo',
            conservativeDesc:
                'Procura obter um rendimento corrente. O crescimento do capital a longo prazo não é um objetivo. Está disposto a aceitar níveis muito baixos de volatilidade e a possível perda de uma parte do capital, mas a preservação do capital continua a ser uma preocupação importante',
            moderateDesc:
                'Procura tanto a valorização do capital como o rendimento atual. Está disposto a aceitar níveis moderados de volatilidade para obter uma valorização do capital ou rendimentos mais elevados e a possível perda de uma parte do capital para atingir o seu objetivo de investimento',
            aggressiveDesc:
                'Procura o crescimento do capital a longo prazo. O rendimento atual não é um objetivo. Está disposto a aceitar níveis muito elevados de volatilidade e a possível perda de uma parte substancial do capital para atingir o seu objetivo de investimento',
            speculativeDesc:
                'Procura ganhos realizados em movimentos de curto prazo nos preços dos títulos. Está disposto a aceitar os níveis mais elevados de volatilidade e a possível perda total de capital que possa ocorrer na tentativa de atingir o seu objetivo de investimento'
        },
        optionsLevel: {
            title: 'Nível de opções',
            description: 'Activámos níveis de opções com base na sua tolerância ao risco',
            level1: {
                title: 'Nível 1',
                action: 'Escrever chamadas cobertas'
            },
            level2: {
                title: 'Nível 2',
                action1: 'Estratégias de nível 1',
                action2: 'Comprar opções de compra e venda'
            },
            level3: {
                title: 'Nível 3',
                action1: 'Estratégias de nível 1-2',
                action2: 'Spreads e straddles'
            },
            level4: {
                title: 'Nível 4',
                action1: 'Estratégias de nível 1-3',
                action2: 'Escrever opções de venda não cobertas',
                action3: 'Escrever opções de venda de acções garantidas por dinheiro'
            },
            level5: {
                title: 'Nível 5',
                action1: 'Estratégias de nível 1-4',
                action2: 'Escrever chamadas não cobertas'
            },
            requiresMargin: 'Necessita de margem',
            addMargin: 'Add margin to your account',
            levelRequiresMargin: 'Nível requer margem',
            level2Margin: 'For trading levels above 2, you must have a margin agreement on file.'
        },
        optionsAgreement: {
            title: 'Acordo de Opções',
            description: 'Antes de submeter a sua candidatura, deve ler atentamente e compreender o seguinte acordo',
            review: 'Rever Acordo de Opções',
            close: 'Fechar'
        },
        review: {
            title: 'Review',
            financialInfo: 'Informação financeira',
            estLiquidNw: 'Estimativa do património líquido líquido',
            netWorth: 'Património líquido',
            income: 'Rendimento',
            experienceInfo: 'Informação sobre a experiência',
            stocksAndBonds: 'Acções e obrigações',
            options: 'Opções',
            mutualFunds: 'Fundos mútuos',
            annuities: 'Anuidades',
            altInvestments: 'Investimentos alternativos',
            optionsInfo: 'Informações sobre opções',
            optionsLevel: 'Nível de opções',
            investmentObjective: 'Objetivo de investimento'
        }
    },
    sharedOnboarding: {
        submissionModal: {
            multiAssetTrading: 'A negociação de vários ativos está chegando.',
            loading: 'Um momento...',
            allDone: 'Tudo feito! Vamos começar a negociar.',
            notAbleToCreateAccount: 'Parece que não conseguimos terminar de criar sua conta.',
            tapAnywhereContinue: 'Toque em qualquer lugar para continuar',
            tapAnywhereForSupport: 'Toque em qualquer lugar para conversar com o suporte',
            applicationPending: 'Sua conta está sendo processada. Enviaremos um e-mail quando sua conta for criada.',
            applicationQueued: 'O aplicativo da sua conta foi colocado na fila. Enviaremos um e-mail quando sua conta for criada.'
        },
        agreementRequired: (agreementName: string) => `${agreementName} deve ser aceito para continuar.`,
        selectAccount: {
            title: (name: string) => `Bem vindo ${name}!`,
            subtitle: 'Vamos obter uma nova conta StoneX One',
            equitiesButtonLabelTitle: 'Ações',
            futuresButtonLabelTitle: 'Futuros',
            equitiesButtonLabelSubtitle: 'Abra uma conta de negociação de ações',
            futuresButtonLabelSubtitle: 'Abra uma conta de negociação de futuros'
        },
        signup: {
            leaveSignup: 'Sair da inscrição',
            confirmCancelSignup: 'Tem certeza de que deseja cancelar sua inscrição?',
            yesCancel: 'Sim, Cancelar',
            noStayHere: 'Não, fique aqui'
        },
        headerTranslations: {
            personal: 'Informação pessoal',
            account: 'Informações da Conta',
            address: 'Informações de endereço',
            employment: 'Informações de emprego',
            funding: 'Informações de Financiamento',
            trustedContact: 'Contato de confiança',
            stonexAffiliation: 'Afiliação StoneX',
            industryAffiliation: 'Afiliação da indústria',
            whichAccount: 'Qual tipo de conta você gostaria de abrir?'
        },
        true: 'Sí',
        false: 'No',
        fieldTranslations: {
            personal: {
                firstName: 'Primeiro nome',
                lastName: 'Sobrenome',
                email: 'E-mail',
                phoneNumber: 'Número de telefone',
                usCitizen: 'Você é um cidadão dos E.U.A.?',
                countryOfCitizenship: 'País de cidadania',
                ssnOrItin: 'Número de Segurança Social ou ITIN'
            },
            account: {
                accountType: 'Tipo de conta'
            },
            address: {
                address: 'Endereço',
                city: 'Endereço',
                state: 'Estado',
                zipCode: 'CEP'
            },
            employment: {
                employmentStatus: 'Situação de emprego',
                occupation: 'Ocupação',
                employer: 'Empregador',
                employerPhoneNumber: 'Número de telefone do empregador',
                jobTitle: 'Cargo',
                industry: 'Indústria',
                jobFunction: 'Função de trabalho',
                employerCountry: 'País do Empregador',
                employerAddress: 'Endereço do empregador',
                employerAddress2: 'Endereço do empregador 2',
                employerCity: 'Cidade empregadora',
                employerState: 'Estado Empregador',
                employerZipCode: 'CEP do empregador'
            },
            funding: {
                sourceOfIncome: 'Fonte de renda',
                estimatedLiquidNetWorth: 'Patrimônio Líquido Estimado'
            },
            trustedContact: {
                addTrustedContact: 'Adicionar contato confiável',
                withTrustedContact: 'Adicionar contato confiável?',
                trustedContactFirstName: 'Nome de contato confiável',
                trustedContactLastName: 'Sobrenome de contato confiável',
                trustedContactEmail: 'E-mail de contato confiável',
                trustedContactPhoneNumber: 'Número de telefone de contato confiável',
                trustedContactRelationship: 'Relacionamento de contato confiável',

                withTrustedContactAddress: 'Adicionar endereço de contato confiável?',
                trustedContactAddress: 'Endereço de contato confiável',
                trustedContactCity: 'Cidade de contato confiável',
                trustedContactState: 'Estado de contato confiável',
                trustedContactZipCode: 'CEP de contato confiável'
            },
            stonexAffiliation: {
                withStonexAffiliation: 'Adicionar afiliação StoneX?',
                stonexAffilFirstName: 'Nome próprio da afiliação da StoneX',
                stonexAffilLastName: 'Sobrenome da afiliação StoneX',
                stonexAffilPosition: 'Posição de afiliação da StoneX',
                stonexAffilEntity: 'Entidade Afiliada StoneX',
                sameAsEmploymentInformation: 'O mesmo que informações de emprego',

                stonexAffilOtherUsBrokers: 'Adicionar afiliação StoneX Outros corretores dos EUA?',
                stonexAffilAccreditedMember: 'Membro Credenciado da Afiliação StoneX',
                stonexAffilWithRegisteredAdvisor: 'Afiliação da StoneX com Consultor Registrado',
                stonexAffilOfficerOwnsTenPercent: 'O oficial de afiliação da StoneX possui dez por cento',
                stonexAffilEmployeeOfStonex: 'Afiliação StoneX Funcionário da StoneX',
                stonexAffilNaturalPersonOrEntityWithoutTaxId: 'Afiliação StoneX Pessoa Física ou Entidade sem CNPJ'
            },
            industryAffiliation: {
                withIndustryAffiliation: 'Adicionar afiliação da indústria?',
                industryAffilFirmName: 'Nome da empresa de afiliação da indústria',
                withIndustryAffiliationRule144: 'Adicionar regra de afiliação da indústria 144?',
                industryAffilRule144CompanyName: 'Regra de afiliação da indústria 144 Nome da empresa',
                industryAffilRule144CompanySymbolOrCusip: 'Regra de afiliação do setor 144 Símbolo da empresa ou CUSIP'
            },
            whichAccount: {
                continueStockTradingApplication: 'Continuar con la solicitud de acciones',
                stockTradingAccount: 'Conta de Negociação de Ações',
                futuresTradingAccount: 'Conta de Negociação de Ações'
            }
        },
        accountOpeningSelection: {
            pageTitle: 'Accounts',
            pageSubtitle: 'Select an account to manage or create a new account',
            margin: 'Margin',
            options: 'Options',
            accountNumber: 'Account Number',
            accountType: 'Type',
            activeApplication: 'Active Application',
            selectedAccountOptions: {
                addMargin: 'Add Margin',
                marginSubLabel: 'Margin gives you access to various option strategies and more.',
                addOptions: 'Add Options',
                optionsSubLabel: 'Options trading allows you to trade contracts if you think the price of an asset will go up or down.',
                continueApplication: 'Continue Application',
                continueApplicationSubLabel: 'Continue your application where you left off.'
            },
            selectionBadge: {
                inProgress: 'In Progress',
                created: 'Created',
                pending: 'Pending'
            }
        }
    },
    futuresGlance: {
        labels: {
            cash: 'Dinheiro',
            netLiq: 'Net Liq',
            openPnl: 'Lucro/Perda Não Realizado',
            realizedPnl: 'Lucro/Perda Realizado',
            buyingPower: 'Poder de compra'
        },
        info: {
            netLiq: 'Valor da conta se liquidada a preços correntes de mercado.',
            openPnl: 'Lucro/perda atual de todas as posições abertas.',
            realizedPnl: 'Este valor é o lucro/perda realizado no dia superior.',
            lastUpdated: 'Para sua conta de Futuros, este é o Valor Líquido de Liquidez da conta.',
            buyingPower:
                'Seu poder de compra é a quantidade total de dinheiro na conta disponível para comprar futuros e opções de futuros mais toda a margem disponível.'
        }
    },
    fplBanner: {
        bannerText: (isSummary?: boolean, theme?: AppliedAppTheme): ShadedTextList => [
            {
                text: isSummary ? 'Parece que uma ou mais das suas contas estão qualificadas para solicitar ' : 'Parece que sua conta está qualificada para solicitar ',
                shade: theme === 'dark' ? 'light' : 'dark'
            },
            {
                text: 'Empréstimos totalmente pagos',
                shade: 'dark',
                color: theme === 'dark' ? null : '#2cb333'
            },
            {
                text: '!',
                shade: theme === 'dark' ? 'light' : 'dark',
                lineBreak: true
            },
            {
                text: 'Clique aqui para saber mais',
                shade: 'dark',
                color: '#2cb333'
            },
            {
                text: 'sobre como transformar ativos ociosos em renda.',
                shade: theme === 'dark' ? 'light' : 'dark'
            }
        ],
        bannerTextMobileSummary: [
            { shade: 'light', text: 'This account is eligible to apply for' },
            { shade: 'dark', text: ' \nFully Paid Lending! ' },
            { shade: 'light', text: 'Tap here to start turning idle assets into income.' }
        ],
        bannerTextMobileAccount: [
            { shade: 'light', text: 'This account is eligible to apply for' },
            { shade: 'dark', text: ' \nFully Paid Lending! ' },
            { shade: 'light', text: 'Tap here to start turning idle assets into income.' }
        ],
        applyNow: 'Aplique agora',
        learnMore: 'Saber mais'
    },
    fplModal: {
        headerText: [
            {
                text: 'Pronto para transformar ativos ociosos em renda?',
                shade: 'dark',
                lineBreak: true
            },
            {
                text: 'Desbloqueie empréstimos de títulos totalmente pagos',
                shade: 'dark'
            }
        ],
        eligibilityText: 'Você precisa de pelo menos US$ 50.000 de patrimônio em uma conta de margem para ser elegível',
        aboutText:
            'O empréstimo de títulos é a prática de emprestar títulos de uma parte para outra. Freqüentemente, essa prática permite que o mutuário venda e assuma uma posição vendida no mercado.',
        revenueStreamText: [
            {
                text: 'Novo fluxo de receita:',
                shade: 'dark',
                lineBreak: true
            },
            {
                text: 'Com o empréstimo de títulos totalmente pagos, você pode aumentar potencialmente os retornos de seu portfólio, apenas emprestando títulos que já possui.',
                shade: 'light'
            }
        ],
        maintainOwnershipText: [
            {
                text: 'Manter a propriedade:',
                shade: 'dark',
                lineBreak: true
            },
            {
                text: 'Você ainda manterá a propriedade total de seus investimentos, o que significa que seus ganhos continuarão a ter desempenho com base nas condições de mercado, e o mutuário pagará todos os dividendos e pagamentos de juros pagos enquanto o título estiver emprestado. Embora possa ser contabilizado como dinheiro em substituição em vez de um dividendo, o que pode ter implicações fiscais.',
                shade: 'light'
            }
        ],
        loansAreSecureText: [
            {
                text: 'Os empréstimos são seguros:',
                shade: 'dark',
                lineBreak: true
            },
            {
                text: 'Enquanto os títulos estão emprestados, o mutuário fornece uma garantia pelo valor total dos títulos que toma emprestado.',
                shade: 'light'
            }
        ],
        howItWorksTitle: 'Como funciona o empréstimo de títulos totalmente pagos',
        howItWorksText:
            'StoneX gerencia o processo de empréstimo, desde a conexão de credores e devedores até a organização da custódia das garantias e a liquidação de taxas após o prazo do empréstimo.',
        bulletPointsText: [
            'O credor obtém um retorno sobre o investimento em dinheiro em um ambiente de taxas positivas',
            'O credor ganha alguns por empréstimos garantidos por dinheiro ou títulos',
            'Os títulos são recuperados mediante solicitação do credor ou venda de títulos pelo credor',
            'O mutuário paga todos os pagamentos de dividendos/juros pagos enquanto o título estiver emprestado',
            'O cliente é garantido no mesmo dia',
            'A garantia é mantida em relação ao valor do ativo emprestado',
            'O Mutuário detém quaisquer direitos de voto associados ao título emprestado'
        ],
        invitedToFpl: `Estás invitado a préstamos totalmente pagados`,
        fullyPaidLending: 'Préstamos totalmente pagados',
        maximizeProfits: `Maximice las ganancias potenciales prestando sus acciones con préstamos de valores totalmente pagados: convierta los activos inactivos en ingresos.`,
        addFunds: 'Añadir fondos'
    },
    fplProfile: {
        applyForFPL: 'Inscreva-se para empréstimo de títulos totalmente pagos',
        fplProfileLink: 'Empréstimo totalmente pago',
        fplProfileBannerText: (theme?: AppliedAppTheme) => [
            {
                text: 'Maximize os lucros potenciais emprestando suas ações com',
                shade: theme === 'dark' ? 'light' : 'dark'
            },
            {
                text: 'Empréstimo de títulos totalmente pagos',
                shade: theme === 'dark' ? 'dark' : null,
                color: theme === 'dark' ? null : '#2cb333'
            },
            {
                text: '!',
                shade: theme === 'dark' ? 'light' : 'dark'
            }
        ],
        learnMore: 'Learn More',
        pending: {
            header: 'Empréstimo Integralmente Pago - Pendente',
            body: 'Aplicação submetida'
        },
        enabled: {
            header: 'Empréstimo Integralmente Pago - Habilitado',
            body: `Você habilitou o Empréstimo de Títulos Integralmente Pagos para esta conta.`
        }
    },
    fplOnboarding: {
        welcome: {
            title: 'Candidate-se ao Empréstimo de Valores Mobiliários Totalmente Pagos',
            text: 'Ganhe juros sobre as ações em sua carteira. Mantenha a propriedade / venda suas ações a qualquer momento. Você não poderá votar enquanto as ações estiverem emprestadas. Você precisa ter pelo menos $50.000 de patrimônio em uma Conta de Margem para ser elegível.'
        },
        fplInfo: {
            title: 'Selecione uma conta elegível',
            text: 'A Conta de Margem selecionada deve ter pelo menos $50.000 de patrimônio'
        },
        agreements: {
            title: 'Acordos Importantes Aplicáveis à Sua Conta',
            requiredClick:
                'É necessário que você revise cuidadosamente todo o conteúdo desta página e de cada um dos documentos necessários abaixo, clicando nos títulos dos documentos para acessar seu conteúdo. Por favor, leia todos os documentos cuidadosamente antes de concluir a seção.',
            subtitle: (): ShadedTextList => [
                { text: 'É necessário que você revise cuidadosamente todo o conteúdo desta página e de cada um dos documentos necessários, clicando nos ' },
                { text: 'títulos dos documentos abaixo para acessar seu conteúdo. Por favor, leia todos os documentos cuidadosamente antes de concluir a seção.' }
            ],
            clientCertTitle: 'Certificação do Cliente',
            loanAgreementText: (): ShadedTextList => [
                { text: 'Ao marcar esta caixa, o Mutuário concorda com os termos e condições estabelecidos aqui, incluindo os seguintes:', lineBreak: 'double' },
                { text: '1. OS VALORES MOBILIÁRIOS EM SUA(S) CONTA(S) DE MARGEM E QUAISQUER VALORES MOBILIÁRIOS PELOS QUAIS VOCÊ NÃO TENHA PAGO INTEGRALMENTE, ' },
                { text: 'JUNTAMENTE COM TODOS OS DIREITOS DE PROPRIEDADE A ELES ASSOCIADOS, PODEM SER EMPRESTADOS À STONEX FINANCIAL OU EMPRESTADOS A OUTROS; E' },
                { text: '2. ESTE ACORDO CONTÉM UMA CLÁUSULA DE ARBITRAGEM PRÉVIA À DISPUTA NO PARÁGRAFO 29, INICIANDO NA PÁGINA 11 DESTE ACORDO.', lineBreak: 'double' },
                { text: 'Determinação da taxa de empréstimo', shade: 'dark', lineBreak: true },
                {
                    text: 'A taxa de empréstimo será uma porcentagem dos novos rendimentos ganhos e recebidos pelo Mutuário para emprestar Valores Mobiliários do Mutuante. '
                },
                {
                    text: 'A taxa de empréstimo acumula diariamente, incluindo a data em que os Valores Mobiliários emprestados são transferidos para o Mutuário e excluindo a data em '
                },
                { text: 'que os Valores Mobiliários emprestados são devolvidos ao Mutuante. A taxa de empréstimo será automaticamente ' },
                { text: 'creditada na Conta do Mutuante mensalmente.' }
            ],
            loanAgreementPDFUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/FPL/SNEX_Fully%20Paid%20Master%20Securities%20Loan%20Agreement%2020231219X.pdf',
            loanAgreementPDFTitle: 'Acordo Mestre de Empréstimo de Valores Mobiliários Totalmente Pagos',
            fplAccountControlAgreementText: (): ShadedTextList => [
                { text: 'Ao marcar esta caixa, o Mutuante concorda com os termos e condições estabelecidos aqui, incluindo os seguintes:', lineBreak: 'double' },
                { text: 'Aviso de Controle Exclusivo', shade: 'dark', lineBreak: true },
                { text: 'O Parte Segura por meio deste (i) notifica o Depositário que a Parte Segura está exercendo controle exclusivo sobre o Colateral ' },
                { text: '(conforme definido no ACA); (ii) instrui o Depositário a parar de cumprir as instruções referentes ao Colateral originadas ' },
                { text: 'pelo Cliente; e (iii) representa e garante ao Depositário que este Aviso de Controle Exclusivo é legal e autorizado ' },
                { text: 'pelo acordo aplicável entre o Cliente e a Parte Segura. O Depositário não tem o dever, obrigação ou autoridade para determinar ' },
                { text: 'se a entrega deste Aviso de Controle Exclusivo pela Parte Segura (ou os termos aqui contidos) é adequada, mesmo que o Cliente ' },
                { text: 'objete ou instrua o Depositário a não honrar este Aviso de Controle Exclusivo.' }
            ],
            fplAccountControlAgreementPDFUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/FPL/SNEX_FPL%20Account%20Control%20Agreement%2020231227X.pdf',
            fplAccountControlAgreementPDFTitle: 'Contrato de controle de conta de empréstimo totalmente pago',
            axosAcknowledgementText:
                'Você receberá um e-mail da Axos em alguns dias para preencher a inscrição via DocuSign. Marque esta caixa para reconhecer que a aprovação da sua conta não pode ser processada sem preencher o formulário Axos que será enviado para o seu endereço de e-mail registrado.'
        },
        disclosures: {
            title: 'Divulgações Importantes Aplicáveis à Sua Conta',
            requiredClick:
                'É necessário que você revise cuidadosamente todo o conteúdo desta página e o documento de divulgações necessário abaixo, clicando no título do documento para acessar seu conteúdo. Por favor, leia todos os documentos cuidadosamente antes de concluir a seção de Divulgações.',
            importantCharAndRiskAgreementPDFUrl:
                'https://snex1storage.blob.core.windows.net/$web/docs/FPL/SNEX_FPL%20Important%20Characteristics%20and%20Risks%20202401117X.pdf',
            subtitle: 'É necessário que você revise todo o conteúdo desta página antes de enviar',
            scrollTitle: 'Características e Riscos Importantes',
            scrollSubtitle: 'Programa de Empréstimo de Valores Mobiliários Totalmente Pagos da StoneX',
            importantCharAndRiskAgreement: 'Acordo de Características e Riscos Importantes do Empréstimo Totalmente Pago',
            importantCharAndRiskAgreementText: (): ShadedTextList => [
                { text: 'Ao marcar esta caixa, o Mutuante reconhece o recebimento do documento de divulgação da StoneX Financial Inc. intitulado' },
                { text: '"PROGRAMA DE EMPRÉSTIMO DE VALORES MOBILIÁRIOS TOTALMENTE PAGOS, CARACTERÍSTICAS E RISCOS IMPORTANTES"' }
            ]
        },
        submitting: {
            title: 'Enviando sua solicitação para aprovação',
            completedTitle: 'Solicitação Enviada',
            loadingMessage: (): ShadedTextList => [{ text: 'Aguarde enquanto enviamos sua solicitação para se inscrever no Empréstimo Totalmente Pago.' }],
            successMessage: (): ShadedTextList => [
                { text: 'Aguarde enquanto enviamos sua solicitação para se inscrever no Empréstimo Totalmente Pago.', shade: 'dark', lineBreak: true },
                { text: 'Tempo estimado de espera para aprovação é de 2 dias úteis', shade: 'light' }
            ],
            axosMessage: 'Fique atento a um e-mail da Axos',
            errorMessage: 'Algo deu errado ao enviar sua solicitação.'
        }
    },
    loggedOutScreen: {
        title: 'Você foi desconectado',
        body: 'Você pode fechar esta janela do navegador ou, se tiver feito logout por engano, pode clicar no botão abaixo para fazer login novamente.'
    },
    mobileFplOnboarding: {
        apply: {
            title: 'Aplicar para Empréstimo de Valores Totalmente Pago',
            account: 'Conta',
            selectAccount: 'Selecionar Conta',
            incomeGenerationTitle: 'Geração de Renda',
            incomeGenerationDesc: 'Gere renda adicional a partir de sua carteira de ações existente sem vender seus ativos.',
            ownershipRetentionTitle: 'Retenção de Propriedade',
            ownershipRetentionDesc: 'Mantenha a propriedade e os possíveis ganhos de capital de suas ações enquanto obtém testes de empréstimo.',
            riskAdjustedReturnsTitle: 'Retornos Ajustados ao Risco',
            riskAdjustedReturnsDesc: 'Melhore os retornos da sua carteira com risco mínimo e sem investimento adicional necessário.',
            votingTitle: 'Votação',
            votingDesc: 'Você não poderá votar enquanto as ações estiverem emprestadas.',
            eligibilityTitle: 'Elegibilidade',
            eligibilityDesc: 'Você precisa de pelo menos $50,000 em patrimônio líquido para ser elegível.'
        },
        disclosures: {
            title: 'Acordos',
            masterSecuritiesLoan: 'Contrato de Empréstimo de Títulos Totalmente Pago',
            lendingAccountControl: 'Contrato de Controle de Conta de Empréstimo Totalmente Pago',
            lendingImportantCharacteristicsAndRisks: 'Contrato de Características e Riscos Importantes de Empréstimo Totalmente Pago',
            reviewAccept: 'Revise e aceite nossas divulgações e acordos',
            reviewDisclosures: 'Revisar divulgações',
            disclosuresAccepted: 'Divulgações aceitas!',
            acceptContinue: 'Aceitar e continuar',
            pressFinishToCreate: 'Pressione Concluir para criar sua conta',
            finish: 'Concluir',
            missingData: 'Parece que ainda estamos faltando alguns dados',
            finishLater: 'Concluir mais tarde'
        },
        flowStage: {
            submittedTitle: 'Enviou sua solicitação para aprovação',
            submittedSubtitle: 'Estimativa de 2 dias úteis para aprovação. Fique de olho em um email da Axos!',
            loadingTitle: 'Por favor, aguarde enquanto preparamos sua conta FPL',
            failedTitle: 'Parece que não conseguimos enviar sua conta para aprovação'
        },
        acknowledgements: {
            title: 'Reconhecimentos',
            message:
                'Você receberá um e-mail da Axos em alguns dias para completar o aplicativo via DocuSign. Ao aceitar abaixo, você reconhece que a aprovação de sua conta não pode ser processada sem completar o aplicativo Axos que será enviado para o seu endereço de e-mail registrado.',
            accept: 'Aceitar reconhecimento'
        }
    },
    mobileNavBar: {
        home: 'Início',
        cash: 'Dinheiro',
        trade: 'Comércio',
        orders: 'Pedidos'
    },
    oneProNetwork: {
        discoverMore: 'Descubra más',
        insights: 'insights',
        latestPosts: 'últimas postagens',
        enableOneProNetwork: 'Habilite uma rede Pro'
    }
};

export const Snex1PortugueseTsxStub = null;

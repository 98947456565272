// @ts-strict-ignore
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { TradeTicket } from '../../TradeTicket/TradeTicket';
import { Flex } from '../..';
import style from './style.module.scss';
import { AnimatedModal } from '../AnimatedModal';
import { Order } from 'phoenix/redux/models';
import { convertOrderToViewModel, getDefaultOrderProps, useTradeTicketViewModel } from 'components/TradeTicket/Store/useTradeTicketViewModel';
import { TradeTicketViewModel } from 'components/TradeTicket/Store/TradeTicketViewModel';
import { useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { GetTradeTicketQuote } from 'components/TradeTicket/Shared/helpers';
import { SetSelectedAccount } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { Typography } from '@mui/material';

interface EditTradeModalProps {
    isOpen: boolean;
    order: Order;
    toggleModal: (nextOpenState: boolean) => void;
    onSuccess?: () => void;
    isDuplicate?: boolean;
}

export const EditTradeModal = ({ isOpen, order, toggleModal, isDuplicate }: EditTradeModalProps): ReactElement => {
    const [orderAgain, setOrderAgain] = useState<boolean | null>(null);
    const assetClass = useAssetClass(order?.symbol);
    const [marketTimeSegment] = useMarketTimeSegmentV2();
    const quote = GetTradeTicketQuote(order?.symbol);
    const lang = useText((s) => s);
    const meta = useSnexStore((s) => s.securities.bySymbol[order?.symbol]?.metadata?.data);
    const initialViewModel = useMemo(() => convertOrderToViewModel({ lang, meta, order }), [lang, meta, order]);
    const viewModel = useTradeTicketViewModel<TradeTicketViewModel>();
    const text = lang;
    const bannerMessage = orderAgain ? text.orders.orderAgain : orderAgain === false ? text.orders.editOrder : '';

    useEffect(() => {
        if (isOpen) {
            setOrderAgain(isDuplicate);
        }
    }, [isOpen, isDuplicate]);

    const {
        checkTradeFieldChanged,
        getViewModel,
        initialLimitPrice,
        initialQuantity,
        initialQuantityQualifier,
        initialStopPrice,
        limitPrice,
        modifyingOrder,
        orderType,
        quantity,
        quantityQualifier,
        stopPrice,
        setViewModel,
        state,
        timeInForce,
        variant
    } = viewModel;

    useEffect(() => {
        if (isOpen) {
            SetSelectedAccount(order?.accountNumber);
            const newVariant = variant === 'option-individual' ? 'option-individual' : 'normal';
            setViewModel({
                ...initialViewModel,
                modifyingOrder: !isDuplicate,
                state: 'input',
                variant: newVariant,
                initialViewModel
            });
        }
    }, [assetClass.family, assetClass.type, initialViewModel, isOpen, order, setViewModel, variant]);

    useEffect(() => {
        if (state === 'input' && (isDuplicate || modifyingOrder)) {
            checkTradeFieldChanged();
        }
    }, [
        state,
        modifyingOrder,
        setViewModel,
        getViewModel,
        initialViewModel,
        initialLimitPrice,
        initialQuantity,
        initialQuantityQualifier,
        initialStopPrice,
        limitPrice,
        orderType,
        quantity,
        quantityQualifier,
        stopPrice,
        timeInForce,
        checkTradeFieldChanged,
        isDuplicate
    ]);

    const handleToggleModal = useCallback(() => {
        setViewModel({
            ...getDefaultOrderProps({ assetClass, marketTimeSegment, quote }),
            modifyingOrder: !isDuplicate,
            hasModifyingOrderChanged: false,
            state: 'input'
        });
        toggleModal(false);
        setOrderAgain(null);
    }, [setViewModel, assetClass, marketTimeSegment, quote, toggleModal]);

    return (
        <AnimatedModal open={isOpen} onClose={handleToggleModal}>
            <Flex center className={style.ticketWrapper}>
                <Typography variant='h4' style={{ marginBottom: '10px', textAlign: 'center' }}>
                    {bannerMessage}
                </Typography>
            </Flex>
            <Flex center className={style.ticketWrapper}>
                <TradeTicket />
            </Flex>
        </AnimatedModal>
    );
};
